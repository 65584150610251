import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import MainLayout from "../Components/MainLayout/MainLayout";
import { getTokenInLocaleStorage } from "../Utils/general";

const PrivateRoute = () => {
  const { authData } = useSelector((state) => state?.authReducer);
  return getTokenInLocaleStorage() &&
    !!authData?.boarded &&
    !!authData?.verified ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : getTokenInLocaleStorage() &&
    Object.values(authData || {})?.length > 0 &&
    (!authData?.boarded || !authData?.verified) ? (
    <Navigate to="/onboarding/intro" />
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
