import React, { useEffect, useState } from "react";
import { Col, Row, Spin, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";
import CardIcon from "../../../../Assets/images/Invoices/your_credit.png";
import EditRoundIcon from "../../../../Assets/Icons/EditRoundIcon";
import "./CreditDebitinfo.scss";
import {
  editAutopayinfo,
  getCardList,
} from "../../../../redux/actions/settingsAction";
const CreditDebitInfo = (props) => {
  const dispatch = useDispatch();
  const { setCurrentStep, setPaymentData } = props;
  const [active, setActive] = useState("");
  const [editId, setEditId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);
  const { cardList } = useSelector((state) => state?.settingsReducer);
  useEffect(() => {
    setCardData([
      ...cardList?.filter((c) => c.payment_method == "Primary"),
      ...cardList?.filter((c) => c.payment_method == "Secondary"),
    ]);
  }, [cardList]);
  const onSave = (id) => {
    let data = {
      autopay: active === "Enable",
    };
    setLoading(true);
    dispatch(
      editAutopayinfo(
        id,
        data,
        (res) => {
          dispatch(
            getCardList(() => {
              setLoading(false);
              setActive("");
              setEditId("");
              message.success(res, 2);
            })
          );
        },
        (error) => {
          setLoading(false);
          message.success(error, 2);
        }
      )
    );
  };
  return (
    <div className="bank-account-details-wrap">
      <Spin spinning={loading}>
        <div className="btn-wrap">
          <button
            className="bank-card-button"
            onClick={() => {
              setCurrentStep(4);
            }}
          >
            <img className="bank-card-img" src={CardIcon} />
            <span className="bank-card-text">Add Credit/Debit card</span>
          </button>
        </div>
        {cardData &&
          cardData?.map((data, index) => {
            return (
              <>
                <div className="bank-detail-headding">
                  <div className="headding-bank">
                    {index + 1}.Credit/Debit Card Info
                    <Button
                      onClick={() => {
                        setCurrentStep(6);
                        setPaymentData({ type: "card", ...data });
                      }}
                    >
                      Pay
                    </Button>
                  </div>
                </div>
                <Row className="bank-desc-wrapper" gutter={[32, 50]}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Payment Method</h6>

                      <span className="bank-desc-text">
                        {data?.payment_method === null
                          ? "N/A"
                          : data?.payment_method}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Name on card</h6>

                      <span className="bank-desc-text">
                        {data?.source?.billing_details?.name === null
                          ? "N/A"
                          : data?.source?.billing_details?.name}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Card Number</h6>
                      <span className="bank-desc-text">
                        ******{data?.source?.card?.last4}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Expiry Date</h6>
                      <span className="bank-desc-text">
                        {data?.source?.card?.exp_month}/
                        {data?.source?.card?.exp_year}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">ZIP/Postal code</h6>
                      <span className="bank-desc-text">
                        {data?.source?.billing_details?.address?.postal_code ===
                        null
                          ? "N/A"
                          : data?.source?.billing_details?.address?.postal_code}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Autopay</h6>
                      <span className="bank-desc-text">
                        {editId !== data?.id ? (
                          <span className="bank-desc-text">
                            {data?.auto_pay ? "Enable" : "Disable"}
                            <EditRoundIcon
                              onClick={() => {
                                setEditId(data?.id);
                                setActive(
                                  data?.auto_pay ? "Enable" : "Disable"
                                );
                              }}
                            />
                          </span>
                        ) : (
                          <FormGroup>
                            <div className="btn-toggle-wrapper">
                              <div
                                className="btn-group btn-toggle custom-toggle-button custom-switch"
                                data-toggle="buttons"
                              >
                                <button
                                  type="button"
                                  className={
                                    active === "Enable"
                                      ? "btn btn-default active"
                                      : "btn btn-default"
                                  }
                                  name="Enable"
                                  onClick={() => {
                                    setActive("Enable");
                                  }}
                                >
                                  Enable
                                </button>
                                <button
                                  type="button"
                                  className={
                                    active === "Disable"
                                      ? "btn btn-default active"
                                      : "btn btn-default"
                                  }
                                  name="Disable"
                                  onClick={() => {
                                    setActive("Disable");
                                  }}
                                >
                                  Disable
                                </button>
                              </div>
                              <button
                                className="save-btn"
                                onClick={() => {
                                  onSave(data?.id);
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </FormGroup>
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>

                {cardList?.length > index + 1 && (
                  <div>
                    <hr
                      style={{
                        background: "#C8C8C8",
                        color: "#C8C8C8",
                        borderColor: "#C8C8C8",
                        height: "3px",
                        margin: "50px 0",
                      }}
                    ></hr>
                  </div>
                )}
              </>
            );
          })}
      </Spin>
    </div>
  );
};

export default CreditDebitInfo;
