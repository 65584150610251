import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getTokenInLocaleStorage } from "../Utils/general";

function OnboardingRoutes() {
  const { authData } = useSelector((state) => state?.authReducer);
  return getTokenInLocaleStorage() &&
    Object.values(authData || {})?.length > 0 &&
    (!authData?.boarded || !authData?.verified) ? (
    <Outlet />
  ) : !getTokenInLocaleStorage() ? (
    <Navigate to="/" />
  ) : (
    <Navigate to="/dashboard" />
  );
}

export default OnboardingRoutes;
