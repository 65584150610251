import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ForgotPassword.scss";
import { useDispatch, useSelector } from "react-redux";
import { handleForgotPassword } from "../../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import LogoHeader from "../../../Components/LogoHeader/LogoHeader";

function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const { loading } = useSelector((state) => state.authReducer);

  const rules = {
    email: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter your email");
          } else if (
            new RegExp(
              "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+(?:.[a-zA-Z0-9-]+)*$"
            ).test(value)
          ) {
            setDisabled(false);
            return Promise.resolve();
          }
          setDisabled(true);
          return Promise.reject("Please enter valid email");
        },
      },
    ],
  };

  const onFinish = (values) => {
    dispatch(handleForgotPassword(values.email, navigate));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="onboard pb-3">
        <LogoHeader />

        <Container className="">
          <Row>
            <Col sm={12}>
              <div className="d-flex justify-content-center align-items-center bodyVh90">
                <div className="forgotPassword-form">
                  <div className="form-header">
                    <h4>Forgot Password</h4>
                    <p className="sub-text">
                      We will send you an email with instructions on how to
                      reset your password.
                    </p>
                  </div>
                  <Form
                    layout="vertical mt-4"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Form.Item
                      label="Email Address"
                      rules={rules.email}
                      name="email"
                    >
                      <Input placeholder="stevejobs@gmail.com" />
                    </Form.Item>

                    <div className="mt-5 btn-yelow">
                      <Button
                        className="btn-primary"
                        type="primary"
                        shape="round"
                        size="large"
                        htmlType="submit"
                        loading={loading}
                        block
                        disabled={disabled}
                      >
                        Send Email
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ForgotPassword;
