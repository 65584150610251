import React, { useEffect, useState } from "react";
import "./UpdateDetails.scss";
import moment from "moment";
import UpdatesIcon from "../../Assets/Icons/UpdatesIcon";
import ArrowBackIcon from "../../Assets/Icons/ArrowBackIcon";
import { useLocation, useNavigate } from "react-router-dom";

function UpdateDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const [updateDetails, setUpdateDetails] = useState();

  useEffect(() => {
    if (location?.state?.update) {
      setUpdateDetails(location?.state?.update);
    } else {
      navigate("/updates");
    }
  }, [location?.state]);

  return (
    <div className="update-details-page-main-wrap">
      <div className="update-details-content">
        <div className="backCon">
          <div className="backIcon" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </div>
          <div className="backText">Back</div>
        </div>
        <div className="detailCardCon">
          <div className="detailHeaderCon">
            <UpdatesIcon />
            <div className="header-right">
              <div className="detailHeaderText">{updateDetails?.name}</div>
              <div className="detailDateText">
                {moment(updateDetails?.created_at).format("MM-DD-YYYY")}
              </div>
            </div>
          </div>
          <div className="detailContentText">{updateDetails?.details}</div>
        </div>
      </div>
    </div>
  );
}

export default UpdateDetail;
