import { Button, Form, Input, message } from "antd";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { handleResetPassword } from "../../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./NewPassword.scss";
import LogoHeader from "../../../Components/LogoHeader/LogoHeader";

const CreatePassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.authReducer);

  const handleSubmit = (values) => {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    const token = searchParams.get("token");
    values["token"] = token;
    if (!token) {
      navigate("/signin");
      message.warn("Not a valid attempt...");
      return;
    }
    dispatch(handleResetPassword(values, navigate));
  };

  return (
    <>
      <div className="onboard pb-3">
        <LogoHeader />
        <Container className="mt-5">
          <Row>
            <Col sm={12}>
              <div className="d-flex justify-content-center">
                <div className="newPassword-form">
                  <div className="form-header">
                    <h4>Create new password</h4>
                    <p className="sub-text">
                      Please select a strong, new password that you have not
                      used before.
                    </p>
                  </div>
                  <Form
                    layout="vertical mt-4"
                    form={form}
                    onFinish={handleSubmit}
                  >
                    <Form.Item
                      name="newPassword"
                      label="Password (must be at least 8 characters)"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        {
                          validator(_, value) {
                            if (
                              !value ||
                              new RegExp(
                                "^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$"
                              ).test(value)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "Please choose a strong password"
                            );
                          },
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder="Enter password" />
                    </Form.Item>

                    <Form.Item
                      name="confPassword"
                      label="Confirm password  (Both passwords must match)"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Both passwords must match")
                            );
                          },
                        }),
                      ]}
                      hasFeedback
                    >
                      <Input.Password placeholder="Enter confirm password" />
                    </Form.Item>

                    <div className="mt-5 btn-yelow">
                      <Button
                        className="btn-primary"
                        type="primary"
                        shape="round"
                        block
                        size="large"
                        htmlType="submit"
                        loading={loading}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CreatePassword;
