import * as React from "react";

function PerkIcon(props) {
  return (
    <svg
      width="197"
      height="162"
      viewBox="0 0 197 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_344_756)">
        <path
          d="M157.94 158.52C157.68 162.22 122.11 162.72 78.5 159.64C34.89 156.55 -0.250022 151.04 0.00997846 147.34C0.269978 143.64 35.84 143.14 79.45 146.23C123.06 149.31 158.2 154.82 157.94 158.52Z"
          fill="#F0F0F0"
        />
        <path
          d="M133.35 6.1L121.38 110.29L43.8799 103.91L59.2199 0L133.35 6.1Z"
          fill="#F24822"
        />
        <path
          d="M133.35 6.1001L133.27 104.82L121.38 110.29L133.35 6.1001Z"
          fill="#F24822"
        />
        <path
          d="M109.56 15.3799C107.93 21.5799 104.32 26.5399 99.6599 28.9499C97.2299 30.2199 94.5299 30.6999 91.9499 30.4899C88.5199 30.2099 85.3199 28.6899 83.3199 26.0999C80.8699 22.9499 80.0999 18.4699 80.9499 12.4399C81.0799 11.5199 81.2299 10.5999 81.3799 9.6699C81.8199 6.9799 82.2499 4.4099 82.1399 1.8999L84.6799 2.1099C84.7499 4.7999 84.3099 7.4799 83.8799 10.0899C83.7399 10.9899 83.5899 11.8999 83.4499 12.7899C82.6999 18.1199 83.3099 21.9599 85.3099 24.5599C88.0499 28.0899 93.9599 29.0599 98.4699 26.7199C102.5 24.6299 105.64 20.2599 107.09 14.7499C107.99 11.2799 108.23 7.6599 108.26 4.0499L110.79 4.2599C110.78 7.9599 110.53 11.7099 109.56 15.3799Z"
          fill="#2A2A7B"
        />
        <path
          d="M80.22 41.9101L72.84 106.18L25.03 102.24L34.49 38.1401L80.22 41.9101Z"
          fill="#8678FA"
        />
        <path
          d="M80.2201 41.9102L81.3301 106.34L72.8401 106.18L80.2201 41.9102Z"
          fill="#2B2981"
        />
        <path
          d="M65.5501 47.6303C64.5401 51.4603 62.3201 54.5103 59.4501 56.0003C57.9501 56.7803 56.2801 57.0803 54.6901 56.9503C52.5701 56.7803 50.6001 55.8403 49.3701 54.2403C47.8601 52.3003 47.3801 49.5403 47.9101 45.8103C47.9901 45.2403 48.0801 44.6703 48.1801 44.1003C48.4501 42.4403 48.7101 40.8603 48.6501 39.3003L50.2201 39.4303C50.2701 41.0903 49.9901 42.7403 49.7301 44.3503C49.6401 44.9103 49.5501 45.4703 49.4701 46.0203C49.0101 49.3103 49.3801 51.6803 50.6201 53.2803C52.3101 55.4603 55.9501 56.0603 58.7401 54.6103C61.2301 53.3203 63.1601 50.6303 64.0601 47.2303C64.6201 45.0903 64.7701 42.8603 64.7801 40.6303L66.3401 40.7603C66.3001 43.0503 66.1401 45.3603 65.5501 47.6303Z"
          fill="#DCE5F2"
        />
        <path
          d="M196.28 17.1902L161.77 14.4902L129.8 110.99L25.2399 102.8L7.40991 27.6602L153.9 38.1102"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M129.8 110.99L131 111.08C136.94 111.55 141.63 113.63 140.44 128.84C139.97 134.78 134.78 139.23 128.83 138.76L25.1299 130.63"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M109.42 146.71C108.99 152.18 113.08 156.97 118.55 157.4C124.02 157.83 128.81 153.74 129.24 148.27C129.67 142.8 125.58 138.01 120.11 137.58C114.64 137.15 109.85 141.23 109.42 146.71Z"
          fill="#1D2582"
        />
        <path
          d="M29.1299 140.41C28.6999 145.88 32.7899 150.67 38.2599 151.1C43.7299 151.53 48.5199 147.44 48.9499 141.97C49.3799 136.5 45.2899 131.71 39.8199 131.28C34.3399 130.85 29.5599 134.94 29.1299 140.41Z"
          fill="#1D2582"
        />
        <path
          d="M123.27 36.4004L114.48 109.1"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M101.91 34.7202L94.6599 107.55"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M80.5598 33.0503L74.8398 106"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M59.2 31.3701L55.02 104.44"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M36.31 29.5801L35.21 102.89"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M146.01 59.8404L12.5898 49.3804"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
        <path
          d="M136.51 86.7701L20.3398 77.6602"
          stroke="#1D2582"
          strokeWidth="3"
          stroke-miterlimit="10"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_344_756">
          <rect width="196.4" height="161.66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PerkIcon;
