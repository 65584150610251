import * as React from "react";

function ReviewIcon(props) {
  return (
    <svg
      width="165"
      height="100"
      viewBox="0 0 165 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_344_823)">
        <path
          d="M153.458 12.9805H21.0875C15.1082 12.9805 10.2593 17.8049 10.2593 23.7539V79.7873C10.2593 85.7363 15.1082 90.5607 21.0875 90.5607H72.0091L81.1296 99.6351L90.2502 90.5607H153.458C159.437 90.5607 164.286 85.7363 164.286 79.7873V23.7587C164.291 17.8049 159.442 12.9805 153.458 12.9805Z"
          fill="white"
          stroke="#020202"
          strokeWidth="2.05"
          stroke-miterlimit="10"
        />
        <path
          d="M39.4073 45.908C45.1653 43.4955 47.8673 36.8956 45.4426 31.1668C43.0178 25.438 36.3844 22.7496 30.6264 25.1621C24.8684 27.5746 22.1663 34.1744 24.5911 39.9032C27.0159 45.632 33.6493 48.3204 39.4073 45.908Z"
          fill="#DAE0FF"
        />
        <path
          d="M58.5925 25.312L60.6672 31.5044L67.2287 31.4557L61.9491 35.3357L64.0286 41.5281L58.6904 37.7309L53.4108 41.6109L55.3876 35.3844L50.0493 31.5872L56.6108 31.5385L58.5925 25.312Z"
          fill="#FAAF3B"
        />
        <path
          d="M79.4953 25.312L81.57 31.5044L88.1315 31.4557L82.8519 35.3357L84.9315 41.5281L79.5932 37.7309L74.3136 41.6109L76.2904 35.3844L70.9521 31.5872L77.5137 31.5385L79.4953 25.312Z"
          fill="#FAAF3B"
        />
        <path
          d="M100.398 25.312L102.473 31.5044L109.034 31.4557L103.755 35.3357L105.834 41.5281L100.491 37.7309L95.2165 41.6109L97.1933 35.3844L91.855 31.5872L98.4165 31.5385L100.398 25.312Z"
          fill="#FAAF3B"
        />
        <path
          d="M121.302 25.312L123.376 31.5044L129.938 31.4557L124.658 35.3357L126.738 41.5281L121.394 37.7309L116.115 41.6109L118.097 35.3844L112.758 31.5872L119.32 31.5385L121.302 25.312Z"
          fill="#FAAF3B"
        />
        <path
          d="M142.204 25.312L144.279 31.5044L150.84 31.4557L145.561 35.3357L147.64 41.5281L142.297 37.7309L137.018 41.6109L138.999 35.3844L133.661 31.5872L140.223 31.5385L142.204 25.312Z"
          fill="#FAAF3B"
        />
        <path
          d="M33.095 51.8145H23.1035V54.4725H33.095V51.8145Z"
          fill="#4E81EE"
        />
        <path
          d="M50.5385 51.8145H35.561V54.4725H50.5385V51.8145Z"
          fill="#4E81EE"
        />
        <path
          d="M44.6523 59.7012H23.1035V62.3592H44.6523V59.7012Z"
          fill="#4E81EE"
        />
        <path
          d="M103.853 59.7012H53.4351V62.3592H103.853V59.7012Z"
          fill="#4E81EE"
        />
        <path
          d="M151.442 59.7012H112.636V62.3592H151.442V59.7012Z"
          fill="#4E81EE"
        />
        <path
          d="M71.2801 68.1572H23.1035V70.8153H71.2801V68.1572Z"
          fill="#4E81EE"
        />
        <path
          d="M151.442 68.1572H127.364V70.8153H151.442V68.1572Z"
          fill="#4E81EE"
        />
        <path
          d="M121.703 68.1572H79.4956V70.8153H121.703V68.1572Z"
          fill="#4E81EE"
        />
        <path
          d="M84.5891 76.6133H23.1035V79.2713H84.5891V76.6133Z"
          fill="#4E81EE"
        />
      </g>
      <g clipPath="url(#clip1_344_823)">
        <path
          d="M50.0005 25.0442C50.0054 38.8209 38.7846 50.0295 25.0791 50.0001C11.1623 49.9707 1.49818 39.3503 0.196861 28.1416C-0.66741 20.7215 1.32631 14.1199 6.2173 8.52291C11.1525 2.88185 17.5265 -0.0146473 25.0349 5.5696e-05C38.6619 0.0245607 50.0791 11.0469 50.0005 25.0442Z"
          fill="#4E81EE"
        />
        <path
          d="M28.5063 34.3806C26.3014 34.3806 24.0965 34.3855 21.8917 34.3806C21.1011 34.3806 20.8064 34.1257 20.8162 33.3317C20.8457 29.901 20.6591 26.4605 20.9144 23.0347C21.0765 20.8293 21.9506 18.8591 23.3157 17.1094C24.2389 15.9283 25.2063 14.7814 26.2818 13.7326C26.6304 13.3944 26.8072 13.0122 26.7974 12.5221C26.7876 11.9339 26.8465 11.3458 26.8318 10.7577C26.8121 10.0225 27.0576 9.41482 27.7451 9.13546C28.467 8.8463 29.1692 8.99333 29.7536 9.53735C30.3232 10.0667 30.5442 10.7773 30.7161 11.4977C31.1679 13.3503 30.8192 15.1049 30.0531 16.8104C29.7978 17.379 29.567 17.9622 29.2773 18.516C29.0907 18.8787 29.2331 19.0061 29.5572 19.06C29.7241 19.0845 29.896 19.0747 30.0679 19.0747C32.0469 19.0698 34.0209 19.0649 35.9999 19.06C37.0753 19.06 37.8119 19.5746 38.0673 20.496C38.3521 21.5252 37.9396 22.7113 37.0704 23.172C36.6776 23.3827 36.6923 23.4709 37.0312 23.7062C37.753 24.211 38.2343 24.853 38.0869 25.8136C37.974 26.5389 37.6155 27.0339 36.9035 27.2643C36.7709 27.3084 36.5597 27.2937 36.5352 27.4701C36.5057 27.6564 36.7218 27.6858 36.8347 27.7691C37.7825 28.4748 37.9102 30.3617 36.5155 30.9352C36.2454 31.0479 36.1276 31.1116 36.4124 31.3811C37.0262 31.9693 37.0312 32.7191 36.7463 33.4347C36.4664 34.1306 35.8428 34.3463 35.116 34.3463C32.9111 34.3365 30.7063 34.3414 28.5014 34.3414C28.5063 34.361 28.5063 34.3708 28.5063 34.3806Z"
          fill="#FDFDFE"
        />
        <path
          d="M18.1495 36.3459C16.3276 36.3508 14.5009 36.3557 12.679 36.3655C12.1339 36.3704 11.8835 36.0665 11.8246 35.5715C11.7951 35.3068 11.8098 35.0422 11.8098 34.7775C11.8098 30.9645 11.8098 27.1565 11.8147 23.3435C11.8147 22.4025 12.0505 22.177 13.0031 22.1721C14.6384 22.1672 16.2687 22.1672 17.9039 22.1721C18.557 22.177 18.8959 22.4613 18.8959 23.0837C18.9008 27.0829 18.8861 31.0822 18.8762 35.0814C18.8762 35.101 18.8664 35.1157 18.8615 35.1353C18.7584 35.1696 18.7731 35.2039 18.8566 35.2431C18.8861 35.6744 18.8762 36.0861 18.3803 36.2674C18.3655 36.1498 18.3262 36.0175 18.1838 36.0567C18.0218 36.1057 18.1691 36.2478 18.1495 36.3459Z"
          fill="#FDFDFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_344_823">
          <rect
            width="154.546"
            height="87.2729"
            fill="white"
            transform="translate(10 12.7275)"
          />
        </clipPath>
        <clipPath id="clip1_344_823">
          <rect width="50.0001" height="50.0001" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReviewIcon;
