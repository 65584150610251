import api from "../../Utils/api";
import {
  GET_REVIEWS_DETAILS_DATA_INIT,
  GET_REVIEWS_DETAILS_DATA_SUCCESS,
  GET_REVIEWS_DETAILS_DATA_FAIL,
  GET_REVIWS_DATA_INIT,
  GET_REVIWS_DATA_SUCCESS,
  GET_REVIWS_DATA_FAIL,
  PUT_REVIWS_DATA_INIT,
  PUT_REVIWS_DATA_SUCCESS,
  PUT_REVIWS_DATA_FAIL,
} from "./actionTypes";
export const getReviewsData = (page) => {
  return (dispatch) => {
    dispatch({
      type: GET_REVIWS_DATA_INIT,
    });

    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/reviews`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_REVIWS_DATA_SUCCESS,
            payload: res,
            loading: false,
          });
        } else {
          dispatch({
            type: GET_REVIWS_DATA_FAIL,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_REVIWS_DATA_FAIL,
        });
      });
  };
};
export const putReviews = (id) => {
  return async (dispatch) => {
    dispatch({
      type: PUT_REVIWS_DATA_INIT,
    });

    await api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/claimReview/${id}`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: PUT_REVIWS_DATA_SUCCESS,
            payload: res,
            loading: false,
          });
        } else {
          dispatch({
            type: PUT_REVIWS_DATA_FAIL,
          });
        }
        return;
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_REVIWS_DATA_FAIL,
        });
      });
  };
};
// export const getReviews = () => (dispatch) => {
//   api
//     .getWithToken(`${process.env.REACT_APP_API_URL}/client/reviews`)
//     .then((res) => {
//       console.log("res_review",res)
//       dispatch(setReviewsData(res?.data));
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
export const getReviewsDetailData = (id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({
      type: GET_REVIEWS_DETAILS_DATA_INIT,
    });

    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/review/${id}`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_REVIEWS_DETAILS_DATA_SUCCESS,
            payload: res,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: GET_REVIEWS_DETAILS_DATA_FAIL,
          });
          onFail && onFail(res?.error);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_REVIEWS_DETAILS_DATA_FAIL,
        });
        onFail && onFail();
      });
  };
};
