import {
  GET_AGREEMENTS_DATA_INIT,
  GET_AGREEMENTS_DATA_SUCCESS,
  GET_AGREEMENTS_DATA_FAIL,
  GET_AGREEMENT_DETAILS_DATA_INIT,
  GET_AGREEMENT_DETAILS_DATA_SUCCESS,
  GET_AGREEMENT_DETAILS_DATA_FAIL,
  UPDATE_AGREEMENT_DETAILS_DATA_INIT,
  UPDATE_AGREEMENT_DETAILS_DATA_SUCCESS,
  UPDATE_AGREEMENT_DETAILS_DATA_FAIL,
} from "../actions/actionTypes";

const initialState = {
  agreements: [],
  agreementDetails: null,
  loading: false,
};

export const agreementReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AGREEMENTS_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_AGREEMENTS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        agreements: payload?.data,
      };
    }
    case GET_AGREEMENTS_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_AGREEMENT_DETAILS_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_AGREEMENT_DETAILS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        agreementDetails: payload?.data,
      };
    }
    case GET_AGREEMENT_DETAILS_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_AGREEMENT_DETAILS_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case UPDATE_AGREEMENT_DETAILS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        agreementDetails: payload?.data,
      };
    }
    case UPDATE_AGREEMENT_DETAILS_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
