import React from "react";
import { useLocation } from "react-router-dom";
import "./PaymentStep.scss";

function PaymentStep(props) {
  const { paymentdata } = props;
  const location = useLocation();
  const invoice = location?.state?.invoice;

  return (
    <div className="payment-step-main">
      <div className="payment-details-wrapper">
        <div className="balance-and-account-wrapper d-flex flex-column flex-md-row">
          <div className="balance-wrapper">
            <p className="payment-text">Payment</p>
            <h4 className="payment-details">
              ${invoice?.amount || location?.state?.amount}
            </h4>
          </div>
          <div className="account-wrapper">
            <p className="account-text">Account Number</p>
            <h5 className="account-details">
              ************
              {paymentdata?.type == "bank"
                ? paymentdata?.source?.last4
                : paymentdata?.source?.card?.last4}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentStep;
