import React, { useEffect } from "react";
import "./SupportHome.scss";
import supportHomeIcon from "../../../../src/Assets/images/Support/support_home_icon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { getSupportData } from "../../../redux/actions/supportActions";
import NoScheduleMeeting from "../../NoScheduleMeeting/NoScheduleMeeting";
function SupportHome(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { supportName, loading } = useSelector(
    (state) => state?.supportReducer
  );

  useEffect(() => {
    dispatch(getSupportData());
  }, []);
  if (loading) {
    return (
      <div className="support-home-main">
        <Spin />
      </div>
    );
  }
  return (
    <>
      {supportName ? (
        <div className="support-home-main">
          <div className="support-home-wrapper d-flex flex-column justify-content-center align-items-center">
            <img
              className="img-fluid"
              src={supportHomeIcon}
              alt="supportHomeIcon"
            />
            <h2 className="support-home-client-name">Hello, {supportName}!</h2>
            <h4 className="support-home-client-posiction">
              {supportName?.charAt(0)?.toUpperCase() + supportName?.slice(1)} is
              your dedicated Project Manager.
            </h4>
            <p className="support-home-text">
              {supportName?.charAt(0)?.toUpperCase() + supportName?.slice(1)} is
              here to discuss updates, questions, or any issues you may have.{" "}
              {supportName?.charAt(0)?.toUpperCase() + supportName?.slice(1)}{" "}
              will always try to get back to you as soon as possible within 48
              business hours.
            </p>
            <button
              className="support-home-button"
              onClick={() =>
                navigate("/support/support-chat", { state: supportName })
              }
            >
              Chat now
            </button>
          </div>
        </div>
      ) : (
        <NoScheduleMeeting page="support" />
      )}
    </>
  );
}

export default SupportHome;
