import React, { useState } from "react";

export default function OnboardingStepper({ activeElement }) {
  const [items] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
  return (
    <>
      <div className="step-form mt-3">
        <div className="step-form--hanger">
          <ul>
            {items.map((item, index) => (
              <li key={index}>
                <a
                  className={
                    item % 2 === 0
                      ? activeElement >= item
                        ? "bulb-even bulb-even-active"
                        : "bulb-even"
                      : activeElement >= item
                      ? "bulb-odd bulb-odd-active"
                      : "bulb-odd"
                  }
                  href="#"
                >
                  <span>{item}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
