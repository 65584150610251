import React, { useState, useEffect } from "react";
import "./Profile.scss";
import profileIcon from "../../Assets/images/MainLayout/profile-icon.png";
import profileImage from "../../Assets/images/Profile/user.png";
import {
  CameraOutlined,
  MailOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { message, Spin, Upload, Select } from "antd";
import api from "../../Utils/api";
import {
  setProfileData,
  uploadFile,
  getProfileData,
} from "../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import moment from "moment-timezone";
const Profile = () => {
  const [loader, setLoader] = useState(false);
  const timeZones = moment.tz.names();
  const [timezone, setTimezone] = useState("");
  const [profileURL, setProfileURL] = useState("");
  const { profileData } = useSelector((state) => state?.dashboardReducer);
  const dispatch = useDispatch();
  const uploadProfilePic = ({ file }) => {
    setLoader(true);
    api
      .getWithToken(
        `${process.env.REACT_APP_API_URL}/client/getPresignedUrl?key_type=client_profile&file_name=${file.name}`
      )
      .then((data) => {
        setProfileURL(data?.data?.fields?.key);
        setLoader(false);
        updateProfile({ profile_image: data?.data?.fields?.key });
        uploadFile(file, data?.data?.fields, data?.data?.url);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  useEffect(() => {
    dispatch(getProfileData());
  }, []);
  const updateProfile = (bodyFormData) => {
    api
      .putWithToken(
        `${process.env.REACT_APP_API_URL}/client/profile`,
        bodyFormData
      )
      .then((res) => {
        setLoader(false);
        if (res.result) {
          dispatch(
            setProfileData({
              ...profileData,
              profile_image: res?.data?.profile_image,
              time_zone: res?.data?.time_zone,
            })
          );

          setLoader(false);
        } else {
          message.error(res.error);
          setLoader(false);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    profileData?.time_zone && setTimezone(profileData?.time_zone);
  }, [profileData?.time_zone]);

  const updateTimezone = (timezone) => {
    setTimezone(timezone);
    let payload = { time_zone: timezone };
    updateProfile(payload);
  };

  return (
    <div className="profile-main-wrapper">
      <div className="d-flex align-items-center profile-head-wrapper">
        <img src={profileIcon} alt="profileIcon" />
        <h5 className="mb-0 profile-head">Profile</h5>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center profile-wrapper">
        <div className="profile-img-wrapper">
          {loader ? (
            <Spin className="profilePicLoader" />
          ) : profileURL ? (
            <img
              src={
                profileURL
                  ? process.env.REACT_APP_IMAGE_URL + profileURL
                  : profileImage
              }
              className="userProfileImg"
            />
          ) : (
            <img
              src={
                profileData?.profile_image
                  ? process.env.REACT_APP_IMAGE_URL + profileData?.profile_image
                  : profileImage
              }
              className="userProfileImg"
            />
          )}

          <ImgCrop rotate>
            <Upload
              id="myFileInput"
              style={{ display: "none" }}
              showUploadList={false}
              customRequest={(e) => uploadProfilePic(e)}
              accept=".png, .jpeg, .jpg"
            >
              <CameraOutlined className="profile-camera-img" />
            </Upload>
          </ImgCrop>
        </div>

        <h5 className="profile-name">
          {profileData?.first_name} {profileData?.last_name}
        </h5>

        <div className="profile-info-wrap">
          <div className="d-flex align-items-center profile-email-wrapper">
            <div className="d-flex align-items-center justify-content-center email-circle">
              <MailOutlined />
            </div>
            <div className="d-flex flex-column email-wrapper">
              <span className="email-lable">email address</span>
              <span className="email-text">{profileData?.email}</span>
            </div>
          </div>

          <div className="d-flex align-items-center profile-time-wrapper">
            <div className="d-flex align-items-center justify-content-center time-circle">
              <GlobalOutlined />
            </div>
            <div className="d-flex flex-column time-wrapper">
              <span className="time-lable">time zone</span>

              <Select
                className="time-zone-selector"
                name="time_zone"
                id="time_zone"
                onChange={(value) => updateTimezone(value)}
                value={timezone || ""}
              >
                <option value="">Select time-zone</option>
                {timeZones?.map((time_zone) => {
                  return (
                    <option value={time_zone}>{`${
                      time_zone.split("/")[time_zone.split("/").length - 1]
                    } (GMT${moment.tz(time_zone).format("Z")})`}</option>
                  );
                })}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
