import React, { useEffect, useState } from "react";
import "./EditTargetDate.scss";
import { useNavigate } from "react-router-dom";
import { Button, DatePicker, Form, Space } from "antd";
import YesIcon from "../../Assets/Icons/YesIcon";
import NoIcon from "../../Assets/Icons/NoIcon";
import moment from "moment";
import { updateProjectData } from "../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";

function EditTargetDate() {
  const navigate = useNavigate();
  const [appLive, isAppLive] = useState(null);
  const [targetDate, setTargetDate] = useState(null);
  const [error, setError] = useState("");
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );
  const dispatch = useDispatch();
  useEffect(() => {
    isAppLive(projectData?.needed_live);
    if (projectData?.target_date) {
      setTargetDate(moment(projectData?.target_date));
    }
  }, [projectData]);
  const onSend = () => {
    let payload = {};
    if (appLive) {
      payload = {
        ...projectData,
        needed_live: appLive,
        target_date: targetDate && targetDate.format("YYYY-MM-DD"),
      };
    } else {
      setTargetDate(null);
      payload = { ...projectData, needed_live: appLive, target_date: null };
    }
    dispatch(updateProjectData(payload, navigate));
  };
  return (
    <div className="editTargetDate-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">Edit target date</div>
                <div className="projectTypeCon">
                  <div
                    className={`projectTypeItem ${
                      appLive === true && "selectedProjectTypeItem"
                    }`}
                    onClick={() => isAppLive(true)}
                  >
                    <YesIcon />
                    <div className="projectTypeText">Yes</div>
                  </div>
                  <div
                    className={`projectTypeItem ${
                      appLive === false && "selectedProjectTypeItem"
                    }`}
                    onClick={() => isAppLive(false)}
                  >
                    <NoIcon />
                    <div className="projectTypeText">No</div>
                  </div>
                </div>
                {appLive === true && (
                  <>
                    <div className="TargetDate">
                      <Form layout="vertical">
                        <Form.Item name="Target Date" label="Target Date">
                          <DatePicker
                            placeholder="Please select target date"
                            disabledDate={(current) =>
                              current.isBefore(moment().subtract(1, "day"))
                            }
                            defaultValue={targetDate && targetDate}
                            onChange={(date) => {
                              setTargetDate(date);
                              date && setError("");
                            }}
                            value={targetDate}
                          />
                        </Form.Item>
                      </Form>
                    </div>
                    <p className="text-danger">{error}</p>
                  </>
                )}
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() => {
                        targetDate === null && appLive
                          ? setError("Please select target date")
                          : onSend();
                      }}
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditTargetDate;
