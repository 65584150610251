import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import IMAGES from "../../../Assets/images/Onboarding";
import LogoHeader from "../../../Components/LogoHeader/LogoHeader";
import "./PasswordSuccess.scss";

function PasswordSuccess() {
  return (
    <>
      <div className="onboard pb-3">
        <LogoHeader />
        <Container className="mt-5">
          <Row>
            <div className="d-flex justify-content-center">
              <Col sm={12} md={4}>
                <div className="password-success">
                  <img
                    className="img-fluid"
                    src={IMAGES.passwordSuccess}
                    alt=""
                  />
                  <div className="title">Password changed successfully!</div>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PasswordSuccess;
