import { DownloadOutlined, CheckOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Row, Col, Spin, Button, Divider, Empty } from "antd";
import agreementsIcon from "../../../src/Assets/images/MainLayout/agreements-icon.png";
import "./Agreements.scss";
import { useDispatch, useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import { getAgreementsData } from "../../redux/actions/agreementAction";
import { useNavigate } from "react-router-dom";
import { convertUTCToTimezone } from "../../Utils/general";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Agreements = (props) => {
  const dispatch = useDispatch();
  const { agreements, loading } = useSelector(
    (state) => state?.agreementReducer
  );
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    dispatch(getAgreementsData(filter));
  }, [filter]);

  return (
    <div className="agreements-page-main-wrap">
      <Spin spinning={loading}>
        <div className="container">
          <div className="sub-header d-flex justify-content-md-between justify-content-center align-items-center flex-md-row flex-column">
            <div className="heading-wrapper d-flex align-items-center">
              <img src={agreementsIcon} alt="" className="agreement-icon" />
              <h4 className="text-capitalize font-weight-bold mb-0">
                agreements
              </h4>
            </div>
            <div className="all-complete-tabs d-flex justify-content-center align-items-center">
              <div
                onClick={() => setFilter("all")}
                className={`tabs d-flex justify-content-center align-items-center ${
                  filter === "all" && "active"
                }`}
              >
                <span className="text-capitalize">all</span>
              </div>
              <div
                onClick={() => setFilter("incomplete")}
                className={`tabs d-flex justify-content-center align-items-center ${
                  filter === "incomplete" && "active"
                }`}
              >
                <span className="text-capitalize">incomplete</span>
              </div>
              <div
                onClick={() => setFilter("complete")}
                className={`tabs d-flex justify-content-center align-items-center ${
                  filter === "complete" && "active"
                }`}
              >
                <span className="text-capitalize">complete</span>
              </div>
            </div>
          </div>
          <Row className="cards-group" gutter={[36, 36]}>
            {agreements?.map((agreement) => {
              return (
                <AgreementCard key={agreement?.id} agreement={agreement} />
              );
            })}
          </Row>
          {!agreements?.length && !loading && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Spin>
    </div>
  );
};

const AgreementCard = ({ agreement }) => {
  const [numPages, setNumPages] = useState(null);
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state?.dashboardReducer);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDownloadAgreement = (link) => {
    link && window.open(`${process.env.REACT_APP_IMAGE_URL}${link}`, "_blank");
  };

  return (
    <Col xl={{ span: 8 }} lg={{ span: 12 }} xs={{ span: 24 }}>
      <div className="card border-0">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5
            className="text-capitalize mb-0"
            role={"button"}
            onClick={() => navigate(`/agreement-details/${agreement?.id}`)}
          >
            {agreement?.name}
          </h5>
          {!!agreement?.agreement_pdf && (
            <span
              onClick={() => onDownloadAgreement(agreement?.agreement_pdf)}
              className="download-icon rounded-circle d-flex justify-content-center align-items-center"
            >
              <DownloadOutlined />
            </span>
          )}
        </div>
        <div className="card-body d-flex justify-content-center align-items-center flex-column">
          <div
            className="pdf-wrapper"
            role={"button"}
            onClick={() => navigate(`/agreement-details/${agreement?.id}`)}
          >
            {agreement?.agreement_pdf ? (
              <Document
                file={`${process.env.REACT_APP_IMAGE_URL}${agreement?.agreement_pdf}`}
                onLoadSuccess={onDocumentLoadSuccess}
                className="agreement-details overflow-auto h-100 w-100"
              >
                {Array.from({ length: numPages }, (v, i) => i)?.map((item) => (
                  <Page pageIndex={item} />
                ))}
              </Document>
            ) : (
              <div className="agreement-details overflow-auto h-100 w-100">
                <div dangerouslySetInnerHTML={{ __html: agreement?.details }} />
              </div>
            )}
          </div>
          <Divider />
          {!agreement?.completed ? (
            <>
              <span className="issue-date">
                Issue date:{" "}
                {convertUTCToTimezone(
                  agreement?.created_at,
                  true,
                  profileData?.time_zone ? profileData?.time_zone : "",
                  "MM-DD-YYYY"
                )}{" "}
              </span>
              <Button
                onClick={() => navigate(`/agreement-details/${agreement?.id}`)}
                className="btn text-capitalize d-flex justify-content-center align-items-center"
              >
                review and agree
              </Button>
            </>
          ) : (
            <>
              <span className="issue-date">
                Issue date:{" "}
                {convertUTCToTimezone(
                  agreement?.created_at,
                  true,
                  profileData?.time_zone ? profileData?.time_zone : "",
                  "MM-DD-YYYY"
                )}{" "}
              </span>
              <span className="completion-date text-center">
                Completion Date:{" "}
                {convertUTCToTimezone(
                  agreement?.completed_date,
                  true,
                  profileData?.time_zone ? profileData?.time_zone : "",
                  "MM-DD-YYYY (h:mm a)"
                )}
              </span>
              <span className="check-icon d-flex justify-content-center align-items-center rounded-circle">
                <CheckOutlined />
              </span>
            </>
          )}
        </div>
      </div>
    </Col>
  );
};

export default Agreements;
