import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Input, Col, Row, Spin, message } from "antd";
import { FormGroup } from "reactstrap";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCardList,
  addCreditDebitCard,
} from "../../../redux/actions/settingsAction";
import "./AddBankAccount.scss";
const AddCreditDebitCard = (props) => {
  const { setCurrentPaymentSection, setAdd } = props;
  const [active, setActive] = useState("primary");
  const [data, setData] = useState({ autopay: false });
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (onSubmitValidation()) {
      if (elements == null) {
        return;
      }
      setLoading(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            postal_code: data?.postal_code,
          },
          name: data?.name,
        },
      });
      if (error) {
        setLoading(false);
        message.error(error?.message, 2);
        return;
      }
      if (paymentMethod) {
        dispatch(
          addCreditDebitCard(
            data,
            paymentMethod?.id,
            () => {
              dispatch(
                getCardList(
                  () => {
                    message.success("Successfully Credit/Debit Card Added", 2);
                    setAdd(false);
                    setCurrentPaymentSection(3);
                    setLoading(false);
                  },
                  () => {
                    setLoading(false);
                  }
                )
              );
            },
            (err) => {
              message.error(err, 2);
              setLoading(false);
            }
          )
        );
      }
    }
  };
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const onSubmitValidation = () => {
    let postalCoderegx = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if (!data?.name) {
      message.error("Please enter name of card", 2);
      return false;
    } else if (!new RegExp("^[A-Za-z]( ?[A-Za-z0-9] ?)*$").test(data?.name)) {
      message.error("Please enter valid name of card", 2);
      return false;
    } else if (!data?.postal_code) {
      message.error("Please enter ZIP/Postal code", 2);
      return false;
    } else if (!postalCoderegx.test(data?.postal_code)) {
      message.error("Please enter valid ZIP/Postal code", 2);
      return false;
    }
    return true;
  };
  return (
    <form onSubmit={handleSubmit}>
      <Spin spinning={loading}>
        <div className="add-bank-account-wrapper">
          <div className="bank-account-box">
            <div className="hadeing_title">
              <h2 className="edit-bank-title">Add Credit/Debit Card</h2>
            </div>

            <Row
              className="bank-input-box"
              gutter={[
                { xs: 8, sm: 16, md: 24, lg: 32 },
                { xs: 16, sm: 24, md: 32, lg: 40 },
              ]}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <div className="input-text ">
                  <label className="input-label">Name on card</label>
                  <Input name="name" onChange={handleChange} />
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <div className="input-text">
                  <label className="input-label">Card number</label>
                  <CardNumberElement />
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <div className="input-text">
                  <label className="input-label">Expiry date</label>

                  <CardExpiryElement />
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                <div className="input-text">
                  <label className="input-label">Security code</label>

                  <CardCvcElement />
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
              >
                {" "}
                <div className="input-text">
                  <label className="input-label">ZIP/Postal code</label>

                  <Input name="postal_code" onChange={handleChange} />
                </div>
              </Col>
              <div className="input-text">
                <label className="input-label">Action Type</label>
                <FormGroup>
                  <div className="btn-toggle-wrapper">
                    <div
                      className="btn-group btn-toggle custom-toggle-button custom-switch"
                      data-toggle="buttons"
                    >
                      <button
                        type="button"
                        className={
                          active === "primary"
                            ? "btn btn-default active"
                            : "btn btn-default"
                        }
                        name="primary"
                        onClick={() => {
                          setActive("primary");
                        }}
                      >
                        Primary
                      </button>
                      <button
                        type="button"
                        className={
                          active === "secondary"
                            ? "btn btn-default active"
                            : "btn btn-default"
                        }
                        name="secondary"
                        onClick={() => {
                          setActive("secondary");
                        }}
                      >
                        Secondary
                      </button>
                    </div>
                  </div>
                </FormGroup>
              </div>
              <Col span={24} className="check_box d-flex">
                <label className="con_input">
                  <input
                    type="checkbox"
                    name="autopay"
                    onChange={(e) =>
                      setData({ ...data, autopay: e.target.checked })
                    }
                  />
                  <span class="checkmark"></span>
                </label>

                <p className="checkbox-text">
                  Enable Autopay For Future Invoices
                </p>
              </Col>
              <Col span={24}>
                <button
                  type="submit"
                  disabled={!stripe || !elements}
                  className="btn_banking"
                >
                  Add
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </form>
  );
};
export default AddCreditDebitCard;
