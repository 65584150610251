import React, { useEffect, useState } from "react";
import "./EditWantToStart.scss";
import { useNavigate } from "react-router-dom";
import { Button, Space } from "antd";
import IMAGES from "../../Assets/images/Onboarding";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectData } from "../../redux/actions/dashboardActions";

const EditWantToStart = () => {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState();
  const dispatch = useDispatch();
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );

  const Starting_options = [
    { title: "I want to start ASAP", shortTitle: "ASAP", image: "asap" },
    {
      title: "I want to start within a week",
      shortTitle: "WEEk",
      image: "withinWeek",
    },
    {
      title: "I'll start within a month",
      shortTitle: "MONTH",
      image: "withinMonth",
    },
    {
      title: "I'll start after A few months",
      shortTitle: "LATER",
      image: "fewMonth",
    },
  ];

  useEffect(() => {
    setSelectedType(projectData?.starting_option);
  }, [projectData]);

  return (
    <div className="editWantToStart-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">Edit want to start</div>
                <div className="projectTypeCon">
                  {Starting_options.map((startType) => (
                    <div
                      className={`projectTypeItem ${
                        selectedType === startType.title &&
                        "selectedProjectTypeItem"
                      }`}
                      onClick={() => setSelectedType(startType.title)}
                    >
                      <img src={IMAGES[startType.image]} alt="" />
                      <div className="projectTypeText">{startType.title}</div>
                    </div>
                  ))}
                </div>
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() =>
                        dispatch(
                          updateProjectData(
                            {
                              ...projectData,
                              starting_option: selectedType,
                            },
                            navigate
                          )
                        )
                      }
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditWantToStart;
