import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import "./WantToStart.scss";
import IMAGES from "../../../Assets/images/Onboarding";
import OnboardingStepper from "../../../Components/OnboardingStepper";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveOnboarding,
  setOnboardingData,
} from "../../../redux/actions/onboardingActions";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import SaveAndNext from "../../../Components/Save&Next";
import { useNavigate } from "react-router-dom";

const WantToStart = () => {
  const { payload } = useSelector((state) => state.onboardingReducer);
  const [saveType, setSaveType] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Starting_options = [
    { title: "I want to start ASAP", shortTitle: "ASAP", image: "asap" },
    {
      title: "I want to start within a week",
      shortTitle: "WEEk",
      image: "withinWeek",
    },
    {
      title: "I'll start within a month",
      shortTitle: "MONTH",
      image: "withinMonth",
    },
    {
      title: "I'll start after A few months",
      shortTitle: "LATER",
      image: "fewMonth",
    },
  ];

  const handleChange = (option) => {
    setError({ ...error, startErr: "" });
    dispatch(
      setOnboardingData({ ...payload, starting_option: option.shortTitle })
    );
  };

  useEffect(() => {
    if (saveType === "save&Logout") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
    } else if (saveType === "save&next") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
      navigate("/onboarding/appLive");
    }
  }, [saveType]);

  const validateForm = (clickType) => {
    if (!payload?.starting_option) {
      setError({ ...error, startErr: "Please select starting option" });
    } else if (clickType === "Save&Logout") {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 7,
        })
      );
      setSaveType("save&Logout");
    } else {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 7,
        })
      );
      setSaveType("save&next");
    }
  };
  const onSave_Logout_Fun = () => {
    dispatch(
      setOnboardingData({
        ...payload,
        steps_completed: 6,
      })
    );
    setSaveType("save&Logout");
  };
  return (
    <div className="onboard pb-3">
      <OnboardingHeader steps_completed={6} validateForm={onSave_Logout_Fun} />

      <Container className="mt-5">
        <Row>
          <div className="d-flex justify-content-center">
            <Col sm={10}>
              <OnboardingStepper activeElement={7} />

              <Row>
                <div className="d-flex justify-content-center">
                  <Col sm={12} md={7}>
                    <div className="wantStart mt-5 mb-3">
                      <span className="title">
                        How soon do you <span>want to start?</span>
                      </span>
                      <div className="wantStart-links">
                        <ul>
                          {Starting_options?.map((option, index) => (
                            <li
                              onClick={() => handleChange(option)}
                              key={index}
                            >
                              <a
                                href={null}
                                className={
                                  payload?.starting_option === option.shortTitle
                                    ? "project-active"
                                    : ""
                                }
                              >
                                <img src={IMAGES[option.image]} alt="" />
                                <span>{option.title}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                        <span className="text-danger">{error?.startErr}</span>
                      </div>
                    </div>

                    <SaveAndNext
                      backUrl={"/onboarding/budget"}
                      validateForm={validateForm}
                    />
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default WantToStart;
