import api from "../../Utils/api";
import {
  GET_PERKS_DATA_INIT,
  GET_PERKS_DATA_FAIL,
  GET_PERKS_DATA_SUCCESS,
  GET_PERKS_DETAIL_INIT,
  GET_PERKS_DETAIL_SUCCESS,
  GET_PERKS_DETAIL_FAIL,
} from "../actions/actionTypes";

export const getPerkList = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PERKS_DATA_INIT,
    });

    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/perks`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_PERKS_DATA_SUCCESS,
            payload: res,
          });
        } else {
          dispatch({
            type: GET_PERKS_DATA_FAIL,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PERKS_DATA_FAIL,
        });
      });
  };
};

export const getPerkDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_PERKS_DETAIL_INIT,
    });

    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/perk/${id}`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_PERKS_DETAIL_SUCCESS,
            payload: res,
          });
        } else {
          dispatch({
            type: GET_PERKS_DETAIL_FAIL,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PERKS_DETAIL_FAIL,
        });
      });
  };
};
