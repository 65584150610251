const IMAGES = {
  previcon: require("./arrow-left.svg").default,
  nexticon: require("./arrow-right.svg").default,
  iblogo: require("./Inventive-bite-log.svg").default,
  signupImg: require("./signup-img.svg").default,

  //   onboarding Project Type images
  introo: require("./introo.svg").default,
  android: require("./android.svg").default,
  web: require("./web.svg").default,
  ios: require("./ios.svg").default,
  other: require("./other-devices.svg").default,

  //   onboarding Project Category images
  autoVechicles: require("./auto-vehicles.svg").default,
  artDesign: require("./art-design.svg").default,
  beauty: require("./beauty.svg").default,
  booksRef: require("./books-ref.svg").default,
  business: require("./business.svg").default,
  communication: require("./communication.svg").default,
  dating: require("./dating.svg").default,
  education: require("./education.svg").default,
  entertainment: require("./entertainment.svg").default,
  events: require("./events.svg").default,
  finance: require("./finance.svg").default,
  food: require("./food-drink.svg").default,
  healthFitness: require("./health-fitness.svg").default,
  houseHome: require("./house-home.svg").default,
  lifestyle: require("./lifestyle.svg").default,
  maps: require("./maps.svg").default,
  music: require("./music.svg").default,
  news: require("./news-magazines.svg").default,
  parenting: require("./parenting.svg").default,
  personalization: require("./presonalization.svg").default,
  photography: require("./photography.svg").default,
  productivity: require("./productivity.svg").default,
  shopping: require("./shopping.svg").default,
  social: require("./social.svg").default,
  sports: require("./sports.svg").default,
  tools: require("./tools.svg").default,
  travel: require("./travel-local.svg").default,
  videoPlayer: require("./videoplayer.svg").default,
  weather: require("./weather.svg").default,
  othercategory: require("./other-category.svg").default,

  // Onboarding product development
  strategyDev: require("./strategy-icon.svg").default,
  furtherAlong: require("./further-along-icon.svg").default,

  // Onboarding Featurs
  accountLogin: require("./account.svg").default,
  analytics: require("./analytics.svg").default,
  chat: require("./chat.svg").default,
  connectivity: require("./connectivity.svg").default,
  contacts: require("./contacts.svg").default,
  dataView: require("./data-view.svg").default,
  editor: require("./editor.svg").default,
  email: require("./email.svg").default,
  feeds: require("./feeds.svg").default,
  filterSort: require("./filter.svg").default,
  map1: require("./map.svg").default,
  media: require("./media.svg").default,
  notification: require("./notification.svg").default,
  payment: require("./payment.svg").default,
  profile: require("./profile.svg").default,
  scheduler: require("./scheduler.svg").default,
  scanner: require("./scanner.svg").default,
  storage: require("./storage.svg").default,
  thirdPartyApi: require("./third-party.svg").default,

  // onboard DevelopmentStage
  strategicAdvice: require("./strategicAdvice.svg").default,
  wireframe: require("./wireframe.svg").default,
  thirdPartyApi: require("./upgrade.svg").default,
  upload: require("./upload.svg").default,

  //Onboard Budject
  checkIcon: require("./check-icon.svg").default,
  closeIcon: require("./close-icon.svg").default,

  // //Onboard Idea Details
  ideaDetails: require("./idea-details.svg").default,
  confirmEmail: require("./confim-email.svg").default,

  // //Onboard want to start
  asap: require("./asap.svg").default,
  withinWeek: require("./within-week.svg").default,
  withinMonth: require("./within-month.svg").default,
  fewMonth: require("./few-month.svg").default,

  // ForgotPassword
  passwordSuccess: require("./password-success.svg").default,

  //////// website

  //hero
  heroImage: require("./hero-image.svg").default,
  forbes: require("./forbes.png").default,
  blackStone: require("./blackstone.png").default,
  clutch: require("./clutch.png").default,
  bma: require("./bma.png").default,
  goodfirms: require("./goodfirms.png").default,

  /// our process
  strategy: require("./strategy.svg").default,
  mvp: require("./mvp.svg").default,
  earlyTraction: require("./early-traction.svg").default,
  fundraising: require("./fundraising.svg").default,
  recruitment: require("./recruitment.svg").default,

  /// service page

  mainService: require("./main-service.svg").default,

  /// Faq page
  mainFaq: require("./faq.svg").default,

  /// Corporate Giving page
  mainCorporateGiving: require("./corporate_giving.svg").default,

  /// Blogs page
  mainBlogs: require("./blogs.svg").default,
};

export default IMAGES;
