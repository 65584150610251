import { Button, Col, Form, Input, message, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../Utils/api";
import "./inviteTeamMember.scss";

const InviteTeamMember = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [clientMembers, setClientMembers] = useState([]);

  useEffect(() => {
    getClientMembers();
  }, []);

  const getClientMembers = () => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/clientMembers`)
      .then((data) => {
        setClientMembers(data?.data);
      })
      .catch((err) => console.log(err));
  };

  const rules = {
    memberName: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter the member's name");
          } else if (new RegExp("^[A-Za-z]( ?[A-Za-z] ?)*$").test(value)) {
            return Promise.resolve();
          }
          return Promise.reject("Please enter valid member's name");
        },
      },
    ],
    memberEmail: [
      {
        required: true,
        message: "Please enter the member's email",
      },
      {
        type: "email",
        message: "Please enter valid email",
      },
    ],
    tempPassword: [
      {
        required: true,
        message: "Please enter the member's password",
      },
      {
        validator(_, value) {
          if (
            !value ||
            new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$").test(value)
          ) {
            return Promise.resolve();
          }
          return Promise.reject("Please choose a strong password");
        },
      },
    ],
  };

  const onFinish = (values) => {
    setLoader(true);
    const memberName = values.memberName.split(" ");
    const bodyFormData = {
      email: values.memberEmail,
      first_name: memberName[0],
      last_name: memberName[1] || "",
      password: values.tempPassword,
    };
    api
      .postWithToken(
        `${process.env.REACT_APP_API_URL}/client/inviteMember`,
        bodyFormData
      )
      .then((res) => {
        setLoader(false);
        if (res.result) {
          message.info(res.data);
          form.resetFields();
        } else {
          message.error(res.error);
        }
      })
      .catch((err) => console.log(err));
  };

  const updateAction = (member_id, active) => {
    let objIndex = clientMembers.findIndex((obj) => obj.id === member_id);
    clientMembers[objIndex].active = !active;
    setClientMembers([...clientMembers]);
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/team/${member_id}`)
      .then((res) => {
        if (res.result) {
          // getClientMembers();
        } else {
          message.error(res.error);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="invite-team-member-wrapper">
      <div className="invite-team-member-form">
        <h5 className="invite-team-member-headding">Invite Team Member</h5>
        <Form form={form} onFinish={onFinish}>
          <Row
            className="invite-team-member-row d-flex justify-content-center justify-content-md-start"
            gutter={[28, 28]}
          >
            <Col
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}
            >
              <Form.Item
                className="invite-team-member-formgroup"
                rules={rules.memberName}
                name="memberName"
              >
                <div className="formgroup-wrapper">
                  <label className="formgroup-lable">Name</label>
                  <Input className="formgroup-input" placeholder="cassandra" />
                </div>
              </Form.Item>
            </Col>
            <Col
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}
            >
              <Form.Item
                className="invite-team-member-formgroup"
                rules={rules.memberEmail}
                name="memberEmail"
              >
                <div className="formgroup-wrapper">
                  <label className="formgroup-lable">Email</label>
                  <Input
                    className="formgroup-input"
                    placeholder="cassandra@gamil.com"
                  />
                </div>
              </Form.Item>
            </Col>
            <Col
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}
            >
              <Form.Item
                className="invite-team-member-formgroup"
                rules={rules.tempPassword}
                name="tempPassword"
              >
                <div className="formgroup-wrapper">
                  <label className="formgroup-lable">Temporary Password</label>
                  <Input
                    className="formgroup-input"
                    placeholder="Member4@2021"
                  />
                </div>
              </Form.Item>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <div className="d-flex align-items-center justify-content-center invite-team-member-buttons">
                <Button
                  htmlType="submit"
                  className="invite-now-button"
                  loading={loader}
                >
                  Invite Now
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <div className="invite-team-member-table">
        <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
          <div className="th-data">User Name</div>
          <div className="th-data ">Email</div>
          <div className="th-data justify-content-end">Action</div>
        </div>
        <div className="table-body">
          {clientMembers?.length
            ? clientMembers?.map((member, index) => (
                <div className="table-row" key={index}>
                  <div className="invite-team-cell d-flex flex-column flex-md-row">
                    <div className="d-flex align-items-center invite-team-wrap">
                      <span className="d-flex d-sm-flex d-md-none cell-text-lable">
                        User Name
                      </span>
                      <span
                        className="cell-text-name"
                        role={"button"}
                        onClick={() => navigate("/invoices/invoice-details")}
                      >
                        {member?.first_name} {member?.last_name}
                      </span>
                    </div>
                  </div>
                  <div className="invite-team-cell ">
                    <div className="d-flex align-items-center invite-team-wrap">
                      <span className="d-flex d-sm-flex d-md-none cell-text-lable">
                        Email
                      </span>
                      <span className="cell-text-email">{member?.email}</span>
                    </div>
                  </div>
                  <div className="invite-team-cell justify-content-end">
                    <div className="d-flex align-items-center invite-team-wrap">
                      <span className="d-flex d-sm-flex d-md-none cell-text-lable">
                        Action
                      </span>
                      <Switch
                        className="cm-switch"
                        checked={member?.active}
                        onChange={() =>
                          updateAction(member?.id, member?.active)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))
            : "No Invited Members"}
        </div>
      </div>
    </div>
  );
};

export default InviteTeamMember;
