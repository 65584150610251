import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { onboardingReducer } from "./onboardingReducer";
import { dashboardReducer } from "./dashboardReducer";
import { updatesReducer } from "./updatesReducer";
import { invoicesReducer } from "./invoicesReducer";
import { agreementReducer } from "./agreementReducer";
import { perksReducer } from "./perkReducer";
import { settingReducer } from "./settingsReducer";
import { reviewsReducer } from "./reviewsReducer";
import { supportReducer } from "./supportReducer";
export default combineReducers({
  initial: "initial",
  authReducer: authReducer,
  onboardingReducer: onboardingReducer,
  dashboardReducer: dashboardReducer,
  updatesReducer: updatesReducer,
  settingsReducer: settingReducer,
  invoicesReducer: invoicesReducer,
  agreementReducer: agreementReducer,
  perksReducer: perksReducer,
  reviewsReducer: reviewsReducer,
  supportReducer: supportReducer,
});
