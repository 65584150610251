import React from "react";
import { Avatar } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import LinkedinIcon from "../../Assets/images/blog/linkedin-icon.png";
import FacebookIcon from "../../Assets/images/blog/fb-icon.png";
import TwitterIcon from "../../Assets/images/blog/twitter-icon.png";
import IMAGEDUMMY from "../../Assets/images/image_dummy.png";
import moment from "moment";
import "./BlogDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../../Components/Footer/Footer";
const BlogDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  return (
    <div className="blog-details-main">
      <Header />
      <div className="blog-detail-main-layout">
        <div className="blog-detail-body">
          <div className="reading-material-detail-header">
            <div className="header-text">
              <span className="back-button" onClick={() => navigate(-1)}>
                <ArrowLeftOutlined />
                <div>Back</div>
              </span>
            </div>
          </div>
          <div className="blog-detail-container">
            <div className="blog-content-wrapper">
              <h5>{state?.category}</h5>
              <p className="body-header-text">{state?.title}</p>
            </div>

            <div className="blog-section">
              <h5>{state.sub_title}</h5>
              <div className="profile-info">
                <div className="user-detail">
                  <div className="user-icon">
                    <Avatar
                      src={
                        state?.profile_image
                          ? `${process.env.REACT_APP_IMAGE_URL}${state.profile_image}`
                          : ""
                      }
                    >
                      {state?.author_first_name
                        ? state?.author_first_name?.charAt(0)
                        : "U"}
                    </Avatar>
                  </div>
                  <div className="user-info">
                    <h6>
                      {state.author_first_name} {state.author_last_name}
                    </h6>
                    <p>{moment(state?.publish_date).format("MM-DD-YYYY")}</p>
                  </div>
                </div>
                <div className="btn-group">
                  <a
                    href="https://www.linkedin.com/company/inventivebyte"
                    target="_blank"
                  >
                    <img src={LinkedinIcon} alt="linkedinIcon" />
                  </a>

                  <a href="https://twitter.com/inventivebyte" target="_blank">
                    <img src={TwitterIcon} alt="TwiiterIcon" className="mr-2" />
                  </a>
                  <a
                    href="https://www.facebook.com/inventivebyte"
                    target="_blank"
                  >
                    <img
                      src={FacebookIcon}
                      alt="TwiiterIcon"
                      className="mr-2"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="blog-detail-context">
              <img
                className="w-100"
                src={
                  state?.banner_image
                    ? `${process.env.REACT_APP_IMAGE_URL}${state.banner_image}`
                    : IMAGEDUMMY
                }
              />
              <p className="body-main-text">{state?.summary}</p>
              <p className="body-main-text">
                <div dangerouslySetInnerHTML={{ __html: state?.content }}></div>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
