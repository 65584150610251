import {
  GET_PROJECT_UPDATES_DATA_INIT,
  GET_PROJECT_UPDATES_DATA_SUCCESS,
  GET_PROJECT_UPDATES_DATA_FAIL,
  GET_PROJECT_UPDATE_BYID_INIT,
  GET_PROJECT_UPDATE_BYID_SUCCESS,
  GET_PROJECT_UPDATE_BYID_FAILED,
} from "../actions/actionTypes";

const initialState = {
  updates: [],
  totalUpdateCount: 0,
  updateDetails: null,
  loading: false,
  currPage: 1,
  totalData: 1,
};

export const updatesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROJECT_UPDATES_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PROJECT_UPDATES_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        updates: payload?.data,
        totalUpdateCount: payload?.paginations?.total_docs,
        currPage: payload?.paginations?.page,
        totalData: payload?.paginations?.total_docs,
        pageSize: payload?.paginations?.per_page,
      };
    }
    case GET_PROJECT_UPDATES_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_PROJECT_UPDATE_BYID_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECT_UPDATE_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        SingleUpdate: payload?.data,
      };
    case GET_PROJECT_UPDATE_BYID_FAILED:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
