import * as React from "react";

function UploadIcon(props) {
  return (
    <svg
      width="138"
      height="92"
      viewBox="0 0 138 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.7394 57.1706C76.3426 59.9558 78.9115 62.7761 81.566 65.5088C82.9146 66.897 84.593 67.1248 86.2884 66.1876C87.9111 65.2898 88.6004 63.7746 88.2365 61.9572C88.0609 61.0857 87.5643 60.166 86.952 59.5266C82.054 54.4291 77.109 49.371 72.1382 44.3479C70.173 42.364 67.861 42.3553 65.9086 44.3304C60.9379 49.3578 55.9971 54.4116 51.0819 59.496C49.2752 61.3659 49.2323 63.906 50.8593 65.5351C52.5119 67.1861 54.8796 67.1379 56.7592 65.2241C59.3238 62.6053 61.7813 59.877 64.286 57.1925C64.4658 57.3019 64.6456 57.407 64.8254 57.5165C64.8254 68.6575 64.8254 79.7942 64.8254 91.1498C64.1618 91.1936 63.4811 91.2811 62.796 91.2811C51.0991 91.2899 39.3978 91.3162 27.7008 91.2768C11.8551 91.2242 0.200992 78.9928 0.522102 62.8068C0.744738 51.5913 8.77677 41.1466 19.8272 37.9803C21.6725 37.4504 22.3875 36.5746 22.8371 34.7484C27.9663 14.0342 46.0598 -0.316888 66.8292 0.318114C82.859 0.808599 95.3052 8.27097 103.911 22.1797C104.784 23.5899 105.598 24.2774 107.349 24.4176C123.571 25.7226 136.857 39.5963 137.486 55.721C138.18 73.4485 126.551 88.1587 109.665 90.8651C107.983 91.1322 106.261 91.2592 104.562 91.268C94.7914 91.3074 85.0168 91.2899 75.2465 91.2899C74.6129 91.2899 73.9749 91.2899 73.1614 91.2899C73.1614 79.9474 73.1614 68.7758 73.1614 57.5997C73.3584 57.4508 73.5511 57.3107 73.7394 57.1706Z"
        fill="#E5C243"
      />
    </svg>
  );
}

export default UploadIcon;
