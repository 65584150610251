import { message } from "antd";
import { store } from "../../store";
import api from "../../Utils/api";
import {
  SET_LOADER,
  SET_PROFILE_DATA,
  SET_PROJECT_DATA,
  SET_USER_STATUS,
  SET_DASHBOARD_POSITION,
  SET_FEEDBACK_INIT,
  SET_FEEDBACK_SUCCESS,
  SET_FEEDBACK_FAIL,
  GET_DASHBOARD_DATA_INIT,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  SEND_EMAIL_INIT,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  SEND_FEEDBACK_INIT,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAIL,
  GET_NOTIFICATIONS_DATA_INIT,
  GET_NOTIFICATIONS_DATA_SUCCESS,
  GET_NOTIFICATIONS_DATA_FAIL,
  SET_SCHEDULE_MEETING_INIT,
  SET_SCHEDULE_MEETING_SUCCESS,
  SET_SCHEDULE_MEETING_FAIL,
  GET_SCHEDULE_MEETING_INFO_INIT,
  GET_SCHEDULE_MEETING_INFO_SUCCESS,
  GET_SCHEDULE_MEETING_INFO_FAIL,
  UPDATE_PROJECT_DATA_INIT,
  UPDATE_PROJECT_DATA_SUCCESS,
  UPDATE_PROJECT_DATA_FAILED,
} from "./actionTypes";

export const getDashboardData = (onSuccess, onFail) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_DATA_INIT });
  try {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/getDashboard`)
      .then((res) => {
        dispatch({ type: GET_DASHBOARD_DATA_SUCCESS, payload: res?.data });
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch({ type: GET_DASHBOARD_DATA_FAIL });
        onFail && onFail();
      });
  } catch (e) {
    dispatch({ type: GET_DASHBOARD_DATA_FAIL });
  }
};
export const getUserStatus =
  (navigate = false, onSuccess, onFail) =>
  (dispatch) => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/user`)
      .then((res) => {
        if (res.result) {
          const response = res;
          store.dispatch({ type: SET_USER_STATUS, payload: response });
          navigate && navigate("/dashboard");
        } else {
          navigate && navigate("/onboarding/confirm-email");
        }
        onSuccess && onSuccess();
      })
      .catch((err) => {
        console.log(err);
        onFail && onFail();
      });
  };

export const getProjectData = () => (dispatch) => {
  dispatch({ type: SET_LOADER });
  api
    .getWithToken(`${process.env.REACT_APP_API_URL}/client/project`)
    .then((res) => {
      dispatch({ type: SET_LOADER });
      if (res.result) {
        dispatch(setProjectData(res.data));
      } else {
        message.info(res.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getFeedbackData = (data) => (dispatch) => {
  dispatch({ type: SET_FEEDBACK_INIT });
  api
    .postWithToken(`${process.env.REACT_APP_API_URL}/client/feedback`, data)
    .then((res) => {
      if (res?.result) {
        dispatch({
          type: SET_FEEDBACK_SUCCESS,
          payload: res,
        });
        return res;
      } else {
        dispatch({
          type: SET_FEEDBACK_FAIL,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: SET_FEEDBACK_FAIL,
      });
    });
};
export const updateProjectData = (updatedData, navigate) => (dispatch) => {
  dispatch({ type: UPDATE_PROJECT_DATA_INIT });
  delete updatedData["steps_completed"];
  delete updatedData["created_at"];
  delete updatedData["id"];
  const modifiedData = {
    ...updatedData,
    category: updatedData?.category?.id,
    features: updatedData?.features?.map((item) => item?.id),
    phase: updatedData?.phase?.id,
    types: updatedData?.types?.id,
  };
  api
    .putWithToken(
      `${process.env.REACT_APP_API_URL}/client/project`,
      modifiedData
    )
    .then((res) => {
      dispatch({ type: UPDATE_PROJECT_DATA_SUCCESS });
      if (res.result) {
        dispatch(setProjectData(res.data));
        navigate(-1);
      } else {
        dispatch({ type: UPDATE_PROJECT_DATA_FAILED });
        message.info(res.error);
      }
    })
    .catch((err) => {
      dispatch({ type: UPDATE_PROJECT_DATA_FAILED });
      console.log(err);
    });
};

export const setProjectData = (data) => (dispatch) => {
  dispatch({
    type: SET_PROJECT_DATA,
    payload: data,
  });
};

export const setProfileData = (data) => (dispatch) => {
  dispatch({
    type: SET_PROFILE_DATA,
    payload: data,
  });
};

export const getProfileData = () => (dispatch) => {
  api
    .getWithToken(`${process.env.REACT_APP_API_URL}/client/profile`)
    .then((data) => dispatch(setProfileData(data?.data)))
    .catch((err) => console.log(err));
};

export const uploadFile = (file, fields, uploadURL) => {
  const formData = new FormData();
  formData.append("AWSAccessKeyId", fields?.AWSAccessKeyId);
  formData.append("key", fields?.key);
  formData.append("policy", fields?.policy);
  formData.append("signature", fields?.signature);
  formData.append("file", file);

  // Upload file to s3 bucket
  let request = new XMLHttpRequest();
  request.open("POST", uploadURL);
  request.send(formData);
};

export const setDashboardPosition = (position) => (dispatch) => {
  dispatch({
    type: SET_DASHBOARD_POSITION,
    payload: position,
  });
};

export const sendReferClientEmail =
  (email, onSuccess, onFail) => (dispatch) => {
    dispatch({ type: SEND_EMAIL_INIT });
    try {
      api
        .postWithToken(`${process.env.REACT_APP_API_URL}/client/referClient`, {
          email: email,
        })
        .then((res) => {
          dispatch({ type: SEND_EMAIL_SUCCESS, payload: res?.data });
          onSuccess && onSuccess(res);
        })
        .catch((err) => {
          dispatch({ type: SEND_EMAIL_FAIL });
          onFail && onFail(err);
        });
    } catch (e) {
      dispatch({ type: SEND_EMAIL_FAIL });
      onFail && onFail(e);
    }
  };

export const sendClientFeedback = (data, onSuccess, onFail) => (dispatch) => {
  dispatch({ type: SEND_FEEDBACK_INIT });
  try {
    api
      .postWithToken(`${process.env.REACT_APP_API_URL}/client/feedback`, {
        ...data,
      })
      .then((res) => {
        dispatch({ type: SEND_FEEDBACK_SUCCESS });
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        dispatch({ type: SEND_FEEDBACK_FAIL });
        onFail && onFail(err);
      });
  } catch (e) {
    dispatch({ type: SEND_FEEDBACK_FAIL });
    onFail && onFail(e);
  }
};
export const getNotifications = (onSuccess, onFail) => (dispatch) => {
  dispatch({ type: GET_NOTIFICATIONS_DATA_INIT });
  try {
    api
      .getWithToken(
        `${process.env.REACT_APP_API_URL}/client/user?is_clicked=true`
      )
      .then((res) => {
        dispatch({
          type: GET_NOTIFICATIONS_DATA_SUCCESS,

          payload: res?.notification_data,
        });
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch({ type: GET_NOTIFICATIONS_DATA_FAIL });
        onFail && onFail();
      });
  } catch (e) {
    dispatch({ type: GET_NOTIFICATIONS_DATA_FAIL });
  }
};
export const clearNotifications = (onSuccess, onFail) => (dispatch) => {
  dispatch({ type: GET_NOTIFICATIONS_DATA_INIT });
  try {
    api
      .getWithToken(
        `${process.env.REACT_APP_API_URL}/client/user?is_cleared=true`
      )
      .then((res) => {
        dispatch({
          type: GET_NOTIFICATIONS_DATA_SUCCESS,
          payload: res?.notification_data,
        });
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch({ type: GET_NOTIFICATIONS_DATA_FAIL });
        onFail && onFail();
      });
  } catch (e) {
    dispatch({ type: GET_NOTIFICATIONS_DATA_FAIL });
  }
};
export const scheduleMeetingAction =
  (data, onSuccess, onFail) => (dispatch) => {
    dispatch({ type: SET_SCHEDULE_MEETING_INIT });
    try {
      api
        .postWithToken(
          `${process.env.REACT_APP_API_URL}/client/scheduleMeeting`,
          { ...data }
        )
        .then((res) => {
          dispatch({
            type: SET_SCHEDULE_MEETING_SUCCESS,
            payload: res,
          });
          onSuccess && onSuccess(res);
        })
        .catch((err) => {
          dispatch({ type: SET_SCHEDULE_MEETING_FAIL });
          onFail && onFail(err);
        });
    } catch (e) {
      dispatch({ type: SET_SCHEDULE_MEETING_FAIL });
    }
  };
export const GetscheduleMeetingDetails = (onSuccess, onFail) => (dispatch) => {
  dispatch({ type: GET_SCHEDULE_MEETING_INFO_INIT });
  try {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/scheduleMeeting`)
      .then((res) => {
        dispatch({
          type: GET_SCHEDULE_MEETING_INFO_SUCCESS,
          payload: res?.data,
        });
        onSuccess && onSuccess();
      })
      .catch((err) => {
        dispatch({ type: GET_SCHEDULE_MEETING_INFO_FAIL });
        onFail && onFail();
      });
  } catch (e) {
    dispatch({ type: GET_SCHEDULE_MEETING_INFO_FAIL });
  }
};
