import React, { useEffect } from "react";
import { Row, Col, Divider, Button, Spin, message } from "antd";
import "./Reviews.scss";
import ReviewIcon from "../../Assets/Icons/ReviewIcon";
import Credited from "../../Assets/images/MainLayout/Credited.svg";
import UnderReview from "../../Assets/images/MainLayout/under-review.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getReviewsData,
  putReviews,
  getReviewsDetailData,
} from "../../redux/actions/reviewsAction";
function Reviews() {
  const dispatch = useDispatch();

  const { reviews, loading } = useSelector((state) => state?.reviewsReducer);
  useEffect(() => {
    dispatch(getReviewsData());
  }, []);
  const claimReview = (review_id) => {
    dispatch(putReviews(review_id)).then(() => {
      dispatch(getReviewsData());
    });
  };
  const ReviewAction = (review) => {
    dispatch(
      getReviewsDetailData(
        review?.id,
        () => {
          review?.link
            ? window.open(review?.link, "_black")
            : message.error("Review link is missing", 2);
        },
        (err) => {
          message.error(err || "Action Can Not Be Performed", 2);
        }
      )
    );
  };
  const renderView = (review) => {
    if (
      !review?.reviewed &&
      !review?.credited &&
      review?.status === "pending"
    ) {
      return (
        <Button
          className="learnMoreButton"
          onClick={() => {
            ReviewAction(review);
          }}
        >
          1. Review Now
        </Button>
      );
    } else if (review?.credited && review?.status === "approved") {
      return <img src={Credited} alt="credited" />;
    } else if (review?.reviewed && review?.status === "pending") {
      return <img src={UnderReview} alt="pending" />;
    } else {
      return (
        <Button
          className="learnMoreButton whiteButton"
          onClick={() => claimReview(review?.id)}
        >
          2. Claim Credit
        </Button>
      );
    }
  };

  return (
    <div className="mainDashboardPage perkPage reviewPage">
      <div className="mainContainer">
        <div className="dashboard-content">
          <Row gutter={[36, 36]}>
            <Col span={24} className="marginBottom30">
              <div className="welcomeCon">
                <div className="welcomeContentCon">
                  <div className="welcomeText">Get $200+ Off To Review Us!</div>
                  <div className="welcomeSubText marginBottom30">
                    If you feel comfortable sharing a 5-star review, we would
                    love to see it! For completing a review, we will
                    automatically credit your next invoice.If you are not happy
                    with our service for any reason, please let us know in our
                    feedback form and we will do our best to resolve any issues.
                  </div>
                  <div className="welcomeText leftText">
                    Here’s How To Claim Credit:{" "}
                  </div>
                  <div className="welcomeSubText">
                    1. Click Review Now: You will be redirected to the review
                    website where you can write the review.
                  </div>
                  <div className="welcomeSubText">
                    2. Click Claim Credit: Once we verify your review is live,
                    we will credit your next invoice with the amount attached to
                    the specified review!
                  </div>
                </div>
                <div className="flyIconCon frameIcon">
                  <ReviewIcon />
                </div>
              </div>
            </Col>
          </Row>
          <Spin spinning={loading}>
            <Row gutter={[16, 16]}>
              {reviews?.data?.map((review, index) => (
                <Col
                  lg={{ span: 6 }}
                  sm={{ span: 12 }}
                  xs={{ span: 24 }}
                  className="updateColumn"
                >
                  <div className="updateCard">
                    <div className="updateIcon">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + review?.image}
                        alt="review_image"
                      />
                    </div>
                    <div className="creditText">${review?.value} Credit</div>
                    <Divider />
                    <div className="amazonBottomCon claimCon">
                      {renderView(review)}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
