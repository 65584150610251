import React, { useEffect, useRef, useState } from "react";
import "./SupportChat.scss";
import {
  SettingOutlined,
  CheckOutlined,
  PaperClipOutlined,
  SmileOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import supportIcon from "../../../../src/Assets/images/Support/support_icon.png";
import supportAvatar from "../../../../src/Assets/images/Support/support-avatar1.svg";
import PDFIcon from "../../../Assets/images/Support/slide-pdf.svg";
import DocIcon from "../../../Assets/images/Support/doc_file_icon.svg";
import CSVIcon from "../../../Assets/images/Support/csv_icon.svg";
import doubleCheckIcon from "../../../Assets/images/Support/done-check-icon.svg";
import SendIcon from "../../../Assets/images/Support/send-icon.svg";
import { Divider, message, Upload } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSupportChatData } from "../../../redux/actions/supportActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import api from "../../../Utils/api";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import EmojiPicker from "emoji-picker-react";
import { dummyRequest, convertUTCToTimezone } from "../../../Utils/general";
function SupportChat() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emoji, setEmoji] = useState(false);
  const messageEl = useRef(null);
  const [state, setState] = useState({
    message: "",
    attachment: "",
  });
  const DocumentExt = ["doc", "docx"];
  const pdfExt = ["pdf"];
  const CSVExt = ["csv"];
  const audioExt = ["mp3", "mp4"];
  const [timeline, setTimeline] = useState({});
  const [data, setData] = useState([]);
  const { chatData } = useSelector((state) => state?.supportReducer);
  const { profileData } = useSelector((state) => state?.dashboardReducer);
  useEffect(() => {
    dispatch(getSupportChatData()).then(() => {
      scrollToMyRef();
    });
  }, []);
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const postSupport = async () => {
    if (!state?.message) return;
    const formData = new FormData();
    formData.append("message", state?.message);
    await api
      .postwithFormData(
        `${process.env.REACT_APP_API_URL}/client/support`,
        formData
      )
      .then((res) => {
        if (res.result) {
          message.success(res.data);
          setState({
            ...state,
            message: "",
          });
          dispatch(getSupportChatData()).then(() => {
            scrollToMyRef();
          });
        }
      });
  };
  const OnEnterPressAction = async (e) => {
    const { code } = e;
    if (code == "Enter") {
      postSupport();
    }
  };
  useEffect(() => {
    setData(chatData);
  }, [chatData]);
  useEffect(() => {
    if (data && data?.length !== 0) {
      let currDate = moment();
      let count = 0;
      let newObj = {};
      data?.map((i, index) => {
        let obj = Object.values(newObj);
        count++;
        let con_created_date = convertUTCToTimezone(
          i?.created_at,
          true,
          profileData?.time_zone ? profileData?.time_zone : ""
        );
        let custom_obj = profileData?.time_zone
          ? moment
              .utc(con_created_date, null)
              .tz(profileData?.time_zone)
              .format("MM-DD-YYYY")
          : moment(con_created_date).utc(true).local().format("MM-DD-YYYY");
        if (
          !obj?.includes("Today") &&
          moment(con_created_date).isSame(currDate, "d")
        ) {
          newObj = { ...newObj, [index]: "Today" };
        } else if (
          !obj?.includes("Yesterday") &&
          moment(con_created_date).isSame(moment().subtract(1, "day"), "d")
        ) {
          newObj = { ...newObj, [index]: "Yesterday" };
        } else if (
          !moment(con_created_date).isSame(currDate, "d") &&
          !moment(con_created_date).isSame(moment().subtract(1, "day"), "d") &&
          !obj?.includes(custom_obj)
        ) {
          newObj = {
            ...newObj,
            [index]: custom_obj,
          };
        }
        setTimeline(newObj);
      });
    }
  }, [data]);
  const onBannerSelect = async (files, file) => {
    if (
      files?.file?.status !== "removed" &&
      files?.file?.status !== "uploading"
    ) {
      const formData = new FormData();
      formData.append("attachment", files.file.originFileObj);
      formData.append("message", "");
      api
        .postwithFormData(
          `${process.env.REACT_APP_API_URL}/client/support`,
          formData
        )
        .then((res) => {
          if (res.result) {
            message.success(res.data);
            dispatch(getSupportChatData()).then(() => {
              scrollToMyRef();
            });
          } else {
            message.error(res.data);
          }
        })
        .catch((err) => {
          message.error("Network request failed");
        });
    }
  };

  const addEmoji = (e) => {
    setState({
      ...state,
      message: state.message + e.emoji,
    });
  };

  const scrollToMyRef = () => {
    const scroll =
      messageEl.current.scrollHeight - messageEl.current.clientHeight;
    messageEl.current.scrollTo(0, scroll);
  };
  const oncloseEmoijiBox = () => {
    setEmoji(false);
  };
  return (
    <div className="support-chat-main">
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between support-setting-wrapper">
        <div className="d-flex align-items-center justify-content-center support-chat-wrapper">
          <img src={supportIcon} alt="supportIcon" />
          <span className="support-tex">Support</span>
        </div>
        <button
          className="support-setting-button d-flex align-items-center justify-content-center"
          onClick={() =>
            navigate("/support/add-new-feature", { state: location?.state })
          }
        >
          <SettingOutlined />
          <span className="button-text">Request New Feature</span>
        </button>
      </div>

      <div className="support-chat-card" style={{ textAlign: "left" }}>
        <div className="support-card-header">
          <div className="d-flex align-items-center justify-content-center support-header-main">
            <img src={supportAvatar} alt="supportAvatar" />
            <div className="d-flex flex-column align-items center support-user-wrapper">
              <span className="support-user-name">{location?.state}</span>
              <span className="support-user-chat">Support Chat</span>
            </div>
          </div>
        </div>
        <div className="support-card-body">
          <div
            className="support-card-wrapper"
            ref={messageEl}
            onClick={oncloseEmoijiBox}
          >
            {chatData?.map((chat, index) => {
              return chat?.sender === "support" ? (
                <div
                  className="d-flex align-items-start flex-column support-admin-chat"
                  key={index}
                >
                  {Object.keys(timeline)?.includes(index?.toString()) && (
                    <Divider className="support-divider">
                      <span>{timeline[index]}</span>
                    </Divider>
                  )}
                  <div className="d-flex align-items-center admin-chat-wrapper">
                    <img
                      className="img-fluid admin-img"
                      src={supportAvatar}
                      alt="supportAvatar"
                    />
                    <div className="d-flex flex-column admin-chat-message">
                      <span className="admin-name">
                        {location?.state?.charAt(0)?.toUpperCase() +
                          location?.state?.slice(1)}
                      </span>
                      <span className="admin-meassage">{chat?.message}</span>
                      {chat?.attachments?.length === 0
                        ? ""
                        : chat?.attachments?.map((attachment) => {
                            let type = attachment
                              ?.split("/")
                              ?.reverse()[0]
                              ?.split(".")[1];
                            if (
                              DocumentExt?.includes(type) ||
                              pdfExt?.includes(type) ||
                              CSVExt?.includes(type)
                            ) {
                              return (
                                <>
                                  <Link
                                    to={`${process.env.REACT_APP_IMAGE_URL}${attachment}`}
                                  >
                                    <div className="icon-group">
                                      {pdfExt?.includes(type) ? (
                                        <img src={PDFIcon} alt="pdf" />
                                      ) : DocumentExt?.includes(type) ? (
                                        <img src={DocIcon} alt="DocIcon" />
                                      ) : (
                                        <img src={CSVIcon} alt="csvIcon" />
                                      )}
                                      {attachment?.split("/")?.reverse()[0]}
                                    </div>
                                  </Link>
                                </>
                              );
                            } else if (audioExt?.includes(type)) {
                              return (
                                <AudioPlayer
                                  autoPlay={false}
                                  src={`${process.env.REACT_APP_IMAGE_URL}${attachment}`}
                                />
                              );
                            } else {
                              return (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}${attachment}`}
                                  className="attachment-img"
                                  alt="attachment"
                                />
                              );
                            }
                          })}
                    </div>
                  </div>
                  <span className="admin-chat-date mt-2">
                    {convertUTCToTimezone(
                      chat?.created_at,
                      true,
                      profileData?.time_zone ? profileData?.time_zone : "",
                      "hh:mm A"
                    )}
                  </span>
                </div>
              ) : !chat?.requested_feature ? (
                <div className="d-flex align-items-end flex-column support-user-chat">
                  {Object.keys(timeline)?.includes(index?.toString()) && (
                    <Divider className="support-divider">
                      {<span>{timeline[index]}</span>}
                    </Divider>
                  )}
                  <div className="d-flex align-items-center user-chat-wrapper">
                    <div className="d-flex flex-column user-chat-message">
                      <span className="user-meassage">{chat?.message}</span>
                      {chat?.attachments?.length === 0
                        ? ""
                        : chat?.attachments?.map((attachment) => {
                            let type = attachment
                              ?.split("/")
                              ?.reverse()[0]
                              ?.split(".")[1];
                            if (
                              DocumentExt?.includes(type) ||
                              pdfExt?.includes(type) ||
                              CSVExt?.includes(type)
                            ) {
                              return (
                                <>
                                  <Link
                                    to={`${process.env.REACT_APP_IMAGE_URL}${attachment}`}
                                  >
                                    <div className="icon-group">
                                      {pdfExt?.includes(type) ? (
                                        <img src={PDFIcon} alt="pdf" />
                                      ) : DocumentExt?.includes(type) ? (
                                        <img src={DocIcon} alt="DocIcon" />
                                      ) : (
                                        <img src={CSVIcon} alt="csvIcon" />
                                      )}
                                      {attachment?.split("/")?.reverse()[0]}
                                    </div>
                                  </Link>
                                </>
                              );
                            } else if (audioExt?.includes(type)) {
                              return (
                                <AudioPlayer
                                  autoPlay={false}
                                  src={`${process.env.REACT_APP_IMAGE_URL}${attachment}`}
                                />
                              );
                            } else {
                              return (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}${attachment}`}
                                  className="attachment-img"
                                  alt="attachment"
                                />
                              );
                            }
                          })}
                    </div>
                  </div>
                  <span className="d-flex align-items-center user-chat-date mt-2">
                    <span>
                      {convertUTCToTimezone(
                        chat?.created_at,
                        true,
                        profileData?.time_zone ? profileData?.time_zone : "",
                        "hh:mm A"
                      )}
                    </span>
                    {chat?.read ? (
                      <img src={doubleCheckIcon} alt="doubleCheckIcon" />
                    ) : (
                      <CheckOutlined />
                    )}
                  </span>
                </div>
              ) : (
                <div className="d-flex align-items-end flex-column support-features-chat">
                  <div className="d-flex flex-column features-chat-wrapper">
                    <div className="features-settings d-flex align-items-center">
                      <SettingOutlined className="features-settings-icon" />
                      <span className="features-settings-text">
                        New feature request
                      </span>
                    </div>
                    <div className="d-flex flex-column features-chat-message">
                      <span className="features-name">
                        {chat?.feature_name}
                      </span>
                      <span className="features-meassage">
                        {chat?.feature_details}
                      </span>
                    </div>
                  </div>
                  <span className="d-flex align-items-center features-chat-date">
                    <span>
                      {convertUTCToTimezone(
                        chat?.created_at,
                        true,
                        profileData?.time_zone ? profileData?.time_zone : "",
                        "hh:mm A"
                      )}
                    </span>

                    {chat?.read ? (
                      <img src={doubleCheckIcon} alt="doubleCheckIcon" />
                    ) : (
                      <CheckOutlined />
                    )}
                  </span>
                </div>
              );
            })}
          </div>

          <div className="support-card-meassage">
            <div className="d-flex align-items center support-meassage-wrapper">
              <div className="measssage-input">
                <input
                  className="input"
                  onChange={handleChange}
                  value={state?.message}
                  name="message"
                  onFocus={oncloseEmoijiBox}
                  onKeyPress={(e) => {
                    OnEnterPressAction(e);
                  }}
                />
                <span onClick={postSupport} style={{ cursor: "pointer" }}>
                  <img src={SendIcon} className="meassage-icon" />
                </span>
              </div>

              <div
                className="d-flex align-items-center justify-conten-center chat-icons"
                style={{ position: "relative" }}
              >
                <SmileOutlined
                  className="icons"
                  style={{ cursor: "pointer" }}
                  onClick={() => setEmoji(!emoji)}
                />
                {emoji && (
                  <div
                    className="emoijibox-wrap"
                    style={{ position: "absolute", bottom: 70 }}
                  >
                    <CloseOutlined
                      onClick={oncloseEmoijiBox}
                      className="close-icon"
                    />
                    <EmojiPicker onEmojiClick={addEmoji} />
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center justify-conten-center chat-files">
                <Upload
                  customRequest={dummyRequest}
                  accept="image/png,image/jpeg"
                  showUploadList={false}
                  onChange={(file) =>
                    onBannerSelect(file, file.file.originFileObj)
                  }
                  maxCount={1}
                >
                  <PaperClipOutlined className="files" />
                </Upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportChat;
