import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import "./DevelopmentStage.scss";
import IMAGES from "../../../Assets/images/Onboarding";
import { message, Upload, Button } from "antd";
import OnboardingStepper from "../../../Components/OnboardingStepper";
import {
  SaveOnboarding,
  setOnboardingData,
} from "../../../redux/actions/onboardingActions";
import { useDispatch, useSelector } from "react-redux";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import api from "../../../Utils/api";
import { dummyRequest } from "../../../Utils/general";
import SaveAndNext from "../../../Components/Save&Next";
import { useNavigate } from "react-router-dom";
import { uploadFile } from "../../../redux/actions/dashboardActions";
import { FilePdfOutlined, DeleteOutlined } from "@ant-design/icons";

const DevelopmentStage = () => {
  const [error, setError] = useState({});
  const [saveType, setSaveType] = useState("");
  const navigate = useNavigate();
  const { payload } = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,
    showUploadList: false,
    accept: ".pdf",
    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        api
          .getWithToken(
            `${process.env.REACT_APP_API_URL}/client/getPresignedUrl?key_type=project_doc&file_name=${info.file.name}`
          )
          .then((data) => {
            dispatch(
              setOnboardingData({
                ...payload,
                documentUploadURL: data?.data?.url,
                documentURL: [...payload?.documentURL, data?.data?.fields?.key],
              })
            );
            uploadFile(info.fileList, data?.data?.fields, data?.data?.url);
          })
          .catch((err) => console.log(err));
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const onSave_Logout_Fun = () => {
    dispatch(
      setOnboardingData({
        ...payload,
        steps_completed: 3,
      })
    );
    setSaveType("save&Logout");
  };
  const deleteFile = (item) => {
    dispatch(
      setOnboardingData({
        ...payload,
        documentURL: payload?.documentURL?.filter((i) => {
          return i !== item;
        }),
      })
    );
  };

  const handleChange = (data) => {
    setError({ ...error, stageErr: "" });
    dispatch(setOnboardingData({ ...payload, developmentStage: data }));
  };

  const developmentPhases = [
    {
      title: "I need strategic advice before I start.",
      shortTitle: "ADVISORY",
      image: "strategicAdvice",
    },
    {
      title:
        "I am ready with Wireframes/Screen designs and functional requirements.",
      shortTitle: "DEVELOPMENT",
      image: "wireframe",
    },
    {
      title: "I want to enhance/upgrade my existing product.",
      shortTitle: "UPGRADATION",
      image: "thirdPartyApi",
    },
  ];

  useEffect(() => {
    if (saveType === "save&Logout") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
    } else if (saveType === "save&next") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
      navigate("/onboarding/features");
    }
  }, [saveType]);

  const validateForm = (clickType) => {
    if (!payload?.developmentStage) {
      setError({ ...error, stageErr: "Please select development stage" });
    } else if (clickType === "Save&Logout") {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 4,
        })
      );
      setSaveType("save&Logout");
    } else {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 4,
        })
      );
      setSaveType("save&next");
    }
  };

  return (
    <div className="onboard pb-3">
      <OnboardingHeader steps_completed={3} validateForm={onSave_Logout_Fun} />

      <Container className="mt-5">
        <Row>
          <Col md={10} className="m-auto">
            <OnboardingStepper activeElement={4} />
          </Col>
          <Col md={12}>
            <Row>
              <Col sm={12} md={12}>
                <div className="developmentStage">
                  <span className="title">
                    Which <span>development stage</span> are you at?
                  </span>
                  <div className="developmentStage-links d-flex justify-content-center">
                    <ul>
                      {developmentPhases?.map((phase, index) => (
                        <li
                          onClick={() => handleChange(phase.title)}
                          key={index}
                        >
                          <a
                            href={null}
                            className={
                              payload?.developmentStage === phase.title
                                ? "project-active"
                                : ""
                            }
                          >
                            <div className="img-wrap">
                              <img src={IMAGES[phase.image]} alt="" />
                            </div>
                            <span>{phase.title}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {error?.stageErr && (
                    <p className="mt-3 text-danger">{error?.stageErr}</p>
                  )}

                  <div className="helpful-documents-wrap">
                    <div className="title document-wrap">
                      Do you have any <span>helpful documents</span> we could
                      check out?
                    </div>
                    <Row>
                      <div className="d-sm-flex justify-content-center">
                        <Col md={10} sm={12}>
                          <div className="upload-file">
                            <Dragger {...props} className="upload-docs-dragger">
                              <div className="icon-upload">
                                <img src={IMAGES.upload} alt="" />
                              </div>
                              <div className="upload-right-section">
                                <p className="ant-upload-text">
                                  Drag and drop your PDF file here <div>OR</div>
                                </p>
                                <Button>Choose File</Button>
                              </div>
                            </Dragger>
                            <div className="file-main-wrap">
                              {payload?.documentURL?.map((item) => (
                                <Row>
                                  <Col md={12} className="text-white">
                                    <div className="file-wrap">
                                      <p>
                                        <FilePdfOutlined />
                                        <span>
                                          {item?.split("/")?.slice(-1)[0]}
                                        </span>
                                      </p>

                                      <DeleteOutlined
                                        onClick={() => deleteFile(item)}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </div>
                </div>

                <SaveAndNext
                  backUrl={"/onboarding/development"}
                  validateForm={validateForm}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DevelopmentStage;
