import * as React from "react";

function UpdatesIcon(props) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="#E5C243" />
      <g clipPath="url(#clip0_359_805)">
        <path
          d="M25.2322 12.5293C25.7037 12.044 26.1639 11.5777 26.6146 11.1036C26.8697 10.8353 27.1653 10.6979 27.5216 10.8432C27.8869 10.9919 28.0026 11.2971 28.0004 11.682C27.9914 13.3277 27.997 14.9734 27.9965 16.6191C27.9959 17.2279 27.7149 17.5163 27.108 17.5175C25.4502 17.5203 23.7925 17.5158 22.1341 17.5208C21.7638 17.5219 21.466 17.4092 21.3126 17.0551C21.1563 16.6943 21.2996 16.4059 21.5615 16.1495C22.0622 15.6591 22.5573 15.1625 23.1013 14.6222C22.7759 14.3787 22.4882 14.1301 22.1701 13.9304C19.4098 12.1938 15.679 13.0377 13.9571 15.7999C13.6924 16.2247 13.5137 16.705 13.3148 17.1685C13.208 17.4165 13.0518 17.527 12.7787 17.5225C12.0858 17.5113 11.3924 17.5236 10.6995 17.5169C10.3067 17.513 10.1521 17.307 10.2505 16.9339C10.9698 14.2064 12.5264 12.0984 15.1018 10.9145C18.459 9.37145 21.6615 9.81359 24.6197 12.0187C24.8147 12.1618 24.9928 12.3279 25.2322 12.5293Z"
          fill="black"
        />
        <path
          d="M12.7767 25.5153C12.303 25.9945 11.8383 26.4557 11.3836 26.9265C11.1263 27.193 10.8324 27.331 10.4761 27.184C10.1119 27.0331 9.9973 26.7279 9.99954 26.3435C10.0085 24.6972 10.0029 23.0515 10.0035 21.4053C10.004 20.7993 10.2867 20.5126 10.897 20.5114C12.5553 20.5086 14.2136 20.5131 15.872 20.5086C16.2429 20.5075 16.5385 20.6254 16.6896 20.9805C16.8436 21.3436 16.6941 21.6292 16.4328 21.885C15.9984 22.3109 15.5702 22.7429 15.1403 23.1733C15.0757 23.2378 15.0167 23.3074 14.9431 23.3871C15.4972 23.9196 16.1232 24.3017 16.8144 24.576C19.4691 25.6303 22.5149 24.654 24.0316 22.243C24.2979 21.8194 24.48 21.3396 24.6773 20.8762C24.7874 20.6164 24.9453 20.5019 25.2319 20.5075C25.8877 20.521 26.5435 20.5081 27.1993 20.5131C27.6174 20.5165 27.7573 20.7039 27.6545 21.1028C27.0341 23.5105 25.7096 25.4177 23.5775 26.7245C21.5938 27.9404 19.4353 28.2518 17.169 27.8063C15.5623 27.4904 14.1558 26.7542 12.9425 25.6534C12.9015 25.6163 12.8571 25.5821 12.7767 25.5153Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_359_805">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UpdatesIcon;
