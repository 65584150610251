import {
  ArrowLeftOutlined,
  CheckCircleFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Button, Checkbox, message, Spin } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment";
import "./AgreementDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAgreementDetailsData,
  updateAgreementDetailsData,
} from "../../redux/actions/agreementAction";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function AgreementDetails(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const agreementId = params?.agreementId;
  const { agreementDetails, loading } = useSelector(
    (state) => state?.agreementReducer
  );
  const [numPages, setNumPages] = useState(null);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (agreementId) {
      dispatch(getAgreementDetailsData(agreementId));
    }
  }, [agreementId]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDownloadAgreement = (link) => {
    link && window.open(`${process.env.REACT_APP_IMAGE_URL}${link}`, "_blank");
  };

  const onSubmit = () => {
    if (!agree) {
      message.info(
        "Please agree to the terms and conditions to submit the agreement"
      );
      return;
    }
    dispatch(
      updateAgreementDetailsData(
        agreementId,
        {
          completed: true,
        },
        () => {
          message.success("Updated.");
        },
        () => {
          message.error("Failed to update.");
        }
      )
    );
  };

  return (
    <div className="agreement-details-page">
      <Spin spinning={loading}>
        <div className="container">
          <div className="card-wrapper d-flex justify-content-center align-items-center flex-column">
            <div className="sub-header d-flex justify-content-start align-items-center">
              <a
                className="text-capitalize d-flex align-items-center justify-content-start text-decoration-none"
                onClick={() => navigate("/agreements")}
              >
                <span className="back-icon d-flex align-items-center justify-content-center">
                  <ArrowLeftOutlined />
                </span>
                <span className="back-text text-capitalize">back</span>
              </a>
            </div>
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="text-capitalize font-weight-bold mb-0">
                  {agreementDetails?.name}
                </h5>
                {agreementDetails?.agreement_pdf && (
                  <span
                    onClick={() =>
                      onDownloadAgreement(agreementDetails?.agreement_pdf)
                    }
                    className="download-icon rounded-circle d-flex justify-content-center align-items-center"
                  >
                    <DownloadOutlined />
                  </span>
                )}
              </div>
              <div className="card-body">
                <div className="pdf-wrapper">
                  {!!agreementDetails?.agreement_pdf ? (
                    <Document
                      file={`${process.env.REACT_APP_IMAGE_URL}${agreementDetails?.agreement_pdf}`}
                      onLoadSuccess={onDocumentLoadSuccess}
                      className="agreement-details overflow-auto h-100 w-100"
                    >
                      {Array.from({ length: numPages }, (v, i) => i)?.map(
                        (item) => (
                          <Page pageIndex={item} />
                        )
                      )}
                    </Document>
                  ) : (
                    <div className="agreement-details overflow-auto h-100 w-100">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: agreementDetails?.details,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-center align-items-center flex-column">
                {!agreementDetails?.completed ? (
                  <>
                    <div className="card-agreement d-flex justify-content-center align-items-center">
                      <Checkbox
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                      ></Checkbox>
                      <span className="agree-text">
                        I agree to the terms and conditions within this
                        agreement.
                      </span>
                    </div>
                    <Button
                      loading={loading}
                      onClick={onSubmit}
                      className="btn text-capitalize"
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <div className="d-flex justify-content-center align-items-center flex-column after-agreement">
                    <div className="d-flex justify-content-center align-items-center">
                      <CheckCircleFilled />
                      <span>
                        You have agreed to the terms and conditions within this
                        agreement.
                      </span>
                    </div>
                    <span>
                      Completion Date:
                      <br />{" "}
                      {moment
                        ?.utc(agreementDetails?.completed_date)
                        ?.local()
                        .format("MM-DD-YYYY (h:mm a)")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default AgreementDetails;
