import {
  GET_SUPPORT_DATA_INIT,
  GET_SUPPORT_DATA_SUCCESS,
  GET_SUPPORT_DATA_FAIL,
  GET_SUPPORT_CHAT_DATA_INIT,
  GET_SUPPORT_CHAT_DATA_SUCCESS,
  GET_SUPPORT_CHAT_DATA_FAIL,
} from "../actions/actionTypes";

const initialState = {
  supportName: null,
  loading: false,
  chatData: [],
};

export const supportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUPPORT_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SUPPORT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        supportName: payload,
      };
    }
    case GET_SUPPORT_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_SUPPORT_CHAT_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SUPPORT_CHAT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        chatData: payload,
      };
    }
    case GET_SUPPORT_CHAT_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
