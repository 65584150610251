import {
  SET_SETTING_TAB,
  GET_CARD_DATA_INIT,
  GET_CARD_DATA_SUCCESS,
  GET_CARD_DATA_FAIL,
  GET_ACCOUNT_DATA_INIT,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAIL,
  DELETE_ACCOUNT_INIT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILED,
  POST_LINK_TOKEN_INIT,
  POST_LINK_TOKEN_SUCCESS,
  POST_LINK_TOKEN_FAILED,
  GET_LINK_TOKEN_INIT,
  GET_LINK_TOKEN_SUCCESS,
  GET_LINK_TOKEN_FAILED,
  DELETE_CREDIT_DEBIT_DATA_INIT,
  DELETE_CREDIT_DEBIT_DATA_SUCCESS,
  DELETE_CREDIT_DEBIT_DATA_FAIL,
  ADD_CREDIT_DEBIT_DATA_INIT,
  ADD_CREDIT_DEBIT_DATA_SUCCESS,
  ADD_CREDIT_DEBIT_DATA_FAIL,
  UPDATE_AUTOPAY_DATA_INIT,
  UPDATE_AUTOPAY_DATA_SUCCESS,
  UPDATE_AUTOPAY_DATA_FAIL,
} from "../actions/actionTypes";
const initialState1 = {
  currentTab: 0,
  cardList: [],
  accountList: [],
  accountDetail: {},
  total_count: 0,
  get_link_token_data: {},
};
export const settingReducer = (state = initialState1, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SETTING_TAB: {
      return {
        ...state,
        currentTab: payload,
      };
    }
    case GET_CARD_DATA_INIT: {
      return {
        ...state,
        CardListLoading: true,
      };
    }
    case GET_CARD_DATA_SUCCESS: {
      return {
        ...state,
        CardListLoading: false,
        cardList: payload?.data,
      };
    }
    case GET_CARD_DATA_FAIL: {
      return {
        ...state,
        CardListLoading: false,
      };
    }
    case GET_ACCOUNT_DATA_INIT: {
      return {
        ...state,
        AccountListLoading: true,
      };
    }
    case GET_ACCOUNT_DATA_SUCCESS: {
      return {
        ...state,
        AccountListLoading: false,
        accountList: payload?.data,
      };
    }
    case GET_ACCOUNT_DATA_FAIL: {
      return {
        ...state,
        AccountListLoading: false,
      };
    }
    case DELETE_ACCOUNT_INIT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        accountList: action.payload?.data,
        totalAccount: action.payload.total_count,
      };
    case DELETE_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case ADD_CREDIT_DEBIT_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case ADD_CREDIT_DEBIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_CREDIT_DEBIT_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_CREDIT_DEBIT_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CREDIT_DEBIT_DATA_SUCCESS:
      return {
        ...state,
        deleteList: payload,
        loading: false,
      };
    case DELETE_CREDIT_DEBIT_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    case POST_LINK_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      };
    case POST_LINK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        create_link_token_data: action.payload,
      };
    case POST_LINK_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case GET_LINK_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_LINK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        get_link_token_data: action.payload,
      };
    case GET_LINK_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_AUTOPAY_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_AUTOPAY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_AUTOPAY_DATA_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};
