import React, { useEffect } from "react";
import { Row, Col, Divider, Button, Spin, Empty } from "antd";
import "./Perks.scss";
import PerkIcon from "../../Assets/Icons/PerkIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPerkList } from "../../redux/actions/perkAction";
import { getPerkType } from "../../../src/Utils/general";
function Perks() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPerkList());
  }, []);
  const { PerksList, PerksListLoading } = useSelector(
    (state) => state?.perksReducer
  );
  return (
    <div className="mainDashboardPage updatesPage perkPage w-100">
      <div className="dashboard-content w-100">
        <Row gutter={[24, 24]}>
          <Col span={24} className="marginBottom30">
            <div className="welcomeCon marginBottom30">
              <div className="welcomeContentCon d-flex align-items-center align-items-lg-start flex-column">
                <div className="welcomeText">Awesome Perks</div>
                <div className="welcomeSubText">
                  Things you will need at a discount!
                </div>
              </div>
              <div className="flyIconCon frameIcon">
                <PerkIcon />
              </div>
            </div>
          </Col>
        </Row>
        <Spin spinning={PerksListLoading}>
          <Row gutter={[24, 24]}>
            {PerksList && PerksList?.length !== 0 ? (
              PerksList?.map((data, key) => {
                return (
                  <>
                    <Col
                      key={key}
                      xl={{ span: 6 }}
                      lg={{ span: 8 }}
                      md={{ span: 8 }}
                      sm={{ span: 12 }}
                      xs={{ span: 24 }}
                      className="updateColumn"
                    >
                      <div className="d-flex flex-column align-items-center updateCard">
                        <div className="updateIcon">
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + data?.image}
                            alt="perk"
                          />
                        </div>
                        <div className="highValueText">
                          {getPerkType(data?.perk_type)}
                        </div>
                        <Divider className="perks-custom-divider" />
                        <div className="amazonBottomCon">
                          <div className="amazonHeadingText">{data?.name}</div>
                          <div className="amazonNormalText">
                            {data?.description}
                          </div>

                          <Button
                            className="learnMoreButton"
                            onClick={() =>
                              navigate(`/perk/detail/${data?.id}`, {
                                state: { data },
                              })
                            }
                          >
                            Learn more
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })
            ) : (
              <div className="m-auto">
                <Empty description="No Perks Data" />
              </div>
            )}
          </Row>
        </Spin>
      </div>
    </div>
  );
}

export default Perks;
