import React, { useEffect, useState } from "react";
import { Button, Progress, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import FlyIcon from "../../../Assets/Icons/FlyIcon";
import TickIcon from "../../../Assets/Icons/TickIcon";
import CalendlyPopup from "../../CalendlyPopup";
import { useNavigate } from "react-router-dom";
import { GetscheduleMeetingDetails } from "../../../redux/actions/dashboardActions";
import NoScheduleMeeting from "../../../Pages/NoScheduleMeeting/NoScheduleMeeting";
const ProjectOnbordingProgress = ({ boarding_stage = 0 }) => {
  const dispatch = useDispatch();
  const [noDataModal, setNoDataModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { Loader, scheduleMeetingData } = useSelector(
    (state) => state.dashboardReducer
  );
  const projectProgressTypes = [
    { id: 1, title: "Share your idea" },
    {
      id: 2,
      title: "Schedule Call with Startup Development Analyst",
    },
    { id: 3, title: "Finalize Work Agreement" },
    { id: 4, title: "Complete Invoice & Get Started!" },
  ];
  useEffect(() => {
    setLoading(true);
    dispatch(
      GetscheduleMeetingDetails(
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  }, []);
  const [projectProgress, updateProjectProgress] =
    useState(projectProgressTypes);
  const [calendlyPopup, setCalendlyPopup] = useState(false);
  const navigate = useNavigate();
  const completeNow = (progressId) => {
    if (progressId === 2) {
      if (Object.keys(scheduleMeetingData)?.length !== 0) {
        setCalendlyPopup(true);
      } else {
        setNoDataModal(true);
      }
    } else if (progressId === 3) {
      navigate("/agreements");
    } else if (progressId === 4) {
      navigate("/invoices");
    }
  };

  return (
    <>
      {!noDataModal ? (
        <div className="dashboard-page">
          <div className="mainContainer">
            <div className="dashboard-content">
              <div className="welcomeCon">
                <div className="welcomeContentCon">
                  <div className="welcomeText">Welcome, johnny!</div>
                  <div className="welcomeSubText">
                    We are so excited to learn more about you and your vision.
                    Being an entrepreneur is not an easy journey. By getting
                    here, you’ve already taken the first big step. Now let’s
                    work together to complete the next steps of the startup
                    journey!
                  </div>
                </div>
                <div className="flyIconCon">
                  <FlyIcon />
                </div>
              </div>
              <div className="bottomContentCon">
                {loading ? (
                  <Spin />
                ) : (
                  <>
                    <div className="projectOnboardingCon">
                      <div className="onboardingText">Project Onboarding</div>
                      <div className="percentText">
                        {boarding_stage * 25}% completed
                      </div>
                    </div>
                    <Progress percent={boarding_stage * 25} showInfo={false} />

                    <div className="dashboardlistCon">
                      {projectProgress?.map((progressType, index) => (
                        <div
                          key={index}
                          className={
                            progressType.id <= boarding_stage + 1
                              ? "dashboardlistItem"
                              : "dashboardlistItem disabledListItem"
                          }
                        >
                          <div
                            className={
                              progressType.id === boarding_stage
                                ? "disabledCircle enableOpacity"
                                : "disabledCircle"
                            }
                          >
                            <TickIcon />
                          </div>
                          <div className="itemContentText">
                            {progressType.title}
                          </div>
                          {progressType.id <= boarding_stage ? (
                            <div className="completedText">Completed!</div>
                          ) : (
                            <Button
                              className="completeNow"
                              onClick={() => {
                                completeNow(progressType?.id);
                              }}
                            >
                              Complete Now
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {calendlyPopup && (
            <CalendlyPopup
              setCalendlyPopup={setCalendlyPopup}
              calendlyPopup={calendlyPopup}
            />
          )}
        </div>
      ) : (
        <NoScheduleMeeting page="dashbaord" setNoDataModal={setNoDataModal} />
      )}
    </>
  );
};

export default ProjectOnbordingProgress;
