import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import api from "../../Utils/api";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [eyeController, setEyeController] = useState({
    currPassword: false,
    newPassword: false,
    confPassword: false,
  });

  const rules = {
    currPassword: [
      {
        required: true,
        message: "Please enter current password",
      },
    ],
    newPassword: [
      {
        required: true,
        message: "Please enter new password",
      },
      {
        validator(_, value) {
          if (
            !value ||
            new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$").test(value)
          ) {
            return Promise.resolve();
          }
          return Promise.reject("Please choose a strong password");
        },
      },
    ],
    confPassword: [
      {
        required: true,
        message: "Please confirm your password",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("newPassword") === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Both passwords must match"));
        },
      }),
    ],
  };

  const onFinish = (values) => {
    setLoader(true);
    const bodyFormData = {
      old_password: values.currPassword,
      new_password: values.newPassword,
      confirm_password: values.confPassword,
    };
    api
      .postWithToken(
        `${process.env.REACT_APP_API_URL}/client/changePassword`,
        bodyFormData
      )
      .then((res) => {
        setLoader(false);
        if (res.result) {
          message.info(res.data);
          form.resetFields();
        } else {
          message.error(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="d-flex justify-content-center change-password-wrapper">
      <div className="change-password-main">
        <h5 className="change-password-headding">Change password</h5>
        <Form onFinish={onFinish}>
          <Form.Item
            className="change-password-formgroup mb-4"
            name="currPassword"
            rules={rules.currPassword}
          >
            <div className="formgroup-wrapper">
              <Input
                className="formgroup-input"
                placeholder="Current password"
                type={eyeController.currPassword ? "text" : "password"}
              />
              <span
                onClick={() =>
                  setEyeController({
                    ...eyeController,
                    currPassword: !eyeController.currPassword,
                  })
                }
              >
                {eyeController.currPassword ? (
                  <EyeFilled className="change-password-icon" />
                ) : (
                  <EyeInvisibleFilled className="change-password-icon" />
                )}
              </span>
            </div>
          </Form.Item>
          <Form.Item
            className="change-password-formgroup mb-4"
            name="newPassword"
            rules={rules.newPassword}
          >
            <div className="formgroup-wrapper">
              <Input
                className="formgroup-input"
                placeholder="New password"
                type={eyeController.newPassword ? "text" : "password"}
              />
              <span
                onClick={() =>
                  setEyeController({
                    ...eyeController,
                    newPassword: !eyeController.newPassword,
                  })
                }
              >
                {eyeController.newPassword ? (
                  <EyeFilled className="change-password-icon" />
                ) : (
                  <EyeInvisibleFilled className="change-password-icon" />
                )}
              </span>
            </div>
          </Form.Item>
          <Form.Item
            className="change-password-formgroup mb-4"
            name="confPassword"
            rules={rules.confPassword}
          >
            <div className="formgroup-wrapper">
              <Input
                className="formgroup-input"
                placeholder="Confirm new pasword"
                type={eyeController.confPassword ? "text" : "password"}
              />

              <span
                onClick={() =>
                  setEyeController({
                    ...eyeController,
                    confPassword: !eyeController.confPassword,
                  })
                }
              >
                {eyeController.confPassword ? (
                  <EyeFilled className="change-password-icon" />
                ) : (
                  <EyeInvisibleFilled className="change-password-icon" />
                )}
              </span>
            </div>
          </Form.Item>
          <Button
            className="change-password-button"
            htmlType="submit"
            loading={loader}
          >
            Change Password
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
