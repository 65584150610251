import { applyMiddleware, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import indexReducer from "./redux/reducers";

const enhancers = [];

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, indexReducer);

const middleware = [thunkMiddleware];

/* eslint-disable */
const devToolsExtension =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
/* eslint-enable */

if (typeof devToolsExtension === "function") {
  enhancers.push(devToolsExtension);
}

const composeEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
export const store = createStore(persistedReducer, composeEnhancers);

// export default store;
export const persistor = persistStore(store);

// export const store = createStore(
//   persistedReducer,
//   composeWithDevTools(applyMiddleware(...middlewares))
// );

// export const persistor = persistStore(store);
