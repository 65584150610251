import React, { useEffect, useState } from "react";
import {
  CheckCircleFilled,
  DownloadOutlined,
  InfoCircleFilled,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { Tooltip, Pagination, Spin, Empty } from "antd";
import invoicesIcon from "../../../src/Assets/images/Invoices/invoices_icon.png";
import yourCreditIcon from "../../../src/Assets/images/Invoices/your_credit.png";
import "./InvoicesList.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInvoicesData } from "../../redux/actions/invoicesAction";
import { convertUTCToTimezone } from "../../Utils/general";

function InvoicesList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invoices, totalInvoiceCount, creditAmount, loading } = useSelector(
    (state) => state?.invoicesReducer
  );
  const { profileData } = useSelector((state) => state?.dashboardReducer);
  const [invoicesList, setInvoicesList] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    dispatch(getInvoicesData(1, filter));
    setPage(1);
  }, [filter]);
  useEffect(() => {
    setInvoicesList(invoices);
  }, [invoices]);
  const onPageChange = (page) => {
    setPage(page);
    dispatch(getInvoicesData(page, filter));
  };
  const onDownLoadInvoice = (link) => {
    link && window.open(`${process.env.REACT_APP_IMAGE_URL}${link}`);
  };

  return (
    <div className="invoices-list-page">
      <Spin spinning={loading}>
        <div>
          <div className="invoices-list-header d-flex justify-content-between align-items-center">
            <div className="header-invoices d-flex align-items-center flex-column">
              <img className="header-invoices-img" src={invoicesIcon} />
              <span className="header-invoices-text">Invoices</span>
            </div>

            <div className="header-credit d-flex justify-content-center align-items-center">
              <img src={yourCreditIcon} />
              <div className="header-credit-text d-flex flex-column">
                <span className="header-your-credit">
                  Your Credit{" "}
                  <Tooltip
                    placement="bottom"
                    color={"#000"}
                    title={
                      "Your credit will be auto-applied to your next invoice."
                    }
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </span>
                <span className="header-credit-amount">${creditAmount}</span>
              </div>
            </div>

            <div className="all-complete-tabs d-flex justify-content-center align-items-center">
              <div
                onClick={() => setFilter("all")}
                className={`tabs d-flex justify-content-center align-items-center ${
                  filter === "all" && "active"
                }`}
              >
                <span className="text-capitalize">all</span>
              </div>
              <div
                onClick={() => setFilter("unpaid")}
                className={`tabs d-flex justify-content-center align-items-center ${
                  filter === "unpaid" && "active"
                }`}
              >
                <span className="text-capitalize">incomplete</span>
              </div>
              <div
                onClick={() => setFilter("paid")}
                className={`tabs d-flex justify-content-center align-items-center ${
                  filter === "paid" && "active"
                }`}
              >
                <span className="text-capitalize">complete</span>
              </div>
            </div>
          </div>
          <div className="invoices-grid-wrap">
            <div className="main-invoices-wrapper">
              <div className="table-header flex justify-content-start align-items-center d-none d-sm-none d-md-none d-lg-flex">
                <div className="th-data">Invoice</div>
                <div className="th-data">Amount Due</div>
                <div className="th-data">Due Date</div>
                <div className="th-data">Status</div>
                <div className="th-data justify-content-center">Action</div>
              </div>
              <div className="table-body">
                {invoicesList?.map((invoice, index) => (
                  <div
                    onClick={() =>
                      navigate(`/invoices/invoice-details/${invoice?.id}`, {
                        state: { invoice },
                      })
                    }
                    role={"button"}
                    className="table-row"
                    key={index}
                  >
                    <div className="invoice-cell d-flex flex-column flex-md-row">
                      <div className="invoice-icon-wrap">
                        <img src={invoicesIcon} alt="" />
                      </div>
                      <span className="cell-text-primary">
                        {invoice?.id?.slice(-3)}
                      </span>
                    </div>
                    <div className="invoice-cell">
                      <div className="d-flex align-items-center flex-row invoice-wrap">
                        <span className="d-flex d-sm-flex d-md-none cell-text-lable">
                          Amount Due
                        </span>
                        <span className="cell-text-primary">
                          ${invoice?.amount}
                        </span>
                      </div>
                    </div>
                    <div className="invoice-cell">
                      <div className="d-flex align-items-center flex-row invoice-wrap">
                        <span className="d-flex d-sm-flex d-md-none cell-text-lable">
                          Due Date
                        </span>
                        <span className="cell-text-primary">
                          {convertUTCToTimezone(
                            invoice?.due_date,
                            true,
                            profileData?.time_zone
                              ? profileData?.time_zone
                              : "",
                            "MM-DD-YYYY"
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="invoice-cell">
                      <div className="d-flex align-items-center flex-row invoice-wrap">
                        <span className="d-flex d-sm-flex d-md-none cell-text-lable">
                          Status
                        </span>
                        <div className="d-flex align-items-center">
                          {invoice?.paid ? (
                            <>
                              <CheckCircleFilled className="paid-icon" />
                              <span className="cell-text-primary paid-text">
                                Paid
                              </span>
                            </>
                          ) : (
                            <>
                              <InfoCircleFilled className="incomplete-icon" />
                              <span className="cell-text-primary incomplete-text">
                                Incomplete
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="invoice-cell justify-content-center">
                      {!invoice?.paid ? (
                        <div
                          className="paynow-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/invoices/invoice-details/${invoice?.id}`,
                              {
                                state: { invoice },
                              }
                            );
                          }}
                        >
                          Pay Now
                        </div>
                      ) : (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            onDownLoadInvoice(invoice?.invoice_pdf);
                          }}
                          className="download-btn"
                        >
                          <DownloadOutlined />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!!invoicesList?.length && (
            <div className="paginationContainer">
              <Pagination
                current={page}
                onChange={(page) => onPageChange(page)}
                total={totalInvoiceCount}
                pageSize={5}
              />
            </div>
          )}
          {!invoicesList?.length && !loading && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </Spin>
    </div>
  );
}

export default InvoicesList;
