import React, { useEffect, useState } from "react";
import "./EditDevelopment.scss";
import { useNavigate } from "react-router-dom";
import { Button, Space } from "antd";
import IMAGES from "../../Assets/images/Onboarding";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectData } from "../../redux/actions/dashboardActions";

const EditDevelopment = () => {
  const navigate = useNavigate();
  const [selectedPhase, setSelectedPhase] = useState();
  const dispatch = useDispatch();
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );

  const developmentPhases = [
    {
      title: "I need strategic advice before I start.",
      shortTitle: "ADVISORY",
      image: "strategicAdvice",
    },
    {
      title:
        "I am ready with Wireframes/Screen designs and functional requirements.",
      shortTitle: "DEVELOPMENT",
      image: "wireframe",
    },
    {
      title: "I want to enhance/upgrade my existing product.",
      shortTitle: "UPGRADATION",
      image: "thirdPartyApi",
    },
  ];

  useEffect(() => {
    setSelectedPhase(projectData?.stage);
  }, [projectData]);

  return (
    <div className="editDevelopment-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">Edit development stage</div>
                <div className="projectTypeCon phaseProjectMainCon">
                  {developmentPhases?.map((stage, index) => (
                    <div
                      key={index}
                      className={`phaseProjectCon ${
                        selectedPhase === stage.title &&
                        "selectedProjectTypeItem"
                      }`}
                      onClick={() => setSelectedPhase(stage.title)}
                    >
                      <img src={IMAGES[stage.image]} alt="" />
                      <div className="phaseProjectText m-3">{stage?.title}</div>
                    </div>
                  ))}
                </div>
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() =>
                        dispatch(
                          updateProjectData(
                            {
                              ...projectData,
                              stage: selectedPhase,
                            },
                            navigate
                          )
                        )
                      }
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDevelopment;
