import * as React from "react";

function ProjectInfoIcon(props) {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3927 17.7232C12.3927 17.5947 12.3927 17.4872 12.3927 17.3796C12.3927 14.7584 12.3921 12.1373 12.3934 9.51609C12.3941 8.47365 12.9498 7.91375 13.9857 7.91309C15.7998 7.91178 17.6146 7.91178 19.4287 7.91309C20.4194 7.91375 20.9987 8.4907 20.9994 9.47675C21.0007 12.1196 21 14.763 21 17.4059C21 17.5035 21 17.6012 21 17.7238C18.1362 17.7232 15.2901 17.7232 12.3927 17.7232ZM16.6967 9.75802C16.4461 9.75802 16.1955 9.74884 15.9448 9.75998C15.5925 9.77572 15.3583 10.0006 15.3393 10.3225C15.3189 10.6647 15.5328 10.9486 15.891 10.967C16.4231 10.9939 16.9585 10.9939 17.4906 10.9676C17.8475 10.9499 18.0686 10.6634 18.0502 10.3278C18.0325 10.0085 17.7957 9.77768 17.4473 9.76064C17.198 9.74884 16.9467 9.75867 16.6967 9.75802Z"
        fill="black"
      />
      <path
        d="M2.67563 13.5484C2.03725 13.5484 1.45004 13.5484 0.822161 13.5484C0.822161 13.4061 0.822161 13.2776 0.822161 13.1491C0.822161 9.60941 0.84578 6.07036 0.809695 2.53131C0.797229 1.24301 1.72166 0.426759 2.91051 0.434627C7.94996 0.468063 12.9901 0.468063 18.0295 0.434627C19.1895 0.426759 20.1566 1.25022 20.1034 2.51819C20.0503 3.78289 20.0916 5.05218 20.0916 6.31949C20.0916 6.43816 20.0916 6.55617 20.0916 6.67484C19.4716 6.67484 18.8785 6.67484 18.2467 6.67484C18.2467 6.52602 18.2467 6.40931 18.2467 6.29261C18.2467 5.21084 18.2487 4.12971 18.2454 3.04794C18.2441 2.51229 18.0249 2.29201 17.4863 2.29201C12.8077 2.29004 8.12842 2.29069 3.44982 2.29201C2.89082 2.29201 2.67759 2.50639 2.67759 3.0663C2.67628 6.40931 2.67694 9.75168 2.67694 13.0947C2.67563 13.2337 2.67563 13.374 2.67563 13.5484Z"
        fill="black"
      />
      <path
        d="M11.144 15.6043C11.144 16.4435 11.144 17.2368 11.144 18.0602C11.0285 18.0661 10.9235 18.076 10.8179 18.076C8.1194 18.0766 5.42089 18.0806 2.72237 18.0747C1.35048 18.0714 0.262022 17.1476 0.029109 15.8128C-0.0843952 15.1624 0.120306 14.7756 0.886624 14.7874C2.77617 14.8162 4.66704 14.8018 6.55659 14.7913C6.8164 14.79 7.027 14.8326 7.18381 15.049C7.51645 15.5066 7.95866 15.6443 8.52224 15.6161C9.38107 15.5722 10.2445 15.6043 11.144 15.6043Z"
        fill="black"
      />
      <path
        d="M12.3931 18.9883C15.2687 18.9883 18.1142 18.9883 20.982 18.9883C20.982 19.4112 21.0227 19.8268 20.9728 20.2307C20.8902 20.9014 20.2905 21.4213 19.6134 21.4246C17.67 21.4351 15.726 21.4357 13.782 21.4252C13.0203 21.4213 12.4154 20.8057 12.3944 20.0478C12.3845 19.7009 12.3931 19.3535 12.3931 18.9883Z"
        fill="black"
      />
    </svg>
  );
}

export default ProjectInfoIcon;
