import React from "react";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../Assets/images/Onboarding";
import "./style.scss";
const LogoHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="header">
        <div className="d-flex header-logo">
          <div
            className="logo"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={IMAGES.iblogo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoHeader;
