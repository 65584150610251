import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Badge, Popover, Spin, Empty } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import Tubelight from "../../../src/Assets/images/MainLayout/tubelight.png";
import dashboardIcon from "../../../src/Assets/images/MainLayout/dashboard.png";
import updatesIcon from "../../../src/Assets/images/MainLayout/updates_icon.png";
import invoicesIcon from "../../../src/Assets/images/MainLayout/invoices_icon.png";
import perksIcon from "../../../src/Assets/images/MainLayout/perks_icon.png";
import reviewsIcon from "../../../src/Assets/images/MainLayout/reviews_icon.png";
import supportIcon from "../../../src/Assets/images/MainLayout/support_icon.png";
import profileImage from "../../Assets/images/Profile/user.png";
import DropDownIcon from "../../../src/Assets/images/MainLayout/drop-down.png";
import profileIcon from "../../../src/Assets/images/MainLayout/profile-icon.png";
import projectInfoIcon from "../../../src/Assets/images/MainLayout/project-info-icon.png";
import agreementsIcon from "../../../src/Assets/images/MainLayout/agreements-icon.png";
import settingIcon from "../../../src/Assets/images/MainLayout/setting-icon.png";
import logoutIcon from "../../../src/Assets/images/MainLayout/logout-icon.png";
import notification from "../../../src/Assets/images/MainLayout/notification.svg";
import notificationLight from "../../../src/Assets/images/MainLayout/notification-icon-light.png";
import yellowDotImg from "../../Assets/images/MainLayout/yellowdot.svg";
import moment from "moment";
import "./MainLayout.scss";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileData,
  getNotifications,
  getUserStatus,
  clearNotifications,
} from "../../redux/actions/dashboardActions";
const { Header, Content, Sider } = Layout;
function MainLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [isOpened, setIsOpen] = useState(false);
  const [activeKey1, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profileData, notifications, notificationCount, userStatus } =
    useSelector((state) => state?.dashboardReducer);
  const path = window?.location?.href?.split("/")[3];
  useEffect(() => {
    if (profileData) {
      dispatch(getUserStatus());
    }
  }, [profileData]);
  useEffect(() => {
    dispatch(getProfileData(navigate));
  }, []);
  const onSetActiveKey = (path) => {
    let index = {
      dashboard: "1",
      updates: "2",
      invoices: "3",
      perks: "4",
      reviews: "5",
      support: "6",
    };
    return index[path];
  };
  const popup_content = (
    <div className="popup-content-wrapper">
      You can only access this feature once your project has started.
    </div>
  );
  useEffect(() => {
    let key = onSetActiveKey(path);
    setActiveKey(key);
  }, [path]);

  const onClearNotification = () => {
    setLoading(true);
    dispatch(
      clearNotifications(() => {
        dispatch(getUserStatus());
        setLoading(false);
      })
    );
  };
  const menu = (
    <div className="profile-menu-wrap mt-1">
      <div className="profile-menu-item" onClick={() => navigate("/profile")}>
        <img src={profileIcon} alt="profileIcon" />
        <span className="item-text">Profile</span>
      </div>
      <div
        className="profile-menu-item"
        onClick={() => navigate("/project-info")}
      >
        <img src={projectInfoIcon} alt="projectInfoIcon" />
        <span className="item-text">Project Info</span>
      </div>
      <div
        className="profile-menu-item"
        onClick={() => navigate("/agreements")}
      >
        <img src={agreementsIcon} alt="agreementsIcon" />
        <span className="item-text">Agreements</span>
      </div>
      <div onClick={() => navigate("/settings")} className="profile-menu-item">
        <img src={settingIcon} alt="settingIcon" />
        <span className="item-text">Settings</span>
      </div>
      <div
        className="profile-menu-item"
        onClick={() => dispatch(userLogout(navigate))}
      >
        <img src={logoutIcon} alt="logoutIcon" />
        <span className="item-text">Logout</span>
      </div>
    </div>
  );
  const notificationsModal = (
    <>
      <div className="notification-modal pop-header">
        <div className="notification-header">
          Notifications
          {notificationCount ? (
            <div onClick={onClearNotification}>Clear all</div>
          ) : (
            ""
          )}
        </div>
      </div>
      {loading && <Spin spinning={loading} />}
      <div
        className={`${
          notifications?.length !== 0 ? "notification-content" : ""
        }`}
      >
        {!loading &&
          notifications &&
          notifications?.length !== 0 &&
          notifications?.map((item, key) => {
            return (
              <div className="notification-data-wrap" key={key}>
                <div className="list-icon">
                  <img src={yellowDotImg} alt="yellowDotImg" />
                </div>
                <div className="notification-data">
                  <p>{item?.activity}</p>
                  <span>{moment.utc(item?.created_at).local().fromNow()}</span>
                </div>
              </div>
            );
          })}
      </div>
      {!loading && (!notifications || notifications?.length === 0) && (
        <Empty description="Notifications not found" />
      )}
    </>
  );
  return (
    <Layout className="main-layout">
      <Sider
        collapsed={collapsed}
        trigger={null}
        onBreakpoint={(broken) => {
          if (broken) {
            setCollapsed(true);
          } else {
            setIsOpen(false);
            setCollapsed(false);
          }
        }}
        breakpoint="md"
        collapsedWidth={0}
        className={isOpened ? "opened" : "closed"}
      >
        <Menu
          className="left-menu flex flex-column"
          mode="inline"
          onClick={({ key }) => {
            setActiveKey(key);
          }}
          activeKey={activeKey1}
          selectedKeys={activeKey1}
        >
          {isOpened && (
            <Menu.Item
              disabled
              className="close-item"
              onClickCapture={() => setIsOpen(false)}
              icon={<CloseOutlined />}
            ></Menu.Item>
          )}
          <Menu.Item disabled className="lamp-menu-item">
            <img src={Tubelight} alt="Tubelight" />
          </Menu.Item>
          <Menu.Item
            key="1"
            onClickCapture={() => {
              navigate("/dashboard");
            }}
          >
            <img src={dashboardIcon} alt="dashboardIcon" />
            <p className="left-menu-p">Dashboard</p>
          </Menu.Item>
          {userStatus?.boarding_stage !== 4 ? (
            <Menu.Item key="2" className="item-disabled" disabled>
              <Popover
                placement="right"
                trigger={["hover"]}
                overlayClassName="menu-popover-custom-main"
                content={popup_content}
              >
                <img src={updatesIcon} alt="updatesIcon" />
                <p className="left-menu-p">Updates</p>
              </Popover>
            </Menu.Item>
          ) : (
            <Menu.Item
              key="2"
              onClickCapture={() => {
                navigate("/updates");
              }}
            >
              <img src={updatesIcon} alt="updatesIcon" />
              <p className="left-menu-p">Updates</p>
            </Menu.Item>
          )}
          <Menu.Item
            key="3"
            onClickCapture={() => {
              navigate("/invoices");
            }}
          >
            <img src={invoicesIcon} alt="invoicesIcon" />
            <p className="left-menu-p">Invoices</p>
          </Menu.Item>
          {userStatus?.boarding_stage !== 4 ? (
            <Menu.Item key="4" disabled className="item-disabled">
              <Popover
                placement="right"
                trigger={["hover"]}
                content={popup_content}
                overlayClassName="menu-popover-custom-main"
              >
                <img src={perksIcon} alt="perksIcon" />
                <p className="left-menu-p">Perks</p>
              </Popover>
            </Menu.Item>
          ) : (
            <Menu.Item
              key="4"
              onClickCapture={() => {
                navigate("/perks");
              }}
            >
              <img src={perksIcon} alt="perksIcon" />
              <p className="left-menu-p">Perks</p>
            </Menu.Item>
          )}
          {userStatus?.boarding_stage !== 4 ? (
            <Menu.Item key="5" disabled className="item-disabled">
              <Popover
                placement="right"
                trigger={"hover"}
                content={popup_content}
                overlayClassName="menu-popover-custom-main"
              >
                <img src={reviewsIcon} alt="reviewsIcon" />
                <p className="left-menu-p">Reviews</p>
              </Popover>
            </Menu.Item>
          ) : (
            <Menu.Item
              key="5"
              onClickCapture={() => {
                navigate("/reviews");
              }}
            >
              <img src={reviewsIcon} alt="reviewsIcon" />
              <p className="left-menu-p">Reviews</p>
            </Menu.Item>
          )}
          <Menu.Item
            key="6"
            onClickCapture={() => {
              navigate("/support");
            }}
          >
            <img src={supportIcon} alt="supportIcon" />
            <p className="left-menu-p">Support</p>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <MenuOutlined
            onClick={() => {
              setCollapsed(false);
              setIsOpen(true);
            }}
            className="d-md-none d-flex text-white menu-icon"
          />
          <div className="d-flex align-items-center justify-content-center">
            <Popover
              content={notificationsModal}
              placement="bottom"
              trigger={["click"]}
              overlayClassName="notificationModal-wrap"
              onVisibleChange={(e) => {
                if (e) {
                  setLoading(true);
                  dispatch(
                    getNotifications(() => {
                      setLoading(false);
                    })
                  );
                } else {
                  if (notifications?.length !== 0) {
                    dispatch(
                      getUserStatus(() => {
                        dispatch(getNotifications());
                      })
                    );
                  }
                }
              }}
            >
              <Badge count={notificationCount} className="mx-4">
                <img
                  role={"button"}
                  src={notificationLight}
                  alt="notification"
                  className="d-md-none"
                />
                <img
                  role={"button"}
                  src={notification}
                  alt="notification"
                  className="d-none d-md-flex"
                />
              </Badge>
            </Popover>
            <Dropdown
              placement="bottomRight"
              overlay={menu}
              trigger={["click"]}
            >
              <div
                role="button"
                className="flex align-items-center justify-content-center"
              >
                <img
                  src={
                    profileData?.profile_image
                      ? process.env.REACT_APP_IMAGE_URL +
                        profileData?.profile_image
                      : profileImage
                  }
                  className="headerProfilepic"
                />

                <span className="about-self-username ">
                  {profileData?.first_name}
                </span>
                <img src={DropDownIcon} alt="DropDownIcon" />
              </div>
            </Dropdown>
          </div>
        </Header>
        {props.Content}
        <Content>
          <div className="site-layout-background d-flex justify-content-center">
            {props?.children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
