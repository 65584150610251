import * as React from "react";

function MainDashboardFrameIcon(props) {
  return (
    <svg
      width="172"
      height="146"
      viewBox="0 0 172 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_354_2009)">
        <path
          d="M82.0115 135.816C82.0115 135.816 81.0796 131.441 83.1174 122.773C85.0322 114.628 87.8994 111.062 87.8994 111.062C87.8994 111.062 94.3301 119.986 92.8862 126.236C91.4322 132.507 82.0115 135.816 82.0115 135.816Z"
          fill="#1A7178"
        />
        <path
          d="M103.372 143.5C99.9311 141.481 77.9869 131.912 75.3758 130.375C72.7646 128.838 55.9404 122.496 53.1142 122.066C50.2879 121.636 41.9117 122.742 38.7271 122.691C35.5425 122.64 33.9143 106.831 32.9927 105.837C32.0711 104.843 23.2238 98.7368 22.1077 96.7082C20.9915 94.6898 17.0901 87.2412 16.3221 85.3765C15.5541 83.5016 14.4277 78.5222 14.4277 78.5222C14.4277 78.5222 18.0424 77.4362 19.9983 81.2066C21.9541 84.9769 23.9816 87.3642 25.4254 89.4235C26.859 91.4931 30.8731 94.8844 32.1838 95.7041C33.5047 96.5237 35.7575 99.6077 37.1092 99.4642C38.4608 99.3208 40.3347 99.7204 42.2803 100.53C44.2259 101.339 48.3014 100.479 49.8374 96.7082C51.3734 92.9275 50.1856 88.8088 48.7724 85.1409C47.3593 81.4832 47.3389 74.9977 49.2435 72.1187C51.1481 69.2397 53.8514 70.1003 54.1586 71.2171C54.4761 72.3339 55.4898 80.2025 55.8994 82.4565C56.309 84.7106 58.5106 90.3456 61.2754 92.7021C64.0299 95.0586 67.2657 94.782 69.8257 100.673C72.3857 106.564 71.5972 106.278 74.4439 109.146C77.3009 112.015 97.1868 116.349 99.286 116.277C101.395 116.216 103.372 143.5 103.372 143.5Z"
          fill="#C66B4B"
        />
        <path
          opacity="0.2"
          d="M32.6961 107.948C34.099 105.396 31.8871 102.466 30.044 100.643C27.4635 98.0914 25.139 96.6262 23.173 93.5423C21.2683 90.5403 18.9541 86.3499 16.4351 79.5673C15.2575 76.3911 12.5132 78.6349 12.7283 81.8213C13.0252 86.1654 16.2303 90.5916 18.4421 94.198C21.5858 99.3413 25.2517 102.743 29.8494 106.288L32.6961 107.948Z"
          fill="black"
          fill-opacity="0.66"
        />
        <path
          opacity="0.1"
          d="M47.66 108.285C48.2436 110.223 48.7606 115.379 50.3341 114.847C51.9001 114.314 47.6748 108.166 49.684 106.465C52.7422 107.981 57.5512 114.018 58.8956 111.962C60.2401 109.905 56.7903 109.084 55.18 107.952C53.5105 106.783 52.299 105.252 51.2057 103.572C49.8761 101.516 48.8641 98.5123 45.3996 100.243C41.7134 102.093 46.3229 103.824 47.66 108.285Z"
          fill="black"
        />
        <path
          d="M34.3651 105.274C32.4605 105.386 28.856 105.222 26.4906 104.495C24.1354 103.757 21.8622 104.075 20.6846 103.44C19.507 102.804 13.4859 98.8292 12.636 98.2452C11.7861 97.6612 9.48211 92.7843 6.99381 92.0773C4.5055 91.3499 2.45752 92.897 2.45752 92.897C2.45752 92.897 4.20855 96.0731 5.91861 98.5116C7.63892 100.95 13.6498 106.626 15.3189 108.235C16.988 109.833 21.2171 112.138 23.5825 112.333C25.9479 112.538 36.024 110.939 36.4029 110.683"
          fill="#C66B4B"
        />
        <path
          opacity="0.2"
          d="M0.901113 93.4909C1.57695 88.4296 5.2838 93.2245 6.43067 94.7921C8.2841 97.3227 10.0966 100.007 12.3289 102.271C17.5512 107.548 24.2891 108.829 31.5287 109.402C33.0647 109.525 36.4132 110.693 35.5735 114.341C34.7543 117.865 28.6206 116.83 26.2756 116.574C14.8172 115.335 3.51229 105.929 0 95.4683L0.901113 93.4909Z"
          fill="black"
          fill-opacity="0.66"
        />
        <path
          d="M36.3927 110.581C35.3175 109.925 33.1261 110.694 30.73 110.284C28.3236 109.874 24.5246 111.636 23.347 111.001C22.1694 110.366 18.3602 108.675 15.6363 108.521C12.9125 108.358 9.58455 106.442 7.63896 106.144C2.52924 105.386 3.21532 109.187 3.21532 109.187C3.21532 109.187 6.37945 110.837 8.94967 112.159C11.5199 113.491 17.5922 116.615 19.3842 117.128C21.1761 117.65 25.5588 119.597 26.634 119.72C27.7195 119.843 40.2019 119.238 42.0656 118.47"
          fill="#C66B4B"
        />
        <path
          opacity="0.1"
          d="M15.9231 113.203C21.6369 116.513 28.3134 116.339 34.7133 115.919C36.577 115.796 41.3078 114.3 42.1475 116C44.2057 120.15 33.7098 122.015 31.498 122.086C23.5621 122.363 17.5922 121.492 11.2639 116.451C10.0351 115.468 -3.93214 110.622 3.22556 108.419C7.0041 107.271 12.8409 111.421 15.9231 113.203Z"
          fill="black"
          fill-opacity="0.66"
        />
        <path
          d="M41.4614 115.089C38.9322 115.13 35.4813 116.503 33.5665 117.066C31.6618 117.63 28.2315 117.814 26.2961 118.398C24.3608 118.982 22.0568 119.494 21.3093 121.226C20.5618 122.947 20.531 123.91 21.8008 123.89C23.0603 123.869 29.2759 123.685 30.8017 123.941C32.3274 124.187 34.7543 123.009 36.7408 122.855C38.7274 122.701 40.5091 122.671 43.96 122.414"
          fill="#C66B4B"
        />
        <path
          d="M82.0117 135.816C82.0117 135.816 88.0226 130.826 88.9442 125.355C89.8657 119.884 87.8997 111.073 87.8997 111.073L172 146H108.513L82.0117 135.816Z"
          fill="black"
        />
        <path
          d="M33.7097 66.7914C30.9757 66.7914 28.2519 66.8221 25.5178 66.7811C21.5447 66.7299 18.995 64.3017 18.8823 60.3264C18.7594 55.7056 17.0596 51.8328 13.9876 48.421C9.65616 43.6055 6.12338 38.2573 5.14035 31.7001C3.10261 18.1042 12.7077 4.38532 26.5418 0.901814C44.6869 -3.66773 62.3201 9.75402 62.4327 28.4625C62.4532 31.4952 61.7159 34.7943 60.3847 37.5094C58.4699 41.413 55.9406 45.0707 53.227 48.4927C50.4725 51.966 48.6395 55.6134 48.4859 60.0702C48.3221 64.4349 45.885 66.7606 41.5023 66.7811C38.9116 66.8016 36.3107 66.7914 33.7097 66.7914ZM13.1787 26.0138C13.1275 27.858 13.9057 29.0773 15.2984 29.0568C17.0801 29.0363 17.6126 27.8888 17.6843 26.2597C17.7252 25.1429 17.9403 24.0057 18.227 22.9196C19.978 16.1473 26.1834 11.8646 32.2147 11.8134C33.72 11.8031 34.8873 11.2294 34.8156 9.49788C34.744 7.79711 33.5561 7.30532 32.0509 7.32581C22.1693 7.4795 13.4552 16.1063 13.1787 26.0138Z"
          fill="black"
        />
        <path
          d="M22.4459 81.3501C22.1182 81.3911 21.7905 81.4423 21.4526 81.4731C20.0497 81.6165 19.0155 81.094 18.8414 79.6391C18.6776 78.174 19.6094 77.4465 20.9816 77.2929C24.4836 76.883 27.9959 76.4937 31.498 76.1044C36.0035 75.6023 40.4988 75.1003 45.0044 74.5983C45.4038 74.5573 45.8031 74.5061 46.2025 74.4958C47.5542 74.4548 48.5884 75.172 48.4655 76.4322C48.3836 77.2314 47.4108 78.4096 46.653 78.5736C44.0521 79.1166 41.3795 79.2908 38.7376 79.5879C33.3104 80.1924 27.873 80.7764 22.4459 81.3706C22.4459 81.3501 22.4459 81.3501 22.4459 81.3501Z"
          fill="black"
        />
        <path
          d="M45.4245 67.58C46.9809 67.3956 48.4145 67.6517 48.5374 69.4959C48.65 71.1762 47.3496 71.627 45.8648 71.7807C37.9903 72.6311 30.1158 73.543 22.2515 74.4241C21.9238 74.4651 21.5859 74.4753 21.2582 74.4958C19.9271 74.5778 18.9952 73.963 18.8519 72.6516C18.7085 71.3197 19.4867 70.541 20.8282 70.3873C24.7296 69.9263 28.631 69.4755 32.5324 69.0246C36.8229 68.5329 41.1237 68.0616 45.4245 67.58Z"
          fill="black"
        />
        <path
          d="M25.559 88.1122C25.1698 87.6716 24.2278 87.0671 23.9718 86.2372C23.5724 84.9463 24.4428 84.0242 25.7433 83.8705C30.8837 83.2353 36.0242 82.6 41.1851 82.18C41.9019 82.1185 43.1204 83.1123 43.3867 83.8602C43.827 85.0897 42.9464 86.0528 41.6254 86.2167C36.4952 86.8725 31.3445 87.4462 25.559 88.1122Z"
          fill="black"
        />
        <path
          d="M13.1786 26.0138C13.4551 16.1063 22.1693 7.4795 32.0406 7.33606C33.5458 7.31557 34.7337 7.80736 34.8053 9.50813C34.877 11.2396 33.7097 11.8134 32.2044 11.8236C26.1731 11.8646 19.9677 16.1575 18.2167 22.9299C17.9299 24.0159 17.7251 25.1532 17.6739 26.27C17.6125 27.899 17.0698 29.0363 15.288 29.067C13.9159 29.0773 13.1274 27.858 13.1786 26.0138Z"
          fill="#E5C243"
        />
      </g>
      <defs>
        <clipPath id="clip0_354_2009">
          <rect width="172" height="146" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MainDashboardFrameIcon;
