import * as React from "react";

function EditRoundIcon(props) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="10.1035"
        cy="10.9346"
        r="9.75"
        stroke="black"
        strokeWidth="0.5"
      />
      <g clipPath="url(#clip0_529_6276)">
        <path
          d="M12.0962 6.75977C12.8193 7.48436 13.5503 8.21654 14.2661 8.93382C12.6139 10.5867 10.9527 12.2488 9.30212 13.8999C8.59531 13.1888 7.86067 12.4499 7.13672 11.7219C8.77293 10.0848 10.4339 8.42272 12.0962 6.75977Z"
          fill="black"
        />
        <path
          d="M8.92647 14.1955C8.4917 14.3204 8.04288 14.4495 7.5935 14.5781C7.19302 14.6926 6.79254 14.8082 6.39149 14.9201C6.18943 14.9767 6.06071 14.8549 6.11664 14.6574C6.35524 13.8147 6.59693 12.9731 6.83778 12.1312C6.84144 12.1188 6.85183 12.1081 6.84678 12.116C7.53785 12.8065 8.23033 13.4991 8.92647 14.1955Z"
          fill="black"
        />
        <path
          d="M14.6077 8.6631C13.8582 7.91264 13.1244 7.1782 12.374 6.42689C12.5137 6.29862 12.6489 6.14673 12.8105 6.03224C13.0246 5.88035 13.3163 5.9082 13.5108 6.09778C13.9942 6.56866 14.4723 7.04515 14.9413 7.53009C15.1602 7.75624 15.1538 8.07212 14.9483 8.30869C14.8387 8.4347 14.7168 8.55031 14.6077 8.6631Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_529_6276">
          <rect
            width="9"
            height="9"
            fill="white"
            transform="translate(6.10352 5.93457)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default EditRoundIcon;
