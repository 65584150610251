import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import { Form, DatePicker } from "antd";
import "./AppLive.scss";
import IMAGES from "../../../Assets/images/Onboarding";
import OnboardingStepper from "../../../Components/OnboardingStepper";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import {
  SaveOnboarding,
  setOnboardingData,
} from "../../../redux/actions/onboardingActions";
import { useDispatch, useSelector } from "react-redux";
import SaveAndNext from "../../../Components/Save&Next";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const AppLive = () => {
  const dispatch = useDispatch();
  const [saveType, setSaveType] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const { payload } = useSelector((state) => state.onboardingReducer);

  const handleChange = (data) => {
    data?.key === "targetDate"
      ? setError({ ...error, dateErr: "" })
      : setError({ ...error, appliveErr: "" });
    dispatch(
      setOnboardingData({
        ...payload,
        [data?.key]:
          data?.key === "targetDate"
            ? data?.value?.format("YYYY/MM/DD")
            : data?.value,
      })
    );
  };

  useEffect(() => {
    if (saveType === "save&Logout") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
    } else if (saveType === "save&next") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
      navigate("/onboarding/ideaDetails");
    }
  }, [saveType]);

  const validateForm = (clickType) => {
    if (payload?.appLive === null) {
      setError({ ...error, appliveErr: "Please select an option" });
    } else if (!payload?.targetDate && payload?.appLive) {
      setError({ ...error, dateErr: "Please select target date" });
    } else if (clickType === "Save&Logout") {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 8,
        })
      );
      setSaveType("save&Logout");
    } else {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 8,
        })
      );
      setSaveType("save&next");
    }
  };

  const defaultValueDate = () => {
    let finalDate;
    try {
      finalDate = moment(
        payload?.targetDate?.split("T")[0].replace("-", "/").replace("-", "/")
      );
    } catch {
      // finalDate = moment(payload?.targetDate?.replace("-", "/"));
      finalDate = "";
    }
    return finalDate;
  };
  const onSave_Logout_Fun = () => {
    dispatch(
      setOnboardingData({
        ...payload,
        steps_completed: 7,
      })
    );
    setSaveType("save&Logout");
  };
  return (
    <div className="onboard pb-3">
      <OnboardingHeader steps_completed={7} validateForm={onSave_Logout_Fun} />
      <Container className="mt-5">
        <Row>
          <div className="d-flex justify-content-center">
            <Col sm={10}>
              <OnboardingStepper activeElement={8} />

              <Row>
                <div className="d-flex justify-content-center">
                  <Col sm={12} md={4}>
                    <div className="AppLive mt-5 mb-3">
                      <span className="title">
                        Have you decided on a target date{" "}
                        <span className="text-yellow">
                          you want the application to go live?
                        </span>
                      </span>
                      <div className="AppLive-links">
                        <ul>
                          <li>
                            <a
                              href={null}
                              className={
                                payload?.appLive ? "project-active" : ""
                              }
                              onClick={() =>
                                handleChange({ key: "appLive", value: true })
                              }
                            >
                              <img src={IMAGES.checkIcon} alt="" />
                              <span>Yes</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href={null}
                              onClick={() =>
                                handleChange({ key: "appLive", value: false })
                              }
                              className={
                                payload?.appLive === false &&
                                payload?.appLive !== ""
                                  ? "project-active"
                                  : ""
                              }
                            >
                              <img src={IMAGES.closeIcon} alt="" />
                              <span> No</span>
                            </a>
                          </li>
                        </ul>
                        <span className="text-danger">{error?.appliveErr}</span>
                      </div>

                      {payload?.appLive && (
                        <>
                          <div className="TargetDate">
                            <Form layout="vertical">
                              <Form.Item name="Target Date" label="Target Date">
                                <DatePicker
                                  placeholder="Please select target date"
                                  disabledDate={(current) =>
                                    current.isBefore(
                                      moment().subtract(1, "day")
                                    )
                                  }
                                  direction="rtl"
                                  // defaultValue={defaultValueDate}
                                  onChange={(date) =>
                                    handleChange({
                                      key: "targetDate",
                                      value: date,
                                    })
                                  }
                                  // value={defaultValueDate}
                                />
                              </Form.Item>
                            </Form>
                          </div>
                          <span className="text-danger">{error?.dateErr}</span>
                        </>
                      )}
                    </div>

                    <SaveAndNext
                      backUrl={"/onboarding/wantToStart"}
                      saveType={"finishOnboarding"}
                      validateForm={validateForm}
                    />
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AppLive;
