import fetch from "cross-fetch";
import { SET_LOADER } from "../redux/actions/actionTypes";
import { store } from "../store";

import { getTokenInLocaleStorage } from "./general";

function sendRequest(method, url, body, withToken) {
  const headers = {
    "Content-Type": "application/json",
  };
  if (withToken) {
    headers["Authorization"] = getTokenInLocaleStorage();
  }

  if (body) {
    return fetch(url, {
      method,
      headers,
      body: JSON.stringify(body),
    }).then((response) => response.json());
  } else {
    return fetch(url, {
      method,
      headers,
    }).then((response) => response.json());
  }
}

function sendFormDataRequest(method, url, body, withToken) {
  const headers = {
    // 'Content-Type': 'application/json',
  };
  //if (withToken) {
  headers["Authorization"] = getTokenInLocaleStorage();
  // headers['Authorization'] =
  //   'Bearer ' +
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MzM2MDMzNjIsIm5iZiI6MTYzMzYwMzM2MiwianRpIjoiMTYxZTE0MzgtZjAzZC00NjdkLWI2MGYtNTJmMmU2ZTU1MjZlIiwiZXhwIjoxNjMzNjA2OTYyLCJpZGVudGl0eSI6eyJlbWFpbCI6Im5ld3Rlc3RzdGFydHVwMTIzQHlvcG1haWwuY29tIiwibW9kZWwiOiJTdGFydHVwIn0sImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyJ9.KbsNxzt2tROMHClAf5feKOX4MXEs505t7qM6wDxDZsI'
  // //}

  if (body) {
    return fetch(url, {
      method,
      headers,
      body: body,
    }).then((response) => response.json());
  } else {
    return fetch(url, {
      method,
      headers,
    }).then((response) => response.json());
  }
}

function sendFile(url, file, fileType) {
  const headers = {
    // Do not specify Content-Type. See https://stackoverflow.com/a/49510941/10057192
    Authorization: "Bearer " + getTokenInLocaleStorage(),
  };
  const formData = new FormData();
  formData.append("file_obj", file);
  formData.append("type", fileType);
  return fetch(url, {
    method: "POST",
    headers,
    body: formData,
  }).then((response) => response.json());
}

export default {
  getWithoutToken: (url, body) => {
    return sendRequest("GET", url, body, false);
  },
  getWithToken: (url, body) => {
    return sendRequest("GET", url, body, true);
  },
  post: (url, body) => {
    return sendRequest("POST", url, body, true);
  },
  postwithFormData: (url, body) => {
    return sendFormDataRequest("POST", url, body, true);
  },
  postWithoutToken: (url, body) => {
    return sendRequest("POST", url, body, false);
  },
  postWithToken: (url, body) => {
    return sendRequest("POST", url, body, true);
  },
  putWithToken: (url, body) => {
    return sendRequest("PUT", url, body, true);
  },
  patch: (url, body) => {
    return sendRequest("PATCH", url, body, true);
  },
  get: (url, body) => {
    return sendRequest("GET", url, body, true);
  },
  delete: (url, body) => {
    return sendRequest("DELETE", url, body, true);
  },
  sendFile,
};
