import React, { useEffect, useState } from "react";
import "./EditProjectCategory.scss";
import { useNavigate } from "react-router-dom";
import { Button, Input, Space, Spin } from "antd";
import api from "../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectData } from "../../redux/actions/dashboardActions";
// import { categoryArray } from "../../Utils/constant";

const EditProjectCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState();
  const [categoryArray, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );

  useEffect(() => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/projectCategory`)
      .then((data) => {
        setCategories(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setSelectedType(projectData?.category);
  }, [projectData]);

  return (
    <div className="editProjectCategory-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">Edit Project Category</div>
                <div className="projectTypeCon">
                  {loading ? (
                    <Spin />
                  ) : (
                    categoryArray.map((item, key) => {
                      return (
                        <div
                          className={`projectTypeItem ${
                            selectedType.id === item.id &&
                            "selectedProjectTypeItem"
                          }`}
                          onClick={() => setSelectedType(item)}
                          key={key}
                        >
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + item?.image}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              marginRight: "10px",
                            }}
                          />
                          <div className="projectTypeText">{item.name}</div>
                        </div>
                      );
                    })
                  )}
                </div>
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() =>
                        dispatch(
                          updateProjectData(
                            {
                              ...projectData,
                              category: selectedType,
                            },
                            navigate
                          )
                        )
                      }
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProjectCategory;
