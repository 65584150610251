import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./InvoiceDetailStep.scss";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function InvoiceDetailStep(props) {
  const { invoiceDetails } = props;
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <div className="invoice-pdf-details-wrap">
      {invoiceDetails?.invoice_pdf && (
        <Document
          file={`${process.env.REACT_APP_IMAGE_URL}${invoiceDetails?.invoice_pdf}`}
          onLoadSuccess={onDocumentLoadSuccess}
          className="overflow-auto h-100 w-100"
        >
          {Array.from({ length: numPages }, (v, i) => i)?.map((item) => (
            <Page pageIndex={item} />
          ))}
        </Document>
      )}
    </div>
  );
}

export default InvoiceDetailStep;
