import React, { useEffect, useState } from "react";
import "./EditTotalBudget.scss";
import { useNavigate } from "react-router-dom";
import { Button, Slider, Space } from "antd";
import { updateProjectData } from "../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";

const EditTotalBudget = () => {
  const navigate = useNavigate();
  const [updatedBudget, setUpdatedBudget] = useState(5);
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setUpdatedBudget(projectData?.total_budget);
  }, [projectData]);

  const marks = {
    5: {
      style: {
        color: "#000000",
        fontWeight: 900,
        fontSize: 27,
        left: 10,
        top: 50,
      },
      label: <div>$5K</div>,
    },
    500: {
      style: {
        color: "#000000",
        fontWeight: 900,
        fontSize: 27,
        top: 50,
        left: "initial",
        right: -60,
      },
      label: <div>$500K+</div>,
    },
  };
  return (
    <div className="editBudgets-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner" style={{ maxWidth: 800 }}>
                <div className="editHeading">Edit total budget</div>
                <div className="budgetSliderContainer">
                  <Slider
                    marks={marks}
                    defaultValue={60}
                    min={5}
                    max={500}
                    value={updatedBudget}
                    onChange={(e) => setUpdatedBudget(e)}
                    tooltip={{
                      placement: "bottom",
                      formatter: (value) => <span>${value}K</span>,
                    }}
                  />
                </div>
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() =>
                        dispatch(
                          updateProjectData(
                            {
                              ...projectData,
                              total_budget: updatedBudget,
                            },
                            navigate
                          )
                        )
                      }
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTotalBudget;
