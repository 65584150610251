import api from "../../Utils/api";
import {
  GET_SUPPORT_DATA_INIT,
  GET_SUPPORT_DATA_SUCCESS,
  GET_SUPPORT_DATA_FAIL,
  GET_SUPPORT_CHAT_DATA_INIT,
  GET_SUPPORT_CHAT_DATA_SUCCESS,
  GET_SUPPORT_CHAT_DATA_FAIL,
  REQUEST_FEATURE_DATA_INIT,
  REQUEST_FEATURE_DATA_SUCCESS,
  REQUEST_FEATURE_DATA_FAIL,
} from "./actionTypes";
export const getSupportData = () => {
  return (dispatch) => {
    dispatch({
      type: GET_SUPPORT_DATA_INIT,
    });

    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/getSupportTeam`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_SUPPORT_DATA_SUCCESS,
            payload: res.data?.first_name,
            loading: false,
          });
        } else {
          dispatch({
            type: GET_SUPPORT_DATA_FAIL,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_SUPPORT_DATA_FAIL,
        });
      });
  };
};

export const getSupportChatData = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_SUPPORT_CHAT_DATA_INIT,
    });

    await api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/support`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_SUPPORT_CHAT_DATA_SUCCESS,
            payload: res.data.messages,
            loading: false,
          });
        } else {
          dispatch({
            type: GET_SUPPORT_CHAT_DATA_FAIL,
          });
        }
        return res;
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_SUPPORT_CHAT_DATA_FAIL,
        });
      });
  };
};

export const postRequestFeature = (data, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_FEATURE_DATA_INIT,
    });

    api
      .postWithToken(
        `${process.env.REACT_APP_API_URL}/client/requestFeature`,
        data
      )
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: REQUEST_FEATURE_DATA_SUCCESS,
            payload: res,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: REQUEST_FEATURE_DATA_FAIL,
          });
          onFail && onFail();
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: REQUEST_FEATURE_DATA_FAIL,
        });
        onFail && onFail();
      });
  };
};
