import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./ProjectType.scss";
import "antd/dist/antd.css";
import { Form, Input, Spin } from "antd";
import api from "../../../Utils/api";
import OnboardingStepper from "../../../Components/OnboardingStepper";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveOnboarding,
  setOnboardingData,
} from "../../../redux/actions/onboardingActions";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import SaveAndNext from "../../../Components/Save&Next";
import { useNavigate } from "react-router-dom";

const ProjectType = () => {
  const [projectTypes, setProjectTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveType, setSaveType] = useState("");
  const [error, setError] = useState({});
  const [descriptionErr, setDescriptionErr] = useState(false);
  const { payload } = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/projectType`)
      .then((data) => {
        setProjectTypes(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (e) => {
    dispatch(
      setOnboardingData({ ...payload, [e.target.name]: e.target.value })
    );
  };

  const handleProjectType = (selectedOption) => {
    setError({ projectTypeErr: "" });
    dispatch(
      setOnboardingData({ ...payload, ProjectType: selectedOption?.id })
    );
  };

  const rules = {
    projectName: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter the project name");
          } else if (!new RegExp("^[A-Za-z]( ?[A-Za-z0-9] ?)*$").test(value)) {
            return Promise.reject("Please enter valid project name");
          } else if (value.length <= 3 || value.length >= 20) {
            return Promise.reject(
              "Project name is between 3 and 20 characters"
            );
          }
          return Promise.resolve();
        },
      },
    ],
    ProjectDisc: [
      {
        validator(_, value) {
          if (!value) {
            setDescriptionErr(true);
            return Promise.reject("Please enter the project description");
          }
          setDescriptionErr(false);
          return Promise.resolve();
        },
      },
    ],
  };

  const initialValues = {
    projectName: payload?.projectName,
    ProjectDisc: payload?.ProjectDisc,
  };

  useEffect(() => {
    if (saveType === "save&Logout") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
    } else if (saveType === "save&next") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
      navigate("/onboarding/yourProjectCategory");
    }
  }, [saveType]);

  const validateForm = (clickType) => {
    if (!payload?.ProjectType) {
      setError({ projectTypeErr: "Please select project type" });
    } else {
      form
        .validateFields()
        .then(() => {
          if (clickType === "Save&Logout") {
            dispatch(
              setOnboardingData({
                ...payload,
                steps_completed: 1,
              })
            );
            setSaveType("save&Logout");
          } else {
            dispatch(
              setOnboardingData({
                ...payload,
                steps_completed: 1,
              })
            );
            setSaveType("save&next");
          }
        })
        .catch((validationErr) => {
          console.log(validationErr);
        });
    }
  };
  const onSave_Logout_Fun = () => {
    dispatch(
      setOnboardingData({
        ...payload,
        steps_completed: 0,
      })
    );
    setSaveType("save&Logout");
  };
  return (
    <div className="onboard">
      <OnboardingHeader steps_completed={0} validateForm={onSave_Logout_Fun} />

      <Container className="mt-5">
        <Row>
          <Col sm={10} className="m-auto">
            <div className="d-flex justify-content-center flex-column p-0">
              <OnboardingStepper activeElement={1} />
              <Row>
                <div className="col-lg-12">
                  <div className="d-flex justify-content-center">
                    <div className="project-type-wrap">
                      <div className="project-type my-4">
                        <span className="title">
                          What type(s) of project(s)
                          <span> are you working on? {}</span>
                        </span>
                        <div className="project-type-links">
                          <ul className="mb-2">
                            {loading ? (
                              <Container>
                                <Row>
                                  <Col className="text-center">
                                    <Spin />
                                  </Col>
                                </Row>
                              </Container>
                            ) : (
                              projectTypes?.map((projectType, index) => (
                                <li
                                  key={index}
                                  onClick={() => handleProjectType(projectType)}
                                >
                                  <a
                                    href="#"
                                    className={
                                      payload?.ProjectType === projectType?.id
                                        ? "project-active"
                                        : ""
                                    }
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE_URL +
                                        projectType.image
                                      }
                                      alt=""
                                    />
                                    <span>{projectType?.name}</span>
                                  </a>
                                </li>
                              ))
                            )}
                          </ul>
                          <span className="text-danger">
                            {error?.projectTypeErr}
                          </span>
                        </div>
                      </div>

                      <div className="project-form">
                        <Form
                          layout="vertical"
                          form={form}
                          initialValues={initialValues}
                        >
                          <Form.Item
                            label="Project Name"
                            rules={rules.projectName}
                            name="projectName"
                          >
                            <Input
                              placeholder="Project Name"
                              size="large"
                              name="projectName"
                              value={payload?.projectName}
                              onChange={handleChange}
                            />
                          </Form.Item>

                          <Form.Item
                            name="ProjectDisc"
                            label="Project Description"
                            rules={rules.ProjectDisc}
                            className={`custom-textarea ${
                              descriptionErr ? "error-wrapper" : ""
                            }`}
                          >
                            <Input.TextArea
                              style={{ height: 180 }}
                              name="ProjectDisc"
                              onChange={handleChange}
                              defaultValue={payload?.ProjectDisc}
                            />
                          </Form.Item>
                        </Form>
                      </div>

                      <SaveAndNext backUrl={"#"} validateForm={validateForm} />
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProjectType;
