import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../Header";
import IMAGES from "../../Assets/images/Onboarding";
import IMAGEDUMMY from "../../Assets/images/image_dummy.png";
import "./Blogs.scss";
import { useDispatch } from "react-redux";
import Footer from "../../Components/Footer/Footer";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Input,
  Row,
  Tabs,
  message,
  Spin,
} from "antd";
import api from "../../Utils/api";
import { useNavigate } from "react-router-dom";
import FullscreenLoader from "../../Components/FullscreenLoader";
import moment from "moment";
import NoDataImg from "../../Assets/images/no-data.svg";
import { handleSubscribeNewsletter } from "../../redux/actions/authActions";
const Blogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState("All");
  const [blogList, setBlogList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [featuredBlog, setFeaturedBlog] = useState();
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    api
      .getWithoutToken(`${process.env.REACT_APP_API_URL}/client/blogs`)
      .then((data) => {
        let categoriesData = data.data.map((item) => item.category);

        setCategories([...new Set(categoriesData)]);
        setBlogList(data.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setFeaturedBlog(renderFilteredList()?.[0]);
  }, [blogList, activeKey]);
  const onSubscribeAction = () => {
    if (!email) {
      message.error("Please enter email", 2);
      return;
    } else if (
      !new RegExp(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+(?:.[a-zA-Z0-9-]+)*$"
      ).test(email)
    ) {
      message.error("Please enter valid email", 2);
      return;
    }
    setLoader(true);
    dispatch(
      handleSubscribeNewsletter(
        { email: email },
        () => {
          setLoader(false);
          message.success("SuccessFully...", 2);
        },
        (err) => {
          setLoader(false);
          try {
            let objErr = JSON.parse(err);
            if (objErr?.title == "Invalid Resource") {
              message.error(
                "Email looks fake or invalid, please enter a real email address",
                2
              );
            } else if (objErr?.title == "Member Exists") {
              message.error("Email is already exists", 2);
            } else {
              message.error("Anything is wrong", 2);
            }
          } catch (e) {
            message.error("Anything is wrong", 2);
          }
        }
      )
    );
  };
  const renderFilteredList = () => {
    if (activeKey === "All") {
      return blogList;
    } else {
      let array = blogList.filter((item) => item.category === activeKey);
      return array;
    }
  };
  return (
    <>
      {loading ? (
        <FullscreenLoader />
      ) : (
        <>
          <Header />
          <div className="Service blogs-wrap">
            <div className="banner">
              <Container>
                <div className="row">
                  <div className="col-xl-10  col-md-12 m-auto">
                    <div className="banner-grid">
                      <div className="banner-set">
                        <div className="banner-set--left">
                          <h1 className="custom-align">
                            <span>Blog</span>
                            <span style={{ color: "#E5C243" }}>s</span>
                          </h1>
                          <p>
                            Our venture studio provides you with the startup
                            expertise to launch your idea successfully.
                          </p>
                        </div>
                        <div className="banner-set--right">
                          <div>
                            <img
                              className="img-fluid"
                              src={IMAGES.mainBlogs}
                              alt="BlogImg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
              <div className="tabsCon">
                <div className="tabscon-wrap">
                  <div className="categoryMenuListCon">
                    <div className="leftTabsCon">
                      {categories?.length !== 0 && (
                        <Tabs
                          activeKey={activeKey}
                          onChange={(e) => setActiveKey(e)}
                        >
                          <Tabs.TabPane tab="All" key="All"></Tabs.TabPane>
                          {categories.map((item, key) => {
                            return (
                              <Tabs.TabPane
                                tab={item}
                                key={item}
                              ></Tabs.TabPane>
                            );
                          })}
                        </Tabs>
                      )}
                    </div>
                    <div className="rightTabCon">
                      <p>Subscribe to our newsletter</p>
                      <Input.Group compact>
                        <Input
                          placeholder="Please enter your email"
                          value={email || ""}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <Button
                          type="primary"
                          onClick={!loader && onSubscribeAction}
                        >
                          {loader ? <Spin /> : "Go"}
                        </Button>
                      </Input.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderFilteredList().length !== 0 && (
              <div className="blogsListCon">
                <Row gutter={24}>
                  <Col md={24}>
                    <div
                      className="featuredcard"
                      onClick={() => {
                        navigate(`/blogs/${featuredBlog.id}`, {
                          state: featuredBlog,
                        });
                      }}
                    >
                      <div className="categoryText">Featured</div>
                      <div className="mainFlexCard">
                        <div className="imageCon">
                          <img
                            src={
                              featuredBlog?.banner_image
                                ? `${process.env.REACT_APP_IMAGE_URL}${featuredBlog.banner_image}`
                                : IMAGEDUMMY
                            }
                            alt="FeaturedImg"
                          />
                        </div>
                        <div className="rightFeatureCon">
                          <div className="trendingText">
                            {featuredBlog?.category}
                          </div>
                          <div className="title">{featuredBlog?.title}</div>
                          <Divider />
                          <div className="subtitle">
                            {featuredBlog?.subtitle}
                          </div>
                          <div className="summary">{featuredBlog?.summary}</div>
                          <div className="avatarCon">
                            <Avatar
                              size={40}
                              src={
                                featuredBlog?.profile_image
                                  ? `${process.env.REACT_APP_IMAGE_URL}${featuredBlog.profile_image}`
                                  : ""
                              }
                            >
                              {featuredBlog?.author_first_name
                                ? featuredBlog?.author_first_name?.charAt(0)
                                : "U"}
                            </Avatar>
                            <div>
                              <div className="nameText">
                                {featuredBlog?.author_first_name +
                                  " " +
                                  featuredBlog?.author_last_name}
                              </div>
                              <div className="dateText">
                                {moment(featuredBlog?.publish_date).format(
                                  "MM-DD-YYYY"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="normalCardMain" gutter={24}>
                  {renderFilteredList()
                    ?.slice(1)
                    ?.map((item, key) => {
                      return (
                        <Col md={8} sm={24} className="normalCard" key={key}>
                          <div
                            className="featuredcard"
                            onClick={() => {
                              navigate(`/blogs/${item.id}`, {
                                state: item,
                              });
                            }}
                          >
                            <div className="mainFlexCard">
                              <div className="imageCon">
                                <img
                                  src={
                                    item?.banner_image
                                      ? `${process.env.REACT_APP_IMAGE_URL}${item.banner_image}`
                                      : IMAGEDUMMY
                                  }
                                  alt="banner_image"
                                />
                              </div>
                              <div className="rightFeatureCon">
                                <div className="trendingText">
                                  {item?.category}
                                </div>
                                <div className="title">{item?.title}</div>
                                <Divider />
                                <div className="subtitle">{item?.subtitle}</div>
                                <div className="summary">{item?.summary}</div>
                                <div className="avatarCon">
                                  <Avatar
                                    size={40}
                                    src={
                                      item?.profile_image
                                        ? `${process.env.REACT_APP_IMAGE_URL}${item.profile_image}`
                                        : ""
                                    }
                                  >
                                    {item?.author_first_name
                                      ? item?.author_first_name?.charAt(0)
                                      : "U"}
                                  </Avatar>
                                  <div>
                                    <div className="nameText">
                                      {item?.author_first_name +
                                        " " +
                                        item?.author_last_name}
                                    </div>
                                    <div className="dateText">
                                      {moment(item?.publish_date).format(
                                        "MM-DD-YYYY"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
            )}
            {renderFilteredList()?.length === 0 && (
              <div className="no-data-section">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="no-blog">
                        <img src={NoDataImg} alt="NoData" />
                        <h4>Coming soon</h4>
                        <p>In the meantime, please stay in touch.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default Blogs;
