import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import {
  getTokenInLocaleStorage,
  redirectToStepByCompletedStep,
} from "../Utils/general";

function PublicRoutes() {
  const { authData } = useSelector((state) => state?.authReducer);
  return !getTokenInLocaleStorage() ||
    (!!getTokenInLocaleStorage() &&
      Object.values(authData || {})?.length == 0) ? (
    <Outlet />
  ) : !!authData?.boarded && !!authData?.verified ? (
    <Navigate to="/dashboard" />
  ) : !!authData?.boarded && !authData?.verified ? (
    <Navigate to="/onboarding/confirm-email" />
  ) : (
    redirectToStepByCompletedStep(authData?.project?.steps_completed)
  );
}

export default PublicRoutes;
