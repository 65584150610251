import * as React from "react";

function TimeLeftIcon(props) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="30.5" cy="30.5" r="28.5" stroke="#E5C243" strokeWidth="4" />
      <path
        d="M30.5 15.0708V34.0884H48.4412"
        stroke="black"
        strokeWidth="6"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default TimeLeftIcon;
