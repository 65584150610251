import * as React from "react";

function ArrowBackIcon(props) {
  return (
    <svg
      width="29"
      height="20"
      viewBox="0 0 29 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.4985 10H5.53906"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M11.8919 2L3 10.3195L11.8919 17.9991"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default ArrowBackIcon;
