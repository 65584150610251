import React, { useEffect } from "react";
import ArrowBackIcon from "../../Assets/Icons/ArrowBackIcon";
import { Divider, Spin } from "antd";
import { getPerkDetail } from "../../redux/actions/perkAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getPerkType } from "../../Utils/general";
import "./Perks.scss";
function PerkDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { updateId } = useParams("updateId");
  const { PerkDetails, PerksDetailLoading } = useSelector(
    (state) => state?.perksReducer
  );
  useEffect(() => {
    location?.state?.data
      ? dispatch(getPerkDetail(updateId))
      : navigate("/updates");
  }, [location?.state]);
  if (PerksDetailLoading) {
    return (
      <div className="">
        <Spin />
      </div>
    );
  }
  return (
    <div className="perkDetailPage">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="backCon">
              <div className="backIcon" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </div>
              <div className="backText">Back</div>
            </div>
            <div className="detailCardCon">
              <div className="d-flex flex-column flex-lg-row align-items-center detailHeaderCon perkDetailHeaderCon">
                <img
                  src={process.env.REACT_APP_IMAGE_URL + PerkDetails?.image}
                  alt=""
                />
                <div className="d-flex flex-column align-items-center align-items-lg-start perkDetailWrapper">
                  <div className="detailHeaderText">{PerkDetails?.name}</div>
                  <div className="detailText normalFontWeight">
                    {PerkDetails?.description}
                  </div>
                  <div className="highValueCon">
                    <div className="typePerkCon">
                      <div className="typeText">Type</div>
                      <div className="highValueText">
                        {getPerkType(PerkDetails?.perk_type)}
                      </div>
                    </div>
                    <div className="">
                      <div className="typeText">Website</div>
                      <div className="websiteText">{PerkDetails?.website}</div>
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="perk-divider" />
              <div className="d-flex flex-column  align-items-center align-items-lg-start perkDetailContent">
                <div className="contentHeading">Deal Details</div>
                <div className="contentText">{PerkDetails?.details}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PerkDetail;
