import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import IMAGES from "../../../Assets/images/Onboarding";
import LogoHeader from "../../../Components/LogoHeader/LogoHeader";
import "./CheckYourMail.scss";

function Checkyouremail() {
  return (
    <>
      <div className="onboard pb-3">
        <LogoHeader />

        <Container className="">
          <Row>
            <div className="d-flex justify-content-center align-items-center bodyVh90">
              <Col sm={12} md={4} className="">
                <div className="check-your-mail">
                  <img className="img-fluid" src={IMAGES.confirmEmail} alt="" />
                  <div className="title">Check your email</div>
                  <p className="sub-text pt-2">
                    You should have received a Reset Password link. If not,
                    please try again or check your spam.
                  </p>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Checkyouremail;
