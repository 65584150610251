import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserDataFromToken } from "./redux/actions/authActions";
import "bootstrap/dist/css/bootstrap.min.css";

//pages
import AgreementDetails from "./Pages/AgreementDetails/AgreementDetails";
import Agreements from "./Pages/Agreements/Agreements";
import MainDashboard from "./Pages/MainDashboard/MainDashboard";
import PerkDetail from "./Pages/Perks/PerkDetail";
import Perks from "./Pages/Perks/Perks";
import EditApplicationFeatures from "./Pages/ProjectInfo/EditApplicationFeatures";
import EditDevelopment from "./Pages/ProjectInfo/EditDevelopment";
import EditHelpfulDocuments from "./Pages/ProjectInfo/EditHelpfulDocuments";
import EditMonthlyBudget from "./Pages/ProjectInfo/EditMonthlyBudget";
import EditPhase from "./Pages/ProjectInfo/EditPhase";
import EditProjectCategory from "./Pages/ProjectInfo/EditProjectCategory";
import EditProjectNameAndDesc from "./Pages/ProjectInfo/EditProjectNameAndDesc";
import EditProjectType from "./Pages/ProjectInfo/EditProjectType";
import EditTargetDate from "./Pages/ProjectInfo/EditTargetDate";
import EditTotalBudget from "./Pages/ProjectInfo/EditTotalBudget";
import EditWantToStart from "./Pages/ProjectInfo/EditWantToStart";
import ProjectInfo from "./Pages/ProjectInfo/ProjectInfo";
import Reviews from "./Pages/Reviews/Reviews";
import UpdateDetail from "./Pages/Updates/UpdateDetail";
import Updates from "./Pages/Updates/Updates";
import InvoiceDetails from "./Pages/InvoiceDetails/InvoiceDetails";
import PaidInvoiceDetails from "./Pages/InvoiceDetails/PaidInvoiceDetails/PaidInvoiceDetails";
import InvoicesList from "./Pages/InvoicesList/InvoicesList";
import AddNewFeature from "./Pages/Support/AddNewFeature/AddNewFeature";
import SupportChat from "./Pages/Support/SupportChat/SupportChat";
import SupportHome from "./Pages/Support/SupportHome/SupportHome";
import Profile from "./Pages/Profile/Profile";
import Settings from "./Pages/Settings/Settings";
import Home from "./Pages/Home/Home";
import Service from "./Pages/Service/Service";
import Signup from "./Pages/Onboarding/Signup";
import Signin from "./Pages/Onboarding/Signin";
import Introo from "./Pages/Onboarding/Introo";
import ProjectType from "./Pages/Onboarding/ProjectType";
import YourProjectCategory from "./Pages/Onboarding/YourProjectCategory";
import Development from "./Pages/Onboarding/Development";
import Features from "./Pages/Onboarding/Features";
import DevelopmentStage from "./Pages/Onboarding/DevelopmentStage";
import Budjet from "./Pages/Onboarding/Budjet";
import IdeaDetails from "./Pages/Onboarding/IdeaDetails";
import WantToStart from "./Pages/Onboarding/WantToStart";
import AppLive from "./Pages/Onboarding/AppLive";
import EmailConfirm from "./Pages/Onboarding/EmailConfirm";
import ForgotPassword from "./Pages/Password/ForgotPassword";
import Checkyouremail from "./Pages/Password/CheckYourMail";
import CreatePassword from "./Pages/Password/createPassword";
import PasswordSuccess from "./Pages/Password/PasswordSuccess";
import FullscreenLoader from "./Components/FullscreenLoader";
import SetupCard from "./Pages/Settings/PaymentInfo/SetupCard";
import Faq from "./Pages/Faq/Faq";
import CorporateGiving from "./Pages/CorporateGiving/CorporateGiving";
import Blogs from "./Pages/Blogs/Blogs";
import BlogDetails from "./Pages/Blogs/BlogDetails";

// routes
import PublicRoutes from "./Routes/PublicRoutes";
import OnboardingRoutes from "./Routes/OnboardingRoutes";
import PrivateRoute from "./Routes/PrivateRoute";

function App() {
  const location = useLocation();
  const { authLoading } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    dispatch(getUserDataFromToken());
  };

  if (authLoading) {
    return <FullscreenLoader />;
  }

  return (
    <div className="App">
      {/* Routes */}
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/service"} element={<Service />} />
          <Route exact path={"/faq"} element={<Faq />} />
          <Route exact path={"/blogs"} element={<Blogs />} />
          <Route exact path={"/blogs/:blogId"} element={<BlogDetails />} />
          <Route
            exact
            path={"/corporate-giving"}
            element={<CorporateGiving />}
          />
          <Route exact path={"/signup"} element={<Signup />} />
          <Route exact path={"/signup?token=:token"} element={<Signup />} />
          <Route exact path={"/signin"} element={<Signin />} />
          <Route exact path={"/forgot-password"} element={<ForgotPassword />} />
          <Route exact path={"/checkyouremail"} element={<Checkyouremail />} />
          <Route exact path="/reset-password" element={<CreatePassword />} />
          <Route exact path="/passwordSuccess" element={<PasswordSuccess />} />
        </Route>
        <Route element={<OnboardingRoutes />}>
          <Route exact path={"/onboarding/intro"} element={<Introo />} />
          <Route
            exact
            path={"/onboarding/projectType"}
            element={<ProjectType />}
          />
          <Route
            exact
            path={"/onboarding/yourProjectCategory"}
            element={<YourProjectCategory />}
          />
          <Route
            exact
            path={"/onboarding/development"}
            element={<Development />}
          />
          <Route
            exact
            path={"/onboarding/developmentStage"}
            element={<DevelopmentStage />}
          />
          <Route exact path={"/onboarding/features"} element={<Features />} />
          <Route exact path={"/onboarding/budget"} element={<Budjet />} />

          <Route
            exact
            path={"/onboarding/wantToStart"}
            element={<WantToStart />}
          />
          <Route exact path={"/onboarding/appLive"} element={<AppLive />} />
          <Route
            exact
            path={"/onboarding/ideaDetails"}
            element={<IdeaDetails />}
          />
          <Route
            exact
            path={"/onboarding/confirm-email"}
            element={<EmailConfirm />}
          />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path={"/dashboard"} element={<MainDashboard />} />
          <Route path={"/updates"} element={<Updates />} />
          <Route
            path={"/updates/detail/:updateId"}
            element={<UpdateDetail />}
          />
          <Route path={"/perks"} element={<Perks />} />
          <Route path={"/perk/detail/:updateId"} element={<PerkDetail />} />
          <Route path={"/project-info"} element={<ProjectInfo />} />
          <Route
            path={"/edit-project-name"}
            element={<EditProjectNameAndDesc />}
          />
          <Route path={"/edit-project-type"} element={<EditProjectType />} />
          <Route
            path={"/edit-project-category"}
            element={<EditProjectCategory />}
          />
          <Route path={"/edit-project-phase"} element={<EditPhase />} />
          <Route
            path={"/edit-project-development"}
            element={<EditDevelopment />}
          />
          <Route
            path={"/edit-project-total-budget"}
            element={<EditTotalBudget />}
          />
          <Route
            path={"/edit-project-monthly-budget"}
            element={<EditMonthlyBudget />}
          />
          <Route path={"/edit-want-to-start"} element={<EditWantToStart />} />
          <Route path={"/edit-target-date"} element={<EditTargetDate />} />
          <Route
            path={"/edit-application-features"}
            element={<EditApplicationFeatures />}
          />
          <Route
            path={"/edit-helpful-documents"}
            element={<EditHelpfulDocuments />}
          />
          <Route path={"/reviews"} element={<Reviews />} />
          <Route exact path={"/invoices"} element={<InvoicesList />} />
          <Route
            exact
            path={"/invoices/invoice-details/:invoiceId"}
            element={<InvoiceDetails />}
          />
          <Route
            exact
            path={"/invoices/paid-invoice-details"}
            element={<PaidInvoiceDetails />}
          />
          <Route exact path={"/support"} element={<SupportHome />} />
          <Route
            exact
            path={"/support/support-chat"}
            element={<SupportChat />}
          />
          <Route
            exact
            path={"/support/add-new-feature"}
            element={<AddNewFeature />}
          />
          <Route path={"/agreements"} element={<Agreements />} />
          <Route
            path={"/agreement-details/:agreementId"}
            element={<AgreementDetails />}
          />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path={"/setupcard"} element={<SetupCard />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
