import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { useDispatch } from "react-redux";
import inventiveLogoFooter from "../../Assets/images/footer.gif";
import moment from "moment";
import { Button, Input, message, Spin } from "antd";
import { handleSubscribeNewsletter } from "../../redux/actions/authActions";
import Image1 from "../../Assets/images/Footer/facebook-icon.svg";
import Image2 from "../../Assets/images/Footer/twitter-icon.svg";
import Image3 from "../../Assets/images/Footer/linkedin-icon.svg";
import Image4 from "../../Assets/images/Footer/inst-icon1.svg";
function Footer() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const onSubscribeAction = () => {
    if (!email) {
      message.error("Please enter email", 2);
      return;
    } else if (
      !new RegExp(
        "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+(?:.[a-zA-Z0-9-]+)*$"
      ).test(email)
    ) {
      message.error("Please enter valid email", 2);
      return;
    }
    setLoader(true);
    dispatch(
      handleSubscribeNewsletter(
        { email: email },
        () => {
          setLoader(false);
          message.success("Successfully subscribe to our newsletter", 2);
        },
        (err) => {
          setLoader(false);
          try {
            let objErr = JSON.parse(err);
            if (objErr?.title == "Invalid Resource") {
              message.error(
                "Email looks fake or invalid, please enter a real email address",
                2
              );
            } else if (objErr?.title == "Member Exists") {
              message.error("Email is already exists", 2);
            } else {
              message.error(err || "Anything is wrong", 2);
            }
          } catch (e) {
            message.error(err || "Anything is wrong", 2);
          }
        }
      )
    );
  };
  return (
    <>
      <footer>
        <section className="footer-top">
          <Container fluid>
            <Row>
              <Col md={4} className="left">
                <img src={inventiveLogoFooter} alt="Inventive Byte Logo" />
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  <li class="list-inline-item">
                    <Link to="/corporate-giving">Corporate Giving</Link>
                  </li>
                  <li class="list-inline-item">
                    <Link to="/faq">FAQs</Link>
                  </li>
                  <li class="list-inline-item">
                    <Link to="/service">Services</Link>
                  </li>
                </ul>
              </Col>
              <Col md={5} className="middle">
                <p>Contact info</p>
                <p>2375 Zanker Road, Suite 250, San Jose, CA 95131</p>
                <a href="mailto:info@inventivebyte.com">
                  info@inventivebyte.com
                </a>
              </Col>
              <Col md={3} className="right">
                <div className="social-media-icons">
                  <a
                    href="https://www.facebook.com/inventivebyte"
                    target="_blank"
                  >
                    <img src={Image1} alt="imag1" />
                  </a>
                  <a href="https://twitter.com/inventivebyte" target="_blank">
                    <img src={Image2} alt="imag2" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/inventivebyte"
                    target="_blank"
                  >
                    <img src={Image3} alt="imag3" />
                  </a>
                  <a
                    href="https://www.instagram.com/inventivebyte"
                    target="_blank"
                  >
                    <img src={Image4} alt="imag4" />
                  </a>
                </div>
                <p className="mb-2">Subscribe to our newsletter</p>
                <Input.Group compact>
                  <Input
                    placeholder="Please enter your email"
                    value={email || ""}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Button type="primary" onClick={!loader && onSubscribeAction}>
                    {loader ? <Spin /> : "Go"}
                  </Button>
                </Input.Group>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="footer-bottom">
          <Container>
            <Row>
              <Col md={12}>
                <div className="footer-bottom-wrap">
                  ©{moment().format("YYYY")} Inventive Byte. All Right Reserved.{" "}
                  <span className="mx-3">|</span>
                  <Link to="/">Terms & Conditions</Link>{" "}
                  <span className="mx-3">|</span>
                  <Link to="/">Privacy Policy</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </footer>
    </>
  );
}

export default Footer;
