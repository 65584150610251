import React from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./Header.scss";
import IMAGES from "../../Assets/images/Onboarding";

const Header = () => {
  return (
    <>
      <div className="header-main-wrapper">
        <Navbar expand="lg" fixed={"top"}>
          <Container fluid>
            <Navbar.Brand href="/">
              <img src={IMAGES.iblogo} alt="Inventive Byte" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  as={Link}
                  to="/service"
                  className={
                    document?.location?.pathname?.includes("service")
                      ? "navLink navLinkActive"
                      : "navLink"
                  }
                >
                  Services
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/blogs"
                  className={
                    document?.location?.pathname?.includes("blogs")
                      ? "navLink navLinkActive"
                      : "navLink"
                  }
                >
                  Blogs
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/faq"
                  className={
                    document?.location?.pathname?.includes("faq")
                      ? "navLink navLinkActive"
                      : "navLink"
                  }
                >
                  FAQs
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/corporate-giving"
                  className={
                    document?.location?.pathname?.includes("corporate-giving")
                      ? "navLink navLinkActive"
                      : "navLink"
                  }
                >
                  Corporate Giving
                </Nav.Link>
                <Nav.Link as={Link} to="/signup" className="signup-btn">
                  Sign Up
                </Nav.Link>
                <Nav.Link as={Link} to="/signin">
                  Signin
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
