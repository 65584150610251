import {
  GET_PERKS_DATA_INIT,
  GET_PERKS_DATA_SUCCESS,
  GET_PERKS_DATA_FAIL,
  GET_PERKS_DETAIL_INIT,
  GET_PERKS_DETAIL_SUCCESS,
  GET_PERKS_DETAIL_FAIL,
} from "../actions/actionTypes";
const initialState = {
  PerksList: [],
  PerkDetails: {},
};
export const perksReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PERKS_DATA_INIT: {
      return {
        ...state,
        PerksListLoading: true,
      };
    }
    case GET_PERKS_DATA_SUCCESS: {
      return {
        ...state,
        PerksListLoading: false,
        PerksList: payload?.data,
      };
    }
    case GET_PERKS_DATA_FAIL: {
      return {
        ...state,
        PerksListLoading: false,
      };
    }
    case GET_PERKS_DETAIL_INIT: {
      return {
        ...state,
        PerksDetailLoading: true,
      };
    }
    case GET_PERKS_DETAIL_SUCCESS: {
      return {
        ...state,
        PerksDetailLoading: false,
        PerkDetails: payload?.data,
      };
    }
    case GET_PERKS_DETAIL_FAIL: {
      return {
        ...state,
        PerksDetailLoading: false,
      };
    }
    default:
      return state;
  }
};
