import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import "./InvoiceDetails.scss";
import InvoiceDetailStep from "./InvoiceSteps/InvoiceDetailStep/InvoiceDetailStep";
import PaymentTypeStep from "./InvoiceSteps/PaymentTypeStep/PaymentTypeStep";
import AddBankAccountStep from "./InvoiceSteps/AddBankAccountStep/AddBankAccountStep";
import SetupCard from "./InvoiceSteps/AddCreditCardStep/SetupCard";
import CreditDebitInfo from "./InvoiceSteps/CreditDebitInfo/CreditDebitInfo";
import PaymentStep from "./InvoiceSteps/PaymentStep/PaymentStep";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceDetailsData,
  payInvoiceAccount,
  payInvoiceCard,
} from "../../redux/actions/invoicesAction";
import { Spin } from "antd";

function InvoiceDetails(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const invoice_number = location?.state?.invoice?.invoice_id;
  const [paymentdata, setPaymentData] = useState();
  const invoiceId = params?.invoiceId;
  const { invoiceDetails, loading } = useSelector(
    (state) => state?.invoicesReducer
  );
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    if (invoiceId) {
      dispatch(getInvoiceDetailsData(invoiceId));
    }
  }, [invoiceId]);

  useEffect(() => {
    if (currentStep === 7) {
      setTimeout(() => {
        navigate("/invoices");
      }, 3000);
    }
  }, [currentStep]);
  const onNextStep = () => {
    if (currentStep === 3) {
      setCurrentStep(6);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const onBackStep = () => {
    if (currentStep === 4 || currentStep === 5 || currentStep === 6) {
      setCurrentStep(2);
      return;
    }
    setCurrentStep(currentStep - 1);
  };
  const onBankPay = () => {
    let data = {
      invoice: invoiceDetails?.invoice_id,
      source: `${paymentdata?.source?.id}`,
    };
    dispatch(
      payInvoiceAccount(data, () => {
        onNextStep();
      })
    );
  };
  const onCardPay = () => {
    let data = {
      invoice: invoiceDetails?.invoice_id,
      payment_method: `${paymentdata?.source?.id}`,
    };
    dispatch(
      payInvoiceCard(data, () => {
        onNextStep();
      })
    );
  };
  const onDownLoadInvoice = (link) => {
    link && window.open(`${process.env.REACT_APP_IMAGE_URL}${link}`);
  };

  const renderSteps = () => {
    switch (currentStep) {
      case 1:
        return <InvoiceDetailStep invoiceDetails={invoiceDetails} />;
      case 2:
        return <PaymentTypeStep setCurrentStep={setCurrentStep} />;
      case 3:
        return (
          <AddBankAccountStep
            setCurrentStep={setCurrentStep}
            setPaymentData={setPaymentData}
          />
        );
      case 4:
        return (
          <SetupCard
            setCurrentStep={setCurrentStep}
            setPaymentData={setPaymentData}
          />
        );
      case 5:
        return (
          <CreditDebitInfo
            setCurrentStep={setCurrentStep}
            setPaymentData={setPaymentData}
          />
        );
      case 6:
        return <PaymentStep paymentdata={paymentdata} />;

      default:
        return null;
    }
  };

  if (currentStep === 7) {
    return (
      <div className="d-flex invoice-paid-wrap flex-column align-items-center justify-content-center">
        <CheckCircleFilled className="check-circle-paid-icon" />
        <p className="paid-text">Paid Successfully</p>
      </div>
    );
  }

  return (
    <div className="invoice-details-page d-flex align-items-center justify-content-center">
      <div className="w-100">
        <Spin spinning={loading}>
          <div className="invoice-details-page-header d-flex align-items-start justify-content-center">
            {!invoiceDetails?.paid ? (
              <>
                <div className="invoice-steps d-flex align-items-center justify-content-center flex-column">
                  <div
                    className={`stap d-flex align-items-center justify-content-center active`}
                  >
                    1
                  </div>
                  <span className="invoice-steps-text">invoice Details</span>
                </div>
                <div className="invoice-steps d-flex align-items-center justify-content-center flex-column">
                  <div
                    className={`stap d-flex align-items-center justify-content-center ${
                      [2, 3, 4, 5, 6]?.includes(currentStep) && "active"
                    }`}
                  >
                    2
                  </div>
                  <span className="invoice-steps-text">payment Type</span>
                </div>
                <div className="invoice-steps d-flex align-items-center justify-content-center flex-column">
                  <div
                    className={`stap d-flex align-items-center justify-content-center ${
                      currentStep === 6 && "active"
                    }`}
                  >
                    3
                  </div>
                  <span className="invoice-steps-text">payment</span>
                </div>
              </>
            ) : (
              <div className="paid-header">
                <span className="invoice-id">
                  {invoiceDetails?.id?.slice(-3)}
                </span>
                <div className="right-header">
                  <CheckCircleFilled
                    twoToneColor={"red"}
                    className="paid-icon"
                  />
                  <span className="paid-text">Paid</span>
                  <div
                    onClick={() =>
                      onDownLoadInvoice(invoiceDetails?.invoice_pdf)
                    }
                    className="download-wrap"
                  >
                    <DownloadOutlined className="download-icon" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="invoice-details-page-body">
            <div className="d-flex align-items-center justify-content-center p-4">
              {renderSteps()}
            </div>
            {!invoiceDetails?.paid && (
              <div className="invoice-details-page-button d-flex align-items-center justify-content-center">
                {currentStep === 6 ? (
                  <button
                    onClick={() => {
                      if (paymentdata?.type === "bank") {
                        onBankPay();
                      } else {
                        onCardPay();
                      }
                    }}
                    className="invoice-next-button d-flex align-items-center justify-content-center"
                  >
                    Pay Now
                  </button>
                ) : currentStep === 3 ||
                  currentStep === 4 ||
                  currentStep === 5 ? (
                  ""
                ) : (
                  <button
                    onClick={onNextStep}
                    className="invoice-next-button d-flex align-items-center justify-content-center"
                  >
                    next <ArrowRightOutlined />
                  </button>
                )}
                {currentStep !== 1 && (
                  <button
                    onClick={onBackStep}
                    className="invoice-back-button mt-2 d-flex align-items-center justify-content-center"
                  >
                    <ArrowLeftOutlined /> Back
                  </button>
                )}
              </div>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default InvoiceDetails;
