import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Signup.scss";
import { Form, Input, Button, Divider, Spin } from "antd";
import IMAGES from "../../../Assets/images/Onboarding";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  handleSignup,
  handleGoogleSignup,
} from "../../../redux/actions/authActions";
import LogoHeader from "../../../Components/LogoHeader/LogoHeader";
import { GoogleLogin } from "@react-oauth/google";

const Signup = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();

  const responseGoogle = (response) => {
    dispatch(handleGoogleSignup(response?.credential, navigate));
  };
  const onFinish = (values) => {
    if (token) {
      let users = { ...values, refer_token: token };
      dispatch(handleSignup(users, navigate));
    } else {
      dispatch(handleSignup(values, navigate));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const rules = {
    firstName: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter your first name");
          } else if (value !== "" && !new RegExp("^[A-Za-z]+$").test(value)) {
            return Promise.reject("First name only contain letters");
          } else if (value.length < 2 || value.length > 30) {
            return Promise.reject("First name is between 2 and 30 characters");
          }
          return Promise.resolve();
        },
      },
    ],
    lastName: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter your last name");
          } else if (value !== "" && !new RegExp("^[A-Za-z]+$").test(value)) {
            return Promise.reject("Last name only contain letters");
          } else if (value.length < 2 || value.length > 30) {
            return Promise.reject("Last name is between 2 and 30 characters");
          }
          return Promise.resolve();
        },
      },
    ],
    email: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter your email");
          } else if (
            new RegExp(
              "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+(?:.[a-zA-Z0-9-]+)*$"
            ).test(value)
          ) {
            return Promise.resolve();
          }
          return Promise.reject("Please enter valid email");
        },
      },
    ],
    password: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter password");
          } else if (value?.length < 8) {
            return Promise.reject("Password must be at least 8 characters");
          } else if (
            !value ||
            new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$").test(value)
          ) {
            return Promise.resolve();
          }
          return Promise.reject("Please choose a strong password");
        },
      },
    ],
  };
  return (
    <div className="onboard">
      <LogoHeader />

      <Container>
        <Row>
          <Col md={6} sm={12} className="d-none d-md-block">
            <div className="d-flex justify-content-center align-items-center bodyVh90">
              <img src={IMAGES.signupImg} alt="" />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="signup-form-wrap bodyVh90">
              <div className="signup-form">
                <div className="d-flex justify-content-center">
                  <h4>Create your account</h4>
                </div>
                <GoogleLogin
                  onSuccess={(response) => {
                    responseGoogle(response);
                  }}
                  onError={(err) => {
                    console.log("Err", err);
                  }}
                />
                <Divider plain>Or</Divider>
                <Form
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Your First Name"
                    name="firstName"
                    rules={rules.firstName}
                    hasFeedback
                  >
                    <Input placeholder="Your first name" />
                  </Form.Item>

                  <Form.Item
                    label="Your Last Name"
                    name="lastName"
                    rules={rules.lastName}
                    hasFeedback
                  >
                    <Input placeholder="Your last name" />
                  </Form.Item>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    hasFeedback
                    rules={rules.email}
                  >
                    <Input placeholder="Your email" />
                  </Form.Item>
                  <Form.Item
                    label="Password (must be at least 8 characters)"
                    rules={rules.password}
                    hasFeedback
                    name="password"
                  >
                    <Input.Password
                      name="password"
                      placeholder="Your Password"
                    />
                  </Form.Item>

                  <div className="terms">
                    By signing up, I agree to Inventive Byte's
                    <a
                      href="https://stg.inventivebyte.com/signup"
                      target="_blank"
                      className="terms--links"
                    >
                      Terms of Service, Privacy Policy,
                    </a>
                    and{" "}
                    <span className="terms--links">
                      <a
                        href="https://stg.inventivebyte.com/signup"
                        target="_blank"
                        className="terms--links"
                      >
                        NDA{" "}
                      </a>
                    </span>
                  </div>

                  <div className="btn-yelow">
                    <Button
                      className="btn-primary"
                      type="primary"
                      shape="round"
                      block
                      htmlType="submit"
                    >
                      <div className="btn-info-wrap">
                        <div>
                          <Spin spinning={loading} />
                        </div>
                        Sign Up
                      </div>
                    </Button>
                  </div>
                  <div className="pt-3 text-center active-link">
                    <Link className="login-link" to="/signin">
                      Already have an account? Log in
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Signup;
