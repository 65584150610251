import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import QuoteImg from "../../Assets/images/quote.svg";
import { terminalMemberList } from "../../Utils/constant";
import "../Service/Testimonial.scss";
function Testimonial() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <Carousel
        className="RecentWorksCarousel-main-wrapper testimonialCarousel"
        activeIndex={index}
        onSelect={handleSelect}
      >
        {terminalMemberList?.map((item, key) => {
          return (
            <Carousel.Item key={key}>
              <Container fluid className="p-0">
                <Row>
                  <Col md={12} className="mr-auto">
                    <div className="testimonialCon">
                      <div className="imageContainer">
                        <img src={item?.icon} />
                      </div>
                      <div className="textContainer">
                        <div className="quoteImgCon">
                          <img src={QuoteImg} />
                        </div>
                        <div className="textRightCon">
                          <div className="whiteText">{item?.text}</div>
                          <div className="nameText">{item?.name}</div>
                          <div className="desigText">{item?.details}</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default Testimonial;
