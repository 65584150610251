import React, { useEffect, useState } from "react";
import { Row, Col, Pagination, Spin, Empty } from "antd";
import "./Updates.scss";
import UpdatesIcon from "../../Assets/Icons/UpdatesIcon";
import UpdateBlackIcon from "../../Assets/images/Updates/updateIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectUpdatesData } from "../../redux/actions/updatesAction";
import { convertUTCToTimezone } from "../../Utils/general";
function Updates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { updates, loading, currPage, totalData, pageSize } = useSelector(
    (state) => state?.updatesReducer
  );
  const { profileData } = useSelector((state) => state?.dashboardReducer);
  const [updateList, setUpdateList] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    onRefreshUpdate();
  }, []);

  useEffect(() => {
    setUpdateList(updates || []);
  }, [updates]);
  const onPageChange = (page) => {
    setPage(page);
    dispatch(getProjectUpdatesData(page));
  };
  const onRefreshUpdate = () => {
    dispatch(getProjectUpdatesData(page));
  };
  return (
    <div className="updates-page-main-wrap">
      <div className="mainContainer">
        <div className="header-main">
          <img
            src={UpdateBlackIcon}
            alt="refresh-icon"
            onClick={onRefreshUpdate}
          />
          <span className="header-text">Project updates</span>
        </div>
        <Spin spinning={loading}>
          <div className="dashboard-content">
            <Row gutter={[16, 16]}>
              {updateList?.map((update) => {
                return (
                  <Col
                    xs={{ span: 24 }}
                    lg={{ span: 6 }}
                    sm={{ span: 12 }}
                    className="updateColumn"
                  >
                    <div
                      className="updateCard"
                      onClick={() =>
                        navigate(`/updates/detail/${update?.id}`, {
                          state: { update },
                        })
                      }
                    >
                      <div className="updateIcon">
                        <UpdatesIcon />
                      </div>
                      <div className="headingCard">{update?.name}</div>
                      <div className="dateCardText">
                        {convertUTCToTimezone(
                          update?.created_at,
                          true,
                          profileData?.time_zone ? profileData?.time_zone : "",
                          "MM-DD-YYYY"
                        )}
                      </div>
                      <div className="noteUpdatesText">{update?.details}</div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {!!updateList?.length && (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className="paginationContainer">
                    <Pagination
                      total={totalData}
                      current={currPage}
                      pageSize={pageSize}
                      onChange={onPageChange}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {!updateList?.length && !loading && (
              <Empty description="No Updates" />
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default Updates;
