import * as React from "react";

function FlyIcon(props) {
  return (
    <svg
      width="196"
      height="226"
      viewBox="0 0 196 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_370_1557)">
        <path
          d="M39.3781 133.208C30.1038 133.723 18.779 140.624 19.4574 142.176C20.1319 143.732 27.9883 140.955 27.9883 140.955C27.9883 140.955 12.7775 155.922 14.0077 159.348C14.7857 161.51 22.9065 156.606 22.9065 156.606C22.9065 156.606 11.4208 172.499 12.1337 180.507C12.1337 180.507 9.60812 184.055 10.6889 185.542C11.7696 187.028 16.8552 181.382 16.8552 181.382C16.8552 181.382 10.5892 191.188 13.4252 193.212C16.2612 195.236 31.3569 183.533 40.1829 171.631C40.1829 171.631 38.1978 178.425 41.302 178.321C44.4024 178.218 50.5649 165.305 50.5649 165.305C50.5649 165.305 49.6528 170.379 53.1326 170.709C56.302 171.012 57.4402 159.148 55.1714 148.068C55.1714 148.068 47.7902 152.431 49.534 141.719C49.534 141.719 45.3912 145.541 46.5294 138.681C46.5332 138.677 35.9137 143.582 39.3781 133.208Z"
          fill="#E5C243"
        />
        <path
          d="M58.9183 153.06C57.1823 153.66 58.8263 163.707 60.7809 162.962C62.7354 162.217 60.0949 152.653 58.9183 153.06Z"
          fill="#E5C243"
        />
        <path
          d="M10.2686 165.601C9.54433 164.502 3.56197 169.019 5.07959 170.64C6.59722 172.261 11.38 167.291 10.2686 165.601Z"
          fill="#E5C243"
        />
        <path
          d="M0.0870596 188.234C0.903359 189.359 4.90438 185.407 3.78149 184.712C2.65476 184.017 -0.56828 187.335 0.0870596 188.234Z"
          fill="#E5C243"
        />
        <path
          d="M3.01114 203.083C3.60899 204.012 8.90919 198.551 7.11946 197.809C5.33356 197.068 2.16801 201.765 3.01114 203.083Z"
          fill="#E5C243"
        />
        <path
          d="M32.2338 186.302C30.8618 185.473 27.5813 189.582 28.7961 190.631C30.0148 191.675 33.8549 187.278 32.2338 186.302Z"
          fill="#E5C243"
        />
        <path
          d="M36.8201 141.45C36.8201 141.45 28.5574 146.766 29.55 150.092C30.5426 153.418 37.4256 150.299 37.4256 150.299C37.4256 150.299 23.6979 167.229 28.0132 172.18C32.3247 177.131 43.7912 161.019 43.7912 161.019C43.7912 161.019 48.9496 165.827 52.3336 156.091C52.3336 156.091 42.9059 160.946 47.0679 149.335C47.0679 149.335 40.1466 153.552 44.5921 144.949C44.5921 144.949 33.2253 151.225 36.8201 141.45Z"
          fill="#FFFF00"
        />
        <path
          d="M46.6005 102.732L38.0006 116.359C34.4365 122.005 39.9935 131.929 50.4061 138.531C60.8187 145.13 72.1472 145.906 75.7113 140.26L84.3112 126.633L46.6005 102.732Z"
          fill="#888888"
        />
        <path
          d="M39.4377 114.081C39.8401 115.322 40.3038 116.478 40.8135 117.557L37.5215 123.821C37.6748 124.298 37.8549 124.774 38.0619 125.262L41.5455 118.994C42.335 120.43 43.2088 121.717 44.1324 122.88L40.4609 129.433C40.6947 129.755 40.94 130.074 41.1891 130.397L45.2131 124.163C46.7499 125.88 48.3825 127.305 49.9614 128.53L45.627 134.998C45.9796 135.301 46.336 135.605 46.7077 135.9L51.5365 129.705C53.9969 131.48 56.1967 132.828 57.5227 134.149L52.92 140.006C53.4182 140.275 53.9165 140.532 54.4108 140.774C55.5529 139.499 57.1778 137.671 58.7108 135.916C58.7568 136.066 58.7913 136.215 58.8104 136.369C59.1362 138.904 58.0861 140.728 56.9824 141.907C65.2182 145.176 72.8868 144.726 75.7036 140.267L84.3112 126.633L46.6004 102.732L39.4377 114.081Z"
          fill="#676767"
        />
        <path
          d="M84.6975 133.907C81.3212 139.253 68.5516 137.229 56.1768 129.382C43.7982 121.536 36.5013 110.843 39.8739 105.493L82.3061 38.2573L127.13 66.6672L84.6975 133.907Z"
          fill="#D1D1D1"
        />
        <path
          d="M76.9977 96.3947C64.6191 88.5481 57.3222 77.8555 60.6947 72.5054L52.547 85.4141C49.1707 90.7604 56.4714 101.457 68.85 109.303C81.2287 117.15 93.9982 119.174 97.3707 113.824L105.518 100.915C102.142 106.262 89.3725 104.237 76.9977 96.3947Z"
          fill="#F24822"
        />
        <path
          d="M119.657 28.7939C107.279 20.9473 90.558 25.1874 82.3068 38.2574C78.9305 43.6037 86.2312 54.3001 98.6098 62.1468C110.988 69.9934 123.758 72.0174 127.13 66.6673C135.382 53.5973 132.036 36.6405 119.657 28.7939Z"
          fill="#F24822"
        />
        <path
          d="M119.656 28.794C113.463 24.8688 106.185 23.9701 99.421 25.683C98.689 28.8478 97.9838 34.8816 101.931 38.5303C101.931 38.5303 98.7388 44.7177 101.931 47.917C105.124 51.1163 108.745 50.69 108.745 50.69L112.152 54.5307C112.152 54.5307 107.841 55.3834 97.1407 58.744C88.0541 61.5977 72.0692 70.4275 61.852 84.5076C52.3439 97.6084 51.152 104.833 51.152 104.833L48.4923 121.129C45.3842 119.501 42.5674 117.081 40.5938 113.951C43.1538 118.94 48.7491 124.674 56.1763 129.379C68.5549 137.225 81.3244 139.25 84.6969 133.903L127.129 66.6636C135.38 53.5975 132.035 36.6406 119.656 28.794ZM87.7973 95.3961C82.6887 100.942 78.9368 104.89 78.7222 109.157C78.7222 109.157 72.9736 119.186 62.7526 122.815C59.5488 123.952 55.5401 123.806 51.6809 122.5C52.2251 122.016 52.8536 121.359 53.5971 120.465C53.5971 120.465 54.8732 121.317 56.364 119.612C57.8548 117.907 57.0041 109.584 55.5133 107.875L58.9202 103.393C58.4949 102.114 72.5482 85.0415 97.0296 80.7745C97.0296 80.7745 96.6616 81.6463 95.991 83.0136C94.7416 82.4298 88.4335 82.8292 86.7587 84.2695C85.8313 85.0684 85.3216 87.1501 85.6703 91.3825C85.862 93.6831 86.5633 95.5882 87.7973 95.3961Z"
          fill="#C9C9C9"
        />
        <path
          d="M109.198 52.7488C108.757 54.5194 109.677 58.4293 109.677 58.4293L117.104 56.989C117.104 56.989 116.705 52.2687 115.908 51.6273C115.103 50.9898 109.516 51.4699 109.198 52.7488Z"
          fill="#E9E9E9"
        />
        <path
          d="M94.746 83.9545C93.9872 82.6909 87.2575 83.9046 86.6826 84.9147C86.0426 86.0362 86.2036 91.7973 87.1617 93.395C88.1198 94.9966 95.1445 93.9557 95.4665 92.1967C95.7846 90.4376 95.225 84.7534 94.746 83.9545Z"
          fill="#E9E9E9"
        />
        <path
          d="M109.4 57.0656C109.542 57.8645 109.672 58.4291 109.672 58.4291L117.1 56.9888C117.1 56.9888 116.701 52.2686 115.904 51.6272C115.337 51.1701 112.347 51.2853 110.55 51.8461C110.788 52.1341 110.975 52.6065 110.715 53.3055C110.259 54.5192 110.945 56.5164 109.4 57.0656Z"
          fill="#E9E9E9"
        />
        <path
          d="M95.4628 92.197C95.7809 90.4379 95.2252 84.7536 94.7423 83.9547C94.4702 83.5015 93.4239 83.3671 92.1707 83.4286C91.9063 84.0277 91.3698 84.7075 90.2699 84.9956C88.1161 85.5563 88.1927 87.396 88.2732 89.3971C88.3422 91.1062 89.239 92.8384 87.6523 93.8178C89.5379 94.7664 95.1792 93.7717 95.4628 92.197Z"
          fill="#E9E9E9"
        />
        <path
          d="M11.0515 193.811C11.0515 193.811 9.06248 195.274 5.47536 196.603C5.47536 196.603 2.02621 208.705 4.94266 226L6.93167 225.466C6.93167 225.466 9.05482 206.178 16.3555 202.322L11.0515 193.811Z"
          fill="#2E2E42"
        />
        <path
          opacity="0.3"
          d="M16.3601 202.325L11.0522 193.811C11.0522 193.811 9.89866 194.656 7.77551 195.643C7.35395 198.827 6.72927 206.704 9.65338 212.857C11.1174 208.34 13.275 203.954 16.3601 202.325Z"
          fill="#2E2E42"
        />
        <path
          d="M116.3 117.864L82.7209 108.554C82.7209 108.554 78.7391 112.679 73.6956 121.59C68.6522 130.5 40.6489 168.013 14.6346 189.16L11.5802 189.559L9.98596 192.351C9.32295 198.335 11.0475 203.658 16.6237 203.658C16.6237 203.658 36.9851 186.006 52.5906 171.335C65.8929 158.833 74.0635 149.358 80.433 143.032C89.0903 154.574 110.061 172.664 122.536 167.475C133.128 163.073 127.978 134.491 116.3 117.864ZM88.2549 136.415C90.8302 134.775 93.3366 133.861 96.1266 133.688C104.887 133.154 111.79 140.071 113.38 152.577C102.646 149.604 91.2365 139.253 88.2549 136.415Z"
          fill="#3A3A52"
        />
        <path
          opacity="0.4"
          d="M122.54 167.475C123.713 166.987 124.682 166.188 125.483 165.155C121.877 167.206 116.891 169.13 106.218 162.951C95.974 157.024 94.5139 147.837 96.2806 143.228C92.498 140.348 89.5663 137.659 88.2556 136.415C90.8309 134.775 93.3373 133.861 96.1273 133.688C104.888 133.154 111.79 140.071 113.381 152.577C113.381 152.577 113.381 157.366 115.374 159.893C115.374 159.893 114.312 155.503 114.178 152.312C114.044 149.12 111.921 135.155 102.232 132.229C93.8739 129.705 87.972 134.552 80.4375 143.033C89.091 154.574 110.062 172.664 122.54 167.475Z"
          fill="#3A3A52"
        />
        <path
          opacity="0.4"
          d="M11.5796 189.555C11.5796 189.555 10.6522 194.875 13.5686 198.201C13.5686 198.201 12.5071 194.076 14.6302 189.156L11.5796 189.555Z"
          fill="#3A3A52"
        />
        <path
          d="M82.7207 108.55C82.9851 115.867 91.1635 122.938 103.557 123.183C116.963 123.448 119.485 117.465 119.485 117.465L82.7207 108.55Z"
          fill="#2E2E42"
        />
        <path
          d="M101.699 119.992C101.699 119.992 100.104 122.519 99.4414 123.448C99.4414 123.448 104.086 124.113 107.405 123.583C107.405 123.583 109.13 121.72 109.927 120.126L101.699 119.992Z"
          fill="#4A4A64"
        />
        <path
          d="M184.581 20.11C184.581 20.11 183.34 20.0063 182.297 18.4239C181.255 16.8415 179.193 14.4372 179.5 12.8702C179.802 11.2993 182.335 10.2393 182.335 10.2393C182.335 10.2393 182.746 2.95345 186.651 4.48974C186.651 4.48974 187.8 3.33368 189.088 3.66014C190.28 3.95972 190.874 5.41151 190.874 5.41151C190.874 5.41151 193.794 5.05432 194.036 7.93103C194.036 7.93103 196.385 7.81197 195.952 10.6848C195.519 13.5577 194.71 14.3758 194.059 14.6523C194.059 14.6523 193.718 17.9669 192.683 19.169C191.652 20.3712 190.717 21.0049 190.717 21.0049L187.114 22.3722L184.581 20.11Z"
          fill="#F9BA85"
        />
        <path
          d="M182.332 10.2393C182.332 10.2393 186.946 8.88355 187.697 10.681C188.449 12.4823 183.716 14.4641 183.716 14.4641C183.716 14.4641 187.567 14.9634 189.046 18.9578C189.046 18.9578 189.261 15.793 187.18 14.2606L188.042 13.8035C188.042 13.8035 189.556 16.0542 191.2 14.0186C191.2 14.0186 192.005 16.1809 194.051 14.6485C194.051 14.6485 191.614 14.3259 191.599 12.2173C191.599 12.2173 190.093 14.5794 188.483 12.563C188.483 12.563 189.667 11.4914 188.655 9.98968C187.648 8.4918 184.658 8.68768 182.332 10.2393Z"
          fill="#E7985D"
        />
        <path
          d="M136.436 76.0425C136.436 76.0425 130.703 69.2598 134.723 58.3752C134.723 58.3752 167.268 55.4678 183.421 24.4116L188.932 25.5408C188.928 25.5408 176.507 65.3538 136.436 76.0425Z"
          fill="#C7DADD"
        />
        <path
          d="M189.571 27.3342L181.42 25.4907L183.75 19.937C183.75 19.937 188.46 20.1022 190.744 21.1315L189.571 27.3342Z"
          fill="#C7DADD"
        />
        <path
          d="M107.939 58.4058C107.939 58.4058 109.798 65.9874 104.356 76.2306C104.356 76.2306 74.6241 74.6328 54.4504 103.231L49.1426 101.368C49.1426 101.368 66.7946 63.5946 107.939 58.4058Z"
          fill="#DFE9EB"
        />
        <path
          d="M52.5257 106.757C52.5257 106.757 55.0819 109.218 55.4805 110.812C55.879 112.41 55.515 114.138 55.7142 116.531C55.8522 118.21 55.5379 120.234 53.2155 119.105C53.2155 119.105 51.0349 121.255 49.2337 122.296C47.3405 123.391 45.9608 121.785 46.4897 120.122C46.4897 120.122 44.7651 119.692 44.631 117.196C44.631 117.196 43.006 115.997 42.9715 113.969C42.9715 113.969 41.2776 113.447 41.7988 111.208C42.2396 109.311 44.8302 106.952 45.7577 106.154C46.4743 105.539 48.6128 105.024 48.6128 105.024L52.5257 106.757Z"
          fill="#F9BA85"
        />
        <path
          d="M48.7428 99.5054L56.5724 102.432L53.518 107.621C53.518 107.621 48.8731 106.822 46.75 105.493L48.7428 99.5054Z"
          fill="#DFE9EB"
        />
        <path
          d="M138.422 63.3719C136.475 83.7662 131.344 96.8016 127.538 106.999C125.327 112.921 121.962 121.54 104.709 121.186C87.4551 120.833 81.9671 109.837 81.7027 107.886C81.4574 106.073 84.2359 102.778 86.3935 100.082C96.0396 88.0217 101.083 78.5351 102.497 72.4168L106.479 61.0675L107.939 58.4059L112.58 57.0962C112.58 57.0962 117.16 52.8291 126.419 54.3232C135.682 55.8172 136 60.5106 136 60.5106C136 60.5106 138.288 61.5399 138.422 63.3719Z"
          fill="#DFE9EB"
        />
        <path
          d="M135.783 63.0725C133.548 70.1125 130.992 73.9532 130.992 73.9532L126.416 69.6862L119.924 73.6345C116.306 67.9809 112.792 58.5903 112.792 58.5903C112.792 58.5903 114.176 67.5508 119.284 75.7661L123.385 73.6306L124.745 76.25C122.787 85.8557 116.797 106.15 104.135 121.163C104.326 121.171 104.514 121.183 104.706 121.187C105.449 121.202 106.158 121.194 106.852 121.179C112.988 113.474 121.932 98.315 126.374 76.5381L129.394 74.6446L131.203 76.4036C131.207 76.4075 135.357 70.8576 135.783 63.0725Z"
          fill="#DFE9EB"
        />
        <path
          d="M122.055 74.1145L124.369 72.3555C124.369 72.3555 126.366 72.728 128.121 72.8356L130.038 74.971L125.354 79.2381L122.055 74.1145Z"
          fill="#2E2E42"
        />
        <path
          d="M124.45 75.501C124.45 75.501 120.456 101.96 95.2278 111.88C95.2278 111.88 94.0551 116.843 92.3535 122.389C92.3535 122.389 100.126 122.389 103.636 120.468C103.636 120.468 123.013 101.157 127.163 76.1923L124.45 75.501Z"
          fill="#2E2E42"
        />
        <path
          d="M135.628 54.5884L132.436 55.1491C132.436 55.1491 135.536 58.2601 135.95 69.4712C136.268 78.1128 134.593 85.7136 134.593 85.7136C134.593 85.7136 140.066 78.2742 139.782 68.6685C139.46 57.7915 135.628 54.5884 135.628 54.5884Z"
          fill="#888888"
        />
        <g opacity="0.4">
          <g opacity="0.4">
            <path
              opacity="0.4"
              d="M135.938 74.2222L139.624 72.2672C139.67 71.8486 139.709 71.4223 139.735 70.9883L135.976 72.7819C135.968 73.2697 135.953 73.7498 135.938 74.2222Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M135.628 54.5884L132.436 55.1491C132.436 55.1491 132.574 55.2912 132.796 55.6139L136.306 55.3604C135.9 54.8188 135.628 54.5884 135.628 54.5884Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M139.081 75.4165L135.743 77.4598C135.697 78.032 135.643 78.5851 135.59 79.1151L138.66 77.0181C138.809 76.4996 138.951 75.9657 139.081 75.4165Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M135.946 69.4711C135.954 69.6439 135.958 69.8167 135.962 69.9896L139.783 68.8643C139.783 68.799 139.783 68.7375 139.779 68.6722C139.767 68.3266 139.756 67.9924 139.737 67.6621L135.904 68.557C135.92 68.8566 135.935 69.16 135.946 69.4711Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M137.716 57.8491C137.544 57.4573 137.371 57.1002 137.206 56.7891L133.631 57.1386C133.776 57.4574 133.926 57.8184 134.079 58.2255L137.716 57.8491Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M137.652 79.9258L135.188 82.284C135.088 82.9523 134.993 83.5438 134.908 84.0315L136.537 82.3992C136.901 81.6772 137.28 80.8476 137.652 79.9258Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M139.519 65.0274C139.473 64.6241 139.419 64.2323 139.361 63.856L135.518 64.3207C135.575 64.7355 135.629 65.1618 135.679 65.6112L139.519 65.0274Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M138.908 61.5133C138.812 61.1138 138.716 60.7297 138.613 60.3726L134.838 60.7221C134.93 61.0946 135.018 61.4864 135.106 61.905L138.908 61.5133Z"
              fill="#888888"
            />
          </g>
        </g>
        <path
          d="M116.916 59.8271C119.748 62.6654 122.592 65.4807 126.309 68.9412C126.309 68.9412 130.368 66.6559 133.2 62.5771C133.641 59.1204 134.702 55.4833 136.296 52.1149L120.105 48.835C120.105 48.8311 118.951 53.9739 116.916 59.8271Z"
          fill="#E7985D"
        />
        <path
          d="M110.955 54.0274C110.767 55.1643 114.707 59.8692 111.192 70.4312C107.517 81.4848 89.8727 84.7533 89.3937 86.0322C88.9146 87.3112 89.1561 90.7525 90.2713 91.5552C91.3904 92.3541 110.23 86.1129 115.661 74.4332C121.091 62.7497 116.14 53.628 115.343 53.1479C114.549 52.6678 111.116 53.0672 110.955 54.0274Z"
          fill="#888888"
        />
        <g opacity="0.4">
          <g opacity="0.4">
            <path
              opacity="0.4"
              d="M111.057 54.638C111.11 54.8109 111.187 55.0106 111.271 55.2372C112.9 54.8032 114.755 54.5382 116.181 54.3846C116.008 54.0466 115.847 53.7739 115.705 53.5703C114.019 53.8238 112.275 54.2808 111.057 54.638Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M94.9917 83.666C94.5242 83.8504 94.0758 84.0194 93.6504 84.1807L95.2715 90.4027C95.7889 90.2106 96.3331 89.9994 96.9003 89.7651L94.9917 83.666Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M91.4428 91.532C91.7801 91.4706 92.1748 91.3784 92.627 91.2555C92.0828 89.5963 91.508 86.2664 91.3087 85.0527C90.9216 85.2025 90.5805 85.3369 90.3008 85.4599C90.4617 87.7067 91.1171 90.3299 91.4428 91.532Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M118.15 62.4809C118.127 61.9893 118.092 61.5092 118.046 61.0483L112.635 61.2596C112.658 61.7166 112.665 62.1967 112.654 62.6999L118.15 62.4809Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M111.953 57.2113C112.08 57.6376 112.198 58.1061 112.302 58.6246L117.445 57.7989C117.322 57.3303 117.192 56.8886 117.054 56.4854C115.184 56.7081 113.16 57.0192 111.953 57.2113Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M112.365 65.7378C112.3 66.1411 112.223 66.5559 112.135 66.9822C113.557 67.1857 116.143 67.6774 117.753 67.9923C117.86 67.4123 117.948 66.8401 118.014 66.2832L112.365 65.7378Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M109.354 74.0337C109.078 74.4254 108.783 74.8057 108.473 75.1705L113.167 78.4198C113.482 78.0242 113.784 77.6171 114.068 77.2023L109.354 74.0337Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M116.791 71.6217L111.314 70.0547C111.272 70.1814 111.238 70.3043 111.195 70.4311C111.103 70.7038 111.004 70.9688 110.896 71.2338L116.227 73.1426C116.434 72.628 116.618 72.1248 116.791 71.6217Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M98.8647 82.0068C98.4469 82.2066 98.0292 82.3947 97.6191 82.5753L99.8764 88.4247C100.409 88.1635 100.949 87.8908 101.494 87.5989L98.8647 82.0068Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M106.661 77.0371C106.308 77.3559 105.94 77.6631 105.568 77.9589L109.201 82.4564C109.623 82.0992 110.033 81.7343 110.436 81.3618L106.661 77.0371Z"
              fill="#888888"
            />
            <path
              opacity="0.4"
              d="M103.288 79.5835C102.874 79.8485 102.457 80.102 102.035 80.3478L104.86 85.6327C105.4 85.287 105.937 84.9298 106.469 84.5572L103.288 79.5835Z"
              fill="#888888"
            />
          </g>
        </g>
        <path
          d="M109.168 41.5336C109.168 41.5336 113.947 55.3602 131.914 55.2066C149.884 55.0491 154.126 45.1055 152.739 36.0107C151.351 26.9158 147.603 22.9291 148.849 11.5337L107.965 13.9879L105.945 25.3296L109.168 41.5336Z"
          fill="#F9BA85"
        />
        <path
          d="M111.987 34.279C111.987 34.279 120.786 32.4048 110.784 20.2335C110.784 20.2335 129.072 28.9443 143.271 13.7887C143.271 13.7887 150.74 17.7178 152.887 13.5199C155.033 9.32197 150.817 5.30841 145.325 4.15619C145.325 4.15619 149.713 3.37268 148.617 1.27948C147.521 -0.813715 142.033 0.204079 139.883 2.22814C139.883 2.22814 129.199 -2.23478 117.644 1.46768C106.089 5.17014 103.985 13.2472 104.081 16.3582C104.081 16.3582 96.7344 21.9004 105.909 34.7937L111.987 34.279Z"
          fill="black"
        />
        <path
          opacity="0.15"
          d="M142.582 7.37801C142.582 7.37801 148.205 13.5769 153.447 10.9076C153.198 7.7544 149.665 5.06205 145.334 4.1518C145.334 4.1518 149.722 3.36829 148.626 1.27509C147.53 -0.818109 142.042 0.199684 139.892 2.22375C139.892 2.22375 129.207 -2.23918 117.653 1.46328C106.098 5.16575 103.994 13.2428 104.09 16.3538C104.09 16.3538 98.966 20.2291 102.637 29.0244C105.121 27.1616 108.401 23.4015 107.252 16.9337C107.248 16.9376 127.636 26.6201 142.582 7.37801Z"
          fill="black"
        />
        <path
          d="M111.989 34.2785C111.989 34.2785 106.513 30.6413 103.807 36.195C101.101 41.7487 108.521 50.9472 114.795 43.0046L111.989 34.2785Z"
          fill="#F9BA85"
        />
        <path
          d="M111.159 36.6519C111.159 36.6519 106.775 34.3859 105.617 37.7158C104.333 41.4144 107.495 43.4308 110.216 42.8931C110.212 42.8969 105.441 38.7413 111.159 36.6519Z"
          fill="#E7985D"
        />
        <path
          d="M140.131 44.1605C136.85 43.8302 133.148 39.1292 131.06 42.5013C128.971 45.8735 132.466 50.563 138.146 50.54C143.825 50.5169 146.795 46.6493 145.466 44.6329C144.251 42.7894 142.492 44.3987 140.131 44.1605Z"
          fill="#682917"
        />
      </g>
      <defs>
        <clipPath id="clip0_370_1557">
          <rect width="196" height="226" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FlyIcon;
