import React, { useEffect } from "react";
import MainDashboardComponent from "../../Components/DashboardComponents/MainDashboardComponent";
import ProjectOnboardingProgress from "../../Components/DashboardComponents/ProjectOnboardingProgress";
import MainLayout from "../../Components/MainLayout/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { getUserStatus } from "../../redux/actions/dashboardActions";

function MainDashboard() {
  const { userStatus } = useSelector((state) => state.dashboardReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserStatus());
  }, []);

  return (
    <>
      {!userStatus ? (
        ""
      ) : userStatus?.profile_completed === false ? (
        <ProjectOnboardingProgress
          boarding_stage={userStatus?.boarding_stage}
          // boarding_stage={2}
        />
      ) : (
        <MainDashboardComponent />
      )}
    </>
  );
}

export default MainDashboard;
