import { store } from "../../store";
import api from "../../Utils/api";
import {
  GET_ONBOARD_DATA_FAIL,
  GET_ONBOARD_DATA_INIT,
  GET_ONBOARD_DATA_SUCCESS,
  SET_LOADER,
  SET_ONBOARDING_DATA,
} from "./actionTypes";
import { userLogout } from "./authActions";

export const getOnboardData = (result, navigate) => (dispatch) => {
  dispatch({ type: GET_ONBOARD_DATA_INIT });
  api
    .getWithToken(`${process.env.REACT_APP_API_URL}/client/saveProject`)
    .then((res) => {
      const response = res.data;

      if (!!result?.data?.boarded) {
        navigate("/dashboard");
      } else if (response?.steps_completed === null) {
        navigate("/onboarding/intro");
      } else if (response?.steps_completed === 1) {
        navigate("/onboarding/yourProjectCategory");
      } else if (response?.steps_completed === 2) {
        navigate("/onboarding/development");
      } else if (response?.steps_completed === 3) {
        navigate("/onboarding/developmentStage");
      } else if (response?.steps_completed === 4) {
        navigate("/onboarding/features");
      } else if (response?.steps_completed === 5) {
        navigate("/onboarding/budget");
      } else if (response?.steps_completed === 6) {
        navigate("/onboarding/wantToStart");
      } else if (response?.steps_completed === 7) {
        navigate("/onboarding/appLive");
      } else if (response?.steps_completed === 8) {
        navigate("/dashboard");
      } else {
        navigate("/onboarding/intro"); // No Saved Projects For User Found
      }

      dispatch({ type: GET_ONBOARD_DATA_SUCCESS });
      store.dispatch(
        setOnboardingData({
          ProjectType: response?.types?.id,
          projectName: response?.name,
          ProjectDisc: response?.description,
          selectedCategories: response?.category?.id,
          otherCategory: response?.category_other,
          projectPhase: response?.phase?.id,
          otherPhase: response?.phase_other,
          developmentStage: response?.stage,
          documentURL: response?.document,
          documentUploadURL: null,
          selectedFeatures: response?.features.map((feature) => feature.id),
          otherFeature: response?.feature_other,
          fixed_budget: response?.fixed_budget,
          total_budget: response?.total_budget,
          monthly_budget: response?.monthly_budget,
          starting_option: response?.starting_option,
          targetDate: response?.target_date,
          appLive: response?.needed_live,
          steps_completed: response?.steps_completed,
        })
      );
    })
    .catch((err) => {
      dispatch({ type: GET_ONBOARD_DATA_FAIL });
      console.log(err);
    });
};

export const SaveOnboarding =
  (payload, navigate, saveType = "save&Logout") =>
  (dispatch) => {
    console.log("payload", payload);
    const bodyFormData = {
      name: payload?.projectName,
      description: payload?.ProjectDisc,
      types: payload?.ProjectType,
      category: payload?.selectedCategories,
      category_other: payload?.otherCategory,
      features: payload?.selectedFeatures,
      feature_other: payload?.otherFeature,
      phase: payload?.projectPhase,
      phase_other: payload?.otherPhase,
      stage: payload?.developmentStage,
      starting_option: payload?.starting_option,
      fixed_budget: payload?.fixed_budget,
      total_budget: payload?.fixed_budget ? payload?.total_budget : null,
      monthly_budget: payload?.fixed_budget ? payload?.monthly_budget : null,
      needed_live: payload?.appLive,
      target_date: payload?.targetDate,
      document: payload?.documentURL,
      steps_completed: payload?.steps_completed,
    };
    saveType === "save&Logout" && dispatch({ type: SET_LOADER });
    api
      .postWithToken(
        `${process.env.REACT_APP_API_URL}/client/${
          saveType === "save&Logout" || saveType === "save&next"
            ? "saveProject"
            : "project"
        }`,
        bodyFormData
      )
      .then(() => {
        if (saveType === "save&Logout") {
          dispatch({ type: SET_LOADER });
          dispatch(userLogout(navigate));
        } else if (saveType === "finishOnboarding") {
          navigate("/onboarding/ideaDetails");
        }
      })
      .catch((err) => console.log(err));
  };

export const setOnboardingData = (data) => (dispatch) => {
  dispatch({
    type: SET_ONBOARDING_DATA,
    payload: data,
  });
};
