import React, { useEffect } from "react";
import "./ProjectInfo.scss";
import { useNavigate } from "react-router-dom";
import { Divider, Spin } from "antd";
import ProjectInfoIcon from "../../Assets/Icons/ProjectInfoIcon";
import ProjectInfoHeaderIcon from "../../Assets/Icons/ProjectInfoHeaderIcon";
import EditRoundIcon from "../../Assets/Icons/EditRoundIcon";
import FileIcon from "../../Assets/Icons/FileIcon";
import { useDispatch, useSelector } from "react-redux";
import { getProjectData } from "../../redux/actions/dashboardActions";
import { convertUTCToTimezone } from "../../Utils/general";
function ProjectInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectData, Loader } = useSelector(
    (state) => state.dashboardReducer
  );
  const { profileData } = useSelector((state) => state?.dashboardReducer);

  useEffect(() => {
    dispatch(getProjectData());
  }, []);

  return (
    <div className="ProjectInfoPage-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="backCon">
              <div className="backIcon">
                <ProjectInfoIcon />
              </div>
              <div className="backText">Project Page</div>
            </div>
            <Spin spinning={Loader}>
              <div className="detailCardCon">
                <div className="detailHeaderCon">
                  <ProjectInfoHeaderIcon />
                  <div>
                    <div className="detailHeaderText">
                      {projectData?.name}
                      <EditRoundIcon
                        onClick={() => navigate("/edit-project-name")}
                      />
                    </div>
                    <div className="detailDateText normalFontWeight">
                      {projectData?.description}
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="projectDetailCon">
                  <div className="mainProjectDetails">
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Project type</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-project-type")}
                        />
                      </div>
                      <div className="contentText">
                        {projectData?.types?.name}
                      </div>
                    </div>
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Project category</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-project-category")}
                        />
                      </div>
                      <div className="contentText">
                        {projectData?.category?.name}
                      </div>
                    </div>
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Phase</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-project-phase")}
                        />
                      </div>
                      <div className="contentText">
                        {projectData?.phase?.name}
                      </div>
                    </div>
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Development stage</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-project-development")}
                        />
                      </div>
                      <div className="contentText">{projectData?.stage}</div>
                    </div>
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Total budget</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-project-total-budget")}
                        />
                      </div>
                      <div className="contentText">
                        {projectData?.total_budget
                          ? `${projectData?.total_budget}K`
                          : "N/A"}
                      </div>
                    </div>
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Monthly budget</span>
                        <EditRoundIcon
                          onClick={() =>
                            navigate("/edit-project-monthly-budget")
                          }
                        />
                      </div>
                      <div className="contentText">
                        {projectData?.monthly_budget
                          ? `${projectData?.monthly_budget}K`
                          : "N/A"}
                      </div>
                    </div>
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Want to start</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-want-to-start")}
                        />
                      </div>
                      <div className="contentText">
                        {projectData?.starting_option}
                      </div>
                    </div>
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Target date</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-target-date")}
                        />
                      </div>
                      <div className="contentText">
                        {projectData?.needed_live
                          ? projectData?.target_date
                            ? convertUTCToTimezone(
                                projectData?.target_date,
                                true,
                                profileData?.time_zone
                                  ? profileData?.time_zone
                                  : "",
                                "MMMM DD, YYYY"
                              )
                            : "N/A"
                          : "N/A"}
                        {}
                      </div>
                    </div>
                  </div>
                  <div className="featuresContentCon">
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Application features</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-application-features")}
                        />
                      </div>
                      <div className="contentText">
                        {projectData?.features
                          .map((item) => item.name)
                          .join(", ")}
                      </div>
                    </div>
                    <div className="sectionCon">
                      <div className="detailsHeaderCon">
                        <span>Helpful documents</span>
                        <EditRoundIcon
                          onClick={() => navigate("/edit-helpful-documents")}
                        />
                      </div>
                      <div className="fileContainer">
                        {projectData?.document?.length ? (
                          projectData?.document?.map((item) => (
                            <div className="contentText fileContentCon">
                              <FileIcon />
                              <span>{item?.split("/")?.slice(-1)[0]}</span>
                            </div>
                          ))
                        ) : (
                          <p className="contentText">N/A</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectInfo;
