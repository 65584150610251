import api from "../../Utils/api";
import {
  GET_INVOICES_DATA_INIT,
  GET_INVOICES_DATA_SUCCESS,
  GET_INVOICES_DATA_FAIL,
  GET_INVOICE_DETAILS_DATA_INIT,
  GET_INVOICE_DETAILS_DATA_SUCCESS,
  GET_INVOICE_DETAILS_DATA_FAIL,
  REQUEST_INVOICE_PAY_BY_BANK_INIT,
  REQUEST_INVOICE_PAY_BY_BANK_SUCCESS,
  REQUEST_INVOICE_PAY_BY_BANK_FAIL,
  REQUEST_INVOICE_PAY_BY_CARD_INIT,
  REQUEST_INVOICE_PAY_BY_CARD_SUCCESS,
  REQUEST_INVOICE_PAY_BY_CARD_FAIL,
} from "./actionTypes";

export const getInvoicesData = (page, filter) => {
  return (dispatch) => {
    dispatch({
      type: GET_INVOICES_DATA_INIT,
    });

    api
      .getWithToken(
        `${process.env.REACT_APP_API_URL}/client/invoices?filter=${filter}&page=${page}`
      )
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_INVOICES_DATA_SUCCESS,
            payload: res,
          });
        } else {
          dispatch({
            type: GET_INVOICES_DATA_FAIL,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_INVOICES_DATA_FAIL,
        });
      });
  };
};

export const getInvoiceDetailsData = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_INVOICE_DETAILS_DATA_INIT,
    });

    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/invoice/${id}`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_INVOICE_DETAILS_DATA_SUCCESS,
            payload: res,
          });
        } else {
          dispatch({
            type: GET_INVOICE_DETAILS_DATA_FAIL,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_INVOICE_DETAILS_DATA_FAIL,
        });
      });
  };
};

export const payInvoiceAccount = (data, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_INVOICE_PAY_BY_BANK_INIT,
    });
    api
      .postWithToken(
        `${process.env.REACT_APP_API_URL}/payment/payInvoiceAccount`,
        data
      )
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: REQUEST_INVOICE_PAY_BY_BANK_SUCCESS,
            payload: res,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: REQUEST_INVOICE_PAY_BY_BANK_FAIL,
          });
          onFail && onFail();
        }
      })
      .catch((err) => {
        dispatch({
          type: REQUEST_INVOICE_PAY_BY_BANK_FAIL,
        });
      });
  };
};
export const payInvoiceCard = (data, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_INVOICE_PAY_BY_CARD_INIT,
    });
    api
      .postWithToken(
        `${process.env.REACT_APP_API_URL}/payment/payInvoiceCard`,
        data
      )
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: REQUEST_INVOICE_PAY_BY_CARD_SUCCESS,
            payload: res,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: REQUEST_INVOICE_PAY_BY_CARD_FAIL,
          });
          onFail && onFail();
        }
      })
      .catch((err) => {
        dispatch({
          type: REQUEST_INVOICE_PAY_BY_CARD_FAIL,
        });
        onFail && onFail();
      });
  };
};
