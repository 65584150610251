import React, { useEffect, useState } from "react";
import "./AddNewFeature.scss";
import {
  SettingOutlined,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { IsValidForm } from "../../../Utils/validations";
import { message } from "antd";
import { postRequestFeature } from "../../../redux/actions/supportActions";
import BackButtonImg from "../../../Assets/images/Support/back_icon.svg";
import { useDispatch } from "react-redux";

function AddNewFeature() {
  const [showSubmit, setShowSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const [data, setData] = useState({ name: "", details: "" });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let valRegx = /^[A-Za-z]( ?[A-Za-z0-9@#$%^^&*~(){}+-_ *\\\\',:,?<>_s] ?)*$/;
  useEffect(() => {
    if (showSubmit) {
      setTimeout(() => {
        navigate("/support/support-chat", { state: location?.state });
      }, 3000);
    }
  }, [showSubmit]);

  if (showSubmit) {
    return (
      <div className="d-flex invoice-paid-wrap flex-column align-items-center justify-content-center">
        <CheckCircleFilled className="check-circle-paid-icon" />
        <p className="paid-text">Submitted Successfully!</p>
      </div>
    );
  }
  const onSubmitValidation = () => {
    let obj = { ...errors };
    let isvalid = true;
    if (!new RegExp(valRegx).test(data?.name)) {
      obj.name = "please enter valid features name";
      isvalid = false;
    } else {
      obj.name = "";
    }
    if (!new RegExp(valRegx).test(data?.details)) {
      obj.details = "please enter valid features details";
      isvalid = false;
    } else {
      obj.details = "";
    }
    setErrors(obj);
    return isvalid;
  };
  const onChangeValidation = (field, value) => {
    if (!value) {
      setErrors({ ...errors, [field]: "This field is required" });
    } else if (!new RegExp(valRegx).test(value)) {
      setErrors({ ...errors, [field]: `please enter valid features ${field}` });
    } else {
      setErrors({ ...errors, [field]: "" });
    }
  };
  const onSubmit = () => {
    let fields = ["name", "details"];
    let formValidation = IsValidForm(fields, data);
    setErrors(formValidation.errors);
    if (formValidation?.validate) {
      if (onSubmitValidation()) {
        setLoader(true);
        dispatch(
          postRequestFeature(
            {
              feature_name: data?.name,
              feature_details: data?.details,
            },
            () => {
              setShowSubmit(true);
              setLoader(false);
            },
            () => {
              message.error("Failed to Request New Feature");
              setLoader(false);
            }
          )
        );
      }
    }
  };
  const onChange = (e, key) => {
    let newData = { ...data };
    let newErrors = { ...errors };
    newErrors[key] = "";
    newData[key] = e;
    setData(newData);
    setErrors(newErrors);
    onChangeValidation(key, e);
  };

  return (
    <div className="add-new-features-main w-100">
      <div className="add-new-features-wrapper">
        <div
          className="d-flex align-items-center add-new-features-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={BackButtonImg} alt="BackButtonImg" />
          <span className="features-back-text">Back</span>
        </div>
        <div className="add-new-feature-card">
          <div className="d-flex align-items-center justify-content-between add-new-feature-header">
            <div className="d-flex align-items-center feature-header-setting">
              <SettingOutlined className="feature-header-setting-icon" />
              <span className="feature-header-setting-text">
                Request New Feature
              </span>
            </div>
            <CloseOutlined
              className="feature-header-setting-close"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="add-new-feature-body" style={{ textAlign: "left" }}>
            <form className="feature-form-wrapper">
              <div className="form-group feature-form">
                <label className="feature-lable">Feature Name</label>
                <input
                  type="text"
                  className={`form-control feature-input  ${
                    errors?.name ? "custom-error" : ""
                  }`}
                  onChange={(e) => onChange(e.target.value, "name")}
                />
                {errors?.name ? (
                  <p className="validation-eror">{errors?.name}</p>
                ) : null}
              </div>
              <div className="form-group feature-form">
                <label className="feature-lable">Feature Details</label>
                <div
                  className={`textarea-wrap  ${
                    errors?.details ? "error-wrapper" : ""
                  }`}
                >
                  <textarea
                    class="form-control feature-input"
                    rows="6"
                    onChange={(e) => onChange(e.target.value, "details")}
                  ></textarea>
                </div>
                {errors?.details ? (
                  <p className="validation-eror">{errors?.details}</p>
                ) : null}
              </div>
            </form>
            <div className="d-flex align-items-center feature-buttons-group">
              <button
                className="feature-send"
                onClick={onSubmit}
                disabled={loader}
              >
                Send
              </button>
              <button
                className="feature-cancel"
                onClick={() => navigate("/support/support-chat")}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewFeature;
