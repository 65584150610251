import {
  SET_SETTING_TAB,
  GET_CARD_DATA_INIT,
  GET_CARD_DATA_SUCCESS,
  GET_CARD_DATA_FAIL,
  GET_ACCOUNT_DATA_INIT,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAIL,
  DELETE_ACCOUNT_INIT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILED,
  GET_LINK_TOKEN_INIT,
  GET_LINK_TOKEN_SUCCESS,
  GET_LINK_TOKEN_FAILED,
  DELETE_CREDIT_DEBIT_DATA_INIT,
  DELETE_CREDIT_DEBIT_DATA_SUCCESS,
  DELETE_CREDIT_DEBIT_DATA_FAIL,
  ADD_CREDIT_DEBIT_DATA_INIT,
  ADD_CREDIT_DEBIT_DATA_SUCCESS,
  ADD_CREDIT_DEBIT_DATA_FAIL,
  UPDATE_AUTOPAY_DATA_INIT,
  UPDATE_AUTOPAY_DATA_SUCCESS,
  UPDATE_AUTOPAY_DATA_FAIL,
} from "./actionTypes";
import api from "../../Utils/api";
export const settingToggle = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SETTING_TAB,
      payload: data,
    });
  };
};
export const getCardList = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch({
      type: GET_CARD_DATA_INIT,
    });

    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/payment/paymentMethods`)
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: GET_CARD_DATA_SUCCESS,
            payload: res,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: GET_CARD_DATA_FAIL,
          });
          onFail && onFail();
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_CARD_DATA_FAIL,
        });
        onFail && onFail();
      });
  };
};
export const getAccountList = (onSuccess, onfail) => {
  return (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_DATA_INIT,
    });

    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/payment/getAchAccount`)
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: GET_ACCOUNT_DATA_SUCCESS,
            payload: res,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: GET_ACCOUNT_DATA_FAIL,
          });
          onfail && onfail();
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ACCOUNT_DATA_FAIL,
        });
        onfail && onfail();
      });
  };
};
export const deleteAccount = (id, onSuccess, onfail) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_ACCOUNT_INIT,
    });
    api
      .delete(`${process.env.REACT_APP_API_URL}/payment/unlinkAchAccount`, {
        source: id,
      })
      .then((res) => {
        dispatch({
          type: DELETE_ACCOUNT_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess();
        return;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_ACCOUNT_FAILED,
        });
        onfail && onfail();
        throw error?.response?.data?.error;
      });
  };
};
export const addCreditDebitCard = (data, id, onSuccess, onfail) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_CREDIT_DEBIT_DATA_INIT,
    });
    api
      .postWithToken(
        `${process.env.REACT_APP_API_URL}/payment/paymentMethods`,
        { payment_method: id, auto_pay: data?.autopay }
      )
      .then((res) => {
        if (res?.error) {
          dispatch({
            type: ADD_CREDIT_DEBIT_DATA_FAIL,
          });
          onfail && onfail(res?.error);
        } else {
          dispatch({
            type: ADD_CREDIT_DEBIT_DATA_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_CREDIT_DEBIT_DATA_FAIL,
        });
        onfail && onfail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};
export const deleteCreditDebitCard = (id, onSuccess, onfail) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CREDIT_DEBIT_DATA_INIT,
    });
    api
      .delete(
        `${process.env.REACT_APP_API_URL}/payment/paymentMethods?payment_method=${id}`
      )
      .then((res) => {
        if (res?.error) {
          dispatch({
            type: DELETE_CREDIT_DEBIT_DATA_FAIL,
          });
          onfail && onfail();
        } else {
          dispatch({
            type: DELETE_CREDIT_DEBIT_DATA_SUCCESS,
            payload: res,
          });
          onSuccess && onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_CREDIT_DEBIT_DATA_FAIL,
        });
        onfail && onfail();
        throw error?.response?.data?.error || error?.message;
      });
  };
};
export const getLinkToken = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_LINK_TOKEN_INIT,
    });
    api
      .postWithToken(`${process.env.REACT_APP_API_URL}/payment/getLinkToken`)
      .then((res) => {
        dispatch({
          type: GET_LINK_TOKEN_SUCCESS,
          payload: res,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: GET_LINK_TOKEN_FAILED,
        });
        throw error?.response?.data?.error || error?.message;
      });
  };
};
export const editAutopayinfo = (id, data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_AUTOPAY_DATA_INIT,
    });
    api
      .putWithToken(
        `${process.env.REACT_APP_API_URL}/payment/paymentMethod/${id}`,
        {
          auto_pay: data?.autopay,
        }
      )
      .then((res) => {
        if (res?.data) {
          dispatch({
            type: UPDATE_AUTOPAY_DATA_SUCCESS,
            payload: res.data,
          });
          onSuccess && onSuccess(res?.data);
          return;
        } else {
          dispatch({
            type: UPDATE_AUTOPAY_DATA_FAIL,
          });
          onFail && onFail(res?.error);
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_AUTOPAY_DATA_FAIL,
        });
        onFail && onFail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};
