import React, { useState } from "react";
import "./EditProjectNameAndDesc.scss";

import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Space } from "antd";
import { updateProjectData } from "../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";

const EditProjectNameAndDesc = () => {
  const navigate = useNavigate();
  const [updatedData, setupdatedData] = useState({});
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );
  const [projectDiscErr, setProjectDiscErr] = useState(false);
  const dispatch = useDispatch();
  const initialValues = {
    projectName: projectData?.name,
    ProjectDisc: projectData?.description,
  };

  const handleChange = (e) => {
    setupdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const rules = {
    projectName: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter the project name");
          } else if (new RegExp("^[A-Za-z]( ?[A-Za-z0-9] ?)*$").test(value)) {
            return Promise.resolve();
          }
          return Promise.reject("Please enter valid project name");
        },
      },
    ],
    ProjectDisc: [
      {
        validator(_, value) {
          if (!value) {
            setProjectDiscErr(true);
            return Promise.reject("Please enter the project description");
          }
          setProjectDiscErr(false);
          return Promise.resolve();
        },
      },
    ],
  };

  const handleSubmit = (formData) => {
    dispatch(
      updateProjectData(
        {
          ...projectData,
          name: formData?.projectName,
          description: formData?.ProjectDisc,
        },
        navigate
      )
    );
  };

  return (
    <div className="editProjectNameAndDescPage-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">
                  Edit project name & Description
                </div>
                <Form onFinish={handleSubmit} initialValues={initialValues}>
                  <div className="fieldsCon">
                    <div className="fieldConText">Project Name</div>
                    <Form.Item rules={rules.projectName} name="projectName">
                      <Input
                        size="large"
                        name="projectName"
                        onChange={handleChange}
                        defaultValue={projectData?.name}
                        value={projectData?.name}
                      />
                    </Form.Item>
                  </div>
                  <div className="fieldsCon">
                    <div className="fieldConText">Project Description</div>
                    <Form.Item
                      name="ProjectDisc"
                      rules={rules.ProjectDisc}
                      className={`custom-edit-textarea ${
                        projectDiscErr ? "error-wrapper" : ""
                      }`}
                    >
                      <Input.TextArea
                        style={{ height: 180 }}
                        name="ProjectDisc"
                        onChange={handleChange}
                        defaultValue={projectData?.description}
                      />
                    </Form.Item>
                  </div>
                  <div className="buttonContainer">
                    <Space>
                      <Button
                        className="sendButton"
                        htmlType="submit"
                        loading={updateLoader}
                      >
                        Send
                      </Button>
                      <Button
                        className="cancelButton"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProjectNameAndDesc;
