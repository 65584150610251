import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./PaymentTypeStep.scss";
import yourCreditIcon from "../../../../../src/Assets/images/Invoices/your_credit.png";
import bankIcon from "../../../../../src/Assets/images/Invoices/bank_icon.png";
import {
  getCardList,
  getAccountList,
} from "../../../../redux/actions/settingsAction";
import { usePlaidLink } from "react-plaid-link";
import axios from "../../../../Utils/api";
import { message, Spin } from "antd";
function PaymentTypeStep(props) {
  const { setCurrentStep } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { accountList, cardList, get_link_token_data } = useSelector(
    (state) => state?.settingsReducer
  );

  const { open } = usePlaidLink({
    token: get_link_token_data?.link_token,
    onSuccess: async (public_token, metadata) => {
      setLoading(true);
      await axios
        .postWithToken(
          `${process.env.REACT_APP_API_URL}/payment/createPlaidItem`,
          {
            public_token: public_token,
            institution_id: metadata?.institution?.institution_id,
            institution_name: metadata?.institution?.name,
          }
        )
        .then((res) => {
          axios
            .getWithToken(
              `${process.env.REACT_APP_API_URL}/payment/getAccounts?access_token=${res?.data?.access_token}`
            )
            .then((res1) => {
              axios
                .postWithToken(
                  `${process.env.REACT_APP_API_URL}/payment/linkAchAccount`,
                  {
                    access_token: res?.data?.access_token,
                    account_id: res1?.accounts[0]?.account_id,
                    auto_pay: false,
                  }
                )
                .then((res2) => {
                  if (res2?.error) {
                    message.error(res2?.error, 2);
                  } else {
                    message.success("Account Linked Succesfully", 2);
                    dispatch(getAccountList());
                  }
                  setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                  message.error(
                    error?.response?.data?.error || error?.message,
                    2
                  );
                });
            })
            .catch((error) => {
              setLoading(false);
              message.error(error?.response?.data?.error || error?.message, 2);
            });
        })
        .catch((error) => {
          message.error(error?.response?.data?.error || error?.message, 2);
          setLoading(false);
        });
    },
  });
  const onCreditCardAction = () => {
    setLoading(true);
    dispatch(
      getCardList(
        () => {
          cardList?.length > 0 ? setCurrentStep(5) : setCurrentStep(4);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  };
  const onBankAccountAction = () => {
    setLoading(true);
    dispatch(
      getAccountList(
        () => {
          accountList?.length > 0 ? setCurrentStep(3) : open();
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      )
    );
  };
  return (
    <div className="payment-type-step-main mt-3">
      <Spin spinning={loading}>
        <div className="credit-card-btn mb-5" onClick={onCreditCardAction}>
          <img src={yourCreditIcon} alt="" />
          <span>Credit card</span>
        </div>
        <div className="credit-card-btn mb-3" onClick={onBankAccountAction}>
          <img src={bankIcon} alt="" />
          <span>Bank Account</span>
        </div>
      </Spin>
    </div>
  );
}

export default PaymentTypeStep;
