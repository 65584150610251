import * as React from "react";

function AppDevelopmentIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="78"
      height="88"
      viewBox="0 0 78 88"
      fill="none"
    >
      <rect
        x="7.48047"
        y="19.4648"
        width="70.2288"
        height="58.0558"
        fill="#E5C243"
      />
      <g clip-path="url(#clip0_529_15577)">
        <path
          d="M54.2994 44.5274C54.2994 55.9609 54.3094 67.3895 54.2943 78.823C54.2893 84.8522 51.3378 87.7818 45.1833 87.7968C33.4627 87.8218 21.737 87.8268 10.0164 87.7968C3.85189 87.7818 0.930529 84.8672 0.930529 78.808C0.925501 55.781 0.925501 32.754 0.930529 9.72195C0.930529 3.67274 3.86194 0.768121 10.0315 0.753123C21.7521 0.728126 33.4778 0.728126 45.1984 0.753123C51.3478 0.768121 54.2893 3.68774 54.2943 9.73695C54.3044 21.3354 54.2994 32.9339 54.2994 44.5274ZM51.207 44.2875C51.207 32.769 51.2171 21.2555 51.202 9.73695C51.197 5.48751 49.593 3.91271 45.2688 3.90771C33.5431 3.89771 21.8175 3.89771 10.0918 3.90771C5.57654 3.90771 4.02284 5.42251 4.01782 9.83694C4.01279 32.789 4.01279 55.741 4.01782 78.693C4.01782 83.1174 5.57654 84.6472 10.0717 84.6522C21.7119 84.6622 33.3521 84.6622 44.9973 84.6522C49.6735 84.6472 51.202 83.1524 51.207 78.588C51.2121 67.1545 51.207 55.721 51.207 44.2875Z"
          fill="black"
        />
        <path
          d="M48.0798 44.2909C48.0798 53.5747 48.0849 62.8584 48.0748 72.1422C48.0698 75.1268 47.8284 75.3618 44.7462 75.3668C33.2669 75.3768 21.7927 75.3218 10.3134 75.4068C7.90489 75.4268 7.10039 74.6069 7.11044 72.2272C7.18084 53.5797 7.18084 34.9271 7.11044 16.2795C7.10039 13.8598 7.98534 13.1249 10.3536 13.1449C21.9133 13.2299 33.4781 13.2249 45.0378 13.1499C47.3256 13.1349 48.1402 13.9298 48.1201 16.1945C48.0296 25.5583 48.0798 34.9271 48.0798 44.2909ZM44.6858 72.1072C44.6858 53.4097 44.6858 34.9871 44.6858 16.5295C33.1864 16.5295 21.858 16.5295 10.5396 16.5295C10.5396 35.1471 10.5396 53.5697 10.5396 72.1072C21.9787 72.1072 33.2418 72.1072 44.6858 72.1072Z"
          fill="black"
        />
        <path
          d="M27.5899 81.5809C25.6641 81.5809 23.7283 81.7058 21.8226 81.5159C21.1137 81.4459 20.4852 80.621 19.8164 80.141C20.4952 79.6011 21.1589 78.6212 21.8578 78.5962C25.6993 78.4413 29.5559 78.4563 33.4025 78.5862C34.0863 78.6112 34.745 79.4611 35.4137 79.9311C34.7299 80.481 34.0964 81.4159 33.3572 81.5059C31.4566 81.7308 29.5157 81.5809 27.5899 81.5809Z"
          fill="black"
        />
        <path
          d="M27.5196 10.0108C26.519 10.0108 25.4631 10.2008 24.5379 9.94081C23.9295 9.77083 23.2507 9.00593 23.11 8.39601C23.0295 8.04606 23.9446 7.10118 24.4575 7.06119C26.5291 6.90121 28.6309 6.88121 30.6974 7.06119C31.2455 7.11118 31.7131 8.06106 32.2159 8.60099C31.6478 9.07592 31.15 9.80083 30.5013 9.96081C29.5611 10.1958 28.5152 10.0208 27.5146 10.0208C27.5196 10.0158 27.5196 10.0158 27.5196 10.0108Z"
          fill="black"
        />
        <path
          d="M18.1523 10.5573C17.4635 9.67247 16.6338 9.04255 16.7344 8.6426C16.8903 8.01768 17.6797 7.54774 18.1976 7.00781C18.6853 7.55774 19.4396 8.05768 19.5602 8.68259C19.6457 9.10254 18.8362 9.69246 18.1523 10.5573Z"
          fill="black"
        />
        <path
          d="M36.9573 6.37109C37.7014 7.37596 38.4355 7.95089 38.4808 8.57081C38.516 9.00575 37.5003 9.96563 37.1483 9.88064C36.5399 9.74066 35.8008 9.03575 35.66 8.44082C35.5594 7.99588 36.3086 7.35597 36.9573 6.37109Z"
          fill="black"
        />
        <path
          d="M27.6151 56.716C24.2764 56.716 20.9327 56.831 17.6091 56.641C16.7392 56.591 15.9247 55.6011 15.085 55.0412C15.9247 54.5763 16.7543 53.7264 17.599 53.7114C24.2714 53.6014 30.9488 53.6014 37.6211 53.7114C38.4709 53.7264 39.3056 54.5763 40.1453 55.0362C39.3056 55.5961 38.491 56.591 37.6262 56.641C34.2925 56.826 30.9488 56.716 27.6151 56.716Z"
          fill="black"
        />
        <path
          d="M22.9793 50.4793C20.968 50.4793 18.9467 50.6093 16.9606 50.4043C16.2868 50.3343 15.7036 49.4244 15.0801 48.8995C15.7237 48.3846 16.3572 47.4447 17.0209 47.4247C20.953 47.2847 24.89 47.2847 28.822 47.4297C29.4606 47.4547 30.059 48.4295 30.6774 48.9595C30.0388 49.4644 29.4405 50.3393 28.7516 50.4093C26.846 50.6093 24.9051 50.4793 22.9793 50.4793Z"
          fill="black"
        />
        <path
          d="M21.4304 59.8038C22.9288 59.8038 24.4473 59.6538 25.9055 59.8738C26.524 59.9688 27.0217 60.8337 27.5748 61.3486C27.0368 61.8636 26.5139 62.7984 25.9507 62.8234C22.8836 62.9734 19.8013 62.9734 16.7341 62.8284C16.1559 62.8034 15.6229 61.8886 15.0698 61.3836C15.6078 60.8587 16.0956 59.9588 16.6989 59.8738C18.2526 59.6638 19.8516 59.8038 21.4304 59.8038Z"
          fill="black"
        />
        <path
          d="M30.8081 27.2941C29.6063 30.8237 28.5957 33.9733 27.4141 37.0529C27.2381 37.5128 25.976 38.0627 25.6743 37.8677C25.1313 37.5178 24.5078 36.5229 24.6586 36.003C25.5738 32.8334 26.6397 29.6988 27.8063 26.6142C27.9823 26.1543 29.1136 25.6393 29.5661 25.7993C30.1494 26.0093 30.4913 26.8742 30.8081 27.2941Z"
          fill="black"
        />
        <path
          d="M33.8752 44.2677C32.3769 44.2677 30.8533 44.4377 29.4002 44.1927C28.7465 44.0828 28.2286 43.1429 27.6504 42.583C28.2437 42.133 28.8219 41.3181 29.4304 41.2881C32.4171 41.1531 35.4139 41.1531 38.4006 41.2931C38.9989 41.3231 39.5621 42.163 40.1403 42.6279C39.547 43.1779 39.014 44.0878 38.3453 44.1977C36.8921 44.4327 35.3686 44.2677 33.8752 44.2677Z"
          fill="black"
        />
        <path
          d="M18.695 31.5779C19.6202 32.5578 20.3593 33.0678 20.6862 33.7627C20.9828 34.3926 20.837 35.2325 20.8873 35.9774C20.1331 35.9924 19.1475 36.3123 18.6648 35.9574C17.4782 35.0875 16.3921 34.0026 15.5323 32.8128C15.1954 32.3478 15.306 31.208 15.6681 30.6781C16.3519 29.6732 17.2519 28.7133 18.2827 28.0734C18.9414 27.6635 19.9923 27.8534 20.8622 27.7734C20.7415 28.5783 20.7867 29.4632 20.4448 30.1681C20.1884 30.6981 19.4442 30.998 18.695 31.5779Z"
          fill="black"
        />
        <path
          d="M36.2891 31.8656C35.5851 31.1107 34.841 30.5958 34.5292 29.8908C34.2527 29.2609 34.4588 28.426 34.4488 27.6811C35.203 27.7161 36.1634 27.4712 36.6712 27.8361C37.8025 28.651 38.8434 29.6809 39.6479 30.8157C39.9948 31.3007 39.9295 32.4355 39.5775 32.9654C38.8987 33.9803 37.9886 34.9452 36.9578 35.5901C36.2941 36.01 35.2432 35.8301 34.3633 35.9151C34.484 35.1052 34.4588 34.2403 34.7655 33.5104C35.0019 32.9404 35.6455 32.5355 36.2891 31.8656Z"
          fill="black"
        />
        <path
          d="M35.4538 62.9193C34.5437 62.9193 33.5683 63.1143 32.7336 62.8593C31.9995 62.6393 31.4162 61.9194 30.7676 61.4195C31.381 60.8796 31.9542 59.9447 32.623 59.8697C34.5085 59.6547 36.4444 59.6597 38.3299 59.8747C38.9886 59.9497 39.5467 60.8946 40.1551 61.4445C39.5015 61.9394 38.9132 62.6443 38.1791 62.8643C37.3343 63.1093 36.3639 62.9193 35.4538 62.9193Z"
          fill="black"
        />
        <path
          d="M19.8766 41.2321C20.8721 41.2321 21.918 41.0421 22.8382 41.2971C23.4616 41.4671 24.1656 42.202 24.3265 42.8119C24.417 43.1568 23.4918 44.1417 22.9739 44.1817C20.8319 44.3367 18.6648 44.3317 16.5228 44.1717C16.0099 44.1317 15.0998 43.1518 15.1853 42.8069C15.3412 42.202 16.0451 41.4471 16.6586 41.3021C17.6742 41.0521 18.8005 41.2321 19.8766 41.2321Z"
          fill="black"
        />
        <path
          d="M36.927 50.987C35.7957 50.3621 34.8605 49.9722 34.1213 49.3422C33.9504 49.1973 34.423 47.7824 34.6895 47.7524C36.2181 47.5825 37.7869 47.5575 39.3204 47.7125C39.6322 47.7424 40.1451 49.1823 40.0545 49.2423C39.1193 49.8872 38.0835 50.3771 36.927 50.987Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_529_15577">
          <rect
            width="53.3739"
            height="87.0837"
            fill="white"
            transform="translate(0.925781 0.738281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AppDevelopmentIcon;
