import { CheckCircleFilled, DownloadOutlined } from '@ant-design/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import InvoiceDetailStep from '../InvoiceSteps/InvoiceDetailStep/InvoiceDetailStep';
import './PaidInvoiceDetails.scss';

function PaidInvoiceDetails(props) {
	const navigate = useNavigate();
	return (
		<div className='paid-invoice-details-page d-flex align-items-center justify-content-center'>
			<div className='w-100'>
				<div className='paid-invoice-details-page-header d-flex align-items-center justify-content-between'>
					<span className='invoice-number-text'>003</span>
					<div className='d-flex align-items-center'>
						<CheckCircleFilled className='paid-icon' />
						<span className='paid-text'>Paid</span>
						<div className='download-btn'>
							<DownloadOutlined />
						</div>
					</div>
				</div>
				<div className='paid-invoice-details-page-body'>
					<div className='d-flex align-items-center justify-content-center p-4'>
						<InvoiceDetailStep />
					</div>
				</div>
			</div>
		</div>
	);
}

export default PaidInvoiceDetails;
