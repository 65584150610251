import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Space, Upload, message } from "antd";
import { Col, Container, Row } from "react-bootstrap";
import { DeleteOutlined, FilePdfOutlined } from "@ant-design/icons";
import UploadIcon from "../../Assets/Icons/UploadIcon";
import api from "../../Utils/api";
import {
  updateProjectData,
  uploadFile,
} from "../../redux/actions/dashboardActions";
import { dummyRequest } from "../../Utils/general";
import "./EditHelpfulDocuments.scss";

function EditHelpfulDocuments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Dragger } = Upload;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );

  useEffect(() => {
    setUploadedFiles(projectData?.document);
  }, [projectData]);

  const deleteFile = (item) => {
    setUploadedFiles(
      uploadedFiles?.filter((i) => {
        return i !== item;
      })
    );
  };

  const props = {
    name: "file",
    multiple: true,
    showUploadList: false,
    accept: ".pdf",
    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        api
          .getWithToken(
            `${process.env.REACT_APP_API_URL}/client/getPresignedUrl?key_type=project_doc&file_name=${info.file.name}`
          )
          .then((data) => {
            setUploadedFiles([...uploadedFiles, data?.data?.fields?.key]);
            uploadFile(info.fileList, data?.data?.fields, data?.data?.url);
          })
          .catch((err) => console.log(err));
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(info) {
      console.log(info);
    },
  };

  return (
    <div className="editHelpfulDocuments-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">Edit helpful documents</div>
                <div className="projectTypeCon editHelpfulDocuments">
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <UploadIcon />
                    </p>
                    <p className="ant-upload-text">
                      Drag and drop your PDF file here
                    </p>
                    <p className="ant-upload-text">OR</p>
                    <div className="chooseFileText">Choose File</div>
                  </Dragger>
                  {uploadedFiles?.map((item) => (
                    <>
                      <Container className="uploadedFiles">
                        <Row className="mt-3">
                          <Col className="mx-auto" md={6} sm={12}>
                            <p className="w-100">
                              <FilePdfOutlined />
                              <span className="pl-5">
                                {item?.split("/")?.slice(-1)[0]}
                              </span>
                              <DeleteOutlined
                                onClick={() => deleteFile(item)}
                              />
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </>
                  ))}
                </div>
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() =>
                        dispatch(
                          updateProjectData(
                            {
                              ...projectData,
                              document: uploadedFiles,
                            },
                            navigate
                          )
                        )
                      }
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditHelpfulDocuments;
