import {
  login,
  register,
  LOGOUT,
  SET_AUTH_LOADER,
  GET_USER_DATA_FROM_TOKEN_INIT,
  GET_USER_DATA_FROM_TOKEN_SUCCESS,
  GET_USER_DATA_FROM_TOKEN_FAIL,
  INITIALIZE_GOOGLE_SIGNUP,
  GOOGLE_SIGNUP_SUCCESS,
  GOOGLE_SIGNUP_FAIL,
  INITIALIZE_GOOGLE_LOGIN,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAIL,
  ADD_SUBSCRIBE_NEWSLETTER_INIT,
  ADD_SUBSCRIBE_NEWSLETTER_FAIL,
  ADD_SUBSCRIBE_NEWSLETTER_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  userData: null,
  loading: false,
  error: "",
  token: null,
  authLoading: true,
  authData: {},
};

export const authReducer = (state = initialState, action) => {
  const { type, payload, tokenPayload } = action;
  switch (type) {
    //Login reducers
    case SET_AUTH_LOADER: {
      return {
        ...state,
        loading: !state.loading,
      };
    }
    case login.INITIALIZE_LOGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case login.LOGIN_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case login.LOGIN_SUCCESS: {
      return {
        ...state,
        userData: payload,
        token: tokenPayload,
        authData: { ...(state.authData || {}), ...(action.payload || {}) },
        loading: false,
      };
    }

    //Signup reducers
    case register.INITIALIZE_SIGNUP: {
      return {
        ...state,
        loading: true,
      };
    }
    case register.SIGNUP_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case register.SIGNUP_SUCCESS: {
      return {
        ...state,
        userData: payload,
        token: tokenPayload,
        loading: false,
        authData: {
          ...(state.authData || {}),
          boarded: false,
          verified: false,
        },
      };
    }

    //google Signup reducers
    case INITIALIZE_GOOGLE_SIGNUP: {
      return {
        ...state,
        loading: true,
      };
    }
    case GOOGLE_SIGNUP_SUCCESS: {
      return {
        ...state,
        userData: payload,
        token: tokenPayload,
        loading: false,
        authData: {
          ...(state.authData || {}),
          boarded: false,
          verified: false,
        },
      };
    }
    case GOOGLE_SIGNUP_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    //google Signin reducers
    case INITIALIZE_GOOGLE_LOGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case GOOGLE_LOGIN_SUCCESS: {
      return {
        userData: payload,
        token: tokenPayload,
        authData: { ...(state.authData || {}), ...(action.payload || {}) },
        loading: false,
      };
    }
    case GOOGLE_LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_USER_DATA_FROM_TOKEN_INIT:
      return {
        ...state,
        authLoading: true,
        authData: {},
      };
    case GET_USER_DATA_FROM_TOKEN_SUCCESS:
      return {
        ...state,
        authLoading: false,
        authData: action?.payload,
      };
    case GET_USER_DATA_FROM_TOKEN_FAIL:
      return {
        ...state,
        authLoading: false,
        authData: {},
        loading: false,
      };
    case LOGOUT: {
      return {
        ...initialState,
        authData: {},
        authLoading: false,
      };
    }
    case ADD_SUBSCRIBE_NEWSLETTER_INIT:
      return {
        ...state,
        loading: true,
      };
    case ADD_SUBSCRIBE_NEWSLETTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_SUBSCRIBE_NEWSLETTER_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
