import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row, Spin, message } from "antd";
import { FormGroup } from "reactstrap";
import CreditCard from "../../../../src/Assets/images/Invoices/your_credit.png";
import "./BankAccountInfo.scss";
import React, { useState } from "react";
import {
  deleteAccount,
  getAccountList,
  editAutopayinfo,
} from "../../../redux/actions/settingsAction";
import EditRoundIcon from "../../../Assets/Icons/EditRoundIcon";

import { useDispatch, useSelector } from "react-redux";
import SetupCard from "./SetupCard";
const BankAccountInfo = (props) => {
  const { currentPaymentSection, setCurrentPaymentSection } = props;
  const dispatch = useDispatch();
  const [active, setActive] = useState();
  const [editId, setEditId] = useState();
  const { accountList, cardList } = useSelector(
    (state) => state?.settingsReducer
  );
  const [loading, setLoading] = useState(false);
  const [creditCard, setCreditcard] = useState(false);
  const onSave = (id) => {
    let data = {
      autopay: active === "Enable",
    };
    setLoading(true);
    dispatch(
      editAutopayinfo(
        id,
        data,
        (res) => {
          dispatch(
            getAccountList(() => {
              setLoading(false);

              setActive("");
              setEditId("");
              message.success(res, 2);
            })
          );
        },
        (error) => {
          setLoading(false);
          message.success(error, 2);
        }
      )
    );
  };
  if (creditCard === true) {
    return (
      <SetupCard
        currentPaymentSection={currentPaymentSection}
        setCurrentPaymentSection={setCurrentPaymentSection}
      />
    );
  }

  return (
    <div className="bank-account-details-wrap">
      <div className="bank-card-wrapper d-flex justify-content-center justify-content-md-between">
        {accountList && cardList.length === 0 ? (
          <button
            className="bank-card-button"
            onClick={() => {
              setCreditcard(true);
            }}
          >
            <img className="bank-card-img" src={CreditCard} alt="creditCard" />
            <span className="bank-card-text">Add Credit/Debit Card</span>
          </button>
        ) : (
          ""
        )}
      </div>
      {accountList &&
        accountList?.map((data, key) => {
          return (
            <Spin spinning={loading}>
              <div className="bank-details-main">
                <div className="bank-detail-headding">
                  <h6 className="headding-bank d-flex alaign-items-center">
                    Bank Account Info
                    <span className="bank-details-edit-icon d-flex alaign-items-center justify-content-center">
                      <DeleteOutlined
                        className="edit-icon"
                        onClick={() => {
                          setLoading(true);
                          dispatch(
                            deleteAccount(
                              data?.source?.id,
                              () => {
                                message.success("Bank info deleted.", 2);
                                dispatch(
                                  getAccountList(
                                    () => {
                                      setLoading(false);
                                    },
                                    () => {
                                      setLoading(false);
                                    }
                                  )
                                );
                              },
                              () => {
                                message.error("Bank info delete failed.", 2);
                                setLoading(false);
                              }
                            )
                          );
                        }}
                      />
                    </span>
                  </h6>
                </div>
                <Row className="bank-desc-wrapper" gutter={[32, 44]}>
                  <>
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Payment Method</h6>

                        <span className="bank-desc-text">
                          {data?.payment_method === null
                            ? "N/A"
                            : data?.payment_method}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Account Holder Name</h6>

                        <span className="bank-desc-text">
                          {data?.source?.account_holder_name === null
                            ? "N/A"
                            : data?.source?.account_holder_name}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Routing Number</h6>
                        <span className="bank-desc-text">
                          {data?.source?.routing_number}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Account Number</h6>
                        <span className="bank-desc-text">
                          ******{data?.source?.last4}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 12 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Account Type</h6>
                        <span className="bank-desc-text">
                          {data?.account_type === null
                            ? "N/A"
                            : data?.account_type}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Autopay</h6>
                        <span className="bank-desc-text">
                          {editId !== data?.id ? (
                            <>
                              {data?.auto_pay ? "Enable" : "Disable"}
                              <EditRoundIcon
                                onClick={() => {
                                  setEditId(data?.id);
                                  setActive(
                                    data?.auto_pay ? "Enable" : "Disable"
                                  );
                                }}
                              />
                            </>
                          ) : (
                            <FormGroup>
                              <div className="btn-toggle-wrapper">
                                <div
                                  className="btn-group btn-toggle custom-toggle-button custom-switch"
                                  data-toggle="buttons"
                                >
                                  <button
                                    type="button"
                                    className={
                                      active === "Enable"
                                        ? "btn btn-default active"
                                        : "btn btn-default"
                                    }
                                    name="Enable"
                                    onClick={() => {
                                      setActive("Enable");
                                    }}
                                  >
                                    Enable
                                  </button>
                                  <button
                                    type="button"
                                    className={
                                      active === "Disable"
                                        ? "btn btn-default active"
                                        : "btn btn-default"
                                    }
                                    name="Disable"
                                    onClick={() => {
                                      setActive("Disable");
                                    }}
                                  >
                                    Disable
                                  </button>
                                </div>
                                <button
                                  className="save-btn"
                                  onClick={() => {
                                    onSave(data?.id);
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                            </FormGroup>
                          )}
                        </span>
                      </div>
                    </Col>
                  </>
                </Row>
              </div>
            </Spin>
          );
        })}
    </div>
  );
};

export default BankAccountInfo;
