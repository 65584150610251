import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import { Form, Input, Spin } from "antd";
import "./Features.scss";
import OnboardingStepper from "../../../Components/OnboardingStepper";
import api from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveOnboarding,
  setOnboardingData,
} from "../../../redux/actions/onboardingActions";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import SaveAndNext from "../../../Components/Save&Next";
import { useNavigate } from "react-router-dom";

const Features = () => {
  const [projectFeatures, setProjectFeature] = useState([]);
  const [saveType, setSaveType] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const { payload } = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/projectFeature`)
      .then((data) => {
        setProjectFeature(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (data) => {
    setError({ ...error, featureErr: "" });
    payload?.selectedFeatures?.includes(data?.id)
      ? dispatch(
          setOnboardingData({
            ...payload,
            selectedFeatures: payload?.selectedFeatures?.filter(
              (item) => item !== data.id
            ),
          })
        )
      : dispatch(
          setOnboardingData({
            ...payload,
            selectedFeatures: [...payload?.selectedFeatures, data?.id],
          })
        );
  };

  const handleOtherInput = (e) => {
    setError({ ...error, otherErr: "" });
    dispatch(setOnboardingData({ ...payload, otherFeature: e.target.value }));
  };

  useEffect(() => {
    if (saveType === "save&Logout") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
    } else if (saveType === "save&next") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
      navigate("/onboarding/budget");
    }
  }, [saveType]);

  const validateForm = (clickType) => {
    if (!payload?.selectedFeatures.length) {
      setError({ ...error, featureErr: "Please select at least one feature" });
    } else if (
      (payload?.selectedFeatures?.includes(
        projectFeatures.find((item) => item.name === "Others")?.id
      ) &&
        !payload?.otherFeature) ||
      payload?.otherFeature === ""
    ) {
      setError({ ...error, otherErr: "Please input other feature" });
    } else if (clickType === "Save&Logout") {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 5,
        })
      );
      setSaveType("save&Logout");
    } else {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 5,
        })
      );
      setSaveType("save&next");
    }
  };
  const onSave_Logout_Fun = () => {
    dispatch(
      setOnboardingData({
        ...payload,
        steps_completed: 4,
      })
    );
    setSaveType("save&Logout");
  };
  return (
    <div className="onboard pb-3">
      <OnboardingHeader steps_completed={4} validateForm={onSave_Logout_Fun} />
      <Container className="mt-5">
        <Row>
          <div className="d-flex justify-content-center">
            <Col sm={10}>
              <OnboardingStepper activeElement={5} />

              <Row>
                <div className="d-flex justify-content-center">
                  <Col sm={12}>
                    <div className="features my-5">
                      <span className="title">
                        What type of <span>features</span> are in your
                        application?
                      </span>
                      <div className="features-links">
                        {loading ? (
                          <Spin />
                        ) : (
                          <ul>
                            {projectFeatures?.map((feature, index) => (
                              <li
                                key={index}
                                onClick={() => handleChange(feature)}
                              >
                                <a
                                  href={null}
                                  className={
                                    payload?.selectedFeatures?.includes(
                                      feature?.id
                                    )
                                      ? "project-active"
                                      : ""
                                  }
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      feature?.image
                                    }
                                    alt=""
                                  />
                                  <span>{feature?.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}

                        <p className="text-danger">{error?.featureErr}</p>
                      </div>
                    </div>

                    {payload?.selectedFeatures?.includes(
                      projectFeatures.find((item) => item.name === "Other")?.id
                    ) && (
                      <div className="project-form">
                        <Form layout="vertical">
                          <Form.Item
                            name={["user", "Other"]}
                            label="Other"
                            className={`custom-textarea ${
                              error?.otherErr ? "error-wrapper" : ""
                            }`}
                          >
                            <Input.TextArea
                              style={{ height: 180 }}
                              onChange={handleOtherInput}
                              defaultValue={payload?.otherFeature}
                            />
                          </Form.Item>
                        </Form>
                        <p
                          className="text-danger"
                          style={{ textAlign: "left" }}
                        >
                          {error?.otherErr}
                        </p>
                      </div>
                    )}

                    <SaveAndNext
                      backUrl={"/onboarding/developmentStage"}
                      validateForm={validateForm}
                    />
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Features;
