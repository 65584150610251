import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./IdeaDetails.scss";
import "antd/dist/antd.css";
import { Button } from "antd";
import IMAGES from "../../../Assets/images/Onboarding";
import { useNavigate } from "react-router-dom";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../redux/actions/authActions";
import { getUserStatus } from "../../../redux/actions/dashboardActions";

const IdeaDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateForm = () => {
    dispatch(userLogout(navigate));
  };

  return (
    <div className="onboard pb-3">
      <OnboardingHeader validateForm={validateForm} />

      <Container className="mt-5">
        <Row>
          <div className="d-flex justify-content-center">
            <Col sm={12} md={5}>
              <div className="idea-details">
                <img className="img-fluid" src={IMAGES.ideaDetails} alt="" />
                <div className="title">
                  We’ve got your idea details. Now let’s make it into a{" "}
                  <span className="text-yellow">unicorn</span> ;)
                </div>
                <div className="mt-5 btn-yelow">
                  <Button
                    className="btn-primary"
                    type="primary"
                    shape="round"
                    block
                    onClick={() => {
                      dispatch(getUserStatus(navigate));
                    }}
                  >
                    Go to Dashboard
                  </Button>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default IdeaDetails;
