import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header";

import IMAGES from "../../Assets/images/Onboarding";
import "./Faq.scss";
import Footer from "../../Components/Footer/Footer";
import { faqList } from "../../Utils/constant";

const Faq = () => {
  return (
    <>
      <Header />
      <div className="Service Faq">
        <div className="banner">
          <Container>
            <div className="row">
              <div className="col-xl-10 col-md-12 m-auto">
                <div className="banner-grid">
                  <div className="banner-set">
                    <div className="banner-set--left">
                      <h2 className="custom_h1">
                        Frequently
                        <span className="custom_h1_yellow">
                          {" "}
                          Asked Questions
                        </span>
                      </h2>
                      <p className="custom_para">
                        Our venture studio provides you with the startup
                        expertise to launch your idea successfully
                      </p>
                    </div>
                    <div className="banner-set--right">
                      <div>
                        <img
                          className="img-fluid"
                          src={IMAGES.mainFaq}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="faqList">
                    {faqList.map((item, key) => {
                      return (
                        <div className="listItemFaq" key={key}>
                          <div className="innerWrapper">
                            <div className="title">{item?.heading}</div>
                            <div className="subText">{item?.text}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Faq;
