import React from "react";
import { Container } from "react-bootstrap";
import Header from "../Header";

import IMAGES from "../../Assets/images/Onboarding";
import "./CorporateGiving.scss";
import Footer from "../../Components/Footer/Footer";

const CorporateGiving = () => {
  return (
    <>
      <Header />
      <div className="Service Corporate-wrap">
        <div className="banner">
          <Container>
            <div className="banner-grid">
              <div className="banner-set">
                <div className="banner-set--left">
                  <h1 className="custom-align">
                    Corporate
                    <span className="yellowText">Giving</span>
                  </h1>
                  <p>
                    We donate 20% of our revenue towards{" "}
                    <span style={{ color: "#E5C243" }}>
                      Grameen Education Fund,
                    </span>
                    a 501c(3) non-profit that focuses on improving healtcare
                    equality in underprivileged parts of the world. Just $9
                    helps provide health check-ups for a patient for an entire
                    year. Through this partnership, we have helped already 100s
                    of rural villagers. Let's build a better, healthier world
                    together.
                  </p>
                </div>
                <div className="banner-set--right">
                  <div>
                    <img
                      className="img-fluid"
                      src={IMAGES.mainCorporateGiving}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default CorporateGiving;
