import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import { Form, Input, Spin } from "antd";
import "./YourProjectCategory.scss";
import OnboardingStepper from "../../../Components/OnboardingStepper";
import api from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveOnboarding,
  setOnboardingData,
} from "../../../redux/actions/onboardingActions";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import SaveAndNext from "../../../Components/Save&Next";
import { useNavigate } from "react-router-dom";

const YourProjectCategory = () => {
  const [categories, setCategories] = useState([]);
  const [saveType, setSaveType] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const { payload } = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/projectCategory`)
      .then((data) => {
        setCategories(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (data) => {
    setError({ ...error, categoryErr: "" });
    dispatch(setOnboardingData({ ...payload, selectedCategories: data?.id }));
  };

  const handleOtherInput = (e) => {
    setError({ ...error, otherErr: "" });
    dispatch(setOnboardingData({ ...payload, otherCategory: e.target.value }));
  };

  useEffect(() => {
    if (saveType === "save&Logout") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
    } else if (saveType === "save&next") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
      navigate("/onboarding/development");
    }
  }, [saveType]);

  const validateForm = (clickType) => {
    if (!payload?.selectedCategories) {
      setError({ ...error, categoryErr: "Please select project category" });
    } else if (
      (categories.find((item) => item.name === "Others")?.id ===
        payload?.selectedCategories &&
        !payload?.otherCategory) ||
      payload?.otherCategory === ""
    ) {
      setError({ ...error, otherErr: "Please input other category" });
    } else if (clickType === "Save&Logout") {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 2,
        })
      );
      setSaveType("save&Logout");
    } else {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 2,
        })
      );
      setSaveType("save&next");
    }
  };
  const onSave_Logout_Fun = () => {
    dispatch(
      setOnboardingData({
        ...payload,
        steps_completed: 1,
      })
    );
    setSaveType("save&Logout");
  };
  return (
    <div className="onboard pb-3">
      <OnboardingHeader steps_completed={1} validateForm={onSave_Logout_Fun} />

      <Container className="mt-5">
        <Row>
          <div className="d-flex justify-content-center">
            <Col sm={10}>
              <OnboardingStepper activeElement={2} />

              <Row>
                <div className="d-flex justify-content-center">
                  <Col sm={12}>
                    <div className="project-category my-5">
                      <span className="title">
                        Which <span>category</span> best matches your project?
                      </span>
                      <div className="project-category-links">
                        {loading ? (
                          <Spin />
                        ) : (
                          <ul>
                            {categories?.map((category, index) => (
                              <li
                                key={index}
                                onClick={() => handleChange(category)}
                              >
                                <a
                                  href={null}
                                  className={
                                    payload?.selectedCategories === category?.id
                                      ? "project-active"
                                      : ""
                                  }
                                >
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      category?.image
                                    }
                                    alt=""
                                    style={{ width: "30px", height: "30px" }}
                                  />
                                  <span>{category?.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <p className="text-danger mt-3">{error?.categoryErr}</p>
                    </div>

                    {payload?.selectedCategories?.includes(
                      categories.find((item) => item.name === "Other")?.id
                    ) && (
                      <div className="project-form">
                        <Form layout="vertical">
                          <Form.Item
                            name={["user", "Other"]}
                            label="Other"
                            className={`custom-textarea ${
                              error?.otherErr ? "error-wrapper" : ""
                            }`}
                          >
                            <Input.TextArea
                              style={{ height: 180 }}
                              onChange={handleOtherInput}
                              defaultValue={payload?.otherCategory}
                            />
                          </Form.Item>
                        </Form>
                        <p
                          className="text-danger"
                          style={{ textAlign: "left" }}
                        >
                          {error?.otherErr}
                        </p>
                      </div>
                    )}

                    <SaveAndNext
                      backUrl={"/onboarding/projectType"}
                      validateForm={validateForm}
                    />
                  </Col>
                </div>
              </Row>
            </Col>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default YourProjectCategory;
