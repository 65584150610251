import React, { useEffect, useState } from "react";
import "./EditApplicationFeatures.scss";
import { useNavigate } from "react-router-dom";
import { Button, Space, Spin } from "antd";
import api from "../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectData } from "../../redux/actions/dashboardActions";

const EditApplicationFeatures = () => {
  const navigate = useNavigate();

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [projectFeatures, setProjectFeature] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/projectFeature`)
      .then((data) => {
        setProjectFeature(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setSelectedFeatures(projectData?.features);
  }, [projectData]);

  const handleChange = (feature) => {
    if (selectedFeatures?.map((item) => item?.id)?.includes(feature?.id)) {
      setSelectedFeatures(
        selectedFeatures.filter((item) => item?.id !== feature?.id)
      );
    } else {
      setError("");
      setSelectedFeatures([...selectedFeatures, feature]);
    }
  };

  return (
    <div className="editProjectFeatures-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">Edit application features</div>
                <div className="projectTypeCon">
                  {loading ? (
                    <Spin />
                  ) : (
                    projectFeatures.map((feature, key) => (
                      <div
                        className={`projectTypeItem ${
                          selectedFeatures
                            ?.map((item) => item?.id)
                            ?.includes(feature?.id) && "selectedProjectTypeItem"
                        }`}
                        onClick={() => handleChange(feature)}
                        key={key}
                      >
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + feature?.image}
                          alt=""
                        />
                        <div className="projectTypeText m-1">
                          {feature.name}
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <p className="text-danger text-center">{error}</p>
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() => {
                        selectedFeatures?.length
                          ? dispatch(
                              updateProjectData(
                                {
                                  ...projectData,
                                  features: selectedFeatures,
                                },
                                navigate
                              )
                            )
                          : setError("Please select at least one feature");
                      }}
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditApplicationFeatures;
