import {
  GET_INVOICES_DATA_INIT,
  GET_INVOICES_DATA_SUCCESS,
  GET_INVOICES_DATA_FAIL,
  GET_INVOICE_DETAILS_DATA_INIT,
  GET_INVOICE_DETAILS_DATA_SUCCESS,
  GET_INVOICE_DETAILS_DATA_FAIL,
  REQUEST_INVOICE_PAY_BY_BANK_INIT,
  REQUEST_INVOICE_PAY_BY_BANK_SUCCESS,
  REQUEST_INVOICE_PAY_BY_BANK_FAIL,
  REQUEST_INVOICE_PAY_BY_CARD_INIT,
  REQUEST_INVOICE_PAY_BY_CARD_SUCCESS,
  REQUEST_INVOICE_PAY_BY_CARD_FAIL,
} from "../actions/actionTypes";

const initialState = {
  invoices: [],
  totalInvoiceCount: 0,
  invoiceDetails: null,
  loading: false,
  creditAmount: 0,
};

export const invoicesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INVOICES_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_INVOICES_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoices: payload?.invoices,
        totalInvoiceCount: payload?.paginations?.total_docs,
        creditAmount: payload?.credit_amount,
      };
    }
    case GET_INVOICES_DATA_FAIL: {
      return {
        ...state,
        loading: false,
        invoices: [],
        totalInvoiceCount: 0,
        creditAmount: 0,
      };
    }
    case GET_INVOICE_DETAILS_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_INVOICE_DETAILS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoiceDetails: payload?.data,
      };
    }
    case GET_INVOICE_DETAILS_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case REQUEST_INVOICE_PAY_BY_BANK_INIT:
      return {
        loading: true,
      };
    case REQUEST_INVOICE_PAY_BY_BANK_SUCCESS:
      return { loading: false };
    case REQUEST_INVOICE_PAY_BY_BANK_FAIL:
      return {
        loading: false,
      };
    case REQUEST_INVOICE_PAY_BY_CARD_INIT:
      return {
        loading: true,
      };
    case REQUEST_INVOICE_PAY_BY_CARD_SUCCESS:
      return {
        loading: false,
      };
    case REQUEST_INVOICE_PAY_BY_CARD_FAIL:
      return {
        loading: false,
      };

    default:
      return state;
  }
};
