import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../Header";
import { useState } from "react";
import IMAGES from "../../Assets/images/Onboarding";
import awsIcon from "../../Assets/images/home/awsIcon.svg";
import clerkyIcon from "../../Assets/images/home/clerkyIcon.svg";
import left_arrow_icon from "../../Assets/images/home/left-arrow.svg";
import right_arrow_icon from "../../Assets/images/home/right-arrow.svg";

import "./Service.scss";
import Footer from "../../Components/Footer/Footer";

import { Link, useNavigate } from "react-router-dom";
import { serviceStrategyList } from "../../Utils/constant";
import MainHubIcon from "../../Assets/Icons/ApplicationFeaturesIcons/MainHubIcon";
import MainHubMobileIcon from "../../Assets/Icons/ApplicationFeaturesIcons/MainHubMobileIcon";
import Testimonial from "./Testimonial";

const Service = () => {
  const [isMoreVisible, setISmoreVisible] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="Service service-main-wrap">
        <div className="banner">
          <Container>
            <div className="banner-grid">
              <div className="banner-set">
                <div className="banner-set--left ">
                  <h1 className="custom_h1">
                    Learn More About
                    <span className="yellowText">What We Do</span>
                  </h1>
                  <p>
                    Our venture studio specializes in the major components
                    involved in creating a successful startup.
                  </p>
                  <Link className="getstarted-btn" to="/signup">
                    Get Started
                  </Link>
                </div>
                <div className="banner-set--right">
                  <div>
                    <img
                      className="img-fluid"
                      src={IMAGES.mainService}
                      alt="mainService"
                    />
                  </div>
                </div>
              </div>
              <div className="strategyDevContainer">
                {serviceStrategyList.map((item, key) => {
                  return (
                    <>
                      <div
                        className={`strategyDevItem ${
                          key % 2 == 0 ? "" : "odd"
                        }`}
                        key={key}
                      >
                        <span className="iconSvg">{item.icon}</span>
                        <div>
                          <div className="textStrategy">{item.text}</div>
                          <div
                            className={`more-info-wrap ${
                              isMoreVisible === key ? "isOpen" : ""
                            }`}
                          >
                            {isMoreVisible === key && (
                              <>
                                <div className="info-header">
                                  <span className="iconSvg">{item.icon}</span>
                                  <div className="textStrategy">
                                    {item.text}
                                  </div>
                                </div>
                                <div className="details-wrap">
                                  {item?.more_info}
                                </div>

                                <div
                                  className="infoText"
                                  onClick={() => {
                                    setISmoreVisible("");
                                  }}
                                >
                                  <span>Less Info</span>
                                  <img src={left_arrow_icon} alt="left-arrow" />
                                </div>
                              </>
                            )}
                          </div>
                          {isMoreVisible === key ? (
                            <div
                              className="infoText"
                              onClick={() => {
                                setISmoreVisible("");
                              }}
                            >
                              <span>Less Info</span>
                              <img src={left_arrow_icon} alt="left-arrow" />
                            </div>
                          ) : (
                            <div
                              className="infoText"
                              onClick={() => {
                                setISmoreVisible(key);
                              }}
                            >
                              <span>More Info</span>
                              <img src={right_arrow_icon} alt="right-arrow" />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Container>
        </div>
        <section className="our-stats-main-wrapper">
          <Container fluid className="heading">
            <Row>
              <Col>
                <div className="title title-1">
                  <div className="title-text">Additional Perks</div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="bg-white">
            <div className="ourStats">
              <div className="our-stats-inner additionalPerkCon">
                <Container>
                  <Row>
                    <Col lg={6} md={12}>
                      <div className="our-stats-set left">
                        <img
                          className="img-fluid"
                          src={awsIcon}
                          alt="awsIcon"
                        />
                        <div className="wrap">
                          <div className="upToYellowText">
                            Up to
                            <span className="upToWhiteText mlLeft">
                              $10,000
                            </span>
                          </div>
                          <div className="upToYellowText">
                            in free AWS Credits For 2 Years +
                          </div>
                          <div className="upToWhiteText">
                            1 Year
                            <span className="upToYellowText mlLeft">
                              Business Support
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} md={12}>
                      <div className="our-stats-set right">
                        <img
                          className="img-fluid"
                          src={clerkyIcon}
                          alt="clerkyIcon"
                        />
                        <div className="wrap">
                          <div className="upToWhiteText">
                            $4,082{" "}
                            <span className="upToYellowText mlLeft">Off</span>
                          </div>
                          <div className="upToYellowText">
                            Incorporation Lifetime Package +
                          </div>
                          <div className="upToWhiteText">
                            50%{" "}
                            <span className="upToYellowText mlLeft">
                              Off first 409A valuation
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <p className="addMoreText">And More!</p>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </section>
        <section className="our-recent-works-main-wrapper">
          <Container fluid className="heading">
            <Row>
              <Col>
                <div className="title title-2">
                  <div className="title-text">Testimonials</div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="bg-white">
            <div fluid className="our-recent-works">
              <Testimonial />
            </div>
          </div>
        </section>
        <section>
          <Container fluid className="heading">
            <Row>
              <Col>
                <div className="title title-3">
                  <div className="title-text">Main Hubs</div>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="bg-white">
            <div className="investmentStrategy">
              <Container>
                <Row>
                  <Col md={12} className="left">
                    <div className="desktop-hub-view">
                      <MainHubIcon />
                    </div>
                    <div className="mobile-hub-view">
                      <MainHubMobileIcon />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
        <section className="buildwithus-main-wrapper">
          <Container fluid>
            <Row>
              <Col>
                <div className="buildwithus-main-group">
                  <Button
                    variant="warning"
                    size="lg"
                    onClick={() => navigate("/signup")}
                  >
                    Build With Us
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Service;
