import * as React from "react";

function FeedbackIcon(props) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="#E5C243" />
      <g clipPath="url(#clip0_361_1870)">
        <path
          d="M18.1834 11.0027C20.5567 11.0021 22.9293 11.0009 25.3026 11.0027C27.1576 11.0039 28.3627 12.1746 28.3563 13.9786C28.3538 14.7071 28.3977 15.4453 28.2915 16.1608C28.1032 17.4246 26.9961 18.338 25.6854 18.4225C25.5061 18.4342 25.3255 18.4367 25.1455 18.4367C21.4057 18.4373 17.6664 18.4392 13.9265 18.433C13.6811 18.4324 13.5081 18.4891 13.365 18.6908C13.2098 18.9098 13.0318 19.1176 12.841 19.3088C12.3799 19.772 11.6862 19.7529 11.2385 19.278C11.1005 19.1318 9.8744 18.2714 9.70079 18.1887C8.63752 17.6818 8.05756 16.8559 8.01368 15.7044C7.98888 15.0685 8.00287 14.4301 8.00923 13.7936C8.02513 12.2319 9.26327 11.0144 10.8741 11.0064C13.3103 10.9935 15.7472 11.0027 18.1834 11.0027ZM18.1682 14.1057C20.3182 14.1057 22.4677 14.1057 24.6177 14.1057C25.2021 14.1057 25.4908 13.9114 25.4839 13.5259C25.4769 13.1497 25.1939 12.961 24.6323 12.961C20.3328 12.9604 16.0333 12.9604 11.7338 12.9616C11.6073 12.9616 11.4782 12.9597 11.3542 12.9813C11.0858 13.0276 10.8874 13.2657 10.8836 13.5272C10.8792 13.7998 11.0884 14.049 11.3707 14.0909C11.4954 14.1094 11.6238 14.1057 11.751 14.1057C13.8896 14.1057 16.0289 14.1057 18.1682 14.1057ZM14.1885 16.4365C15.0769 16.4365 15.9653 16.442 16.8537 16.434C17.2365 16.4303 17.4769 16.2089 17.4896 15.8839C17.503 15.5348 17.2759 15.2874 16.8747 15.285C15.0871 15.2751 13.3001 15.2788 11.5125 15.2819C11.2639 15.2825 11.0598 15.3843 10.9453 15.6112C10.7431 16.0128 11.0445 16.4291 11.5552 16.434C12.4327 16.4427 13.3109 16.4371 14.1885 16.4365Z"
          fill="black"
        />
        <path
          d="M14.5228 22.5445C14.4662 22.6284 14.3995 22.7684 14.2952 22.8733C14.0084 23.1613 13.6923 23.4228 13.417 23.7195C13.3216 23.8225 13.2561 24.0044 13.2707 24.1413C13.3127 24.5385 13.4259 24.929 13.4615 25.3262C13.4774 25.4976 13.4265 25.7511 13.3063 25.8362C13.1887 25.9195 12.9254 25.8831 12.7613 25.8171C12.4014 25.6716 12.0561 25.4865 11.7197 25.2929C11.5092 25.172 11.3407 25.1825 11.1353 25.3003C10.7982 25.4933 10.4542 25.6802 10.0936 25.8239C9.93145 25.8887 9.67771 25.9102 9.54798 25.8288C9.43733 25.7585 9.39345 25.5081 9.40935 25.3459C9.45069 24.9277 9.55307 24.5163 9.60458 24.0988C9.61793 23.9878 9.56897 23.8354 9.49075 23.7546C9.18741 23.4401 8.84592 23.1594 8.55021 22.8387C8.44656 22.7265 8.36007 22.5131 8.40013 22.386C8.43956 22.2608 8.63734 22.1319 8.78741 22.0998C9.20967 22.0085 9.64274 21.9604 10.0733 21.9068C10.2577 21.8839 10.3741 21.8112 10.4542 21.6397C10.6329 21.2567 10.816 20.8731 11.0354 20.511C11.1136 20.3815 11.3031 20.239 11.4399 20.2402C11.5766 20.2421 11.7597 20.3889 11.8386 20.5196C12.0573 20.8817 12.2189 21.2764 12.4325 21.6416C12.4987 21.7544 12.6488 21.8648 12.7785 21.8907C13.2236 21.9795 13.6764 22.0301 14.1267 22.0955C14.3562 22.1282 14.4821 22.2583 14.5228 22.5445Z"
          fill="black"
        />
        <path
          d="M16.1294 25.46C16.207 25.0049 16.2954 24.5503 16.3539 24.0927C16.3679 23.9829 16.3164 23.8318 16.2382 23.751C15.9418 23.4438 15.6067 23.1718 15.3199 22.8573C15.2099 22.7364 15.1107 22.5125 15.1539 22.3817C15.1965 22.2516 15.4127 22.127 15.5743 22.0918C15.9857 22.0018 16.4131 21.9796 16.8258 21.8926C16.9644 21.8636 17.1227 21.7471 17.1946 21.6268C17.4057 21.2721 17.5584 20.8842 17.7746 20.5332C17.8591 20.3957 18.048 20.2483 18.1968 20.2409C18.3259 20.2347 18.5103 20.3939 18.5905 20.5252C18.7997 20.8688 18.9777 21.2321 19.1431 21.5984C19.2359 21.8038 19.3727 21.8895 19.5946 21.9148C20.0149 21.9623 20.4366 22.0135 20.8486 22.1011C20.9904 22.1313 21.1838 22.2553 21.2156 22.3719C21.2518 22.5069 21.18 22.7172 21.0776 22.8301C20.7984 23.1373 20.4849 23.4161 20.1739 23.6948C20.0302 23.8237 19.9622 23.9452 20.0099 24.1488C20.097 24.5182 20.1453 24.8963 20.2114 25.2701C20.2496 25.4872 20.2884 25.7049 20.0671 25.8585C19.8585 26.0034 19.6671 25.9121 19.475 25.8116C19.1304 25.6315 18.7787 25.4637 18.4385 25.2762C18.2636 25.1794 18.1167 25.1745 17.938 25.2719C17.5514 25.4835 17.1584 25.687 16.7577 25.8727C16.4054 26.0355 16.1104 25.8326 16.1294 25.46Z"
          fill="black"
        />
        <path
          d="M22.8828 25.418C22.9451 25.069 22.9979 24.66 23.0952 24.2616C23.1633 23.9834 23.0838 23.8003 22.8676 23.6202C22.5871 23.3864 22.3149 23.1378 22.0746 22.8665C21.9639 22.7419 21.8564 22.5248 21.8965 22.3915C21.9353 22.2614 22.1509 22.1319 22.3118 22.0949C22.7016 22.0061 23.1041 21.9635 23.5029 21.9172C23.7312 21.8907 23.8831 21.8124 23.9798 21.5891C24.1401 21.2209 24.3219 20.8589 24.5324 20.5159C24.6125 20.3858 24.7963 20.2402 24.9331 20.2402C25.0698 20.2402 25.2555 20.3852 25.335 20.5153C25.5448 20.8582 25.7248 21.2215 25.8857 21.5891C25.9823 21.8105 26.133 21.8907 26.362 21.9172C26.7715 21.9647 27.1836 22.011 27.5849 22.0998C27.7343 22.1331 27.9308 22.2639 27.969 22.3891C28.0078 22.5167 27.9187 22.7271 27.8151 22.8412C27.5429 23.1409 27.2376 23.4135 26.9343 23.6849C26.7811 23.8212 26.7105 23.9514 26.7594 24.166C26.851 24.5663 26.9254 24.9727 26.9655 25.3804C26.9801 25.5322 26.9267 25.7548 26.8173 25.835C26.7086 25.9152 26.4682 25.9109 26.327 25.8516C25.9302 25.6839 25.562 25.4532 25.1671 25.2817C25.0367 25.225 24.8364 25.2231 24.7067 25.2799C24.292 25.46 23.9016 25.6919 23.4908 25.88C23.1518 26.0342 22.872 25.8375 22.8828 25.418Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_361_1870">
          <rect
            width="20.3636"
            height="14.9333"
            fill="white"
            transform="translate(8 11)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FeedbackIcon;
