import React, { useEffect, useState } from "react";
import "./EditProjectType.scss";
import { useNavigate } from "react-router-dom";
import { Button, Input, Space, Spin } from "antd";
import WebIcon from "../../Assets/Icons/WebIcon";
import IosIcon from "../../Assets/Icons/IosIcon";
import AndroidIcon from "../../Assets/Icons/AndroidIcon";
import OtherIcon from "../../Assets/Icons/OtherIcon";
import api from "../../Utils/api";
import { updateProjectData } from "../../redux/actions/dashboardActions";
import { useDispatch, useSelector } from "react-redux";

function EditProjectType() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState();
  const [projectTypes, setProjectTypes] = useState([]);
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/projectType`)
      .then((data) => {
        setProjectTypes(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setSelectedType(projectData?.types);
  }, [projectData]);

  return (
    <div className="editProjectType-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">Edit project type</div>
                <div className="projectTypeCon">
                  {loading ? (
                    <Spin />
                  ) : (
                    projectTypes?.map((category) => (
                      <div
                        className={`projectTypeItem ${
                          selectedType?.id === category?.id &&
                          "selectedProjectTypeItem"
                        }`}
                        onClick={() => setSelectedType(category)}
                      >
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + category.image}
                          alt=""
                          style={{
                            width: "45px",
                            height: "45px",
                            marginRight: "10px",
                          }}
                        />
                        <div className="projectTypeText">{category?.name}</div>
                      </div>
                    ))
                  )}
                </div>
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() =>
                        dispatch(
                          updateProjectData(
                            {
                              ...projectData,
                              types: selectedType,
                            },
                            navigate
                          )
                        )
                      }
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProjectType;
