import { message } from "antd";
import { store } from "../../store";
import api from "../../Utils/api";
import {
  GET_USER_DATA_FROM_TOKEN_FAIL,
  GET_USER_DATA_FROM_TOKEN_INIT,
  GET_USER_DATA_FROM_TOKEN_SUCCESS,
  INITIALIZE_GOOGLE_LOGIN,
  INITIALIZE_GOOGLE_SIGNUP,
  GOOGLE_SIGNUP_FAIL,
  login,
  LOGOUT,
  register,
  SET_AUTH_LOADER,
  GOOGLE_SIGNUP_SUCCESS,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAIL,
  ADD_SUBSCRIBE_NEWSLETTER_INIT,
  ADD_SUBSCRIBE_NEWSLETTER_SUCCESS,
  ADD_SUBSCRIBE_NEWSLETTER_FAIL,
} from "./actionTypes";
import { getOnboardData } from "./onboardingActions";

export const getUserDataFromToken = () => (dispatch) => {
  dispatch({
    type: GET_USER_DATA_FROM_TOKEN_INIT,
  });
  api
    .getWithToken(`${process.env.REACT_APP_API_URL}/client/clientData`)
    .then((res) => {
      if (res.result) {
        dispatch({
          type: GET_USER_DATA_FROM_TOKEN_SUCCESS,
          payload: res?.data,
        });
      } else {
        dispatch({
          type: GET_USER_DATA_FROM_TOKEN_FAIL,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_USER_DATA_FROM_TOKEN_FAIL,
      });
    });
};

export const handleSignup = (user, navigate) => (dispatch) => {
  dispatch({
    type: register.INITIALIZE_SIGNUP,
  });
  let bodyFormData = {};
  bodyFormData = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    password: user.password,
    strategy: "email",
  };
  if (user?.refer_token) {
    bodyFormData = {
      ...bodyFormData,
      refer_token: user?.refer_token.split("'")[1],
    };
  }
  api
    .postWithoutToken(
      `${process.env.REACT_APP_API_URL}/client/registerClient`,
      bodyFormData
    )
    .then((res) => {
      if (res.result) {
        dispatch({
          type: register.SIGNUP_SUCCESS,
          payload: res?.data,
          tokenPayload: res.token,
        });
        localStorage.setItem("token", res.token);
        navigate("/onboarding/intro");
      } else {
        dispatch({
          type: register.SIGNUP_FAILED,
        });
        message.error(res.error);
      }
    })
    .catch((err) => console.log(err));
};
export const handleGoogleSignup = (token, navigate) => (dispatch) => {
  dispatch({
    type: INITIALIZE_GOOGLE_SIGNUP,
  });
  const bodyFormData = {
    token: token,
    strategy: "google",
  };
  api
    .postWithoutToken(
      `${process.env.REACT_APP_API_URL}/client/registerClient`,
      bodyFormData
    )
    .then((res) => {
      if (res.result) {
        dispatch({
          type: GOOGLE_SIGNUP_SUCCESS,
          payload: res?.data,
          tokenPayload: res.token,
        });
        localStorage.setItem("token", res.token);
        navigate("/onboarding/intro");
      } else {
        dispatch({
          type: GOOGLE_SIGNUP_FAIL,
        });
        message.error(res.error);
      }
    })
    .catch((err) => console.log(err));
};

export const handleGoogleLogin = (token, navigate) => (dispatch) => {
  dispatch({
    type: INITIALIZE_GOOGLE_LOGIN,
  });
  const bodyFormData = {
    token: token,
    strategy: "google",
  };
  api
    .postWithoutToken(
      `${process.env.REACT_APP_API_URL}/client/checkLogin`,
      bodyFormData
    )
    .then((result) => {
      if (result.token) {
        dispatch({
          type: GOOGLE_LOGIN_SUCCESS,
          payload: result.data,
          tokenPayload: result.token,
        });
        localStorage.setItem("token", result.token);
        if (result?.data?.boarded && result?.data?.verified) {
          navigate("/dashboard");
        } else if (result?.data?.boarded && !result?.data?.verified) {
          navigate("/onboarding/confirm-email");
        } else {
          store.dispatch(getOnboardData(result, navigate));
        }
      } else {
        dispatch({
          type: GOOGLE_LOGIN_FAIL,
        });
        message.error(result.error);
      }
    })
    .catch((err) => console.log(err));
};

export const handleLogin = (user, navigate) => (dispatch) => {
  dispatch({
    type: login.INITIALIZE_LOGIN,
  });
  const bodyFormData = {
    email: user.email,
    password: user.password,
    strategy: "email",
  };
  api
    .postWithoutToken(
      `${process.env.REACT_APP_API_URL}/client/checkLogin`,
      bodyFormData
    )
    .then((result) => {
      if (result.token) {
        dispatch({
          type: login.LOGIN_SUCCESS,
          payload: result.data,
          tokenPayload: result.token,
        });
        localStorage.setItem("token", result.token);
        if (result?.data?.boarded && result?.data?.verified) {
          navigate("/dashboard");
        } else if (result?.data?.boarded && !result?.data?.verified) {
          navigate("/onboarding/confirm-email");
        } else {
          store.dispatch(getOnboardData(result, navigate));
        }
      } else {
        dispatch({
          type: login.LOGIN_FAILED,
        });
        message.error(result.error);
      }
    })
    .catch((err) => console.log(err));
};

export const handleForgotPassword = (email, navigate) => (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADER,
  });
  const bodyFormData = {
    email: email,
  };
  api
    .postWithoutToken(
      `${process.env.REACT_APP_API_URL}/client/forgotPassword`,
      bodyFormData
    )
    .then((response) => {
      dispatch({
        type: SET_AUTH_LOADER,
      });
      if (response.result) {
        navigate("/checkyouremail");
      } else {
        message.error(response.error);
      }
    })
    .catch((err) => console.log(err));
};

export const handleResetPassword = (payload, navigate) => (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADER,
  });
  const bodyFormData = {
    token: payload?.token,
    new_password: payload?.newPassword,
    confirm_password: payload?.confPassword,
  };
  api
    .postWithoutToken(
      `${process.env.REACT_APP_API_URL}/client/resetPassword`,
      bodyFormData
    )
    .then((response) => {
      dispatch({
        type: SET_AUTH_LOADER,
      });
      if (response.result) {
        message.success("Password reset successfully");
        navigate("/signin");
      } else {
        message.error(response.error);
      }
    })
    .catch((err) => console.log(err));
};

export const userLogout = (navigate) => (dispatch) => {
  api
    .getWithToken(`${process.env.REACT_APP_API_URL}/client/logout`)
    .then(() => {
      localStorage.clear();
      dispatch({
        type: LOGOUT, // For Reset states to InitialStates
      });
      navigate("/signin");
    });
};

export const resendMail = () => (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADER,
  });
  api
    .getWithToken(
      `${process.env.REACT_APP_API_URL}/client/resendVerificationMail`
    )
    .then((response) => {
      dispatch({
        type: SET_AUTH_LOADER,
      });
      if (response.result === true) {
        message.success(response?.data);
      } else {
        message.error(response?.data);
      }
    })
    .catch((err) => console.log(err));
};

export const handleSubscribeNewsletter =
  (payload, onSuccess, onFail) => (dispatch) => {
    dispatch({
      type: ADD_SUBSCRIBE_NEWSLETTER_INIT,
    });
    api
      .postWithoutToken(
        `${process.env.REACT_APP_API_URL}/client/subscribeNewsletter`,
        payload
      )
      .then((response) => {
        if (response?.result) {
          dispatch({
            type: ADD_SUBSCRIBE_NEWSLETTER_SUCCESS,
          });
          onSuccess && onSuccess();
        } else {
          dispatch({
            type: ADD_SUBSCRIBE_NEWSLETTER_FAIL,
          });
          onFail && onFail(response?.error);
        }
      })
      .catch((err) => console.log(err));
  };
