import React, { useState } from "react";
import { Col, Row, Button, message, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";
import "./AddBankAccountStep.scss";
import {
  getCardList,
  editAutopayinfo,
} from "../../../../redux/actions/settingsAction";
import EditRoundIcon from "../../../../Assets/Icons/EditRoundIcon";

const AddBankAccountStep = (props) => {
  const dispatch = useDispatch();
  const { setCurrentStep, setPaymentData } = props;
  const [active, setActive] = useState(false);
  const [editId, setEditId] = useState("");
  const [loading, setLoading] = useState(false);

  const { accountList } = useSelector((state) => state?.settingsReducer);
  const onSave = (id) => {
    let data = {
      autopay: active === "Enable",
    };
    setLoading(true);
    dispatch(
      editAutopayinfo(
        id,
        data,
        (res) => {
          dispatch(
            getCardList(() => {
              setLoading(false);
              setActive("");
              setEditId("");
              message.success(res, 2);
            })
          );
        },
        (error) => {
          setLoading(false);
          message.success(error, 2);
        }
      )
    );
  };
  return (
    <div className="bank-account-details account-list-wrap">
      <Spin spinning={loading}>
        <div className="bank-card-wrapper d-flex justify-content-center justify-content-md-between"></div>
        {accountList &&
          accountList?.map((data, key) => {
            return (
              <>
                <div className="bank-detail-headding bank-headding-wrap">
                  <div className="headding-wrap">
                    <h6 className="headding-bank d-flex alaign-items-center">
                      Bank Account Info
                    </h6>

                    <Button
                      onClick={() => {
                        setCurrentStep(6);
                        setPaymentData({ type: "bank", ...data });
                      }}
                    >
                      Pay
                    </Button>
                  </div>
                  <span className="headding-text">
                    Associating your bank account makes it easy to transfer
                    funds.
                  </span>
                </div>
                <Row className="bank-desc-wrapper" gutter={[32, 44]}>
                  <>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Payment Method</h6>
                        <span className="bank-desc-text">
                          {data?.payment_method === null
                            ? "N/A"
                            : data?.payment_method}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Account Holder Name</h6>
                        <span className="bank-desc-text">
                          {data?.source?.account_holder_name === null
                            ? "N/A"
                            : data?.source?.account_holder_name}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Routing Number</h6>
                        <span className="bank-desc-text">
                          {data?.source?.routing_number}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Account Number</h6>
                        <span className="bank-desc-text">
                          ******{data?.source?.last4}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Account Type</h6>
                        <span className="bank-desc-text">
                          {data?.account_type === null
                            ? "N/A"
                            : data?.account_type}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 8 }}
                      xl={{ span: 8 }}
                    >
                      <div className="bank-desc">
                        <h6 className="bank-desc-lable">Autopay</h6>
                        <span className="bank-desc-text">
                          {editId !== data?.id ? (
                            <span className="bank-desc-text">
                              {data?.auto_pay ? "Enable" : "Disable"}
                              <EditRoundIcon
                                onClick={() => {
                                  setEditId(data?.id);
                                  setActive(
                                    data?.auto_pay ? "Enable" : "Disable"
                                  );
                                }}
                              />
                            </span>
                          ) : (
                            <FormGroup>
                              <div className="btn-toggle-wrapper">
                                <div
                                  className="btn-group btn-toggle custom-toggle-button custom-switch"
                                  data-toggle="buttons"
                                >
                                  <button
                                    type="button"
                                    className={
                                      active === "Enable"
                                        ? "btn btn-default active"
                                        : "btn btn-default"
                                    }
                                    name="Enable"
                                    onClick={() => {
                                      setActive("Enable");
                                    }}
                                  >
                                    Enable
                                  </button>
                                  <button
                                    type="button"
                                    className={
                                      active === "Disable"
                                        ? "btn btn-default active"
                                        : "btn btn-default"
                                    }
                                    name="Disable"
                                    onClick={() => {
                                      setActive("Disable");
                                    }}
                                  >
                                    Disable
                                  </button>
                                </div>
                                <button
                                  className="save-btn"
                                  onClick={() => {
                                    onSave(data?.id);
                                  }}
                                >
                                  Save
                                </button>
                              </div>
                            </FormGroup>
                          )}
                        </span>
                      </div>
                    </Col>
                  </>
                </Row>
              </>
            );
          })}
      </Spin>
    </div>
  );
};

export default AddBankAccountStep;
