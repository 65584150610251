// Auth Action Types

export const register = {
  INITIALIZE_SIGNUP: "INITIALIZE_SIGNUP",
  SIGNUP_FAILED: "SIGNUP_FAILED",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
};

export const INITIALIZE_GOOGLE_SIGNUP = "INITIALIZE_GOOGLE_SIGNUP";
export const GOOGLE_SIGNUP_SUCCESS = "GOOGLE_SIGNUP_SUCCESS";
export const GOOGLE_SIGNUP_FAIL = "GOOGLE_SIGNUP_FAIL";

export const INITIALIZE_GOOGLE_LOGIN = "INITIALIZE_GOOGLE_LOGIN";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL";

export const login = {
  INITIALIZE_LOGIN: "INITIALIZE_LOGIN",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
};

export const GET_USER_DATA_FROM_TOKEN_INIT = "GET_USER_DATA_FROM_TOKEN_INIT";
export const GET_USER_DATA_FROM_TOKEN_SUCCESS =
  "GET_USER_DATA_FROM_TOKEN_SUCCESS";
export const GET_USER_DATA_FROM_TOKEN_FAIL = "GET_USER_DATA_FROM_TOKEN_FAIL";

export const LOGOUT = "LOGOUT";
export const SET_AUTH_LOADER = "SET_AUTH_LOADER";
export const SET_LOADER = "SET_LOADER";
export const SET_ONBOARDING_DATA = "SET_ONBOARDING_DATA";

export const GET_ONBOARD_DATA_INIT = "GET_ONBOARD_DATA_INIT";
export const GET_ONBOARD_DATA_SUCCESS = "GET_ONBOARD_DATA_SUCCESS";
export const GET_ONBOARD_DATA_FAIL = "GET_ONBOARD_DATA_FAIL";

//Dashboard Action Types
export const SET_USER_STATUS = "SET_USER_STATUS";
export const SET_PROJECT_DATA = "SET_PROJECT_DATA";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";

export const UPDATE_PROJECT_DATA_INIT = "UPDATE_PROJECT_DATA_INIT";
export const UPDATE_PROJECT_DATA_SUCCESS = "UPDATE_PROJECT_DATA_SUCCESS";
export const UPDATE_PROJECT_DATA_FAILED = "UPDATE_PROJECT_DATA_FAILED";

export const GET_DASHBOARD_DATA_INIT = "GET_DASHBOARD_DATA_INIT";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL";

export const SET_FEEDBACK_INIT = "SET_FEEDBACK_INIT";
export const SET_FEEDBACK_SUCCESS = "SET_FEEDBACK_SUCCESS";
export const SET_FEEDBACK_FAIL = "SET_FEEDBACK_FAIL";

export const SEND_EMAIL_INIT = "SEND_EMAIL_INIT";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

export const SEND_FEEDBACK_INIT = "SEND_FEEDBACK_INIT";
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";
export const SEND_FEEDBACK_FAIL = "SEND_FEEDBACK_FAIL";

// project updates action types
export const GET_PROJECT_UPDATES_DATA_INIT = "GET_PROJECT_UPDATES_DATA_INIT";
export const GET_PROJECT_UPDATES_DATA_SUCCESS =
  "GET_PROJECT_UPDATES_DATA_SUCCESS";
export const GET_PROJECT_UPDATES_DATA_FAIL = "GET_PROJECT_UPDATES_DATA_FAIL";

export const GET_PROJECT_UPDATE_BYID_INIT = "GET_PROJECT_UPDATE_BYID_INIT";
export const GET_PROJECT_UPDATE_BYID_SUCCESS =
  "GET_PROJECT_UPDATE_BYID_SUCCESS";
export const GET_PROJECT_UPDATE_BYID_FAILED = "GET_PROJECT_UPDATE_BYID_FAILED";

export const GET_INVOICES_DATA_INIT = "GET_INVOICES_DATA_INIT";
export const GET_INVOICES_DATA_SUCCESS = "GET_INVOICES_DATA_SUCCESS";
export const GET_INVOICES_DATA_FAIL = "GET_INVOICES_DATA_FAIL";

export const GET_INVOICE_DETAILS_DATA_INIT = "GET_INVOICE_DETAILS_DATA_INIT";
export const GET_INVOICE_DETAILS_DATA_SUCCESS =
  "GET_INVOICE_DETAILS_DATA_SUCCESS";
export const GET_INVOICE_DETAILS_DATA_FAIL = "GET_INVOICE_DETAILS_DATA_FAIL";
// agreement action types
export const GET_AGREEMENTS_DATA_INIT = "GET_AGREEMENTS_DATA_INIT";
export const GET_AGREEMENTS_DATA_SUCCESS = "GET_AGREEMENTS_DATA_SUCCESS";
export const GET_AGREEMENTS_DATA_FAIL = "GET_AGREEMENTS_DATA_FAIL";

export const GET_AGREEMENT_DETAILS_DATA_INIT =
  "GET_AGREEMENT_DETAILS_DATA_INIT";
export const GET_AGREEMENT_DETAILS_DATA_SUCCESS =
  "GET_AGREEMENT_DETAILS_DATA_SUCCESS";
export const GET_AGREEMENT_DETAILS_DATA_FAIL =
  "GET_AGREEMENT_DETAILS_DATA_FAIL";

export const UPDATE_AGREEMENT_DETAILS_DATA_INIT =
  "UPDATE_AGREEMENT_DETAILS_DATA_INIT";
export const UPDATE_AGREEMENT_DETAILS_DATA_SUCCESS =
  "UPDATE_AGREEMENT_DETAILS_DATA_SUCCESS";
export const UPDATE_AGREEMENT_DETAILS_DATA_FAIL =
  "UPDATE_AGREEMENT_DETAILS_DATA_FAIL";

export const GET_PERKS_DATA_INIT = "GET_PERKS_DATA_INIT";
export const GET_PERKS_DATA_SUCCESS = "GET_PERKS_DATA_SUCCESS";
export const GET_PERKS_DATA_FAIL = "GET_PERKS_DATA_FAIL";

export const GET_PERKS_DETAIL_INIT = "GET_PERKS_DETAIL_INIT";
export const GET_PERKS_DETAIL_SUCCESS = "GET_PERKS_DETAIL_SUCCESS";
export const GET_PERKS_DETAIL_FAIL = "GET_PERKS_DETAIL_FAIL";

export const GET_REVIEWS_DETAILS_DATA_INIT = "GET_REVIEWS_DETAILS_DATA_INIT";
export const GET_REVIEWS_DETAILS_DATA_SUCCESS =
  "GET_REVIEWS_DETAILS_DATA_SUCCESS";
export const GET_REVIEWS_DETAILS_DATA_FAIL = "GET_REVIEWS_DETAILS_DATA_FAIL";

export const GET_REVIWS_DATA_INIT = "GET_REVIWS_DATA_INIT";
export const GET_REVIWS_DATA_SUCCESS = "GET_REVIWS_DATA_SUCCESS";
export const GET_REVIWS_DATA_FAIL = "GET_REVIWS_DATA_FAIL";

export const PUT_REVIWS_DATA_INIT = "PUT_REVIWS_DATA_INIT";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAIL = "GET_REVIEWS_FAIL";

export const PUT_REVIWS_DATA_SUCCESS = "PUT_REVIWS_DATA_SUCCESS";
export const PUT_REVIWS_DATA_FAIL = "PUT_REVIWS_DATA_FAIL";
export const SET_SETTING_TAB = "SET_SETTING_TAB";

export const GET_CARD_DATA_INIT = "GET_CARD_DATA_INIT";
export const GET_CARD_DATA_SUCCESS = "GET_CARD_DATA_SUCCESS";
export const GET_CARD_DATA_FAIL = "GET_CARD_DATA_FAIL";

export const GET_ACCOUNT_DATA_INIT = "GET_ACCOUNT_DATA_INIT";
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS";
export const GET_ACCOUNT_DATA_FAIL = "GET_ACCOUNT_DATA_FAIL";

export const DELETE_ACCOUNT_INIT = "DELETE_ACCOUNT_INIT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILED = "DELETE_ACCOUNT_FAILED";

export const POST_LINK_TOKEN_INIT = "POST_LINK_TOKEN_INIT";
export const POST_LINK_TOKEN_SUCCESS = "POST_LINK_TOKEN_SUCCESS";
export const POST_LINK_TOKEN_FAILED = "POST_LINK_TOKEN_FAILED";

export const GET_LINK_TOKEN_INIT = "GET_LINK_TOKEN_INIT";
export const GET_LINK_TOKEN_SUCCESS = "GET_LINK_TOKEN_SUCCESS";
export const GET_LINK_TOKEN_FAILED = "GET_LINK_TOKEN_FAILED";

export const POST_FEEDBACK_INIT = "POST_FEEDBACK_INIT";
export const POST_FEEDBACK_SUCCESS = "POST_FEEDBACK_SUCCESS";
export const POST_FEEDBACK_FAILED = "POST_FEEDBACK_FAILED";

export const DELETE_CARD_INIT = "DELETE_CARD_INIT";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILED = "DELETE_CARD_FAILED";

export const GET_SUPPORT_DATA_INIT = "GET_SUPPORT_DATA_INIT";
export const GET_SUPPORT_DATA_SUCCESS = "GET_SUPPORT_DATA_SUCCESS";
export const GET_SUPPORT_DATA_FAIL = "GET_SUPPORT_DATA_FAIL";

export const GET_SUPPORT_CHAT_DATA_INIT = "GET_SUPPORT_CHAT_DATA_INIT";
export const GET_SUPPORT_CHAT_DATA_SUCCESS = "GET_SUPPORT_CHAT_DATA_SUCCESS";
export const GET_SUPPORT_CHAT_DATA_FAIL = "GET_SUPPORT_CHAT_DATA_FAIL";

export const REQUEST_FEATURE_DATA_INIT = "REQUEST_FEATURE_DATA_INIT";
export const REQUEST_FEATURE_DATA_SUCCESS = "REQUEST_FEATURE_DATA_SUCCESS";
export const REQUEST_FEATURE_DATA_FAIL = "REQUEST_FEATURE_DATA_FAIL";
export const SET_DASHBOARD_POSITION = "SET_DASHBOARD_POSITION";

export const ADD_CREDIT_DEBIT_DATA_INIT = "ADD_CREDIT_DEBIT_DATA_INIT";
export const ADD_CREDIT_DEBIT_DATA_SUCCESS = "ADD_CREDIT_DEBIT_DATA_SUCCESS";
export const ADD_CREDIT_DEBIT_DATA_FAIL = "ADD_CREDIT_DEBIT_DATA_FAIL";

export const DELETE_CREDIT_DEBIT_DATA_INIT = "DELETE_CREDIT_DEBIT_DATA_INIT";
export const DELETE_CREDIT_DEBIT_DATA_SUCCESS =
  "DELETE_CREDIT_DEBIT_DATA_SUCCESS";
export const DELETE_CREDIT_DEBIT_DATA_FAIL = "DELETE_CREDIT_DEBIT_DATA_FAIL";

export const REQUEST_INVOICE_PAY_BY_BANK_INIT =
  "REQUEST_INVOICE_PAY_BY_BANK_INIT";
export const REQUEST_INVOICE_PAY_BY_BANK_SUCCESS =
  "REQUEST_INVOICE_PAY_BY_BANK_SUCCESS";
export const REQUEST_INVOICE_PAY_BY_BANK_FAIL =
  "REQUEST_INVOICE_PAY_BY_BANK_FAIL";

export const REQUEST_INVOICE_PAY_BY_CARD_INIT =
  "REQUEST_INVOICE_PAY_BY_CARD_INIT";
export const REQUEST_INVOICE_PAY_BY_CARD_SUCCESS =
  "REQUEST_INVOICE_PAY_BY_CARD_SUCCESS";
export const REQUEST_INVOICE_PAY_BY_CARD_FAIL =
  "REQUEST_INVOICE_PAY_BY_CARD_FAIL";

export const UPDATE_AUTOPAY_DATA_INIT = "UPDATE_AUTOPAY_DATA_INIT";
export const UPDATE_AUTOPAY_DATA_SUCCESS = "UPDATE_AUTOPAY_DATA_SUCCESS";
export const UPDATE_AUTOPAY_DATA_FAIL = "UPDATE_AUTOPAY_DATA_FAIL";

export const GET_NOTIFICATIONS_DATA_INIT = "GET_NOTIFICATIONS_DATA_INIT";
export const GET_NOTIFICATIONS_DATA_SUCCESS = "GET_NOTIFICATIONS_DATA_SUCCESS";
export const GET_NOTIFICATIONS_DATA_FAIL = "GET_NOTIFICATIONS_DATA_FAIL";

export const GET_NOTIFICATIONS_COUNT_INIT = "GET_NOTIFICATIONS_COUNT_INIT";
export const GET_NOTIFICATIONS_COUNT_SUCCESS =
  "GET_NOTIFICATIONS_COUNT_SUCCESS";
export const GET_NOTIFICATIONS_COUNT_FAIL = "GET_NOTIFICATIONS_COUNT_FAIL";

export const SET_SCHEDULE_MEETING_INIT = "SET_SCHEDULE_MEETING_INIT";
export const SET_SCHEDULE_MEETING_SUCCESS = "SET_SCHEDULE_MEETING_SUCCESS";
export const SET_SCHEDULE_MEETING_FAIL = "SET_SCHEDULE_MEETING_FAIL";

export const GET_SCHEDULE_MEETING_INFO_INIT = "GET_SCHEDULE_MEETING_INFO_INIT";
export const GET_SCHEDULE_MEETING_INFO_SUCCESS =
  "GET_SCHEDULE_MEETING_INFO_SUCCESS";
export const GET_SCHEDULE_MEETING_INFO_FAIL = "GET_SCHEDULE_MEETING_INFO_FAIL";

export const ADD_SUBSCRIBE_NEWSLETTER_INIT = "ADD_SUBSCRIBE_NEWSLETTER_INIT";
export const ADD_SUBSCRIBE_NEWSLETTER_SUCCESS =
  "ADD_SUBSCRIBE_NEWSLETTER_SUCCESS";
export const ADD_SUBSCRIBE_NEWSLETTER_FAIL = "ADD_SUBSCRIBE_NEWSLETTER_FAIL";
