import { message, Switch } from "antd";
import React, { useEffect, useState } from "react";
import api from "../../Utils/api";
import { Spin } from "antd";
import "./EditNotifications.scss";
const EmailNotification = () => {
  const [notificationSettings, updateNotificationSettings] = useState();
  const [Loader, setLoader] = useState(true);
  useEffect(() => {
    api
      .getWithToken(
        `${process.env.REACT_APP_API_URL}/client/notificationSettings`
      )
      .then((res) => {
        setLoader(false);
        updateNotificationSettings(res?.data);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }, []);

  const updateSettings = (type, value, name) => {
    updateNotificationSettings({ ...notificationSettings, [type]: value });
    const bodyFormData = {
      [type]: value,
    };
    api
      .putWithToken(
        `${process.env.REACT_APP_API_URL}/client/notificationSettings`,
        bodyFormData
      )
      .then((res) => {
        if (res.result) {
          message.success(
            value ? `${name} notication enable` : `${name} notification disable`
          );
        } else {
          message.error(res.error);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="email-notification-wrapper">
      {Loader ? (
        <Spin />
      ) : (
        <div className="email-notification-main">
          <h5 className="email-notification-headding">Email Notification</h5>
          <div className="d-flex justify-content-between align-items-center email-notification">
            <span className="email-notification-name">Project Updates</span>
            <Switch
              className="cm-switch"
              checked={notificationSettings?.updates_notify}
              onChange={(value) =>
                updateSettings("updates_notify", value, "Project Updates")
              }
            />
          </div>
          <div className="d-flex justify-content-between align-items-center email-notification">
            <span className="email-notification-name">Invoice Updates</span>
            <Switch
              className="cm-switch"
              checked={notificationSettings?.invoice_notify}
              onChange={(value) =>
                updateSettings("invoice_notify", value, "Invoice Updates")
              }
            />
          </div>
          <div className="d-flex justify-content-between align-items-center email-notification">
            <span className="email-notification-name">Promotional Updates</span>
            <Switch
              className="cm-switch"
              checked={notificationSettings?.promotional_notify}
              onChange={(value) =>
                updateSettings(
                  "promotional_notify",
                  value,
                  "Promotional Updates"
                )
              }
            />
          </div>
          <div className="d-flex justify-content-between align-items-center email-notification">
            <span className="email-notification-name">
              Referral/Reviews Updates
            </span>
            <Switch
              className="cm-switch"
              checked={notificationSettings?.referral_notify}
              onChange={(value) =>
                updateSettings(
                  "referral_notify",
                  value,
                  "Referral/Reviews Updates"
                )
              }
            />
          </div>
          <div className="d-flex justify-content-between align-items-center email-notification">
            <span className="email-notification-name">Agreements Updates</span>
            <Switch
              className="cm-switch"
              checked={notificationSettings?.agreement_notify}
              onChange={(value) =>
                updateSettings("agreement_notify", value, "Agreements Updates")
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailNotification;
