import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Divider,
  Rate,
  Input,
  message,
  Spin,
  Empty,
} from "antd";
import "./style.scss";
import {
  getDashboardData,
  setDashboardPosition,
  sendReferClientEmail,
  sendClientFeedback,
} from "../../../redux/actions/dashboardActions";
import { getProjectUpdateById } from "../../../redux/actions/updatesAction";
import MainDashboardFrameIcon from "../../../Assets/Icons/MainDashboardFrameIcon";
import UpdatesIcon from "../../../Assets/Icons/UpdatesIcon";
import InvoiceIcon from "../../../Assets/Icons/InvoiceIcon";
import SendIcon from "../../../Assets/Icons/SendIcon";
import CopyIcon from "../../../Assets/Icons/CopyIcon";
import FeedbackIcon from "../../../Assets/Icons/FeedbackIcon";
import ReferFriendsIcon from "../../../Assets/Icons/ReferFriendsIcon";
import YellowTickIcon from "../../../Assets/Icons/YellowTickIcon";
import TimeLeftIcon from "../../../Assets/Icons/TimeLeftIcon";
import TwiiterIcon from "../../../Assets/images/Dashboard/twitter.svg";
import LinkedinIcon from "../../../Assets/images/Dashboard/linkedin.svg";
import FacebookIcon from "../../../Assets/images/Dashboard/facebook.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { convertUTCToTimezone } from "../../../Utils/general";
import { useNavigate } from "react-router-dom";
import moment from "moment";
function MainDashboardComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [rating, setRating] = useState();
  const [againFeedback, setAgainFeedback] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remainTime, setRemainTime] = useState(0);
  const { dashbaordData, profileData } = useSelector(
    (state) => state?.dashboardReducer
  );
  const { SingleUpdate } = useSelector((state) => state?.updatesReducer);
  const validEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  useEffect(() => {
    setLoading(true);
    dispatch(
      getDashboardData(() => {
        setLoading(false);
      })
    );
  }, []);
  useEffect(() => {
    if (dashbaordData?.feedback_date) {
      setRemainTime(
        48 -
          moment(new Date())
            .utc(true)
            .local()
            .diff(dashbaordData?.feedback_date, "hours")
      );
    }
  }, [dashbaordData]);
  useEffect(() => {
    setFeedback(false);
    setAgainFeedback(false);
    if (remainTime <= 0) {
      setAgainFeedback(true);
    }
  }, [remainTime]);

  const sendEmailInvite = () => {
    if (!email) {
      message.error("Email is required", 2);
      return;
    } else if (!validEmail.test(email)) {
      message.error("Invalid email", 2);
      return;
    }
    dispatch(
      sendReferClientEmail(
        email,
        (res) => {
          if (res?.result) {
            message.success(res?.data, 2);
            setEmail("");
          } else {
            message.error(res?.error, 2);
          }
        },
        (err) => {
          message.success(err || "something wrong", 2);
        }
      )
    );
  };

  const sendFeedback = () => {
    if (!rating) {
      message.error("Please select rating", 2);
      return;
    }
    if (!feedbackText) {
      message.error("Please enter feedback", 2);
      return;
    } else if (!new RegExp("^[A-Za-z]( ?[A-Za-z0-9] ?)*$").test(feedbackText)) {
      message.error("Please enter valid feedback", 2);
      return;
    }
    const data = {
      feedback: feedbackText,
      rating: rating,
    };
    dispatch(
      sendClientFeedback(
        data,
        (res) => {
          if (res?.result) {
            message.success(res?.data, 2);
            dispatch(getDashboardData());
            setFeedback(true);
          } else {
            message.error(res?.error, 2);
          }
        },
        (err) => {
          message.success(err || "something wrong", 2);
        }
      )
    );
  };

  return (
    <div className="dashboard-page mainDashboardPage">
      <div className="mainContainer">
        <Spin spinning={loading}>
          <div className="dashboard-content">
            <Row gutter={[36, 36]}>
              <Col span={24}>
                <div className="welcomeCon">
                  <div className="welcomeContentCon">
                    <div className="welcomeText">
                      You’re on your way, johnny!
                    </div>
                    <div className="welcomeSubText">
                      How does it feel to see your vision become more and more
                      of a reality?
                    </div>
                  </div>
                  <div className="flyIconCon frameIcon">
                    <MainDashboardFrameIcon />
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={[36, 46]} className="updatesRow">
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <div className="mainDashboardCardCon">
                  <div className="headerCardCon">
                    <div className="leftHeaderCon">
                      <div className="updatesIcon">
                        <UpdatesIcon />
                      </div>
                      <div className="updatesText">Project updates</div>
                    </div>
                    {dashbaordData?.latest_updates && (
                      <div
                        className="rightHeaderCon"
                        onClick={() => {
                          dispatch(setDashboardPosition("2"));
                          navigate("/updates");
                        }}
                      >
                        See All{" "}
                      </div>
                    )}
                  </div>
                  <Divider />
                  {dashbaordData?.latest_updates ? (
                    <div className="headerContentCon">
                      <div>
                        <div className="detailPageText">
                          {dashbaordData?.latest_updates?.name}
                        </div>
                        <div className="dateText">
                          {convertUTCToTimezone(
                            dashbaordData?.latest_updates?.created_at,
                            true,
                            profileData?.time_zone
                              ? profileData?.time_zone
                              : "",
                            "MM-DD-YYYY"
                          )}
                        </div>
                      </div>
                      <Button
                        className="viewHeaderButton"
                        onClick={() => {
                          dispatch(
                            getProjectUpdateById(
                              dashbaordData?.latest_updates?.id
                            )
                          );
                          navigate(
                            `/updates/detail/${dashbaordData?.latest_updates?.id}`,
                            {
                              state: { update: SingleUpdate },
                            }
                          );
                        }}
                      >
                        View
                      </Button>
                    </div>
                  ) : (
                    <Empty description="No Project Update" />
                  )}
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <div className="mainDashboardCardCon">
                  <div className="headerCardCon">
                    <div className="leftHeaderCon">
                      <div className="updatesIcon">
                        <InvoiceIcon />
                      </div>
                      <div className="updatesText">Invoices</div>
                    </div>
                    {dashbaordData?.latest_invoices && (
                      <div
                        className="rightHeaderCon"
                        onClick={() => {
                          dispatch(setDashboardPosition("3"));
                          navigate("/invoices");
                        }}
                      >
                        See All
                      </div>
                    )}
                  </div>
                  <Divider />
                  {dashbaordData?.latest_invoices ? (
                    <div className="headerContentCon">
                      <div>
                        <div className="detailPageText">
                          ${dashbaordData?.latest_invoices?.amount}
                        </div>
                        <div className="dateText">
                          due date:{" "}
                          {convertUTCToTimezone(
                            dashbaordData?.latest_invoices?.due_date,
                            true,
                            profileData?.time_zone
                              ? profileData?.time_zone
                              : "",
                            "MM-DD-YYYY"
                          )}
                        </div>
                      </div>
                      <Button
                        className="viewHeaderButton"
                        onClick={() => {
                          navigate(
                            `/invoices/invoice-details/${dashbaordData?.latest_invoices?.id}`,
                            {
                              state: dashbaordData?.latest_invoices,
                            }
                          );
                        }}
                      >
                        Pay Now
                      </Button>
                    </div>
                  ) : (
                    <Empty description="No Invoice" />
                  )}
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <div className="mainDashboardCardCon fullHeight">
                  <div className="headerCardCon">
                    <div className="leftHeaderCon">
                      <div className="updatesIcon">
                        <ReferFriendsIcon />
                      </div>
                      <div>
                        <div className="updatesText">Refer your friends</div>
                        <div className="headerNoteText">
                          Have a friend sign up with us for a two-month plus
                          development contract and receive a $1,000 credit
                          towards your next invoice! They'll also get a $1,000
                          discount!
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="emailSendCon">
                    <div className="sendInviteMainCon">
                      <div className="sendInviteCon">
                        <div className="sendInviteText">Send Email Invite</div>
                        <div className="emailInputCon">
                          <Input
                            placeholder="Enter the email address"
                            type="text"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <div className="sendIcon">
                            <SendIcon onClick={sendEmailInvite} />
                          </div>
                        </div>
                      </div>
                      <div className="sendInviteCon">
                        <div className="sendInviteText">
                          Share Your Invite Link
                        </div>
                        <div className="emailInputCon">
                          <Input
                            placeholder="/search?q=google.co.in"
                            value={dashbaordData?.invite_link}
                            disabled="true"
                          />
                          <div className="sendIcon">
                            <CopyToClipboard
                              text={dashbaordData?.invite_link}
                              onCopy={() => {
                                message.success("Copy");
                              }}
                            >
                              <CopyIcon />
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="socialMediaCon">
                      <div className="socialMediaText">
                        Share on Social Media
                      </div>
                      <div className="btn-group">
                        <a
                          href="https://www.facebook.com/inventivebyte"
                          target="_blank"
                        >
                          facebook
                          <img src={FacebookIcon} alt="facebookIcon" />
                        </a>
                        <a
                          href="https://twitter.com/inventivebyte"
                          target="_blank"
                        >
                          twitter
                          <img src={TwiiterIcon} alt="TwiiterIcon" />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/inventivebyte"
                          target="_blank"
                        >
                          linkedin
                          <img src={LinkedinIcon} alt="linkedinIcon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <div className="mainDashboardCardCon fullHeight">
                  <div className="headerCardCon">
                    <div className="leftHeaderCon">
                      <div className="updatesIcon">
                        <FeedbackIcon />
                      </div>
                      <div>
                        <div className="updatesText">Give us feedback</div>
                        <div className="headerNoteText">
                          Help us help you better!
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  {feedback ? (
                    <div className="headerContentCon startContenetCon">
                      <div className="tickIcon">
                        <YellowTickIcon />
                      </div>
                      <div className="thankyouText">Thank you!</div>
                      <div className="submi`tFeedbackText">
                        Feedback submitted successfully
                      </div>
                    </div>
                  ) : againFeedback ? (
                    <div className="headerContentCon startContenetCon">
                      <Rate
                        defaultValue={0}
                        value={rating}
                        onChange={(value) => setRating(value)}
                      />
                      <Input.TextArea
                        rows={4}
                        placeholder="Tell us more..."
                        name="feedback"
                        value={feedbackText}
                        onChange={(e) => setFeedbackText(e.target.value)}
                      />
                      <Button className="sendButton" onClick={sendFeedback}>
                        Send
                      </Button>
                    </div>
                  ) : (
                    <div className="headerContentCon startContenetCon">
                      <div className="tickIcon">
                        <TimeLeftIcon />
                      </div>
                      <div className="submitFeedbackText">
                        You can give feedback again after
                      </div>
                      <div className="hoursText">
                        0{remainTime}: second
                        {/* :00 hours */}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default MainDashboardComponent;
