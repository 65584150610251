import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import { Slider } from "antd";
import "./Budjet.scss";
import IMAGES from "../../../Assets/images/Onboarding";
import OnboardingStepper from "../../../Components/OnboardingStepper";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveOnboarding,
  setOnboardingData,
} from "../../../redux/actions/onboardingActions";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import SaveAndNext from "../../../Components/Save&Next";
import { useNavigate } from "react-router-dom";

const totalBudget = {
  5: {
    style: {
      color: "#FFF",
    },
    label: <strong>$5k</strong>,
  },
  500: {
    style: {
      color: "#FFF",
    },
    label: <strong>$500K+</strong>,
  },
};

const monthlyBudget = {
  10: {
    style: {
      color: "#FFF",
    },
    label: <strong>$2.5k</strong>,
  },
  1000: {
    style: {
      color: "#FFF",
    },
    label: <strong>$1000K+</strong>,
  },
};

const Budjet = () => {
  const { payload } = useSelector((state) => state.onboardingReducer);
  const [saveType, setSaveType] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (data) => {
    setError({ ...error, budgetErr: "" });
    dispatch(setOnboardingData({ ...payload, [data.key]: data.value }));
  };

  useEffect(() => {
    if (payload?.total_budget === null || payload?.monthly_budget === null) {
      dispatch(
        setOnboardingData({ ...payload, total_budget: 60, monthly_budget: 10 })
      );
    }
  }, []);

  useEffect(() => {
    if (saveType === "save&Logout") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
    } else if (saveType === "save&next") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
      navigate("/onboarding/wantToStart");
    }
  }, [saveType]);

  const validateForm = (clickType) => {
    if (payload?.fixed_budget === null) {
      setError({ ...error, budgetErr: "Please select budget" });
    } else if (clickType === "Save&Logout") {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 6,
        })
      );
      setSaveType("save&Logout");
    } else {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 6,
        })
      );
      setSaveType("save&next");
    }
  };
  const onSave_Logout_Fun = () => {
    dispatch(
      setOnboardingData({
        ...payload,
        steps_completed: 5,
      })
    );
    setSaveType("save&Logout");
  };
  return (
    <div className="onboard pb-3">
      <OnboardingHeader steps_completed={5} validateForm={onSave_Logout_Fun} />

      <div className="application-wrap">
        <OnboardingStepper activeElement={6} />

        <Container fluid>
          <Row>
            <Col sm={12} md={10} className="m-auto">
              <div className="budjet mt-5 mb-3">
                <span className="title">
                  Do you have a <span>budget</span> in mind for your
                  application?
                </span>
                <div className="budjet-links">
                  <ul>
                    <li
                      onClick={() =>
                        handleChange({ key: "fixed_budget", value: true })
                      }
                    >
                      <a
                        href={null}
                        className={payload.fixed_budget ? "project-active" : ""}
                      >
                        <img src={IMAGES.checkIcon} alt="" />
                        <span>Yes I Do</span>
                      </a>
                    </li>
                    <li
                      onClick={() =>
                        handleChange({
                          key: "fixed_budget",
                          value: false,
                        })
                      }
                    >
                      <a
                        href={null}
                        className={
                          payload?.fixed_budget === false &&
                          payload?.fixed_budget !== ""
                            ? "project-active"
                            : ""
                        }
                      >
                        <img src={IMAGES.closeIcon} alt="" />
                        <span> Not Decided</span>
                      </a>
                    </li>
                  </ul>
                  <span className="text-danger">{error?.budgetErr}</span>
                </div>
              </div>

              {payload?.fixed_budget && (
                <>
                  <div className="budject-slider">
                    <h4 className="title">
                      What is your total{" "}
                      <span className="text-yellow">budget?</span>
                    </h4>
                    <Slider
                      min={5}
                      max={500}
                      marks={totalBudget}
                      value={payload?.total_budget}
                      defaultValue={60}
                      className="slider"
                      tooltip={{
                        // open: true,
                        placement: "bottom",
                        formatter: (value) => <span>${value}K</span>,
                      }}
                      draggableTrack={true}
                      onChange={(e) =>
                        handleChange({ key: "total_budget", value: e })
                      }
                    />
                  </div>

                  <div className="budject-slider">
                    <h4 className="title">
                      What is your monthly
                      <span className="text-yellow"> budget?</span>
                    </h4>
                    <Slider
                      min={2}
                      max={1000}
                      marks={monthlyBudget}
                      value={payload?.monthly_budget}
                      defaultValue={10}
                      tooltip={{
                        // open: false,
                        placement: "bottom",
                        formatter: (value) => (
                          <span className="custom-tag">${value}K</span>
                        ),
                      }}
                      className="custom-slider"
                      onChange={(e) =>
                        handleChange({ key: "monthly_budget", value: e })
                      }
                    />
                  </div>
                </>
              )}

              <SaveAndNext
                backUrl={"/onboarding/features"}
                validateForm={validateForm}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Budjet;
