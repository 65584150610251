import React, { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row, message, Spin } from "antd";
import { FormGroup } from "reactstrap";
import BankIcon from "../../../../src/Assets/images/Invoices/bank_icon.png";
import "./CreditDebitinfo.scss";
import { useDispatch, useSelector } from "react-redux";
import { usePlaidLink } from "react-plaid-link";
import EditRoundIcon from "../../../Assets/Icons/EditRoundIcon";
import {
  getAccountList,
  getCardList,
  getLinkToken,
  deleteCreditDebitCard,
  editAutopayinfo,
} from "../../../redux/actions/settingsAction";
import SetupCard from "./SetupCard";
import axios from "../../../Utils/api";
const CreditDebitInfo = (props) => {
  const { setCurrentPaymentSection, currentPaymentSection } = props;
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editId, setEditId] = useState("");
  const [active, setActive] = useState("");
  const [cardData, setCardData] = useState([]);
  const { accountList, get_link_token_data, cardList } = useSelector(
    (state) => state?.settingsReducer
  );
  useEffect(() => {
    dispatch(getLinkToken());
  }, []);
  useEffect(() => {
    setCardData([
      ...cardList?.filter((c) => c.payment_method == "Primary"),
      ...cardList?.filter((c) => c.payment_method == "Secondary"),
    ]);
  }, [cardList]);
  const onDeleteCard = async (id) => {
    setLoading(true);
    dispatch(
      deleteCreditDebitCard(
        id,
        () => {
          message.success("Credit/Debit card info deleted.", 2);
          dispatch(
            getCardList(
              () => {
                setLoading(false);
              },
              () => {
                setLoading(false);
              }
            )
          );
        },
        () => {
          message.error("Credit/Debit card info delete failed.", 2);
          setLoading(false);
        }
      )
    );
  };
  const { open } = usePlaidLink({
    token: get_link_token_data?.link_token,
    onSuccess: async (public_token, metadata) => {
      setLoading(true);
      await axios
        .postWithToken(
          `${process.env.REACT_APP_API_URL}/payment/createPlaidItem`,
          {
            public_token: public_token,
            institution_id: metadata?.institution?.institution_id,
            institution_name: metadata?.institution?.name,
          }
        )
        .then((res) => {
          axios
            .getWithToken(
              `${process.env.REACT_APP_API_URL}/payment/getAccounts?access_token=${res?.data?.access_token}`
            )
            .then((res1) => {
              axios
                .postWithToken(
                  `${process.env.REACT_APP_API_URL}/payment/linkAchAccount`,
                  {
                    access_token: res?.data?.access_token,
                    account_id: res1?.accounts[0]?.account_id,
                    auto_pay: false,
                  }
                )
                .then((res2) => {
                  if (res2?.error) {
                    message.error(res2?.error, 2);
                  } else {
                    message.success("Account Linked Succesfully", 2);
                    dispatch(getCardList());
                    dispatch(getAccountList());
                    setAdd(false);
                    setCurrentPaymentSection(3);
                  }
                  setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                  message.error(
                    error?.response?.data?.error || error?.message,
                    2
                  );
                });
            })
            .catch((error) => {
              setLoading(false);
              message.error(error?.response?.data?.error || error?.message, 2);
            });
        })
        .catch((error) => {
          message.error(error?.response?.data?.error || error?.message, 2);
          setLoading(false);
        });
    },
  });
  const onSave = (id) => {
    let data = {
      autopay: active === "Enable",
    };
    setLoading(true);
    dispatch(
      editAutopayinfo(
        id,
        data,
        (res) => {
          dispatch(
            getCardList(() => {
              setLoading(false);
              setActive("");
              setEditId("");
              message.success(res, 2);
            })
          );
        },
        (error) => {
          setLoading(false);
          message.success(error, 2);
        }
      )
    );
  };
  if (add) {
    return (
      <SetupCard
        currentPaymentSection={currentPaymentSection}
        setCurrentPaymentSection={setCurrentPaymentSection}
        add={add}
        setAdd={setAdd}
      />
    );
  }

  return (
    <div className="credit-card-details-main">
      <Spin spinning={loading}>
        <div className="bank-card-wrapper d-flex justify-content-center justify-content-md-end">
          {cardList && accountList?.length === 0 ? (
            <button className="bank-card-button" onClick={() => open()}>
              <img className="bank-card-img" src={BankIcon} alt="bank icon" />
              <span className="bank-card-text">Add Bank Account</span>
            </button>
          ) : (
            <div className="button-wrap">
              <button
                className="bank-card-button"
                onClick={() => {
                  setAdd(true);
                }}
              >
                <img className="bank-card-img" src={BankIcon} alt="bank icon" />
                <span className="bank-card-text">Add Credit/Debit card</span>
              </button>
            </div>
          )}
        </div>
        {cardData &&
          cardData?.map((data, index) => {
            return (
              <>
                <div className="bank-detail-headding">
                  <h6 className="headding-bank d-flex alaign-items-center">
                    {index + 1}.Credit/Debit Card Info
                    <span className="bank-details-edit-icon d-flex alaign-items-center justify-content-center">
                      <DeleteOutlined
                        className="edit-icon"
                        onClick={() => {
                          onDeleteCard(data?.source?.id);
                        }}
                      />
                    </span>
                  </h6>
                </div>
                <Row className="bank-desc-wrapper" gutter={[32, 44]}>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Payment Method</h6>

                      <span className="bank-desc-text">
                        {data?.payment_method === null
                          ? "N/A"
                          : data?.payment_method}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Name on card</h6>

                      <span className="bank-desc-text">
                        {data?.source?.billing_details?.name === null
                          ? "N/A"
                          : data?.source?.billing_details?.name}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Card Number</h6>
                      <span className="bank-desc-text">
                        ******{data?.source?.card?.last4}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Expiry Date</h6>
                      <span className="bank-desc-text">
                        {data?.source?.card?.exp_month}/
                        {data?.source?.card?.exp_year}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">ZIP/Postal code</h6>
                      <span className="bank-desc-text">
                        {data?.source?.billing_details?.address?.postal_code ===
                        null
                          ? "N/A"
                          : data?.source?.billing_details?.address?.postal_code}
                      </span>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    xl={{ span: 8 }}
                  >
                    <div className="bank-desc">
                      <h6 className="bank-desc-lable">Autopay</h6>
                      <span className="bank-desc-text">
                        {editId !== data?.id ? (
                          <>
                            {data?.auto_pay ? "Enable" : "Disable"}
                            <EditRoundIcon
                              onClick={() => {
                                setEditId(data?.id);
                                setActive(
                                  data?.auto_pay ? "Enable" : "Disable"
                                );
                              }}
                            />
                          </>
                        ) : (
                          <FormGroup>
                            <div className="btn-toggle-wrapper">
                              <div
                                className="btn-group btn-toggle custom-toggle-button custom-switch"
                                data-toggle="buttons"
                              >
                                <button
                                  type="button"
                                  className={
                                    active === "Enable"
                                      ? "btn btn-default active"
                                      : "btn btn-default"
                                  }
                                  name="Enable"
                                  onClick={() => {
                                    setActive("Enable");
                                  }}
                                >
                                  Enable
                                </button>
                                <button
                                  type="button"
                                  className={
                                    active === "Disable"
                                      ? "btn btn-default active"
                                      : "btn btn-default"
                                  }
                                  name="Disable"
                                  onClick={() => {
                                    setActive("Disable");
                                  }}
                                >
                                  Disable
                                </button>
                              </div>
                              <button
                                className="save-btn"
                                onClick={() => {
                                  onSave(data?.id);
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </FormGroup>
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
                {cardList?.length > index + 1 && (
                  <div>
                    <hr
                      style={{
                        background: "#C8C8C8",
                        color: "#C8C8C8",
                        borderColor: "#C8C8C8",
                        height: "3px",
                      }}
                    ></hr>
                  </div>
                )}
              </>
            );
          })}
      </Spin>
    </div>
  );
};

export default CreditDebitInfo;
