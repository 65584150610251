import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { recentWorksList } from "../../Utils/constant";
import recent_works_1 from "../../Assets/images/home/recent_works_1.png";
import recent_works_2 from "../../Assets/images/home/recent_works_2.png";
import recent_works_3 from "../../Assets/images/home/recent_works_3.png";
import recent_works_4 from "../../Assets/images/home/recent_works_4.png";
import recent_works_5 from "../../Assets/images/home/recent_works_5.png";
import recent_img_mob1 from "../../Assets/images/home/mobile/img1.svg";
import recent_img_mob2 from "../../Assets/images/home/mobile/img2.svg";
import recent_img_mob3 from "../../Assets/images/home/mobile/img3.svg";
import recent_img_mob4 from "../../Assets/images/home/mobile/img4.svg";
import recent_img_mob5 from "../../Assets/images/home/mobile/img5.svg";
import "./RecentWorksCarousel/RecentWorksCarousel.scss";
function RecentWorksCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const icons = [
    recent_works_1,
    recent_works_2,
    recent_works_3,
    recent_works_4,
    recent_works_5,
  ];
  const mobile_icon = [
    recent_img_mob1,
    recent_img_mob2,
    recent_img_mob3,
    recent_img_mob4,
    recent_img_mob5,
  ];
  return (
    <div className="position-relative slider-wrap">
      <Container>
        <Row>
          <Col xl={6} lg={6} md={12}>
            <Carousel
              className="RecentWorksCarousel-main-wrapper"
              activeIndex={index}
              onSelect={handleSelect}
            >
              {recentWorksList?.map((item, key) => {
                return (
                  <Carousel.Item key={key}>
                    <Container fluid className="p-0">
                      <Row>
                        <Col
                          md={12}
                          className="mx-auto carousel-item-inner-set"
                        >
                          <img
                            className="img-fluid"
                            src={item?.icon}
                            alt="Second slide"
                          />
                          <p>{item?.description}</p>
                        </Col>
                      </Row>
                    </Container>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </Col>
        </Row>
      </Container>

      <div className="slider-img-wrap">
        <img className="img-fluid" src={icons[index]} alt="Second slide" />
        <img
          className="img-fluid mobile-img"
          src={mobile_icon[index]}
          alt="Second slide"
        />
      </div>
    </div>
  );
}

export default RecentWorksCarousel;
