import AccountLoginSignupIcon from "../Assets/Icons/ApplicationFeaturesIcons/AccountLoginSignupIcon";
import AnalyticsIcon from "../Assets/Icons/ApplicationFeaturesIcons/AnalyticsIcon";
import ChatIcon from "../Assets/Icons/ApplicationFeaturesIcons/ChatIcon";
import ConnectivityIcon from "../Assets/Icons/ApplicationFeaturesIcons/ConnectivityIcon";
import ContactsIcon from "../Assets/Icons/ApplicationFeaturesIcons/ContactsIcon";
import DataViewIcon from "../Assets/Icons/ApplicationFeaturesIcons/DataViewIcon";
import EditorIcon from "../Assets/Icons/ApplicationFeaturesIcons/EditorIcon";
import EmailIcon from "../Assets/Icons/ApplicationFeaturesIcons/EmailIcon";
import FeedsIcon from "../Assets/Icons/ApplicationFeaturesIcons/FeedsIcon";
import FilterIcon from "../Assets/Icons/ApplicationFeaturesIcons/FilterIcon";
import MapIcon from "../Assets/Icons/ApplicationFeaturesIcons/MapIcon";
import MediaIcon from "../Assets/Icons/ApplicationFeaturesIcons/MediaIcon";
import NotificationIcon from "../Assets/Icons/ApplicationFeaturesIcons/NotificationIcon";
import PaymentIcon from "../Assets/Icons/ApplicationFeaturesIcons/PaymentIcon";
import ProfileIcon from "../Assets/Icons/ApplicationFeaturesIcons/ProfileIcon";
import ScannerIcon from "../Assets/Icons/ApplicationFeaturesIcons/ScannerIcon";
import SchedulerIcon from "../Assets/Icons/ApplicationFeaturesIcons/SchedulerIcon";
import StorageIcon from "../Assets/Icons/ApplicationFeaturesIcons/StorageIcon";
import StrategyIcon from "../Assets/Icons/ApplicationFeaturesIcons/StrategyIcon";
import UiUxIcon from "../Assets/Icons/ApplicationFeaturesIcons/UiuxIcon";
import AppDevelopmentIcon from "../Assets/Icons/ApplicationFeaturesIcons/AppDevelopmentIcon";
import WebDevelopmentIcon from "../Assets/Icons/ApplicationFeaturesIcons/WebDevelopmentIcon";
import DigitalMarketingIcon from "../Assets/Icons/ApplicationFeaturesIcons/DigitalMarketingIcon";
import FundraisingIcon from "../Assets/Icons/ApplicationFeaturesIcons/FundraisingIcon";
import BusinessProcessesIcon from "../Assets/Icons/ApplicationFeaturesIcons/BusinessProcessesIcon";
import TechRecruitmentIcon from "../Assets/Icons/ApplicationFeaturesIcons/TechRecruitmentIcon";
import ThirdPartyIcon from "../Assets/Icons/ApplicationFeaturesIcons/ThirdPartyIcon";
import ArtDesignIcon from "../Assets/Icons/ProjectCategoryIcons/ArtDesignIcon";
import AutoMobilesIcon from "../Assets/Icons/ProjectCategoryIcons/AutoMobilesIcon";
import BeautyIcon from "../Assets/Icons/ProjectCategoryIcons/BeautyIcon";
import BooksIcon from "../Assets/Icons/ProjectCategoryIcons/BooksIcon";
import BusinessIcon from "../Assets/Icons/ProjectCategoryIcons/BusinessIcon";
import CommunicationIcon from "../Assets/Icons/ProjectCategoryIcons/CommunicationIcon";
import DatingIcon from "../Assets/Icons/ProjectCategoryIcons/DatingIcon";
import EducationIcon from "../Assets/Icons/ProjectCategoryIcons/EducationIcon";
import EntertainmentIcon from "../Assets/Icons/ProjectCategoryIcons/EntertainmentIcon";
import EventsIcon from "../Assets/Icons/ProjectCategoryIcons/EventsIcon";
import FinanceIcon from "../Assets/Icons/ProjectCategoryIcons/FinanceIcon";
import FoodIcon from "../Assets/Icons/ProjectCategoryIcons/FoodIcon";
import HealthIcon from "../Assets/Icons/ProjectCategoryIcons/HealthIcon";
import HouseIcon from "../Assets/Icons/ProjectCategoryIcons/HouseIcon";
import LifestylesIcon from "../Assets/Icons/ProjectCategoryIcons/LifestylesIcon";
import MapsIcon from "../Assets/Icons/ProjectCategoryIcons/MapsIcon";
import MedicalIcon from "../Assets/Icons/ProjectCategoryIcons/MedicalIcon";
import MusicIcon from "../Assets/Icons/ProjectCategoryIcons/MusicIcon";
import NewsIcon from "../Assets/Icons/ProjectCategoryIcons/NewsIcon";
import OtherIcon from "../Assets/Icons/ProjectCategoryIcons/OtherIcon";
import ParentingIcon from "../Assets/Icons/ProjectCategoryIcons/ParentingIcon";
import PersonalizationIcon from "../Assets/Icons/ProjectCategoryIcons/PersonalizationIcon";
import PhotographyIcon from "../Assets/Icons/ProjectCategoryIcons/PhotographyIcon";
import ProductivityIcon from "../Assets/Icons/ProjectCategoryIcons/ProductivityIcon";
import ShoppingIcon from "../Assets/Icons/ProjectCategoryIcons/ShoppingIcon";
import SocialIcon from "../Assets/Icons/ProjectCategoryIcons/SocialIcon";
import SportsIcon from "../Assets/Icons/ProjectCategoryIcons/SportsIcon";
import ToolsIcon from "../Assets/Icons/ProjectCategoryIcons/ToolsIcon";
import TravelIcon from "../Assets/Icons/ProjectCategoryIcons/TravelIcon";
import VideoPlayerIcon from "../Assets/Icons/ProjectCategoryIcons/VideoPlayerIcon";
import WheatherIcon from "../Assets/Icons/ProjectCategoryIcons/WheatherIcon";
import kristinrulonImg from "../Assets/images/terminalMemberList/kristin_rulon.svg";
import desmondwilliamsonImg from "../Assets/images/terminalMemberList/desmond_williamson.svg";
import drewdavisImg from "../Assets/images/terminalMemberList/drew_davis.svg";
import kevinlinneyImg from "../Assets/images/terminalMemberList/kevin_linney.svg";
import michaelwoodsImg from "../Assets/images/terminalMemberList/michael_woods.svg";

import DoositLogo from "../Assets/images/home/DoositLogo.svg";
import GravityLogo from "../Assets/images/home/GravityLogo.svg";
import BitformanceLogo from "../Assets/images/home/BitformanceLogo.svg";
import SherlockLogo from "../Assets/images/home/SherlockLogo.svg";
import ExitFundLogo from "../Assets/images/home/ExitFundLogo.svg";

import recent_works_1 from "../Assets/images/home/recent_works_1.png";
import recent_works_2 from "../Assets/images/home/recent_works_2.png";
import recent_works_3 from "../Assets/images/home/recent_works_3.png";
import recent_works_4 from "../Assets/images/home/recent_works_4.png";
import recent_works_5 from "../Assets/images/home/recent_works_5.png";

export const categoryArray = [
  {
    icon: <AutoMobilesIcon />,
    text: "Auto & Vehicles",
  },
  {
    icon: <ArtDesignIcon />,
    text: "Art & Design",
  },
  {
    icon: <BeautyIcon />,
    text: "Beauty",
  },
  {
    icon: <BooksIcon />,
    text: "Books & References",
  },
  {
    icon: <BusinessIcon />,
    text: "Business",
  },
  {
    icon: <CommunicationIcon />,
    text: "Communication",
  },
  {
    icon: <DatingIcon />,
    text: "Dating",
  },
  {
    icon: <EducationIcon />,
    text: "Education",
  },
  {
    icon: <EntertainmentIcon />,
    text: "Entertainment",
  },
  {
    icon: <EventsIcon />,
    text: "Events",
  },
  {
    icon: <FinanceIcon />,
    text: "Finance",
  },
  {
    icon: <FoodIcon />,
    text: "Food & Drink",
  },
  {
    icon: <HealthIcon />,
    text: "Health & Fitness",
  },
  {
    icon: <HouseIcon />,
    text: "House & Home",
  },
  {
    icon: <LifestylesIcon />,
    text: "Lifestyle",
  },
  {
    icon: <MapsIcon />,
    text: "Maps & Navigation",
  },
  {
    icon: <MedicalIcon />,
    text: "Medical",
  },
  {
    icon: <MusicIcon />,
    text: "Music & Audio",
  },
  {
    icon: <NewsIcon />,
    text: "News & Magazines",
  },
  {
    icon: <ParentingIcon />,
    text: "Parenting",
  },
  {
    icon: <PersonalizationIcon />,
    text: "Personalization",
  },
  {
    icon: <PhotographyIcon />,
    text: "Photography",
  },
  {
    icon: <ProductivityIcon />,
    text: "Productivity",
  },
  {
    icon: <ShoppingIcon />,
    text: "Shopping",
  },
  {
    icon: <SocialIcon />,
    text: "Social",
  },
  {
    icon: <SportsIcon />,
    text: "Sports",
  },
  {
    icon: <ToolsIcon />,
    text: "Tools",
  },
  {
    icon: <TravelIcon />,
    text: "Travel & Local",
  },
  {
    icon: <VideoPlayerIcon />,
    text: "Video Player & Editor",
  },
  {
    icon: <WheatherIcon />,
    text: "Weather",
  },
  {
    icon: <OtherIcon />,
    text: "Other",
  },
];

export const applicationFeaturesArray = [
  {
    icon: <AccountLoginSignupIcon />,
    text: "Account(Login/Signup)",
  },
  {
    icon: <AnalyticsIcon />,
    text: "Analytics",
  },
  {
    icon: <ChatIcon />,
    text: "Chat",
  },
  {
    icon: <ConnectivityIcon />,
    text: "Connectivity",
  },
  {
    icon: <ContactsIcon />,
    text: "Contacts",
  },
  {
    icon: <DataViewIcon />,
    text: "Data View",
  },
  {
    icon: <EditorIcon />,
    text: "Editor",
  },
  {
    icon: <EmailIcon />,
    text: "Email",
  },
  {
    icon: <FeedsIcon />,
    text: "Feeds",
  },
  {
    icon: <FilterIcon />,
    text: "Filter/Sort",
  },
  {
    icon: <MapIcon />,
    text: "Map",
  },
  {
    icon: <MediaIcon />,
    text: "Media",
  },
  {
    icon: <NotificationIcon />,
    text: "Notification",
  },
  {
    icon: <PaymentIcon />,
    text: "Payment",
  },
  {
    icon: <ProfileIcon />,
    text: "Profile",
  },
  {
    icon: <SchedulerIcon />,
    text: "Scheduler",
  },
  {
    icon: <ScannerIcon />,
    text: "Scanner",
  },
  {
    icon: <StorageIcon />,
    text: "Storage",
  },
  {
    icon: <ThirdPartyIcon />,
    text: "Third Party API",
  },
  {
    icon: <OtherIcon />,
    text: "Other",
  },
];

export const serviceStrategyList = [
  {
    icon: <StrategyIcon />,
    text: "Strategy Development",
    more_info:
      "From our vast experiences, we collaborate with you to identify the right direction to skyrocket to success.",
  },
  {
    icon: <UiUxIcon />,
    text: "UI/UX Design",
    more_info:
      "Working according to your direction, our designers build functional yet beautiful designs that leave the user wanting more. We take time and are patient in our designs so that there is greater innovation in what is built. In the end, we transform the idea into a stable solution through our innovative UI/UX.",
  },
  {
    icon: <AppDevelopmentIcon />,
    text: "App Development",
    more_info:
      "For B2B to B2C, we create high-impact, high-quality mobile apps on all platforms such as Cross-Platform, Native, and Hybrid Technologies. Our award winning team builds focused, yet clean and simple apps that wow the user on both iOS and Android.",
  },
  {
    icon: <WebDevelopmentIcon />,
    text: "Web Development",
    more_info:
      "Our in-house specialists build custom web solutions for any complexity of issues, whether it be to automate processes, establish your brand, or anything else you can think of. Our talented team can work with any web technologies from Python, Java, Drupal, and Ruby on Rails to all others.",
  },
  {
    icon: <DigitalMarketingIcon />,
    text: "Digital Marketing",
    more_info:
      "We provide the maximum ROI, by keeping in step with your audience, every step of the way. Through valuable content, SEO, SEM, and Social Media Marketing, we bring your product to the forefront.",
  },
  {
    icon: <FundraisingIcon />,
    text: "Fundraising",
    more_info:
      "No project could be completed without external capital, so we help open those doors to you, so you are ready to grow.",
  },
  {
    icon: <BusinessProcessesIcon />,
    text: "Business Processes",
    more_info:
      "Whether it be customer or technical support, sales, accounting, or anything else, we got your back. Focus on your core vision and we will take care of the rest. We’ll work on reducing your costs, so that you can work on improving your revenue and combined we will expand your margins.",
  },
  {
    icon: <TechRecruitmentIcon />,
    text: "Tech Recruitment",
    more_info:
      "Finding the right engineers is hard, but with our expertise in the industry, we can simplify the process for you. Our rigorous recruitment process includes understanding your company culture and matching that to identify the right candidates. From there, our screening, interview, testing, and negotiation processes takes the guesswork out of finding the perfect ones.",
  },
];

export const faqList = [
  {
    heading: "How do you handle the secrecy of ideas?",
    text: "We ensure your idea remains confidential by signing an NDA before any discussion. When you sign up, you will see that the NDA is a part of the agreement. We also have legal agreements with all of our employees for maintaining secrecy.",
  },
  {
    heading: "What is your project development methodology?",
    text: "Generally most projects follow the same steps, however some may differ sometimes. These steps include requirement gathering, analysis, solution development, prototyping/wireframes, design, development, and testing.",
  },
  {
    heading: "Can you work according to my timezone? ",
    text: "Yes, we can.",
  },
  {
    heading: "What’s your pricing model?",
    text: "We work on an hourly model, in which you pay for the services at an hourly rate. We pride ourselves in having the best rates in the industry in order to help more ideas come to life.",
  },
  {
    heading: "How do you communicate during the project development phase?",
    text: "On top of our in-built tools, we utilize tools such as Slack, Email, and Zoom to remain in contact and make sure everyone is on the same page.",
  },
];

export const terminalMemberList = [
  {
    icon: kristinrulonImg,
    text: "They are invested in my dream, and that means the world.",
    name: "Kristin Rulon",
    details: "Founder of Mind Star Health, LLC",
  },
  {
    icon: desmondwilliamsonImg,
    text: "They’re a team of qualified individuals that help customers get projects off the ground.",
    name: "Desmond Williamson",
    details: "Founder & CEO, Vakay",
  },
  {
    icon: drewdavisImg,
    text: "Inventive Byte delivers excellent work for an incredible price.",
    name: "Drew Davis",
    details: "Founder & CEO, UniLeaseFounder & CEO, UniLease",
  },
  {
    icon: kevinlinneyImg,
    text: "I feel that Inventive Byte really wants to give me the best app they possibly can.",
    name: "Kevin Linney",
    details: "Founder & CEO, 4Fame",
  },
  {
    icon: michaelwoodsImg,
    text: "Their enthusiasm for their work is impressive.",
    name: "Michael Woods",
    details: "Founder & CEO, Doosit",
  },
];

export const recentWorksList = [
  {
    icon: DoositLogo,
    recentIcon: recent_works_1,
    description: "The App that converts your spare change into positive change",
  },
  {
    icon: GravityLogo,
    recentIcon: recent_works_2,
    description: "A networking app that lets you find people for any activity",
  },
  {
    icon: BitformanceLogo,
    recentIcon: recent_works_3,
    description: "The easiest App to create, track, and analyze crypto indexes",
  },
  {
    icon: SherlockLogo,
    recentIcon: recent_works_4,
    description:
      " The App that is upending the coroner case management industry",
  },
  {
    icon: ExitFundLogo,
    recentIcon: recent_works_5,
    description:
      "The App that Puts the Startup Ecosystem Together in one Solid Stroke",
  },
];
