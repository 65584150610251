import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCreditDebitCard from "./AddCreditDebitCard";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);
const SetupCard = (props) => {
  const { currentPaymentSection, setCurrentPaymentSection, add, setAdd } =
    props;
  return (
    <Elements stripe={stripePromise}>
      <AddCreditDebitCard
        currentPaymentSection={currentPaymentSection}
        setCurrentPaymentSection={setCurrentPaymentSection}
        add={add}
        setAdd={setAdd}
      />
    </Elements>
  );
};

export default SetupCard;
