import * as React from "react";

function ProjectInfoHeaderIcon(props) {
  return (
    <svg
      width="72"
      height="73"
      viewBox="0 0 72 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="36" cy="36.4346" r="36" fill="#E5C243" />
      <path
        d="M38.4749 47.6043C38.4749 47.4024 38.4749 47.2334 38.4749 47.0645C38.4749 42.9454 38.4738 38.8262 38.4759 34.7071C38.4769 33.069 39.3502 32.1891 40.9782 32.1881C43.829 32.186 46.6809 32.186 49.5317 32.1881C51.0885 32.1891 51.9989 33.0958 52 34.6453C52.002 38.7984 52.001 42.9526 52.001 47.1057C52.001 47.2592 52.001 47.4127 52.001 47.6054C47.5005 47.6043 43.0279 47.6043 38.4749 47.6043ZM45.2384 35.0873C44.8446 35.0873 44.4507 35.0729 44.0569 35.0904C43.5032 35.1151 43.1351 35.4685 43.1052 35.9744C43.0733 36.5122 43.4094 36.9583 43.9723 36.9872C44.8085 37.0294 45.6498 37.0294 46.486 36.9882C47.0469 36.9604 47.3943 36.5101 47.3655 35.9826C47.3376 35.4809 46.9654 35.1182 46.4179 35.0914C46.0262 35.0729 45.6313 35.0883 45.2384 35.0873Z"
        fill="black"
      />
      <path
        d="M23.2049 41.0426C22.2017 41.0426 21.2789 41.0426 20.2922 41.0426C20.2922 40.819 20.2922 40.6171 20.2922 40.4151C20.2922 34.8526 20.3294 29.2911 20.2727 23.7295C20.2531 21.705 21.7058 20.4223 23.574 20.4347C31.4934 20.4872 39.4138 20.4872 47.3331 20.4347C49.156 20.4223 50.6758 21.7163 50.5922 23.7089C50.5087 25.6964 50.5737 27.691 50.5737 29.6826C50.5737 29.869 50.5737 30.0545 50.5737 30.241C49.5994 30.241 48.6673 30.241 47.6744 30.241C47.6744 30.0071 47.6744 29.8237 47.6744 29.6403C47.6744 27.9403 47.6775 26.2414 47.6724 24.5414C47.6703 23.6997 47.3259 23.3535 46.4795 23.3535C39.1272 23.3504 31.7738 23.3514 24.4215 23.3535C23.5431 23.3535 23.208 23.6904 23.208 24.5703C23.2059 29.8237 23.207 35.0761 23.207 40.3296C23.2049 40.548 23.2049 40.7685 23.2049 41.0426Z"
        fill="black"
      />
      <path
        d="M36.5125 44.2715C36.5125 45.5902 36.5125 46.8369 36.5125 48.1309C36.331 48.1402 36.166 48.1557 36.0001 48.1557C31.7594 48.1567 27.5188 48.1629 23.2781 48.1536C21.1222 48.1485 19.4118 46.6968 19.0457 44.5991C18.8674 43.577 19.1891 42.9692 20.3933 42.9877C23.3627 43.0331 26.3341 43.0104 29.3035 42.9939C29.7118 42.9918 30.0427 43.0588 30.2892 43.3988C30.8119 44.1179 31.5068 44.3343 32.3925 44.29C33.7421 44.221 35.0989 44.2715 36.5125 44.2715Z"
        fill="black"
      />
      <path
        d="M38.4751 49.5928C42.9941 49.5928 47.4657 49.5928 51.9724 49.5928C51.9724 50.2573 52.0363 50.9105 51.9579 51.5452C51.828 52.5992 50.8857 53.4162 49.8216 53.4213C46.7677 53.4378 43.7128 53.4389 40.6578 53.4224C39.4608 53.4162 38.5102 52.4487 38.4772 51.2577C38.4617 50.7127 38.4751 50.1666 38.4751 49.5928Z"
        fill="black"
      />
    </svg>
  );
}

export default ProjectInfoHeaderIcon;
