import * as React from "react";

function YesIcon(props) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="17.1172"
        cy="16.8604"
        r="15.5"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M11.6172 15.8604L16.1172 21.3604L24.1172 13.3604"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default YesIcon;
