import {
  SET_LOADER,
  LOGOUT,
  SET_USER_STATUS,
  SET_PROJECT_DATA,
  SET_PROFILE_DATA,
  SET_DASHBOARD_POSITION,
  SET_FEEDBACK_INIT,
  SET_FEEDBACK_SUCCESS,
  SET_FEEDBACK_FAIL,
  GET_DASHBOARD_DATA_INIT,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  SEND_EMAIL_INIT,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  SEND_FEEDBACK_INIT,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAIL,
  GET_NOTIFICATIONS_DATA_INIT,
  GET_NOTIFICATIONS_DATA_SUCCESS,
  GET_NOTIFICATIONS_DATA_FAIL,
  SET_SCHEDULE_MEETING_INIT,
  SET_SCHEDULE_MEETING_SUCCESS,
  SET_SCHEDULE_MEETING_FAIL,
  GET_SCHEDULE_MEETING_INFO_INIT,
  GET_SCHEDULE_MEETING_INFO_SUCCESS,
  UPDATE_PROJECT_DATA_INIT,
  UPDATE_PROJECT_DATA_SUCCESS,
  UPDATE_PROJECT_DATA_FAILED,
} from "../actions/actionTypes";

const initialState = {
  Loader: false,
  updateLoader: false,
  Errors: "",
  userStatus: null,
  projectData: null,
  profileData: null,
  position: 0,
  feedbackData: {},
  feedbackTimer: 0,
  dashbaordData: {},
  notifications: [],
  notificationCount: 0,
};

export const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DASHBOARD_DATA_INIT:
      return {
        ...state,
        Loader: true,
        dashbaordData: {},
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        Loader: false,
        dashbaordData: payload,
      };
    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        Loader: false,
      };
    case SET_DASHBOARD_POSITION:
      return {
        ...state,
        position: payload,
      };
    case SET_USER_STATUS:
      return {
        ...state,
        userStatus: payload?.data,
        notificationCount: payload?.count,
      };

    case SET_LOADER:
      return {
        ...state,
        Loader: !state.Loader,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case SET_PROJECT_DATA:
      return {
        ...state,
        projectData: payload,
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        profileData: payload,
      };
    case SET_FEEDBACK_INIT:
      return {
        ...state,
        Loader: true,
      };
    case SET_FEEDBACK_SUCCESS:
      return {
        ...state,
        Loader: false,
        feedbackData: payload,
      };
    case SET_FEEDBACK_FAIL:
      return {
        ...state,
        Loader: false,
      };
    case SEND_EMAIL_INIT:
      return {
        ...state,
        Loader: true,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        Loader: false,
      };
    case SEND_EMAIL_FAIL:
      return {
        ...state,
        Loader: false,
      };
    case SEND_FEEDBACK_INIT:
      return { ...state, Loader: true };
    case SEND_FEEDBACK_SUCCESS:
      return { ...state, Loader: false };
    case SEND_FEEDBACK_FAIL:
      return {
        ...state,
        Loader: false,
      };
    case GET_NOTIFICATIONS_DATA_INIT:
      return { ...state, Loader: true };
    case GET_NOTIFICATIONS_DATA_SUCCESS:
      return { ...state, Loader: false, notifications: payload };
    case GET_NOTIFICATIONS_DATA_FAIL:
      return { ...state, Loader: false, notifications: [] };
    case SET_SCHEDULE_MEETING_INIT:
      return {
        ...state,
        Loader: true,
      };
    case SET_SCHEDULE_MEETING_SUCCESS:
      return {
        ...state,
        scheduleMeeting: payload,
        Loader: false,
      };
    case SET_SCHEDULE_MEETING_FAIL:
      return {
        ...state,
        Loader: false,
      };
    case GET_SCHEDULE_MEETING_INFO_INIT:
      return {
        ...state,
        Loader: true,
      };
    case GET_SCHEDULE_MEETING_INFO_SUCCESS: {
      return {
        ...state,
        Loader: false,
        scheduleMeetingData: payload,
      };
    }
    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        Loader: false,
      };
    case UPDATE_PROJECT_DATA_INIT:
      return {
        ...state,
        updateLoader: true,
      };
    case UPDATE_PROJECT_DATA_SUCCESS:
      return {
        ...state,
        updateLoader: false,
      };
    case UPDATE_PROJECT_DATA_FAILED:
      return {
        ...state,
        updateLoader: false,
      };
    default:
      return state;
  }
};
