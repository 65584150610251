import React, { useEffect, useState } from "react";
import "./EditPhase.scss";
import { useNavigate } from "react-router-dom";
import { Button, Space, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import api from "../../Utils/api";
import { updateProjectData } from "../../redux/actions/dashboardActions";

function EditPhase() {
  const navigate = useNavigate();
  const [selectedPhase, setSelectedPhase] = useState();
  const [projectPhases, setProjectPhases] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { projectData, updateLoader } = useSelector(
    (state) => state.dashboardReducer
  );

  useEffect(() => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/projectPhase`)
      .then((data) => {
        setProjectPhases(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setSelectedPhase(projectData?.phase);
  }, [projectData]);

  return (
    <div className="editPhase-main-wrapper">
      <div className="mainContainer">
        <div className="dashboard-content">
          <div className="updateDetailCon">
            <div className="detailCardCon">
              <div className="maxConatiner">
                <div className="editHeading">Edit Phase</div>
                <div className="projectTypeCon">
                  {loading ? (
                    <Spin />
                  ) : (
                    projectPhases?.map((phase) => (
                      <div
                        className={`phaseProjectCon ${
                          selectedPhase?.id === phase?.id &&
                          "selectedProjectTypeItem"
                        }`}
                        onClick={() => setSelectedPhase(phase)}
                      >
                        <img
                          src={process.env.REACT_APP_IMAGE_URL + phase?.image}
                          alt=""
                          style={{
                            width: "45px",
                            height: "45px",
                            marginRight: "10px",
                          }}
                        />
                        <div className="phaseProjectText">{phase?.name}</div>
                      </div>
                    ))
                  )}
                </div>
                <div className="buttonContainer">
                  <Space>
                    <Button
                      loading={updateLoader}
                      className="sendButton"
                      onClick={() =>
                        dispatch(
                          updateProjectData(
                            {
                              ...projectData,
                              phase: selectedPhase,
                            },
                            navigate
                          )
                        )
                      }
                    >
                      Send
                    </Button>
                    <Button
                      className="cancelButton"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPhase;
