import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Signin.scss";
import "antd/dist/antd.css";
import { Form, Input, Button, Checkbox, Divider, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  handleLogin,
  handleGoogleLogin,
} from "../../../redux/actions/authActions";
import LogoHeader from "../../../Components/LogoHeader/LogoHeader";
import { GoogleLogin } from "@react-oauth/google";
const Signin = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.authReducer);
  const { getOnboardDataLoading } = useSelector(
    (state) => state.onboardingReducer
  );
  const navigate = useNavigate();
  const onFinish = (values) => {
    dispatch(handleLogin(values, navigate));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }
  const responseGoogle = (response) => {
    dispatch(handleGoogleLogin(response?.credential, navigate));
  };
  const rules = {
    email: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter your email");
          } else if (
            new RegExp(
              "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z]+(?:.[a-zA-Z0-9-]+)*$"
            ).test(value)
          ) {
            return Promise.resolve();
          }
          return Promise.reject("Please enter valid email");
        },
      },
    ],
    password: [
      {
        validator(_, value) {
          if (!value) {
            return Promise.reject("Please enter password");
          } else if (value?.length < 8) {
            return Promise.reject("Password must be at least 8 characters");
          } else {
            return Promise.resolve();
          }
        },
      },
    ],
  };
  return (
    <div className="onboard">
      <LogoHeader />

      <Container>
        <Row>
          <Col sm={12}>
            <div className="d-flex justify-content-center align-items-center bodyVh90">
              <div className="signin-form">
                <div className="d-flex justify-content-center">
                  <h4>Sign In</h4>
                </div>
                <GoogleLogin
                  onSuccess={(response) => {
                    responseGoogle(response);
                  }}
                  onError={(err) => {
                    console.log("Err", err);
                  }}
                />
                <Divider className="custom-divider">Or</Divider>
                <Form
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={rules.email}
                    hasFeedback
                  >
                    <Input placeholder="Your email" />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={rules.password}
                    hasFeedback
                    className="signin-password"
                  >
                    <Input.Password placeholder="Enter password" />
                  </Form.Item>

                  <div className="d-flex justify-content-between my-4 remember-forgot-wrap">
                    <div>
                      <Checkbox onChange={onChange}></Checkbox>
                      <span style={{ marginLeft: "10px" }}>Remember me</span>
                    </div>
                    <div className="forgot-link">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                  </div>

                  <div className="mt-3 btn-yelow">
                    <Button
                      className="btn-primary"
                      type="primary"
                      shape="round"
                      block
                      htmlType="submit"
                    >
                      <div className="btn-info-wrap">
                        <div>
                          <Spin spinning={loading || getOnboardDataLoading} />
                        </div>
                        Sign In
                      </div>
                    </Button>
                  </div>
                  <div className="pt-3 text-center">
                    <Link className="login-link " to="/signup">
                      Don't have an account? Sign up
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Signin;
