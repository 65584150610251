import {
  GET_REVIEWS_DETAILS_DATA_INIT,
  GET_REVIEWS_DETAILS_DATA_SUCCESS,
  GET_REVIEWS_DETAILS_DATA_FAIL,
  GET_REVIWS_DATA_INIT,
  GET_REVIWS_DATA_SUCCESS,
  GET_REVIWS_DATA_FAIL,
  PUT_REVIWS_DATA_INIT,
  PUT_REVIWS_DATA_SUCCESS,
  PUT_REVIWS_DATA_FAIL,
} from "../actions/actionTypes";

const initialState = {
  reviews: [],
  loading: false,
  reviewData: {},
};

export const reviewsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REVIWS_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_REVIWS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviews: payload,
      };
    }
    case GET_REVIWS_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case PUT_REVIWS_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case PUT_REVIWS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        reviewData: payload,
      };
    }
    case PUT_REVIWS_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_REVIEWS_DETAILS_DATA_INIT: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_REVIEWS_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: payload,
      };
    case GET_REVIEWS_DETAILS_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
