import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import AddCreditCardStep from "./AddCreditCardStep";

const stripePromise = loadStripe(
  "pk_test_51KbJIpL6R8BgvjmeeXS4wWCyXfzWfTWgl3xO7g0s7Zm6zol15ZQDiY6mCI6lt06j7x3ScbMnmBV4jN67WAe5w0yi00KcVcxun6"
);

const SetupCard = (props) => {
  const { setCurrentStep, setPaymentData } = props;
  return (
    <Elements stripe={stripePromise}>
      <AddCreditCardStep
        setCurrentStep={setCurrentStep}
        setPaymentData={setPaymentData}
      />
    </Elements>
  );
};

export default SetupCard;
