import { Tabs, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import "./Settings.scss";
import CreditCard from "../../../src/Assets/images/Invoices/your_credit.png";
import BankIcon from "../../../src/Assets/images/Invoices/bank_icon.png";
import InviteTeamMember from "./inviteTeamMember";
import ChangePassword from "./ChangePassword";
import EmailNotification from "./EditNotifications";
import axios from "../../Utils/api";
import settingIcon from "../../../src/Assets/images/MainLayout/setting-icon.png";
import BankAccountInfo from "./PaymentInfo/BankAccountInfo";
import CreditDebitInfo from "./PaymentInfo/CreditDebitInfo";
import { settingToggle } from "../../redux/actions/settingsAction";
import { useMedia } from "react-use";
import { usePlaidLink } from "react-plaid-link";
import {
  getCardList,
  getAccountList,
  getLinkToken,
} from "../../redux/actions/settingsAction";
import { useDispatch, useSelector } from "react-redux";
import SetupCard from "./PaymentInfo/SetupCard";
const { TabPane } = Tabs;

function Settings() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPaymentSection, setCurrentPaymentSection] = useState();

  const isMobile = useMedia("(max-width:768px)", false);
  const { currentTab, cardList, accountList, get_link_token_data } =
    useSelector((state) => state?.settingsReducer);
  useEffect(() => {
    dispatch(getLinkToken());
  }, []);
  useEffect(() => {
    if (!cardList?.length && !accountList?.length) {
      return setCurrentPaymentSection(0);
    }
    if (cardList?.length > 0 && !accountList?.length) {
      return setCurrentPaymentSection(4);
    }
    if (!cardList?.length && accountList?.length > 0) {
      return setCurrentPaymentSection(2);
    }
    setCurrentPaymentSection(3);
  }, [cardList, accountList]);
  const handlePaymentTab = (tab) => {
    dispatch(settingToggle(tab));
    setCurrentPaymentSection(0);
    if (tab === "2") {
      setLoading(true);
      axios
        .getWithToken(
          `${process.env.REACT_APP_API_URL}/payment/paymentMethodStatus`
        )
        .then((res) => {
          dispatch(
            getCardList(() => {
              dispatch(
                getAccountList(() => {
                  setLoading(false);
                })
              );
            })
          );
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  const { open } = usePlaidLink({
    token: get_link_token_data?.link_token,
    onSuccess: async (public_token, metadata) => {
      setLoading(true);
      await axios
        .postWithToken(
          `${process.env.REACT_APP_API_URL}/payment/createPlaidItem`,
          {
            public_token: public_token,
            institution_id: metadata?.institution?.institution_id,
            institution_name: metadata?.institution?.name,
          }
        )
        .then((res) => {
          axios
            .getWithToken(
              `${process.env.REACT_APP_API_URL}/payment/getAccounts?access_token=${res?.data?.access_token}`
            )
            .then((res1) => {
              axios
                .postWithToken(
                  `${process.env.REACT_APP_API_URL}/payment/linkAchAccount`,
                  {
                    access_token: res?.data?.access_token,
                    account_id: res1?.accounts[0]?.account_id,
                    auto_pay: false,
                  }
                )
                .then((res2) => {
                  setLoading(false);
                  if (res2?.error) {
                    message.error(res2?.error, 2);
                  } else {
                    message.success("Account Linked Succesfully", 2);
                    dispatch(getCardList());
                    dispatch(getAccountList());
                  }
                })
                .catch((error) => {
                  setLoading(false);

                  message.error(
                    error?.response?.data?.error || error?.message,
                    2
                  );
                });
            })
            .catch((error) => {
              setLoading(false);
              message.error(error?.response?.data?.error || error?.message, 2);
            });
        })
        .catch((error) => {
          message.error(error?.response?.data?.error || error?.message, 2);
          setLoading(false);
        });
    },
  });

  const SelectPayment = () => (
    <div className="tab-payment-info d-flex align-items-center justify-content-center">
      {loading ? (
        <spin spinning={loading} />
      ) : (
        <div className="payment-wrapper">
          <div className="payment-headding">
            <h5 className="payment-head">Add Your Payment Method</h5>
          </div>
          <div
            className="d-flex payment-cards"
            onClick={() => {
              setCurrentPaymentSection(1);
            }}
          >
            <img src={CreditCard} alt="CreditCard" />
            <span className="payment-card-text">Credit/Debit Card</span>
          </div>
          <div className="d-flex payment-bank" onClick={() => open()}>
            <img src={BankIcon} alt="Bank Icon" />
            <span className="payment-bank-text">Bank Account Transfer</span>
          </div>
        </div>
      )}
    </div>
  );

  const PaymentInfo = () => (
    <div className="payment-info-wrap">
      {/* <Spin spinning={loading}> */}
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Bank Account Info" key="101">
          <BankAccountInfo />
        </TabPane>
        <TabPane tab="Credit/Debit Card Info" key="102">
          <CreditDebitInfo
            currentPaymentSection={currentPaymentSection}
            setCurrentPaymentSection={setCurrentPaymentSection}
          />
        </TabPane>
      </Tabs>
      {/* </Spin> */}
    </div>
  );

  const RenderPaymentSections = () => {
    switch (currentPaymentSection) {
      case 0:
        return SelectPayment();

      case 1:
        return (
          <SetupCard
            currentPaymentSection={currentPaymentSection}
            setCurrentPaymentSection={setCurrentPaymentSection}
          />
        );

      case 2:
        return (
          <BankAccountInfo
            currentPaymentSection={currentPaymentSection}
            setCurrentPaymentSection={setCurrentPaymentSection}
          />
        );
      case 3:
        return PaymentInfo();
      case 4:
        return (
          <CreditDebitInfo
            currentPaymentSection={currentPaymentSection}
            setCurrentPaymentSection={setCurrentPaymentSection}
          />
        );
      default:
        return (
          <BankAccountInfo
            currentPaymentSection={currentPaymentSection}
            setCurrentPaymentSection={setCurrentPaymentSection}
          />
        );
    }
  };

  return (
    <>
      <div className="setting-main">
        <Spin spinning={loading}>
          <div className="backCon">
            <div className="backIcon">
              <img src={settingIcon} alt="setting icon" />
            </div>
            <div className="backText">Settings</div>
          </div>
          <div className="settings-main-wrap">
            {!isMobile ? (
              <div className="d-lg-flex setting-left-wrap">
                <Tabs
                  tabPosition={"left"}
                  defaultActiveKey={currentTab}
                  onTabClick={handlePaymentTab}
                >
                  <TabPane tab="Invite Team Member" key="1">
                    <InviteTeamMember />
                  </TabPane>
                  <TabPane tab="Edit Payment Info" key="2">
                    {RenderPaymentSections()}
                  </TabPane>
                  <TabPane tab="Change Password" key="3">
                    <ChangePassword />
                  </TabPane>
                  <TabPane tab="Edit Notifications" key="4">
                    <EmailNotification />
                  </TabPane>
                </Tabs>
              </div>
            ) : (
              <div className="d-flex d-md-flex setting-left-wrap">
                <Tabs
                  tabPosition={"top"}
                  defaultActiveKey={currentTab}
                  onTabClick={handlePaymentTab}
                >
                  <TabPane tab="Invite Team Member" key="1">
                    <InviteTeamMember />
                  </TabPane>
                  <TabPane tab="Edit Payment Info" key="2">
                    {RenderPaymentSections()}
                  </TabPane>
                  <TabPane tab="Change Password" key="3">
                    <ChangePassword />
                  </TabPane>
                  <TabPane tab="Edit Notifications" key="4">
                    <EmailNotification />
                  </TabPane>
                </Tabs>
              </div>
            )}
          </div>
        </Spin>
      </div>
    </>
  );
}

export default Settings;
