import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import IMAGES from "../../Assets/images/Onboarding";
import "./save&next.scss";

export default function SaveAndNext({
  backUrl,
  saveType = "save&next",
  validateForm,
}) {
  return (
    <div className="wizard-btns my-5">
      <Link className="prev-btn" to={backUrl}>
        <img src={IMAGES.previcon} alt="" />
        <span>Back</span>
      </Link>
      <Button className="next-btn" onClick={() => validateForm()}>
        <span>{saveType === "finishOnboarding" ? "Finish" : "Next"}</span>
        <img src={IMAGES.nexticon} alt="" />
      </Button>
    </div>
  );
}
