import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "antd/dist/antd.css";
import { Form, Input, Spin } from "antd";
import "./Development.scss";
import OnboardingStepper from "../../../Components/OnboardingStepper";
import api from "../../../Utils/api";
import { useDispatch, useSelector } from "react-redux";
import {
  SaveOnboarding,
  setOnboardingData,
} from "../../../redux/actions/onboardingActions";
import OnboardingHeader from "../../../Components/OnboardingHeader";
import SaveAndNext from "../../../Components/Save&Next";
import { useNavigate } from "react-router-dom";

const Development = () => {
  const [projectPhases, setProjectPhases] = useState([]);
  const [error, setError] = useState({});
  const [saveType, setSaveType] = useState("");
  const [loading, setLoading] = useState(true);
  const { payload } = useSelector((state) => state.onboardingReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/projectPhase`)
      .then((data) => {
        setProjectPhases(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (saveType === "save&Logout") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
    } else if (saveType === "save&next") {
      dispatch(SaveOnboarding(payload, navigate, saveType));
      navigate("/onboarding/developmentStage");
    }
  }, [saveType]);

  const handleChange = (data) => {
    setError({ ...error, phaseErr: "" });
    dispatch(setOnboardingData({ ...payload, projectPhase: data?.id }));
  };

  const handleOtherInput = (e) => {
    setError({ ...error, otherErr: "" });
    dispatch(setOnboardingData({ ...payload, otherPhase: e.target.value }));
  };

  const validateForm = (clickType) => {
    if (!payload?.projectPhase) {
      setError({ ...error, phaseErr: "Please select project phase" });
    } else if (
      projectPhases?.find((item) => item.id === payload?.projectPhase)?.name ===
        "Futher Along" &&
      !payload?.otherPhase
    ) {
      setError({ ...error, otherErr: "Please input other phase" });
    } else if (clickType === "Save&Logout") {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 3,
        })
      );
      setSaveType("save&Logout");
    } else {
      dispatch(
        setOnboardingData({
          ...payload,
          steps_completed: 3,
        })
      );
      setSaveType("save&next");
    }
  };
  const onSave_Logout_Fun = () => {
    dispatch(
      setOnboardingData({
        ...payload,
        steps_completed: 2,
      })
    );
    setSaveType("save&Logout");
  };
  return (
    <div className="onboard pb-3">
      <OnboardingHeader steps_completed={2} validateForm={onSave_Logout_Fun} />

      <Container className="mt-5">
        <Row>
          <Col md={10} className="m-auto">
            <OnboardingStepper activeElement={3} validateForm={validateForm} />
          </Col>

          <Col md={10} className="m-auto">
            <div className="project-dev">
              <span className="title">
                Are you at the phase or further along?
                <span> Strategy/Product Development</span>
              </span>
              <div className="project-dev-links d-flex justify-content-center">
                {loading ? (
                  <Spin />
                ) : (
                  <ul>
                    {projectPhases?.map((phase, index) => (
                      <li key={index} onClick={() => handleChange(phase)}>
                        <a
                          href={null}
                          className={
                            payload?.projectPhase === phase?.id
                              ? "project-active"
                              : ""
                          }
                        >
                          <img
                            src={process.env.REACT_APP_IMAGE_URL + phase?.image}
                            alt=""
                          />
                          <span>{phase?.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <span className="text-danger">{error?.phaseErr}</span>

              {projectPhases?.find((item) => item.id === payload?.projectPhase)
                ?.name === "Futher Along" && (
                <>
                  <span className="title title-wrap">
                    Please describe where you are in the{" "}
                    <span>startup process</span> and how we can help you.
                  </span>

                  <div className="project-form">
                    <Form layout="vertical">
                      <Form.Item
                        name={["user", "Other"]}
                        label="Other"
                        className={`custom-textarea ${
                          error?.otherErr ? "error-wrapper" : ""
                        }`}
                      >
                        <Input.TextArea
                          style={{ height: 180 }}
                          onChange={handleOtherInput}
                          defaultValue={payload?.otherPhase}
                        />
                      </Form.Item>
                    </Form>
                    <p className="text-danger" style={{ textAlign: "left" }}>
                      {error?.otherErr}
                    </p>
                  </div>
                </>
              )}
            </div>

            <SaveAndNext
              backUrl={"/onboarding/yourProjectCategory"}
              validateForm={validateForm}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Development;
