import React from "react";
import "./NoScheduleMeeting.scss";
import NoSupportTeamImg from "../../Assets/images/Support/no-data-img.svg";
import BackIcon from "../../Assets/images/Support/back_icon.svg";
import { useNavigate } from "react-router-dom";
function NoScheduleMeeting(props) {
  const { page, setNoDataModal } = props;
  const navigate = useNavigate();
  const onBackTask = () => {
    if (page == "support") {
      navigate(-1);
    } else if (page == "dashbaord") {
      setNoDataModal(false);
    }
  };
  return (
    <div className="no-schedule-meeting-main">
      <div className="no-schedule-header" onClick={onBackTask}>
        <div className="back-task">
          <img src={BackIcon} alt="BackIcon" />
          <div className="back-text">Back</div>
        </div>
      </div>
      <div className="no-schedule-content">
        <img src={NoSupportTeamImg} alt="support-icon" />
        <h3>Sorry, No Sales person at moment</h3>
        <p>We will be with you shortly. Thank you for your patience.</p>
      </div>
    </div>
  );
}

export default NoScheduleMeeting;
