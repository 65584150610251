import * as React from "react";

function NoIcon(props) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="17.1172"
        cy="16.8604"
        r="15.5"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M13.7793 21.8604L20.806 12.3604"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.9497 12.7738L21.6126 21.4379"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default NoIcon;
