import api from "../../Utils/api";
import {
  GET_PROJECT_UPDATES_DATA_INIT,
  GET_PROJECT_UPDATES_DATA_SUCCESS,
  GET_PROJECT_UPDATES_DATA_FAIL,
  GET_PROJECT_UPDATE_BYID_INIT,
  GET_PROJECT_UPDATE_BYID_SUCCESS,
  GET_PROJECT_UPDATE_BYID_FAILED,
} from "./actionTypes";

export const getProjectUpdatesData = (page) => {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_UPDATES_DATA_INIT,
    });

    api
      .getWithToken(
        `${process.env.REACT_APP_API_URL}/client/updates?page=${page}`
      )
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_PROJECT_UPDATES_DATA_SUCCESS,
            payload: res,
          });
        } else {
          dispatch({
            type: GET_PROJECT_UPDATES_DATA_FAIL,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PROJECT_UPDATES_DATA_FAIL,
        });
      });
  };
};

export const getProjectUpdateById = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECT_UPDATE_BYID_INIT,
    });
    api
      .getWithToken(`${process.env.REACT_APP_API_URL}/client/update/${id}`)
      .then((res) => {
        if (res?.result) {
          dispatch({
            type: GET_PROJECT_UPDATE_BYID_SUCCESS,
            payload: res,
          });
        } else {
          dispatch({
            type: GET_PROJECT_UPDATE_BYID_FAILED,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_PROJECT_UPDATE_BYID_FAILED,
        });
      });
  };
};
