import React, { useEffect } from "react";
import { Modal } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ScheduleMeeting } from "react-schedule-meeting";
import PersonImg from "../../Assets/images/Dashboard/images.png";
import { ClockCircleFilled, VideoCameraFilled } from "@ant-design/icons";
import {
  scheduleMeetingAction,
  getUserStatus,
} from "../../redux/actions/dashboardActions";
import moment from "moment";
import { gapi } from "gapi-script";
import "./index.scss";
function CalendlyPopup(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let tokenClient;
  let gapiInited = false;
  let gisInited = false;
  const google = window.google;
  const SCOPES =
    "https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar";
  const DISCOVERY_DOC =
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";

  const { calendlyPopup, setCalendlyPopup } = props;
  const { scheduleMeetingData } = useSelector(
    (state) => state.dashboardReducer
  );
  const [scheduleDate, SetScheduleDate] = useState("");
  const [scheduleData, SetScheduleData] = useState({});
  const availableTimeslots = [0, 1, 2, 3, 4, 5].map((id) => {
    return {
      id,
      startTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setUTCHours(
          9,
          0,
          0,
          0
        )
      ),
      endTime: new Date(
        new Date(new Date().setDate(new Date().getDate() + id)).setUTCHours(
          17,
          0,
          0,
          0
        )
      ),
    };
  });
  const onStartTimeSelect = (data) => {
    SetScheduleData(data);
    SetScheduleDate(moment(data?.startTime).format("DD/MM/YYYY"));
  };
  useEffect(() => {
    if (scheduleData && scheduleDate) {
      handleAuthClick();
    }
  }, [scheduleData]);

  function gapiLoaded() {
    gapi.load("client", initializeGapiClient);
  }
  async function initializeGapiClient() {
    await gapi.client.init({
      client_id: process.env.REACT_APP_CLIENTID,
      discoveryDocs: [DISCOVERY_DOC],
    });
    gapiInited = true;
  }

  function gisLoaded() {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: process.env.REACT_APP_CLIENTID,
      scope: SCOPES,
      callback: "", // defined later
    });
    gisInited = true;
  }

  function handleAuthClick() {
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      await CreatedEvents();
    };
    tokenClient.requestAccessToken({ prompt: "consent" });
  }
  async function CreatedEvents() {
    try {
      var event = {
        summary: "30 Minute Meeting",
        description: `Meeting with ${scheduleMeetingData?.first_name} ${scheduleMeetingData?.last_name}`,
        start: {
          dateTime: moment(scheduleData?.startTime),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        end: {
          dateTime: moment(scheduleData?.startTime).add(30, "minutes"),
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        attendees: [{ email: scheduleMeetingData?.email }],
        reminders: {
          useDefault: false,
          overrides: [{ method: "email", minutes: 24 * 60 }],
        },
        conferenceData: {
          createRequest: {
            requestId: "sample123",
            conferenceSolutionKey: { type: "hangoutsMeet" },
          },
        },
      };
      var request1 = gapi.client.calendar.events.insert({
        calendarId: "primary",
        resource: event,
        conferenceDataVersion: 1,
      });
      request1.execute(function (event) {
        let data = { scheduled_date: scheduleDate };
        setCalendlyPopup(false);
        dispatch(
          scheduleMeetingAction(
            data,
            () => {
              dispatch(getUserStatus(navigate));
            },
            () => {}
          )
        );
      });
    } catch (err) {
      console.log("Error", err);
      return;
    }
  }
  gisLoaded();
  gapiLoaded();
  return (
    <div className="schedule-meeting-wrap">
      <Modal
        open={calendlyPopup}
        visible={calendlyPopup}
        onCancel={() => setCalendlyPopup(false)}
        footer={null}
        width="70%"
        className="schedule-meeting-modal-wrap"
      >
        <h2>Schedule meeting</h2>
        <div className="content">
          <div className="profile-wrap">
            <img
              className="img-pro"
              src={
                scheduleMeetingData?.profile_image
                  ? scheduleMeetingData?.profile_image
                  : PersonImg
              }
              alt="PersonImg"
            />
            <h2>
              {scheduleMeetingData?.first_name} {scheduleMeetingData?.last_name}
            </h2>
            <p className="main-title">sales call</p>
            <p className="time-title">
              <ClockCircleFilled /> 30 min
            </p>
            <p className="time-title">
              <VideoCameraFilled />
              Web conferencing details provided upon conirmation.
            </p>
            <p className="content-details">
              Please find a time that works best for you to learn more about our
              product .
            </p>
          </div>
          <div id="customBtn" />
          <div className="celender-wrap">
            <ScheduleMeeting
              primaryColor="#0060e6"
              borderRadius={1}
              startTimeListStyle="scroll-list"
              availableTimeslots={availableTimeslots}
              className="celender"
              onStartTimeSelect={(data) => {
                onStartTimeSelect(data);
              }}
              lang_selectedButtonText=" Schedule Event"
              lang_confirmButtonText="Confirm"
              lang_cancelButtonText="Cancel"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default CalendlyPopup;
