import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../Assets/images/Onboarding";
import { SaveOnboarding } from "../../redux/actions/onboardingActions";
import { getTokenInLocaleStorage } from "../../Utils/general";

export default function OnboardingHeader({ steps_completed, validateForm }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [saveAndLogout] = useState(false);

  const { payload, Loader } = useSelector((state) => state.onboardingReducer);

  useEffect(() => {
    !getTokenInLocaleStorage() && navigate("/signin");
  }, []);

  useEffect(() => {
    saveAndLogout && dispatch(SaveOnboarding(payload, navigate, "save&Logout"));
  }, [saveAndLogout]);

  return (
    <>
      <div className="header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="logo">
            <img src={IMAGES.iblogo} alt="" />
          </div>
          <div className="logout-btn">
            <Button
              className="next-btn"
              shape="round"
              onClick={() => validateForm("Save&Logout")}
              loading={Loader}
            >
              Save & Logout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
