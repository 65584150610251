import * as React from "react";

function SendIcon(props) {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.487793 20.7731C0.487793 18.0717 0.487793 15.3985 0.487793 12.6939C6.03981 11.9344 11.5938 11.1749 17.1481 10.4154C17.1497 10.3963 17.1508 10.3772 17.1524 10.3585C11.6 9.59902 6.04717 8.83951 0.48818 8.0792C0.48818 5.38653 0.48818 2.71215 0.48818 0C8.35299 3.45775 16.2046 6.90913 24.1144 10.3864C16.2093 13.8616 8.35726 17.3138 0.487793 20.7731Z"
        fill="#E5C243"
      />
    </svg>
  );
}

export default SendIcon;
