import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import IMAGES from "../../Assets/images/Onboarding";
import forbes from "../../Assets/images/Onboarding/forbes.png";
import blackStone from "../../Assets/images/Onboarding/blackstone.png";
import clutch from "../../Assets/images/Onboarding/clutch.png";
import bma from "../../Assets/images/Onboarding/bma.png";
import goodfirms from "../../Assets/images/Onboarding/goodfirms.png";
import investment_strategy_home from "../../Assets/images/home/investment_strategy_home.svg";
import our_stats_one from "../../Assets/images/home/our_stats_one.svg";
import our_stats_two from "../../Assets/images/home/our_stats_two.svg";
import our_stats_three from "../../Assets/images/home/our_stats_three.svg";
import Footer from "../../Components/Footer/Footer";
import RecentWorksCarousel from "../../Components/RecentWorksCarousel/RecentWorksCarousel";
import "./Home.scss";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="Home">
        <div className="banner">
          <Container>
            <div className="banner-grid">
              <div className="banner-set">
                <div className="banner-set--left">
                  <h1>
                    Transform your napkin idea into{" "}
                    <span>a funded, high-growth startup</span>
                  </h1>
                  <p>
                    Our venture studio provides you with the startup expertise
                    to launch your idea successfully.
                  </p>
                  <Link className="getstarted-btn" to="/signup">
                    Get Started
                  </Link>
                </div>
                <div className="banner-set--right">
                  <div>
                    <img className="img-fluid" src={IMAGES.heroImage} alt="" />
                  </div>
                </div>
              </div>
              <div className="mentioned">
                <span>As mentioned in</span>
                <div className="mentioned--logos">
                  <div>
                    <img src={forbes} alt="forbes" />
                  </div>
                  <div>
                    <img src={blackStone} alt="blackStone" />
                  </div>
                  <div>
                    <img src={clutch} alt="clutch" />
                  </div>
                  <div>
                    <img src={bma} alt="bma" />
                  </div>
                  <div>
                    <img src={goodfirms} alt="goodfirms" />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <Container fluid className="p-0">
          <section className="our_process">
            <div className="title">Our Process</div>
            <div className="process">
              <div className="process--left-content">
                <div className="content">
                  <div className="content--number">1</div>
                  <div className="content--title">Strategy </div>
                  <p>
                    We discuss your idea and create a unique roadmap for optimal
                    execution.
                  </p>
                </div>
                <div className="image">
                  <img
                    className="img-fluid"
                    src={IMAGES.strategy}
                    alt="strategy"
                  />
                </div>
              </div>

              <div className="process--right-content">
                <div className="image order-md-first order-last">
                  <img className="img-fluid" src={IMAGES.mvp} alt="mvp" />
                </div>
                <div
                  className="content"
                  xs={{ order: "first" }}
                  md={{ order: "last" }}
                >
                  <div className="content--number">2</div>
                  <div className="content--title">
                    MVP (Minimum Viable Product) Development
                  </div>
                  <p>
                    We design and develop your MVP to get you to market quickly
                    and efficiently.
                  </p>
                </div>
              </div>

              <div className="process--left-content">
                <div className="content">
                  <div className="content--number">3</div>
                  <div className="content--title">Early Traction </div>
                  <p>
                    We utilize the latest digital marketing techniques to help
                    you find early users and revenue.
                  </p>
                </div>
                <div className="image">
                  <img
                    className="img-fluid"
                    src={IMAGES.earlyTraction}
                    alt="earlyTraction"
                  />
                </div>
              </div>

              <div className="process--right-content">
                <div className="image order-md-first order-last">
                  <img
                    className="img-fluid"
                    src={IMAGES.fundraising}
                    alt="fundraising"
                  />
                </div>
                <div className="content">
                  <div className="content--number">4</div>
                  <div className="content--title">Fundraising</div>
                  <p>
                    We introduce you to interested investors within our investor
                    network to help you grow faster.
                  </p>
                </div>
              </div>

              <div className="process--left-content">
                <div className="content">
                  <div className="content--number">5</div>
                  <div className="content--title">Recruitment</div>
                  <p>
                    We utilize our world class hiring methodologies and vast
                    talent database to find you the best talent to create your
                    in-house team.
                  </p>
                </div>
                <div className="image mobile-img-wrap">
                  <img
                    className="img-fluid "
                    src={IMAGES.recruitment}
                    alt="recruitment"
                  />
                </div>
              </div>
            </div>
          </section>
        </Container>

        <section className="our-recent-works-main-wrapper">
          <Container fluid className="heading">
            <Row>
              <Col>
                <div className="title title-2">Our Recent Works</div>
              </Col>
            </Row>
          </Container>
          <div className="our-recent-works">
            <RecentWorksCarousel />
          </div>
        </section>

        <section>
          <Container fluid className="heading">
            <Row>
              <Col>
                <div className="title title-3">Our Investment Strategy</div>
                <div className="title title-3 mobile-text">We invest too</div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="investmentStrategy">
            <Row>
              <Col xl={8} lg={8}>
                <div className="investment-main">
                  <h3>We Invest in Entrepreneurs NOT Ideas</h3>
                  <div className="left">
                    <p>
                      Every year we invest in around{" "}
                      <span>10 Entrepreneurs</span> for equity in their startup.
                    </p>
                    <p>
                      This is based on developing a{" "}
                      <span>Close Relationship</span> while working together in
                      the Strategy and MVP Development phases.
                    </p>
                    <p>
                      Usually we are able to make a <span>Decision</span>{" "}
                      whether to invest by the Early Traction or Fundraising
                      phase. Before that, we expect entreprenuers to invest in
                      themselves.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4}>
                <div className="right">
                  <img
                    className="img-fluid"
                    src={investment_strategy_home}
                    alt="investment_strategy_home"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="our-stats-main-wrapper">
          <Container fluid className="heading">
            <Row>
              <Col>
                <div className="title title-4">Our Stats</div>
              </Col>
            </Row>
          </Container>

          <div className="ourStats">
            <Container className="our-stats-inner">
              <Row>
                <Col lg={4}>
                  <div className="our-stats-set left">
                    <div className="our-stats-image-wrap">
                      <img
                        className="img-fluid"
                        src={our_stats_one}
                        alt="our_stats_one"
                      />
                    </div>
                    <h3>5+</h3>
                    <p>successful exits</p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="our-stats-set left">
                    <div className="our-stats-image-wrap">
                      <img
                        className="img-fluid"
                        src={our_stats_two}
                        alt="our_stats_two"
                      />
                    </div>
                    <h3>$10,000,000+</h3>
                    <p>raised</p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="our-stats-set">
                    <div className="our-stats-image-wrap">
                      <img className="img-fluid" src={our_stats_three} alt="" />
                    </div>
                    <h3>20+</h3>
                    <p>apps featured</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section className="buildwithus-main-wrapper">
          <Container fluid>
            <Row>
              <Col>
                <div className="">
                  <Button
                    variant="warning"
                    size="lg"
                    onClick={() => navigate("/signup")}
                  >
                    Build With Us
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Footer />
      </div>
    </>
  );
};

export default Home;
