import * as React from "react";

function YellowTickIcon(props) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="30" cy="30" r="30" fill="#E5C243" />
      <path
        d="M20.5405 28.1852L27.2973 36.7567L42.1621 23.7837"
        stroke="black"
        strokeWidth="7"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default YellowTickIcon;
