import React, { useEffect } from "react";
import "./Introo.scss";
import "antd/dist/antd.css";
import IMAGES from "../../../Assets/images/Onboarding";
import { useNavigate } from "react-router-dom";
const Introo = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/onboarding/projectType");
    }, 5000);
  }, []);

  return (
    <div className="onboard pb-3">
      <div className="header">
        <div className="d-flex">
          <div className="logo">
            <img src={IMAGES.iblogo} alt="" />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center bodyVh90">
        <div className="onboard-land">
          <div className="onboard-land--image">
            <img className="img-fluid" src={IMAGES.introo} alt="" />
          </div>
          <div className="onboard-land--title">
            <h1>
              Let’s talk about your <span>idea!</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introo;
