import * as React from "react";

function ReferFriendsIcon(props) {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="#E5C243" />
      <g clipPath="url(#clip0_361_1869)">
        <path
          d="M25.0868 16.9848C26.2027 16.9848 27.2579 16.9757 28.313 16.99C28.6795 16.9952 28.9101 17.2557 28.9108 17.6285C28.9154 19.7682 28.9154 21.908 28.9108 24.0477C28.9101 24.4541 28.6553 24.6862 28.2124 24.6914C27.461 24.7005 26.709 24.6823 25.9584 24.7031C25.7813 24.7082 25.5729 24.7815 25.437 24.893C24.1264 25.9651 22.8361 27.0619 21.5241 28.1321C20.7369 28.7751 19.6301 28.464 19.3119 27.5364C19.1989 27.2071 19.2264 26.8973 19.4485 26.3126C19.1898 26.5213 18.999 26.6821 18.8017 26.8337C18.2653 27.246 17.6244 27.2693 17.1063 26.9005C16.5823 26.5271 16.3909 25.8478 16.6424 25.254C16.679 25.1672 16.7188 25.0816 16.7816 24.9403C16.1093 25.1562 15.5245 25.0978 15.0829 24.5676C14.6386 24.0341 14.6824 23.4565 15.0195 22.8563C14.8098 22.8932 14.6242 22.9451 14.4374 22.9548C13.835 22.9872 13.3136 22.6573 13.0902 22.1193C12.87 21.5903 12.983 20.994 13.426 20.6063C14.0709 20.0418 14.7353 19.4992 15.4004 18.9579C15.4821 18.8912 15.6193 18.8652 15.7303 18.8646C16.939 18.8581 18.1477 18.8555 19.3564 18.8678C19.4903 18.8691 19.6523 18.9417 19.7503 19.0338C20.2005 19.4571 20.6317 19.9011 21.072 20.3347C22.0658 21.3129 23.5136 21.4263 24.5746 20.6115C25.6252 19.8045 25.8839 18.394 25.2005 17.2045C25.1704 17.1507 25.1436 17.0943 25.0868 16.9848Z"
          fill="black"
        />
        <path
          d="M14.0387 25.0253C13.6075 25.1077 13.2468 25.048 12.9391 24.7038C12.6 24.3246 12.2302 23.9701 11.8532 23.6278C11.7389 23.5241 11.556 23.445 11.4024 23.4424C10.5315 23.4249 9.65997 23.4359 8.78907 23.4333C8.21217 23.4314 8.00114 23.2207 8.00049 22.6516C7.99984 20.6084 7.99984 18.5659 8.00049 16.5227C8.00049 15.9264 8.19519 15.7371 8.80867 15.7371C10.2571 15.7364 11.7056 15.7436 13.154 15.728C13.3252 15.7261 13.5343 15.6405 13.6597 15.5232C14.3771 14.8555 15.2114 14.5081 16.1992 14.5139C17.3864 14.5204 18.5735 14.5217 19.7606 14.5133C20.4989 14.5081 21.1195 14.7628 21.6383 15.2788C22.3923 16.0281 23.1514 16.7729 23.9008 17.5268C24.494 18.1232 24.5163 18.9769 23.9655 19.5291C23.4173 20.0788 22.5419 20.0626 21.9434 19.4812C21.4142 18.9671 20.9105 18.4265 20.3623 17.9332C20.1872 17.7751 19.9089 17.6526 19.675 17.6487C18.2599 17.6227 16.8434 17.6305 15.4283 17.6428C15.282 17.6441 15.1095 17.7148 14.9945 17.8075C14.2935 18.3734 13.6016 18.9503 12.9143 19.533C11.3691 20.8444 11.6768 23.082 13.5134 23.937C13.6016 23.9778 13.7009 24.0556 13.7349 24.1399C13.8466 24.4173 13.9315 24.7064 14.0387 25.0253Z"
          fill="black"
        />
        <path
          d="M15.6508 13.3212C15.4358 13.2058 15.262 13.1533 15.1451 13.0425C14.612 12.5349 14.0912 12.0144 13.5745 11.4907C13.2968 11.2093 13.287 10.8405 13.53 10.5961C13.7744 10.3504 14.1507 10.3543 14.4316 10.6272C14.9602 11.14 15.4783 11.6624 16.0003 12.181C16.2055 12.3852 16.2884 12.6425 16.1414 12.8863C16.0375 13.0587 15.8291 13.1695 15.6508 13.3212Z"
          fill="black"
        />
        <path
          d="M23.0157 10.3643C23.1653 10.4822 23.3933 10.5782 23.4672 10.7428C23.5423 10.9107 23.5501 11.2205 23.443 11.3385C22.8752 11.9608 22.2722 12.5526 21.6587 13.1315C21.4503 13.3285 21.0851 13.2683 20.8845 13.0686C20.6702 12.856 20.6238 12.4962 20.8381 12.2739C21.4085 11.6821 21.9978 11.1078 22.5917 10.5386C22.676 10.457 22.8236 10.4401 23.0157 10.3643Z"
          fill="black"
        />
        <path
          d="M19.0633 10.5943C19.0633 10.9294 19.0724 11.2645 19.0607 11.599C19.0476 11.9711 18.7856 12.2219 18.4426 12.2135C18.1114 12.2057 17.8592 11.962 17.8527 11.6036C17.8415 10.9339 17.8409 10.2637 17.854 9.59409C17.8611 9.23692 18.1186 9.00033 18.4537 8.99903C18.7909 8.99773 19.0437 9.23239 19.0587 9.5889C19.0731 9.92338 19.062 10.2585 19.062 10.5936C19.0626 10.5943 19.0633 10.5943 19.0633 10.5943Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_361_1869">
          <rect
            width="20.914"
            height="19.4717"
            fill="white"
            transform="translate(8 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReferFriendsIcon;
