import {
  SET_ONBOARDING_DATA,
  SET_LOADER,
  LOGOUT,
  GET_ONBOARD_DATA_INIT,
  GET_ONBOARD_DATA_SUCCESS,
  GET_ONBOARD_DATA_FAIL,
} from "../actions/actionTypes";

const initialState = {
  Loader: false,
  Errors: "",
  getOnboardDataLoading: false,
  payload: {
    ProjectType: null,
    projectName: null,
    ProjectDisc: null,
    selectedCategories: null,
    otherCategory: null,
    projectPhase: null,
    otherPhase: null,
    developmentStage: null,
    documentURL: [],
    documentUploadURL: null,
    selectedFeatures: [],
    otherFeature: null,
    fixed_budget: null,
    total_budget: null,
    monthly_budget: null,
    starting_option: null,
    targetDate: null,
    appLive: null,
  },
};

export const onboardingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (action.type) {
    case SET_ONBOARDING_DATA: {
      return {
        ...state,
        payload,
      };
    }
    case SET_LOADER: {
      return {
        ...state,
        Loader: !state.Loader,
      };
    }
    case LOGOUT: {
      return {
        ...initialState,
      };
    }
    case GET_ONBOARD_DATA_INIT: {
      return {
        ...state,
        getOnboardDataLoading: true,
      };
    }
    case GET_ONBOARD_DATA_SUCCESS: {
      return {
        ...state,
        getOnboardDataLoading: false,
      };
    }
    case GET_ONBOARD_DATA_FAIL: {
      return {
        ...state,
        getOnboardDataLoading: false,
      };
    }
    default:
      return state;
  }
};
